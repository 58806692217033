import { yupResolver } from '@hookform/resolvers/yup';
import { viacepRequest } from 'components/util/entity-utils';
import { useApiRequest, useUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading } from 'components/Loading';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';
import { LocalAtendimentoType } from '../pages';
import { cepMask, onlyNumber } from 'shared/util';
import { Box, Flex, Select } from '@chakra-ui/react';

const MySwal = withReactContent(Swal);

interface IPropsModalLocalAtendimento {
  showModalForm: boolean;
  toggle: Function;
  localAtendimentoSelected?: LocalAtendimentoType | null;
  atualizarDados: Function;
}

type FilaAtendimento = {
  sgInstSaude?: string;
  faCodigo: number;
  faDescricao: string;
};

const requiredFieldsMessage = 'Campo obrigatório';
const schema = Yup.object().shape<
  Partial<Record<keyof LocalAtendimentoType, Yup.AnySchema>>
>({
  laNome: Yup.string().required(requiredFieldsMessage),
  sgInstSaude: Yup.string().required(requiredFieldsMessage),
  laCEP: Yup.string()
    .min(8, 'Formato inválido')
    .required(requiredFieldsMessage)
    .matches(/^[0-9]{5}-[0-9]{3}$/, 'Formato inválido'),
  laEndereco: Yup.string().required(requiredFieldsMessage),
  laNumero: Yup.string().required(requiredFieldsMessage),
  laComplemento: Yup.string().nullable(),
  laBairro: Yup.string().required(requiredFieldsMessage),
  laCidade: Yup.string().required(requiredFieldsMessage),
  laUF: Yup.string().required(requiredFieldsMessage),
});

export default function ModalLocalAtendimento({
  showModalForm,
  toggle,
  localAtendimentoSelected,
  atualizarDados,
}: IPropsModalLocalAtendimento) {
  const usuarioLogado = useUser();
  const [instLocal, setInstLocal] = useState('');
  const isEditing = localAtendimentoSelected ? true : false;
  const filaAtendimentoAPI = useApiRequest<FilaAtendimento[]>(
    'aswfilasatendimento',
    []
  );
  // const [instituicao, setInstituicao] = useState('');
  const [filaAtendimentoSelected, setFilaAtendimentoSelected] = useState<
    FilaAtendimento[]
  >([]);

  const filaAtendimentoList = filaAtendimentoAPI.state.filter(
    (filaAtendimento) =>
      filaAtendimentoSelected.every(
        (i) => i.faCodigo !== filaAtendimento.faCodigo
      )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOpenModal = () => {
    if (isEditing) {
      setValue('laNome', localAtendimentoSelected.laNome);
      setValue('laCEP', cepMask(localAtendimentoSelected.laCEP));
      setValue('laEndereco', localAtendimentoSelected.laEndereco);
      setValue('laCidade', localAtendimentoSelected.laCidade);
      setValue('laNumero', localAtendimentoSelected.laNumero);
      setValue('laComplemento', localAtendimentoSelected.laComplemento);
      setValue('laBairro', localAtendimentoSelected.laBairro);
      setValue('laUF', localAtendimentoSelected.laUF);
      setValue('dtInclusao', localAtendimentoSelected.dtInclusao);
      setValue('laTriagem', localAtendimentoSelected.laTriagem);
      setValue('sgInstSaude', localAtendimentoSelected.sgInstSaude);
    }
  };

  const handleCloseModal = () => {
    setFilaAtendimentoSelected([]);
    reset();
  };

  const selecionaFA = (filaAtendimento: FilaAtendimento) => {
    setFilaAtendimentoSelected((stateAntigo) => {
      if (stateAntigo.every((s) => s.faCodigo !== filaAtendimento.faCodigo)) {
        return [...stateAntigo, filaAtendimento];
      }
      return stateAntigo;
    });
  };

  const removeFA = (filaAtendimento: FilaAtendimento) => {
    setFilaAtendimentoSelected((stateAntigo) => {
      return stateAntigo.filter((i) => i.faCodigo !== filaAtendimento.faCodigo);
    });
  };

  const formValues = (data: LocalAtendimentoType) => {
    setIsLoading(true);
    api
      .post('aswagendalocaisatendimento', {
        method: isEditing
          ? 'atualizarAgendaLocalAtendimento'
          : 'criarAgendaLocalAtendimento',
        sgCliente: usuarioLogado.sgCliente,
        sgInstSaude: data.sgInstSaude,
        laNome: data.laNome,
        laDescricao: isEditing ? localAtendimentoSelected.laDescricao : '',
        laCEP: data.laCEP,
        laEndereco: data.laEndereco,
        laNumero: data.laNumero,
        laCidade: data.laCidade,
        laUF: data.laUF,
        laBairro: data.laBairro,
        laComplemento: data.laComplemento,
        laTriagem: data.laTriagem,
        dtInclusao: data.dtInclusao,
        filaAtendimento: filaAtendimentoSelected,
      })
      .then(({ data }) => {
        if (data.success) {
          MySwal.fire({
            title: isEditing
              ? 'Local de Atendimento alterado'
              : 'Local de Atendimento cadastrado',
            html: 'Salvo com sucesso',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
          atualizarDados();
          toggle();
        } else {
          MySwal.fire({
            title: isEditing ? 'Erro ao alterar' : 'Erro ao cadastrar',
            html: isEditing
              ? 'Não foi possível alterar o Local de Atendimento'
              : 'Não foi possível cadastrar o Local de Atendimento',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const listarFilasAtendimento = () => {
    filaAtendimentoAPI
      .request({
        method: 'listarFilaAtendimento',
        sgCliente: usuarioLogado.sgCliente,
        sgInstSaude: isEditing
          ? localAtendimentoSelected.sgInstSaude
          : instLocal,
      })
      .then(() => {
        if (
          isEditing &&
          Array.isArray(localAtendimentoSelected?.filaAtendimento) &&
          localAtendimentoSelected?.filaAtendimento.length > 0
        ) {
          const filaAtendimentoSelected: FilaAtendimento[] =
            localAtendimentoSelected?.filaAtendimento.map((i) => {
              return {
                faCodigo: i.faCodigo,
                faDescricao: i.faDescricao,
              };
            });
          setFilaAtendimentoSelected(filaAtendimentoSelected);
        }
      });
  };

  useEffect(() => {
    console.log('useEffect agindo')
    setFilaAtendimentoSelected([]);
    if (usuarioLogado.sgCliente) {
      listarFilasAtendimento();
    }
  }, [instLocal, usuarioLogado]);

  return (
    <Modal
      size={'xl'}
      isOpen={showModalForm}
      onClosed={handleCloseModal}
      onOpened={handleOpenModal}
      toggle={() => toggle()}
    >
      <ModalHeader toggle={() => toggle()}></ModalHeader>
      <form onSubmit={handleSubmit(formValues)}>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md="11">
              <Row>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="localAtendimentoLabel"
                    for="cadastro-la-local-atendimento"
                  >
                    Local de Atendimento
                  </Label>
                  <input
                    {...register('laNome')}
                    id="cadastro-la-local-atendimento"
                    type="text"
                    placeholder="Local de Atendimento"
                    name="laNome"
                    className={'form-control'}
                    maxLength={200}
                  />
                  {errors?.laNome?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laNome?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="cepLabel"
                    for="cadastro-la-cep"
                  >
                    CEP
                  </Label>
                  <input
                    {...register('laCEP')}
                    name="laCEP"
                    // mask="99999-999"
                    id="cadastro-la-cep"
                    placeholder="CEP"
                    className={'form-control'}
                    maxLength={9}
                    // onChange={(event) => {
                    //   const { value } = event.target;
                    //   event.persist();
                    //   setValue('laCEP', value);
                    // }}
                    onKeyUp={(inputVal) => {
                      setValue('laCEP', cepMask(inputVal.currentTarget?.value));
                      if (
                        onlyNumber(inputVal.currentTarget?.value).length === 8
                      ) {
                        viacepRequest(
                          inputVal.currentTarget.value,
                          (cepRequest: any) => {
                            if (cepRequest !== null) {
                              setValue(
                                'laEndereco',
                                cepRequest.cepRequestLogradouro
                              );
                              setValue('laBairro', cepRequest.cepRequestBairro);
                              setValue(
                                'laCidade',
                                cepRequest.cepRequestLocalidade
                              );
                              setValue('laUF', cepRequest.cepRequestUf);
                            } else {
                              // validarCEP(inputVal.currentTarget.value);
                            }
                          }
                        );
                      } else {
                        setValue('laEndereco', '');
                        setValue('laBairro', '');
                        setValue('laCidade', '');
                        setValue('laUF', '');
                      }
                    }}
                  />
                  {errors?.laCEP?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laCEP?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="enderecoLabel"
                    for="cadastro-la-endereco"
                  >
                    Endereço
                  </Label>
                  <input
                    readOnly={false}
                    {...register('laEndereco')}
                    id="cadastro-la-endereco"
                    type="text"
                    name="laEndereco"
                    placeholder="Endereço"
                    className={'form-control'}
                    maxLength={200}
                  />
                  {errors?.laEndereco?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laEndereco?.message}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="numeroLabel"
                    for="cadastro-la-numero"
                  >
                    Número
                  </Label>
                  <input
                    {...register('laNumero')}
                    id="cadastro-la-numero"
                    type="number"
                    name="laNumero"
                    placeholder="Número"
                    className={'form-control'}
                  />
                  {errors?.laNumero?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laNumero?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="complementoLabel"
                    for="cadastro-la-cidade"
                  >
                    Complemento
                  </Label>
                  <input
                    readOnly={false}
                    {...register('laComplemento')}
                    id="cadastro-la-complemento"
                    type="text"
                    name="laComplemento"
                    placeholder="Complemento"
                    className={'form-control'}
                    maxLength={100}
                  />
                  {errors?.laComplemento?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laComplemento?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="bairroLabel"
                    for="cadastro-la-bairro"
                  >
                    Bairro
                  </Label>
                  <input
                    readOnly={false}
                    {...register('laBairro')}
                    id="cadastro-la-bairro"
                    type="text"
                    name="laBairro"
                    placeholder="Bairro"
                    className={'form-control'}
                    maxLength={100}
                  />
                  {errors?.laBairro?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laBairro?.message}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="cidadeLabel"
                    for="cadastro-la-cidade"
                  >
                    Cidade
                  </Label>
                  <input
                    {...register('laCidade')}
                    id="cadastro-la-cidade"
                    type="text"
                    name="laCidade"
                    placeholder="Cidade"
                    className={'form-control'}
                    maxLength={100}
                  />
                  {errors?.laCidade?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laCidade?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Label
                    className="mt-4 label-single-line"
                    id="ufLabel"
                    for="cadastro-la-uf"
                  >
                    UF
                  </Label>
                  <input
                    maxLength={2}
                    readOnly={false}
                    {...register('laUF')}
                    id="cadastro-la-uf"
                    type="text"
                    name="laUF"
                    placeholder="UF"
                    className={'form-control'}
                  />
                  {errors?.laUF?.message && (
                    <div className="text-warning pt-2">
                      {errors?.laUF?.message}
                    </div>
                  )}
                </Col>
                <Col md="4">
                  <Box marginTop={'6'}>
                    <Label
                      className="mt-4 label-single-line"
                      for="cadastro-la-triagem"
                      check
                    >
                      <input
                        type="checkbox"
                        {...register('laTriagem')}
                        name="laTriagem"
                        id="cadastro-la-triagem"
                        className="form-check-input"
                      />{' '}
                      Triagem
                    </Label>
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <Box marginTop={'4'}>
                    <Label>Instituição de saúde*</Label>
                    <Select
                      disabled={isEditing ? true : false}
                      {...register('sgInstSaude')}
                      name={'sgInstSaude'}
                      maxW="60rem"
                      className="form-control"
                      placeholder={'Selecione a instituição de saúde'}
                      onChange={(e) => setInstLocal(e.target.value)}
                    >
                      {(usuarioLogado?.institucoes || []).map((item, index) => {
                        return (
                          <option key={index} value={item.institucao}>
                            {item.institucaoNome}
                          </option>
                        );
                      })}
                    </Select>
                    {errors?.sgInstSaude?.message && (
                      <div className="text-warning pt-2">
                        {errors?.sgInstSaude?.message}
                      </div>
                    )}
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="12">
                  <Label className="mt-4 label-single-line">
                    Filas de Atendimento
                  </Label>
                  {filaAtendimentoAPI.isLoading && (
                    <>
                      &nbsp;
                      <i className="fas fa-spinner fa-spin fa-1x"></i>
                    </>
                  )}
                  <div className="kn-unselected_status__status___">
                    Disponíveis
                  </div>
                  <div className={`kn-list__list___22Wuc`}>
                    {filaAtendimentoList
                      .map((item, key) => (
                        <div
                          onClick={() => {
                            selecionaFA(item);
                          }}
                          key={key}
                          className="kn-item__item___kQ2Ll kn-item__selected___3tRL6"
                        >
                          <div className="kn-item_label__label___2fJ5p">
                            {item.faDescricao}
                          </div>
                        </div>
                      ))}
                  </div>
                </Col>
                <Col md="6" sm="12">
                  <Label className="mt-4 label-single-line">
                    {/* Filas de Atendimento* */}
                  </Label>
                  <div
                    className="kn-unselected_status__status___"
                    style={{ marginTop: 16 }}
                  >
                    Selecionado*
                  </div>
                  <div className={`kn-list__list___22Wuc`}>
                    <>
                      <Col md="12">
                        {filaAtendimentoSelected.map((filaAtendimento, key) => {
                          return (
                            <div key={key}>
                              <Row>
                                <div className="mt-2">
                                  <span
                                    style={{
                                      color: colors.blue,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {filaAtendimento.faDescricao}
                                  </span>
                                  <Button
                                    onClick={() => {
                                      removeFA(filaAtendimento);
                                    }}
                                    className="btn-sm"
                                    color="warning"
                                    style={{ float: 'right' }}
                                  >
                                    X
                                  </Button>
                                </div>
                              </Row>
                            </div>
                          );
                        })}
                      </Col>
                    </>
                  </div>
                  {/* {formError && !instsSelected.length && (
                    <span className="text-warning">
                      <small>Selecione instituição de saúde</small>
                    </span>
                  )} */}
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="float-right"
            id="save-entity"
            type="submit"
          >
            {isEditing
              ? 'Confirmar Alterações'
              : 'Cadastrar Local de Atendimento'}
          </Button>
        </ModalFooter>
        {isLoading && <Loading />}
      </form>
    </Modal>
  );
}
