import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

type Props = {
    close: (value: boolean) => void;
    confirmar: () => void;
};

export const ConfirmarExclusaoModal = (props: Props) => {
    return (
        <Modal
            isOpen={true}
            size={'sm'}
            toggle={() => props.close(false)}
        >
            <ModalHeader>
            </ModalHeader>
            <ModalBody>
                <Row className="justify-content-center mx-3">
                    <span className="page-header ml-3">
                        Tem certeza que deseja apagar esse dado?
                    </span>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => props.close(false)}>Cancelar</Button>
                <Button color="btn btn-primary" onClick={() => {
                    props.confirmar();
                    props.close(false);
                }}>Sim</Button>
            </ModalFooter>
        </Modal>
    )
}