import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Label, Row, Table } from 'reactstrap';

import { useAlert, useApiRequest, useUser } from '../../hooks/index';
import { Modaltrigem } from '../../pages/atendimento/components/modal-triagem/index';
import { api } from '../../services/api';
import description from './assets/description.svg';

import './style.css';

const ButtonHistory = (props: any) => {
  const { setShowHistory, user, item } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const alert = useAlert();

  const fnSearchButton = async () => {
    setLoading(true);
    try {
      const { data } = await api.post('aswhistusuario', {
        method: '_list',
        sgCliente: user?.sgCliente,
        psCpf: user?.cpf,
        usuarioCPF: item.usuarioCPF,
      });
      if (!data.data.length) {
        alert.warning({
          title: 'Não existe histórico de atendimento para esse paciente',
        });
      }
      setShowHistory(data.data[0]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button onClick={fnSearchButton}>
      {loading ? (
        <i
          style={{ color: '#56a1e1', height: '22px' }}
          className="fas fa-spinner fa-spin"
        ></i>
      ) : (
        <img style={{height: "19px"}} src={description} alt="Description" />
      )}
    </button>
  );
};

function ConsultaAgendamentos() {
  const [startDate, setStartDate] = useState<Date>();
  const [searchInput, setSearchInput] = useState('');
  const [showHistory, setShowHistory] = useState(null);
  const [error, setError] = useState<String>();
  const user = useUser();

  const cols = {
    attendanceDay: 'Data de atendimento',
    hour: 'Horário',
    usuario: 'Paciente',
    action: '',
  };

  const consultaAgenda = useApiRequest<any[]>('aswagendausuprofsaude', []);

  async function fnsearchSchedule() {
    if (!startDate) {
      return setError('Data obrigatória');
    } else if (consultaAgenda.isLoading) {
      return;
    }
    setError('');
    const data = {
      method: '_listarConsultaAgendamentos',
      psCPF: user?.cpf,
      sgCliente: user?.sgCliente,
      sgInstSaude: 'techtools',
      ...(searchInput ? { search: searchInput } : { search: '' }),
      ...(startDate && {
        dataInicial: dayjs(startDate).format('YYYY-MM-DD'),
      }),
    };

    await consultaAgenda.request(data);
  }

  function fnSearchDate(date: Date) {
    setStartDate(date);
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>CONSULTA DE AGENDAMENTOS</h6>
        </div>
      </div>
      <div></div>
      <form action="">
        <Row className="__container-input">
          <Col xl="9" xs="12" md="9" className="__parcel-input">
            <label className="__label-agendamento" htmlFor="schedulingSearch">
              Buscar pelo paciente
            </label>
            <div className="input-group mb-3">
              <input
                aria-describedby="search-input"
                name="search"
                id="schedulingSearch"
                placeholder="CPF ou Usuário"
                type="text"
                className="form-control"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <div
                role={consultaAgenda.isLoading ? 'button' : ''}
                onClick={() => fnsearchSchedule()}
                className="input-group-append"
              >
                <span
                  className="input-group-text"
                  id="search-input"
                  style={{
                    borderRadius: '0px 10px 10px 0px',
                    cursor: 'pointer',
                  }}
                >
                  {consultaAgenda.isLoading ? (
                    <>
                      <i
                        style={{ padding: '4px', color: '#56a1e1' }}
                        className="fas fa-spinner fa-spin"
                      ></i>
                    </>
                  ) : (
                    <i
                      style={{ padding: '4px', color: '#56a1e1' }}
                      className="fa fa-search"
                      color="warning"
                    />
                  )}
                </span>
              </div>
            </div>
          </Col>
          <Col xs="12" md="3" className="__parcel-input">
            <label
              className="__label-agendamento"
              htmlFor="schedulingSearchDate"
            >
              Data de pesquisa
            </label>
            <ReactDatePicker
              name="dataInicial"
              required
              id="schedulingSearchDate"
              className="form-control"
              placeholderText="Data Inicial"
              selected={startDate}
              onChange={(date: Date) => {
                fnSearchDate(date);
              }}
              value={startDate as unknown as string}
              dateFormat="dd/MM/yyyy"
            />
            {error ? (
              <p className="__text-error text-warning">
                Data inicial obrigatória.
              </p>
            ) : null}
          </Col>
        </Row>
      </form>
      <div className="__table-agendamento">
        {consultaAgenda.state.length === 0 ? (
          <div
            style={{ width: '100%', textAlign: 'center', marginBottom: '0' }}
            className="alert alert-warning"
          >
            Nada encontrado
          </div>
        ) : (
          <table
            style={{
              width: '100%',
              borderRadius: '10px',
              minWidth: '510px',
            }}
          >
            <thead>
              <tr className="__tr-agendamento-head">
                {Object.values(cols).map((collumn) => {
                  return (
                    <td key={collumn}>
                      <strong>{collumn}</strong>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody className="__billing-management-table-body">
              {consultaAgenda?.state?.map((item: any, index: Number) => {
                const data = {
                  attendanceDay: item.agendamento
                    .substring(0, 10)
                    .split('-')
                    .reverse()
                    .join('/'),
                  hour: item.agendamento.substring(11),
                  usuario: item.usuario,
                  action: '',
                };
                return (
                  <tr className="__tr-agendamento" key={`cols-${index}`}>
                    {Object.keys(cols).map((collumn, i) => {
                      return collumn === 'action' ? (
                        <td
                          style={{ minWidth: '20px' }}
                          key={`td-${Math.random()}`}
                        >
                          <ButtonHistory key={i} {...{ setShowHistory, user, item }}/>
                        </td>
                      ) : data[collumn] === 'Realizado' ? (
                        <td key={`td-${Math.random()}`}>
                          {data[collumn] === 'Realizado'}
                          <p className="__td-status">{data[collumn]}</p>
                        </td>
                      ) : (
                        <td key={`td-${Math.random()}`}>{data[collumn]}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {showHistory != null && (
        <Modaltrigem
          eHistorico
          idConsulta={showHistory.agIdConsulta}
          nomeUsuario={showHistory?.pacienteNome}
          usuarioNomeSocial={showHistory?.usuarioNomeSocial}
          close={() => {
            setShowHistory(null);
          }}
        />
      )}
    </>
  );
}

export default ConsultaAgendamentos;
