import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  useDisclosure
} from '@chakra-ui/react';
import { ConfirmarExclusaoModal } from 'components/confirmar-exclusao';
import { useEffect, useState } from 'react';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Link as ReactLink,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import { Input } from '../../../components/crud/Input';
import { Table } from '../../../components/crud/Table';
import {
  ActionComponents,
  HandleActionProps, ItemOrdem,
  ordenarProps, TableParamsProps
} from '../../../components/crud/Table/types';
import { ModalCrud } from './components/modal-crud';
import { IAlternativaDTO } from './dtos/IGestaoAlternativa.dto';

const GestaoAlternativas = () => {
  const { qsId, qpId } = useParams<any>();
  const search = useLocation().search;
  const slugQuestao = new URLSearchParams(search).get('slugQuestao');
  const slugPergunta = new URLSearchParams(search).get('slugPergunta');
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [originParams, setOriginParams] = useState(null);
  const [alternativaList, setAlternativaList] = useState<IAlternativaDTO[]>([]);
  const [showDeletarModal, setShowDeletarModal] = useState<boolean>(false);
  const [dadosDelete, setDadosDelete] = useState<IAlternativaDTO>();
  const [inputFilter, setInputFilter] = useState('');
  const [ultima, setUltima] = useState<number>();
  const [ordemList, setOrdemList] = useState<ItemOrdem[]>([]);

  const tableParams: TableParamsProps[] = [
    {
      header: 'Ordem',
      key: 'qrOrdem',
      headerWidth: '8rem',
    },
    {
      header: 'Alternativa',
      key: 'qrTexto',
    },
  ];

  const actionComponents: ActionComponents[] = [

    {
      component: FiEdit,
      type: 'icon',
      action: 'icon-edit',
      header: 'Editar',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: BsTrash,
      type: 'icon',
      action: 'icon-remover-alternativa',
      header: 'Remover',
      props: {
        size: 25,
        color: colors.gray_2,
      },
    },
  ];
  const alterarOrdem = async (props: ordenarProps) => {
    let answers = [];
    filterRespostas.map((e) => {
      if (e.qrID == props.id) {
        answers.push({
          qsID: qsId,
          qpID: qpId,
          qrID: e.qrID,
          qrOrdem: props.novaOrdem,
          qrPergAntecessora: props.novaOrdem - 1,
          qrPergSucessora: props.novaOrdem + 1,
          qrTexto: e.qrTexto,
          qrRespSelecionada: e.qrRespSelecionada,
        });
      } else {
        answers.push({
          qsID: qsId,
          qpID: qpId,
          qrID: e.qrID,
          qrOrdem: props.novaOrdem == e.qrOrdem ? props.ordemSubstituida : e.qrOrdem,
          qrPergAntecessora: props.novaOrdem == e.qrOrdem ? props.ordemSubstituida - 1 : e.qrPergAntecessora,
          qrPergSucessora: props.novaOrdem == e.qrOrdem ? props.ordemSubstituida + 1 : e.qrPergSucessora,
          qrTexto: e.qrTexto,
          qrRespSelecionada: e.qrRespSelecionada,
        });
      }
    });
    const { data } = await api.post('aswquestionario', {
      method: "updateAnswer",
      answers
    });

    if (data.success == true) {
      handleGetAlternativa();
      MySwal.fire({
        title: data?.message || 'Editado com sucesso!',
        icon: 'success',
      });
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar editar!',
        icon: 'error',
      });
    }
  }

  const handlerAction = async (props: HandleActionProps<IAlternativaDTO>) => {
    if (props.action === 'icon-edit') {
      setOriginParams(props.dataLn);
      onOpen();
    }
    if (props.action === "icon-remover-alternativa") {
      setDadosDelete(props.dataLn);
      setShowDeletarModal(true);
    }
  };

  const deletarAlternativa = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'deleteAnswer',
      qsID: dadosDelete.qsID,
      qpID: dadosDelete.qpID,
      qrID: dadosDelete.qrID
    })
    if (data.success == true) {
      handleGetAlternativa();
      MySwal.fire({
        title: data?.message || 'Apagado com sucesso!',
        icon: 'success',
      });
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar apagar!',
        icon: 'error',
      });
    }
  }


  const handleGetAlternativa = async () => {
    setIsLoading(true);
    api.post('aswquestionario', {
      method: "listAnswer",
      qsID: qsId,
      qpID: qpId
    }).then(({ data }) => {

      data.data.sort(function (a, b) {
        if (a.qrOrdem > b.qrOrdem) {
          return 1;
        }
        if (a.qrOrdem < b.qrOrdem) {
          return -1;
        }
        return 0;
      });
      setAlternativaList(data.data);
      let maior = 0;
      data.data?.map((e) => {
        if (e.qrOrdem > maior) {
          maior = e.qrOrdem;
        }
      })
      let ordemData = [];
      for (let index = 1; index < maior + 1; index++) {
        ordemData.push({
          value: index,
          key: index
        });
      }
      setOrdemList(ordemData)
      setUltima(maior);
      setIsLoading(false);
    })
  };

  const filterRespostas =
    inputFilter.length > 0
      ? alternativaList?.filter((alternativa) =>
        alternativa.qrTexto?.includes(inputFilter)
      )
      : alternativaList;

  useEffect(() => {
    handleGetAlternativa();
  }, []);

  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <ModalCrud
        dados={filterRespostas}
        isOpen={isOpen}
        onClose={onClose}
        configParams={{ qsId, qpId, ultima }}
        originParams={originParams}
        handlerChange={async () => {
          handleGetAlternativa();
        }}
      />
      <Breadcrumb spacing="8px" separator={<IoIosArrowForward />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={ReactLink} to="/gestao-questionarios">
            Questionarios
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>{slugQuestao}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={ReactLink}
            to={`/gestao-perguntas/${qsId}?slugQuestao=${slugQuestao}`}
          >
            Pergunta
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>{slugPergunta}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Alternativas</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider color={colors.gray_blue} />
      <Flex gap="2rem" justifyContent="space-between" flexDirection="row">
        <Heading as="h3" size="md">
          Alternativa:
        </Heading>
        <Button
          variant="outline"
          border="1px"
          color={colors.blue}
          borderColor={colors.blue}
          _hover={{ bg: colors.blue, color: '#fff' }}
          _active={{ bg: colors.textBlue }}
          type="submit"
          onClick={() => {
            setOriginParams(null);
            onOpen();
          }}
        >
          Cadastrar alternativa
        </Button>
      </Flex>
      <Flex gap="1rem" justifyContent="space-between" flexDirection="column">
        <Input
          icon={{ icon: BsSearch, size: 20, color: colors.blue }}
          queryDeelay={1000}
          placeholder="Digite sua pesquisa aqui"
          handlerChange={(e) => setInputFilter(e)}
        />
      </Flex>
      <Table
        ordem={(props: ordenarProps) => alterarOrdem(props)}
        ordemData={ordemList}
        isLoading={isLoading}
        data={filterRespostas}
        tableParams={tableParams}
        actionComponents={actionComponents}
        handlerAction={handlerAction}
      />

      {showDeletarModal &&
        <ConfirmarExclusaoModal
          close={(value: boolean) => {
            setShowDeletarModal(value);
          }}
          confirmar={() => {
            deletarAlternativa();
          }}
        />

      }
    </Flex>
  );
};

export { GestaoAlternativas };

