/* eslint-disable no-console */

// @ts-nocheck

import cookie from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css';
import SelectAsync from 'react-select/async';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  AUTH_TOKEN_KEY,
  AUTH_USER_DATA,
  BASE_API_VERSION_PATH,
} from 'shared/util';
import {
  AppProps,
  getFormFromSelect,
  getListAxios,
  GetServerSideProps,
  jsonParse,
  nextCookie,
  showFieldsSelectAsync,
  unique,
  useRouter,
} from '../../../components/util/entity-utils';
import {
  Translate,
  translate,
  useTranslation,
} from '../../../components/util/translate-component';

const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false;

const MySwal = withReactContent(Swal);

export interface IGestaoDeAgendaProps extends AppProps {
  instituicaoId?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  gestaoDeAgendaList?: any;
  setGestaoDeAgendaList?: Function;
  gestaoDeAgendaEntity?: any;
  setGestaoDeAgendaEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  // renderFilter?: Function; antigo select de instituição
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getGestaoDeAgendaState?: Function;
  returnFunction?: Function;
  cadastroInstituicaos?: any;
  profissionalSaudes?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'api/gestao-de-agenda';

const getEntityFetch: any = async (
  context: IGestaoDeAgendaProps,
  entity: any,
  selectFields = ''
) => {
  const requestUrl = `${apiUrl}/${entity.id}`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
    },
  });
  const json = await res.json();
  context.setGestaoDeAgendaEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IGestaoDeAgendaProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(apiUrl, {
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IGestaoDeAgendaProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(`${apiUrl}`, {
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IGestaoDeAgendaProps,
  _gestaoDeAgendaEntity: any,
  listFiltersPage: any
) => {
  await fetch(`${apiUrl}/${_gestaoDeAgendaEntity.id}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (context: IGestaoDeAgendaProps, offset = null) => {
  return (
    '' +
    (context.state.instituicaoId
      ? 'instituicao=' +
        context.state.instituicaoId
          .map((v: any) => v.value + '<->' + v.label)
          .join(',') +
        '&'
      : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = /*  async */ (
  context: IGestaoDeAgendaProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const { instituicaoId, extraFilters, activePage, itemsPerPage, sort, order } =
    context.state;

  // const res = await context.getEntitiesFetch(
  //   instituicaoId,
  //   typeof _fieldsBase.extraFilters !== undefined ? _fieldsBase.extraFilters : extraFilters,
  //   typeof _fieldsBase.activePage !== undefined ? _fieldsBase.activePage : context.state.activePage,
  //   typeof _fieldsBase.itemsPerPage !== undefined ? _fieldsBase.itemsPerPage : context.state.itemsPerPage,
  //   `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${_fieldsBase.order ? _fieldsBase.order : context.state.order}`
  // );

  // context.setState({
  //   ...context.state,
  //   totalItems: res.headers.get('X-Total-Count'),
  // });

  // const json = await res.json();
  // context.setGestaoDeAgendaList(json);
};

const sortEntities = (context: IGestaoDeAgendaProps, _newState = undefined) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IGestaoDeAgendaProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (context: IGestaoDeAgendaProps, activePage: any) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IGestaoDeAgendaProps,
  _gestaoDeAgendaEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="gestaoDeAgenda.delete.question"
        interpolate={{ id: _gestaoDeAgendaEntity.id }}
      >
        Are you sure you want to delete this GestaoDeAgenda?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        <i className="fa fa-ban" />
        &nbsp;
        <Translate t={context.t} contentKey="gestaoDeAgenda.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="gestaoDeAgenda.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_gestaoDeAgendaEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IGestaoDeAgendaProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = [
    'instituicao.instSaude',
    'leftValue',
    'rightValue',
    'leftValue1',
    'rightValue1',
    'leftValue2',
    'rightValue2',
    'leftValue3',
    'rightValue3',
    'diaAgenda',
  ];

  if (errors.length === 0) {
    const _entity = {
      ...context.gestaoDeAgendaEntity,
      // ...values,

      instituicao: getFormFromSelect(
        context.state.instituicaoFormValue,
        'many-to-one'
      ), // many-to-one - ownerSide

      profissionalSaude: getFormFromSelect(
        context.state.profissionalSaudeFormValue,
        'many-to-one'
      ), // many-to-one - ownerSide

      id: context.state.idFormValue,
      sgCliente: 'HA',
      instSaude: context.state.instSaudeFormValue,
      cpf: context.state.cpfFormValue,
      profissional: context.state.profissionalFormValue,
      leftValue: context.state.leftValueFormValue,
      rightValue: context.state.rightValueFormValue,
      leftValue1: context.state.leftValue1FormValue,
      rightValue1: context.state.rightValue1FormValue,
      leftValue2: context.state.leftValue2FormValue,
      rightValue2: context.state.rightValue2FormValue,
      leftValue3: context.state.leftValue3FormValue,
      rightValue3: context.state.rightValue3FormValue,
      diaAgenda: context.state.diaAgendaFormValue,
    };

    const entity = _entity;

    const {
      instituicaoId,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (context: IGestaoDeAgendaProps, isNew: any) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="gestaoDeAgenda.home.createLabel"
            >
              Create a GestaoDeAgenda
            </Translate>
          ) : (
            <Translate t={context.t} contentKey="gestaoDeAgenda.home.editLabel">
              Edit a GestaoDeAgenda
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IGestaoDeAgendaProps,
  _gestaoDeAgendaEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="gestao-de-agenda-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-gestao-de-agenda-first-column">
                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className={'mt-4 label-single-line'}
                          id="idLabel"
                          for="gestao-de-agenda-id"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.id"
                          >
                            ID
                          </Translate>
                        </Label>
                      </Col>

                      <Col md="9">
                        <input
                          className={'form-control'}
                          disabled={true}
                          value={
                            context.gestaoDeAgendaEntity.id
                              ? context.gestaoDeAgendaEntity.id
                              : ''
                          }
                          id="gestao-de-agenda-id"
                          type="text"
                          name="id"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="sgClienteLabel"
                          for="gestao-de-agenda-sgCliente"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.sgCliente"
                          >
                            Cliente
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              sgClienteFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.sgClienteFormValue
                              ? context.state.sgClienteFormValue
                              : ''
                          }
                          id="gestao-de-agenda-sgCliente"
                          type="text"
                          name="sgCliente"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="instSaudeLabel"
                          for="gestao-de-agenda-instSaude"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.instSaude"
                          >
                            Instituição de Saúde
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              instSaudeFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.instSaudeFormValue
                              ? context.state.instSaudeFormValue
                              : ''
                          }
                          id="gestao-de-agenda-instSaude"
                          type="text"
                          name="instSaude"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="cpfLabel"
                          for="gestao-de-agenda-cpf"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.cpf"
                          >
                            CPF
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              cpfFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.cpfFormValue
                              ? context.state.cpfFormValue
                              : ''
                          }
                          id="gestao-de-agenda-cpf"
                          type="text"
                          name="cpf"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="profissionalLabel"
                          for="gestao-de-agenda-profissional"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.profissional"
                          >
                            Profissional
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              profissionalFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.profissionalFormValue
                              ? context.state.profissionalFormValue
                              : ''
                          }
                          id="gestao-de-agenda-profissional"
                          type="text"
                          name="profissional"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="leftValueLabel"
                          for="gestao-de-agenda-leftValue"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.leftValue"
                          >
                            Horário
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              leftValueFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.leftValueFormValue
                              ? context.state.leftValueFormValue
                              : ''
                          }
                          id="gestao-de-agenda-leftValue"
                          type="text"
                          name="leftValue"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="rightValueLabel"
                          for="gestao-de-agenda-rightValue"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.rightValue"
                          >
                            Profissional
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rightValueFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rightValueFormValue
                              ? context.state.rightValueFormValue
                              : ''
                          }
                          id="gestao-de-agenda-rightValue"
                          type="text"
                          name="rightValue"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="leftValue1Label"
                          for="gestao-de-agenda-leftValue1"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.leftValue1"
                          >
                            Horário
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              leftValue1FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.leftValue1FormValue
                              ? context.state.leftValue1FormValue
                              : ''
                          }
                          id="gestao-de-agenda-leftValue1"
                          type="text"
                          name="leftValue1"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="rightValue1Label"
                          for="gestao-de-agenda-rightValue1"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.rightValue1"
                          >
                            Profissional
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rightValue1FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rightValue1FormValue
                              ? context.state.rightValue1FormValue
                              : ''
                          }
                          id="gestao-de-agenda-rightValue1"
                          type="text"
                          name="rightValue1"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="leftValue2Label"
                          for="gestao-de-agenda-leftValue2"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.leftValue2"
                          >
                            Horário
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              leftValue2FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.leftValue2FormValue
                              ? context.state.leftValue2FormValue
                              : ''
                          }
                          id="gestao-de-agenda-leftValue2"
                          type="text"
                          name="leftValue2"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="rightValue2Label"
                          for="gestao-de-agenda-rightValue2"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.rightValue2"
                          >
                            Profissional
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rightValue2FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rightValue2FormValue
                              ? context.state.rightValue2FormValue
                              : ''
                          }
                          id="gestao-de-agenda-rightValue2"
                          type="text"
                          name="rightValue2"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="leftValue3Label"
                          for="gestao-de-agenda-leftValue3"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.leftValue3"
                          >
                            Horário
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              leftValue3FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.leftValue3FormValue
                              ? context.state.leftValue3FormValue
                              : ''
                          }
                          id="gestao-de-agenda-leftValue3"
                          type="text"
                          name="leftValue3"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="rightValue3Label"
                          for="gestao-de-agenda-rightValue3"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.rightValue3"
                          >
                            Profissional
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rightValue3FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rightValue3FormValue
                              ? context.state.rightValue3FormValue
                              : ''
                          }
                          id="gestao-de-agenda-rightValue3"
                          type="text"
                          name="rightValue3"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="diaAgendaLabel"
                          for="gestao-de-agenda-diaAgenda"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.diaAgenda"
                          >
                            Dia Agenda
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              diaAgendaFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.diaAgendaFormValue
                              ? context.state.diaAgendaFormValue
                              : ''
                          }
                          id="gestao-de-agenda-diaAgenda"
                          type="text"
                          name="diaAgenda"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          for="gestao-de-agenda-instituicao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.instituicao"
                          >
                            Instituicao
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <SelectAsync
                          isMulti={false}
                          id="gestao-de-agenda-instituicao-form"
                          name={'instituicao'}
                          instanceId="gestao-de-agenda-instituicao-form"
                          placeholder={translate(
                            context.t,
                            'gestaoDeAgenda.instituicao-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.instituicaoFormValue
                              ? context.state.instituicaoFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              instituicaoFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.instituicaoStartSelectOptions
                              ? context.state.instituicaoStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state.instituicaoStartSelectOptions ===
                            undefined
                              ? translate(
                                  context.t,
                                  'selectAsync.loadingMessage'
                                )
                              : translate(
                                  context.t,
                                  'selectAsync.noOptionsMessage'
                                )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state.instituicaoStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'aswinstsaude',
                                {},
                                0,
                                100,
                                'instSaude,asc',
                                'instSaude'
                              );
                              context.setState({
                                ...context.state,
                                instituicaoStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'instSaude') ||
                                        '',
                                    }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'aswinstsaude',
                                { 'instSaude.contains': inputValue },
                                0,
                                100,
                                'instSaude,asc',
                                'instSaude'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'instSaude') ||
                                        '',
                                    }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          for="gestao-de-agenda-profissionalSaude"
                        >
                          <Translate
                            t={context.t}
                            contentKey="gestaoDeAgenda.profissionalSaude"
                          >
                            Profissional Saude
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <SelectAsync
                          isMulti={false}
                          id="gestao-de-agenda-profissionalSaude-form"
                          name={'profissionalSaude'}
                          instanceId="gestao-de-agenda-profissionalSaude-form"
                          placeholder={translate(
                            context.t,
                            'gestaoDeAgenda.profissionalSaude-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.profissionalSaudeFormValue
                              ? context.state.profissionalSaudeFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              profissionalSaudeFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.profissionalSaudeStartSelectOptions
                              ? context.state
                                  .profissionalSaudeStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state
                              .profissionalSaudeStartSelectOptions === undefined
                              ? translate(
                                  context.t,
                                  'selectAsync.loadingMessage'
                                )
                              : translate(
                                  context.t,
                                  'selectAsync.noOptionsMessage'
                                )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state
                                .profissionalSaudeStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'profissional-saudes',
                                {},
                                0,
                                100,
                                'id,asc',
                                'id'
                              );
                              context.setState({
                                ...context.state,
                                profissionalSaudeStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'id') || '',
                                    }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'profissional-saudes',
                                { 'id.contains': inputValue },
                                0,
                                100,
                                'id,asc',
                                'id'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'id') || '',
                                    }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IGestaoDeAgendaProps) => {
  return (
    <Modal
      className="gestao-de-agenda-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="gestaoDeAgenda.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IGestaoDeAgendaProps) => {
  return context.gestaoDeAgendaEntity &&
    (context.gestaoDeAgendaEntity.id || context.gestaoDeAgendaEntity._id) ? (
    <Modal
      className="gestao-de-agenda-update-modal"
      size={'xl'}
      isOpen={
        context.state.showModalEdit === context.gestaoDeAgendaEntity.id ||
        context.state.showModalEdit === context.gestaoDeAgendaEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.gestaoDeAgendaEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="gestaoDeAgenda.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (context: IGestaoDeAgendaProps, v: any) => {
  let p: any = null;

  p = v.instituicao;
  const instituicaoEntity = p
    ? { ...p, value: p.id, label: p['instSaude'] ? p.instSaude : '' }
    : p;

  p = v.profissionalSaude;
  const profissionalSaudeEntity = p
    ? { ...p, value: p.id, label: p['id'] ? p.id : '' }
    : p;

  return {
    idFormValue: v.id, // id,
    sgClienteFormValue: v.sgCliente, // sgCliente,
    instSaudeFormValue: v.instSaude, // instSaude,
    cpfFormValue: v.cpf, // cpf,
    profissionalFormValue: v.profissional, // profissional,
    leftValueFormValue: v.leftValue, // leftValue,
    rightValueFormValue: v.rightValue, // rightValue,
    leftValue1FormValue: v.leftValue1, // leftValue1,
    rightValue1FormValue: v.rightValue1, // rightValue1,
    leftValue2FormValue: v.leftValue2, // leftValue2,
    rightValue2FormValue: v.rightValue2, // rightValue2,
    leftValue3FormValue: v.leftValue3, // leftValue3,
    rightValue3FormValue: v.rightValue3, // rightValue3,
    diaAgendaFormValue: v.diaAgenda, // diaAgenda,
    instituicaoFormValue: instituicaoEntity,
    profissionalSaudeFormValue: profissionalSaudeEntity,
  };
};

const openUpdateModal = (
  context: IGestaoDeAgendaProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IGestaoDeAgendaProps) => {
  const newState = {
    ...context.state,
    instituicaoId: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IGestaoDeAgendaProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

export const loadPeriodosAgenda = async (startDate) => {
  const userData = localStorage.getItem(AUTH_USER_DATA) || '';
  const psCPF = await JSON.parse(userData).cpf;

  const body = {
    method: '_list',
    sgCliente: 'HA',
    sgInstSaude: 'ColabHA',
    psCPF,
    dtCarga: '',
    agTeleFisico: 'T',
    startDate,
  };

  const res = await api.post('aswcargaagendaperiodos', body);

  const json = await res.data;
  let periodoAgenda = [];
  if (json && json.data) {
    const dtLoaded = unique(json.data.map((item) => item.dtCarga));
    periodoAgenda = dtLoaded.map((dt) => {
      return { day: dt.split('T')[0], dayValue: [] };
    });
    for (let index = 0; index < json.data.length; index++) {
      if (dtLoaded.includes(json.data[index].dtCarga)) {
        const inicioHr = moment(json.data[index].inicio).hour() * 60;
        const inicioMn = moment(json.data[index].inicio).minute();
        const fimHr = moment(json.data[index].fim).hour() * 60;
        const fimMn = moment(json.data[index].fim).minute();
        periodoAgenda.map((dt) => {
          if (dt.day === json.data[index].dtCarga.split('T')[0]) {
            dt.dayValue.push(inicioHr + inicioMn, fimHr + fimMn);
          }
          return dt;
        });
      }
    }
    periodoAgenda.map((dt) => {
      if (dt.dayValue.length < 8) {
        for (let index = dt.dayValue.length; index < 8; index++) {
          dt.dayValue.unshift(0);
        }
      }
      return dt;
    });
  }

  return periodoAgenda;
};

export const loadPeriodosSemAtividade = async (startDate) => {
  const userData = localStorage.getItem(AUTH_USER_DATA) || '';
  const parsedUserData = await JSON.parse(userData);
  const psCPF = parsedUserData.cpf;

  const body = {
    method: '_list',
    sgCliente: 'HA',
    sgInstSaude: 'ColabHA',
    psCPF,
    startDate,
  };
  const res = await api.post(
    `${BASE_API_VERSION_PATH}aswperiodosematividade`,
    body
  );

  const json = await res.data;

  let dadosPeriodoSemAtividade = [];
  if (json && json.data) {
    dadosPeriodoSemAtividade = json.data.map((vlr) => {
      return {
        periodo: [
          moment(vlr.inicio).add(3, 'hours').toDate(),
          moment(vlr.fim).add(3, 'hours').toDate(),
        ],
        descricao: vlr.descricao,
      };
    });
  }

  return dadosPeriodoSemAtividade;
};

// const renderFilter = (context: IGestaoDeAgendaProps) => {
//   const onChangeInstituicao = async (option: any) => {
//     if (option.id !== context.state?.instituicaoId?.id) {
//       context.setState({
//         ...context.state,
//         loading: true,
//       });
//       const dadosPeriodoSemAtividade = await loadPeriodosSemAtividade(context, option);
//       const periodoAgenda = await loadPeriodosAgenda(context, option);

//       context.setState({
//         ...context.state,
//         dadosPeriodoSemAtividade,
//         periodoAgenda,
//         instituicaoId: option,
//         loading: false,
//       });
//     } else {
//       return;
//     }
//   };

//   return (
//     <>
//       <div id="form-filter">
//         <div className="row mt-1 ml-3 mr-3">
//           {context.state.baseFilters !== 'instituicao' ? (
//             <Col md="12">
//               <Row className="mr-1 mt-1">
//                 <div style={{ width: '100%' }}>
//                   <Label for="gestao-de-agenda-instituicao">
//                     <Translate
//                       t={context.t}
//                       contentKey="gestaoDeAgenda.instituicao"
//                     />
//                   </Label>
//                   <SelectAsync
//                     id="gestao-de-agenda-instituicao-filter"
//                     instanceId="gestao-de-agenda-instituicao-filter"
//                     className={'css-select-control'}
//                     placeholder={'Selecione a instituição'}
//                     name={'instituicao'}
//                     cacheOptions
//                     value={context.state?.instituicaoId}
//                     onChange={(options) => onChangeInstituicao(options)}
//                     defaultOptions={
//                       context.state.instituicaoStartFilter
//                         ? context.state.instituicaoStartFilter
//                         : []
//                     }
//                     loadingMessage={(input) =>
//                       translate(context.t, 'selectAsync.loadingMessage')
//                     }
//                     noOptionsMessage={(input) =>
//                       context.state.instituicaoStartFilter === undefined
//                         ? translate(context.t, 'selectAsync.loadingMessage')
//                         : translate(context.t, 'selectAsync.noOptionsMessage')
//                     }
//                     onMenuOpen={async () => {
//                       if (context.state.instituicaoStartFilter === undefined) {
//                         const instSaudeDisponiveis = await JSON.parse(
//                           localStorage.getItem(AUTH_USER_ROUTES)
//                         );
//                         const instSaudeParsed = instSaudeDisponiveis[
//                           'gestao-de-agenda'
//                         ].grupos.map((grupo) => {
//                           return {
//                             ...grupo,
//                             value: grupo.institucao,
//                             id: Math.floor((1 + Math.random()) * 0x10000),
//                             label: grupo.institucaoNome,
//                           };
//                         });
//                         context.setState({
//                           ...context.state,
//                           instituicaoStartFilter: instSaudeParsed,
//                         });
//                       }
//                     }}
//                   />
//                 </div>
//               </Row>
//             </Col>
//           ) : null}
//         </div>
//         <div>
//           <br />
//         </div>
//         <div></div>
//       </div>
//     </>
//   );
// };

export const renderTable = (context: IGestaoDeAgendaProps) => {
  return (
    <>
      {context.gestaoDeAgendaList &&
      context.gestaoDeAgendaList.filter &&
      context.gestaoDeAgendaList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div id="gestao-de-agenda-table-list" className="table-list">
          <Table
            id="gestao-de-agenda-table-list"
            responsive
            aria-describedby="gestao-de-agenda-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th id="instituicao-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.instituicao"
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('leftValue')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.leftValue"
                  />
                  <i
                    className={
                      context.state.sort === 'leftValue'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('rightValue')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.rightValue"
                  />
                  <i
                    className={
                      context.state.sort === 'rightValue'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('leftValue1')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.leftValue1"
                  />
                  <i
                    className={
                      context.state.sort === 'leftValue1'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('rightValue1')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.rightValue1"
                  />
                  <i
                    className={
                      context.state.sort === 'rightValue1'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('leftValue2')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.leftValue2"
                  />
                  <i
                    className={
                      context.state.sort === 'leftValue2'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('rightValue2')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.rightValue2"
                  />
                  <i
                    className={
                      context.state.sort === 'rightValue2'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('leftValue3')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.leftValue3"
                  />
                  <i
                    className={
                      context.state.sort === 'leftValue3'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('rightValue3')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.rightValue3"
                  />
                  <i
                    className={
                      context.state.sort === 'rightValue3'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('diaAgenda')}
                >
                  <Translate
                    t={context.t}
                    contentKey="gestaoDeAgenda.diaAgenda"
                  />
                  <i
                    className={
                      context.state.sort === 'diaAgenda'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.gestaoDeAgendaList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((gestaoDeAgenda: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="instituicao-cell" role="many-to-one">
                      <b className="visible-xs">
                        <Translate
                          t={context.t}
                          contentKey="gestaoDeAgenda.instituicao"
                        />
                      </b>

                      {showFieldsSelectAsync(
                        gestaoDeAgenda,
                        'instituicao.instSaude',
                        null
                      ).map((v: any, ikey: any) => (
                        <span key={ikey}> {v} </span>
                      ))}
                    </td>

                    <td id="leftValue-cell">
                      <b className="visible-xs"> Horário </b>

                      {gestaoDeAgenda.leftValue}
                    </td>

                    <td id="rightValue-cell">
                      <b className="visible-xs"> Profissional </b>

                      {gestaoDeAgenda.rightValue}
                    </td>

                    <td id="leftValue1-cell">
                      <b className="visible-xs"> Horário </b>

                      {gestaoDeAgenda.leftValue1}
                    </td>

                    <td id="rightValue1-cell">
                      <b className="visible-xs"> Profissional </b>

                      {gestaoDeAgenda.rightValue1}
                    </td>

                    <td id="leftValue2-cell">
                      <b className="visible-xs"> Horário </b>

                      {gestaoDeAgenda.leftValue2}
                    </td>

                    <td id="rightValue2-cell">
                      <b className="visible-xs"> Profissional </b>

                      {gestaoDeAgenda.rightValue2}
                    </td>

                    <td id="leftValue3-cell">
                      <b className="visible-xs"> Horário </b>

                      {gestaoDeAgenda.leftValue3}
                    </td>

                    <td id="rightValue3-cell">
                      <b className="visible-xs"> Profissional </b>

                      {gestaoDeAgenda.rightValue3}
                    </td>

                    <td id="diaAgenda-cell">
                      <b className="visible-xs"> Dia Agenda </b>

                      {gestaoDeAgenda.diaAgenda}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.gestaoDeAgendaList &&
                context.gestaoDeAgendaList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate t={context.t} contentKey="gestaoDeAgenda.home.notFound">
            No Gestão de Agenda found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IGestaoDeAgendaProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IGestaoDeAgendaProps) => {
  return (
    <div>
      <h4 id="gestao-de-agenda-heading" data-cy="GestaoDeAgendaHeading">
        <Translate t={context.t} contentKey="gestaoDeAgenda.home.title">
          Gestão de Agenda
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                sgClienteFormValue: null,
                instSaudeFormValue: null,
                cpfFormValue: null,
                profissionalFormValue: null,
                leftValueFormValue: null,
                rightValueFormValue: null,
                leftValue1FormValue: null,
                rightValue1FormValue: null,
                leftValue2FormValue: null,
                rightValue2FormValue: null,
                leftValue3FormValue: null,
                rightValue3FormValue: null,
                diaAgendaFormValue: null,

                instituicaoStartSelectOptions: undefined,
                instituicaoFormValue: null,

                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="gestaoDeAgenda.btnNewItem">
              Cadastrar
            </Translate>
          </Button>
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {/* {context.renderFilter ? context.renderFilter() : () => { }} antigo select de instituição */}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getGestaoDeAgendaState = (
  context: IGestaoDeAgendaProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const instituicaoId = _urlBase.searchParams.get('instituicao') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    instituicaoId: instituicaoId
      ? instituicaoId.split(',').map((v: any) => ({
          value: v.split('<->')[0],
          label: v.split('<->')[1],
        }))
      : [],
  };
};

export const getEntitiesFetch = async (
  context: IGestaoDeAgendaProps,
  instituicaoId: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map((v: any) => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const instituicaoRequest = instituicaoId
    ? `instituicao.id.in=${instituicaoId}&`
    : '';

  const requestUrl = `${apiUrl}?${extraFiltersRequest}${instituicaoRequest}${
    sort ? `page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'
  }`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer ' +
        (authorizationBearer
          ? authorizationBearer
          : cookie.get(AUTH_TOKEN_KEY)),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
      'Get-User-Account': getAccount ? '1' : '0',
    },
  });

  return res;
};

export const GestaoDeAgenda = (props: IGestaoDeAgendaProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [gestaoDeAgendaList, setGestaoDeAgendaList] = useState(
    props.gestaoDeAgendaList ? props.gestaoDeAgendaList : ([] as any)
  );
  const [gestaoDeAgendaEntity, setGestaoDeAgendaEntity] = useState(
    props.gestaoDeAgendaEntity ? props.gestaoDeAgendaEntity : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    instituicaoId: props.instituicaoId ? props.instituicaoId : null,
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    gestaoDeAgendaList,
    setGestaoDeAgendaList,
    gestaoDeAgendaEntity,
    setGestaoDeAgendaEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_gestaoDeAgendaEntity: any, isNew: any) =>
          props['renderBodyUpdate'](context, _gestaoDeAgendaEntity, isNew)
      : (_gestaoDeAgendaEntity: any, isNew: any) =>
          renderBodyUpdate(context, _gestaoDeAgendaEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    // renderFilter: props['renderFilter']
    //   ? () => props['renderFilter'](context)
    //   : () => renderFilter(context), antigo select de instituição
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: props['getEntitiesFetch']
      ? (
          instituicaoId: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          props['getEntitiesFetch'](
            context,
            instituicaoId,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          )
      : (
          instituicaoId: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          getEntitiesFetch(
            context,
            instituicaoId,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          ),

    createEntityFetch: props['createEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['createEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: props['updateEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['updateEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: props['deleteEntityFetch']
      ? (id: any, listFiltersPage: any) =>
          props['deleteEntityFetch'](context, id, listFiltersPage)
      : (id: any, listFiltersPage: any) =>
          deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: props['getEntityFetch']
      ? (id) => props['getEntityFetch'](context, id)
      : (id) => getEntityFetch(context, id),

    getGestaoDeAgendaState: props['getGestaoDeAgendaState']
      ? (location) => props['getGestaoDeAgendaState'](context, location)
      : (location) => getGestaoDeAgendaState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getGestaoDeAgendaState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.instituicaoId,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      gestaoDeAgendaList: await res.json(),
    },
  };
};

export default GestaoDeAgenda;
