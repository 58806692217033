import { Spinner } from '@chakra-ui/react';
import { Box, FormControl, Select, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Loading } from 'components/Loading';
import { unique } from 'components/util/entity-utils';
import { WrapperLoading } from 'components/wraper-loading';
import { useApiRequest, useUrlParams, useUser } from 'hooks';
import { api } from 'services/api';
import { dateIsBefore, dateToServer, LOG } from 'shared/util';

import { GestaoDeAgenda, IGestaoDeAgendaProps } from './_base/gestao-de-agenda';
import CopiarSemanaSeguinte from './components/CopiarSemanaSeguinte';
import DataFiltros from './components/DataFiltros';
import PeriodoSemAtividade from './components/PeriodoSemAtividade';
import { RangeDate } from './components/RangeDate';
import RangeDateHeader from './components/RangeDateHeader';

import 'rc-slider/assets/index.css';

type LocalType = {
  laAtivo: 1;
  laCEP: string;
  laCidade: string;
  laDescricao: string;
  laEndereco: string;
  laNumero: string;
  laQRCode?: string;
  laTriagem?: string;
  laUF: string;
  laNome: string;
};

type Cbo = {
  codigo: string;
  descricao: string;
};

type Instituicoes = {
  sigla: string;
  instituicao: string;
};

type PSObject = {
  nome: string;
  cpf: string;
  cnes: string;
  regConsClasse: string;
  rccOrgaoEmissor: string;
  email: string;
  cidadeRegistro: string;
  ufRegistro: string;
  especialidade: string;
  telefone: string;
  cbo: Cbo[];
  instituicoes: Instituicoes[];
};

type PeriodosType = {
  sgCliente: string;
  sgInstSaude: string;
  psCPF: string;
  dtCarga: string;
  periodo: number;
  inicio: Date;
  fim: Date;
};

const MySwal = withReactContent(Swal);

export const ReturnFunction = (context: IGestaoDeAgendaProps) => {
  const urlParams = useUrlParams();
  const tipoAgenda = urlParams.get('tipo') as 'presencial' | 'telessaude';

  const agTeleFisico = tipoAgenda === 'presencial' ? 'F' : 'T';

  const { cpf } = useParams<{ cpf: string }>();

  const FISICO = agTeleFisico === 'F';

  const usuarioLogado = useUser();

  const diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const [startDate, setStartDate] = useState(
    moment(new Date()).add(0, 'days').toDate()
  );

  const [abrirPeriodoSemAtividade, setAbrirPeriodoSemAtividade] =
    useState(false);

  const [abrirCopiarSemanaSeguinte, setAbrirCopiarSemanaSeguinte] =
    useState(false);

  const [daysSelected, setDaysSelected] = useState([]);
  const [instituicao, setInstituicao] = useState('');

  const tempoMedioApi = useApiRequest<string>('aswcargaagendaperiodos', '');

  const [local, setLocal] = useState('');

  const agendaLocaisApi = useApiRequest<LocalType[]>(
    'aswagendalocaisatendimento',
    []
  );

  useEffect(() => {
    if (instituicao) {
      afterChangeData();
    }
  }, [instituicao]);

  useEffect(() => {
    if (instituicao) {
      if (FISICO) {
        context.setState({
          ...context.state,
          loading: true,
        });
        agendaLocaisApi.request({
          method: 'listarLocalAtendimentoPs',
          psCPF: cpf,
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
        });
        context.setState({
          ...context.state,
          loading: false,
        });
      } else {
        afterChangeData();
      }
    }
  }, [instituicao, local]);

  useEffect(() => {
    if (FISICO) {
      if (!!local) {
        tempoMedioApi.request({
          method: '_getTempoMedioConsulta',
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
          psCPF: cpf,
          localDeAtendimento: local,
        });
        afterChangeData();
      }
    } else {
      if (!!instituicao) {
        tempoMedioApi.request({
          method: '_getTempoMedioConsulta',
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
          psCPF: cpf,
          localDeAtendimento: 'fake',
        });
        afterChangeData();
      }
    }
  }, [local, instituicao]);

  useEffect(() => {
    if (cpf) {
      tempoMedioApi.request({
        method: '_getTempoMedioConsulta',
        sgCliente: usuarioLogado?.sgCliente,
        sgInstSaude: instituicao,
        psCPF: cpf,
        localDeAtendimento: local,
      });

      if (FISICO) {
        agendaLocaisApi.request({
          method: 'listarLocalAtendimentoPs',
          psCPF: cpf,
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
        });
      } else {
        afterChangeData();
      }
    }
  }, [usuarioLogado]);

  useEffect(() => {
    if (!!local) {
      afterChangeData();
    }
  }, [local]);

  const loadPeriodosAgenda = async (startDate) => {
    const body = {
      method: '_list',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      dtCarga: '',
      startDate,
      agTeleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };

    const res = await api.post('aswcargaagendaperiodos', body);

    const json = await res.data;

    if (json && json.data) {
      const periodos: PeriodosType[] = json.data;

      const datasUnicas = unique(periodos.map((item) => item.dtCarga));

      const periodoAgenda = datasUnicas.map((dt) => {
        const day = String(dt).split('T')[0];

        const horarios = periodos
          .filter((p) => String(p.dtCarga).split('T')[0] === day)
          .map((h) => {
            const inicioHr = moment(h.inicio).hour() * 60;
            const inicioMn = moment(h.inicio).minute();
            const fimHr = moment(h.fim).hour() * 60;
            const fimMn = moment(h.fim).minute();

            return {
              inicio: inicioHr + inicioMn,
              fim: fimHr + fimMn,
              periodo: h.periodo,
            };
          });

        const listaPeriodos = [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ];

        const dayValue = listaPeriodos
          .map((i, index) => {
            const periodo = horarios.find((h) => h.periodo === index);
            if (periodo) {
              return [periodo.inicio, periodo.fim];
            }
            return i;
          })
          .reduce((acc, curr) => {
            acc.push(...curr);
            return acc;
          }, []);

        return {
          day,
          dayValue,
        };
      });

      return periodoAgenda;
    }

    return [];
  };

  const loadPeriodosSemAtividade = async (startDate) => {
    if((FISICO && local) || FISICO == false) {
    const body = {
      method: '_list',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      startDate,
      agTeleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };
    const res = await api.post(`aswperiodosematividade`, body);

    const json = await res.data;

    return json.data.map((vlr) => {
      return {
        periodo: [
          new Date(vlr.paInicio + ' 00:00'),
          new Date(vlr.paFim + ' 00:00'),
        ],
        descricao: vlr.paDescricao,
      };
    });
  }
  else{
    return [];
  }
  };

  const [montaTabela, setMontaTabela] = useState(true);
  useEffect(() => {
    if (!montaTabela) {
      setTimeout(() => {
        setMontaTabela(true);
      }, 1);
    }
  }, [montaTabela]);

  const afterChangeData = async () => {
    tempoMedioApi.request({
      method: '_getTempoMedioConsulta',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      localDeAtendimento: 'fake',
    });

    context.setState({
      ...context.state,
      loading: true,
      periodoAgenda: [],
    });

    const periodoAgenda = await loadPeriodosAgenda(startDate);
    const dadosPeriodoSemAtividade = await loadPeriodosSemAtividade(startDate);

    context.setState({
      ...context.state,
      dadosPeriodoSemAtividade,
      periodoAgenda,
      instituicaoId: instituicao,
      loading: false,
    });

    setMontaTabela(false);
  };

  const selectDay = (day: Date) => {
    if (daysSelected.includes(moment(day).format('yyyy-MM-DD'))) {
      setDaysSelected(
        daysSelected.filter(
          (daySelected) => daySelected !== moment(day).format('yyyy-MM-DD')
        )
      );
    } else {
      setDaysSelected([...daysSelected, moment(day).format('yyyy-MM-DD')]);
    }
  };

  const handleDayScheduleChange = (dayValue: Array<number>, day: Date) => {
    if (
      context.state.periodoAgenda.find(
        (periodo) => periodo.day === moment(day).format('yyyy-MM-DD')
      ) !== undefined
    ) {
      const periodoAgenda = context.state?.periodoAgenda.map((periodo) => {
        if (periodo.day === moment(day).format('yyyy-MM-DD')) {
          periodo.dayValue = dayValue.map((value) => value);
        }
        return periodo;
      });
      context.setState({ ...context.state, periodoAgenda });
    } else {
      context.setState({
        ...context.state,
        periodoAgenda: [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(day).format('yyyy-MM-DD'),
            dayValue: dayValue.map((value) => value),
          },
        ],
      });
    }
  };

  const onChangeMinimalTime = (value: string) => {
    tempoMedioApi.setState(value);

    tempoMedioApi.submit({
      method: '_updateTempoMedioConsulta',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      psTempoMedioConsulta: value,
      agTeleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    });
  };

  const timeConvert = (mark) => {
    const hours = mark / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours.toString().length === 1 ? `0${rhours}` : rhours}:${rminutes.toString().length === 1 ? `${rminutes}0` : rminutes
      }:00`;
  };

  const saveWeekSchedule = async () => {
    try {
      const periodos = [];
      const weekDays: string[] = [];

      const endDate = moment(startDate)
        .startOf('week')
        .isoWeekday(7)
        .add(6, 'days')
        .toDate();

      context.state?.periodoAgenda.map((schedule) => {
        let periodo = 0;

        if (
          moment(new Date(schedule.day)).add(1, 'days') >=
          moment(new Date()).add(0, 'days') &&
          moment(new Date(schedule.day)).add(1, 'days') <=
          moment(endDate).add(1, 'days')
        ) {
          weekDays.push(schedule.day);
          console.log('schedule.day->', schedule);
          for (let index = 0; index < schedule.dayValue.length; index += 2) {
            if (schedule.dayValue[index + 1] !== 0) {
              periodos.push({
                periodo,
                dtCarga: schedule.day,
                inicio: timeConvert(schedule.dayValue[index]),
                fim: timeConvert(schedule.dayValue[index + 1]),
              });
            }
            periodo++;
          }
        }
      });

      weekDays.forEach((day) => {
        if (!periodos.find((p) => p.dtCarga === day)) {
          periodos.push({
            periodo: 0,
            dtCarga: day,
            inicio: '00:00:00',
            fim: '00:00:00',
          });
        }
      });

      const body = {
        method: '_updateList',
        sgCliente: usuarioLogado?.sgCliente,
        sgInstSaude: instituicao,
        psCPF: cpf,
        periodos,
        agTeleFisico,
        startDate: dateToServer(startDate),
        endDate: dateToServer(endDate),
        localDeAtendimento: FISICO ? local : 'fake',
      };

      const result = await api.post(`aswcargaagendaperiodos`, body);

      const json = await result.data;
      if (json.statusCode !== 500) {
        setDaysSelected([]);
        MySwal.fire({
          title: 'Agenda salva',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      } else {
        MySwal.fire({
          title: 'Erro ao salvar',
          html: json.message || '',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e70015',
        });
      }
    } catch (err) {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: err.message || '',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
      console.error(err.message);
    }
  };

  const onOpenPeriodoSemAtividade = (show: boolean, resetData?: boolean) => {
    setAbrirPeriodoSemAtividade(show);
    if (resetData === true)
      context.setState({ ...context.state, dadosPeriodoSemAtividade: [] });
  };

  const onChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const onSaveCopyWeek = async (weeks: any) => {
    const body = {
      method: '_insertNextWeek',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      dtCarga: daysSelected,
      semanas: weeks,
      startDate,
      agTeleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      const diasPlanejados =
        json.data?.diasPlanejados.length > 0 ? json.data.diasPlanejados : [];
      const periodoSemAtividade =
        json.data?.periodoSemAtividade.length > 0
          ? json.data.periodoSemAtividade
          : [];
      setDaysSelected([]);
      await afterChangeData();

      if (diasPlanejados.length > 0 && periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados e ${periodoSemAtividade.length} dia(s) sem atividades, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (diasPlanejados.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${periodoSemAtividade.length} dia(s) sem atividade, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else {
        MySwal.fire({
          title: 'Semanas copiadas',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar as datas selecionadas',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  const onSaveCopyDay = async () => {
    context.setState({ ...context.state, loading: true });
    const actualDay = context.state?.periodoAgenda.find(
      (periodo) => periodo.day === daysSelected[0]
    );
    const body = {
      method: '_insertNextDay',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: cpf,
      dtCarga: daysSelected[0],
      startDate: dateToServer(startDate),
      agTeleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      if (
        json.data?.diasPlanejados.length > 0 ||
        json.data?.periodoSemAtividade.length > 0
      ) {
        MySwal.fire({
          title: 'Aviso',
          html: 'Existem horários programados, ou uma data sem atividade após o dia selecionado para cópia',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
        context.setState({
          ...context.state,
          loading: false,
        });
      } else {
        const copyDay = [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(actualDay.day).add(1, 'days').format('yyyy-MM-DD'),
            dayValue: actualDay.dayValue,
          },
        ];
        setDaysSelected([]);
        context.setState({
          ...context.state,
          periodoAgenda: copyDay,
          loading: false,
        });
        MySwal.fire({
          title: 'Dia copiado',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
        setMontaTabela(false);
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar a data selecionada',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  const profissional = useApiRequest<PSObject>(
    'aswprofissionalsaude',
    {} as PSObject,
    {
      method: '_find',
      sgCliente: usuarioLogado?.sgCliente,
      cpf: cpf,
    }
  );

  const history = useHistory();

  return (
    <div id="view-gestao-da-agenda-profissional">
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center jh-create-entity">
            <Button
              color="primary"
              id="save-entity"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left" />
            </Button>
            <b className="ms-2 fw-bold">{`GERENCIAMENTO PROFISSIONAL DE SAÚDE > ${profissional.state?.nome && profissional.state?.nome.toUpperCase()
              } > GESTÃO DE AGENDA`}</b>
          </div>
        </div>

        <Box as="form" className="my-3">
          <h3>{!instituicao ? 'Selecione a instituição' : 'Instituição'}</h3>
          <WrapperLoading isLoading={profissional.isLoading}>
            <Select
              size={'lg'}
              value={instituicao}
              onChange={(event) => {
                event.persist();
                const { value } = event.target;
                context.setState({
                  ...context.state,
                  instituicaoId: value,
                });
                setInstituicao(value);
              }}
            >
              <option value="">Selecione a instituição...</option>
              {profissional.state.instituicoes &&
                profissional.state.instituicoes.map((item, index) => {
                  return (
                    <option key={index} value={item.sigla}>
                      {item.instituicao}
                    </option>
                  );
                })}
            </Select>
          </WrapperLoading>
        </Box>
      </div>

      {instituicao && (
        <>
          <div className="row">
            {FISICO && (
              <div className="mb-2">
                <Box as="form">
                  <p>
                    <small>Local de atendimento</small>
                  </p>
                  <FormControl>
                    <Select
                      disabled={instituicao ? false : true}
                      style={{ borderRadius: '12px' }}
                      placeholder="Selecione..."
                      size={'lg'}
                      value={local}
                      onChange={(event) => {
                        event.persist();
                        const { value } = event.target;
                        setLocal(value);
                      }}
                    >
                      {Array.isArray(agendaLocaisApi.state) &&
                        agendaLocaisApi.state.map((item, index) => {
                          return (
                            <option key={index} value={item.laDescricao}>
                              {item.laNome}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            )}

            <div
              className="col-sm-3 agenda-filtro-data"
              style={{ marginTop: '-3px' }}
            >
              <DataFiltros
                disabled={!context.state?.instituicaoId}
                startDate={startDate}
                setStartDate={onChangeStartDate}
              />
            </div>

            <div className="col-sm-1 prev-next">
              <Button
                disabled={!context.state?.instituicaoId}
                onClick={() =>
                  onChangeStartDate(moment(startDate).add(-7, 'days').toDate())
                }
                className="btn btn-primary"
              >
                {'<'}
              </Button>
              <Button
                disabled={!context.state?.instituicaoId}
                onClick={() =>
                  onChangeStartDate(moment(startDate).add(7, 'days').toDate())
                }
                className="btn btn-primary"
              >
                {'>'}
              </Button>
            </div>

            <div
              className="col-sm-4 d-flex align-items-center"
              style={{ textAlign: 'left' }}
            >
              <span style={{ width: '100px', float: 'left', color: '#666666' }}>
                Tempo médio <br /> de consulta
              </span>
              <span style={{ width: '110px', float: 'left' }}>
                <select
                  disabled={!context.state?.instituicaoId}
                  onChange={(event) => onChangeMinimalTime(event.target.value)}
                  style={{ width: '100px', float: 'left' }}
                  className="form-control"
                  value={tempoMedioApi.state}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={60}>60</option>
                </select>
              </span>
              {tempoMedioApi.isLoading && <Loading />}
              <span style={{ width: '60px', float: 'left', color: '#666666' }}>
                Minutos
              </span>
            </div>
            <div className="col-sm-4 d-flex flex-row justify-content-end">
              <Button
                disabled={
                  FISICO
                    ? local
                      ? false
                      : true
                    : !context.state?.instituicaoId
                }
                onClick={() => setAbrirPeriodoSemAtividade(true)}
                className="btn btn-primary"
              >
                <i className="fa fa-lock me-1" /> Períodos Sem Atividade
              </Button>
            </div>
          </div>

          {context.state?.periodoAgenda &&
            context.state?.periodoAgenda?.length >= 0 &&
            montaTabela && (
              <>
                <table className="table-responsive-css table">
                  <thead>
                    <tr>
                      <th>
                        <div className="row text-end">
                          <div className="col-sm-2">
                            <></>
                          </div>
                          <div className="col-sm-10">
                            <RangeDateHeader />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[0, 1, 2, 3, 4, 5, 6].map((v: number, i: number) => {
                      const dia = moment(startDate)
                        .startOf('week')
                        .isoWeekday(v)
                        .add(7, 'days')
                        .toDate();

                      const deveBloquearStyle = dateIsBefore(dia);

                      const hhh =
                        context.state?.dadosPeriodoSemAtividade?.length > 0
                          ? context.state?.dadosPeriodoSemAtividade?.filter(
                            (psa) => {
                              const _start = moment(psa.periodo[0]).format(
                                'YYYYMMDD'
                              );
                              const _end = moment(psa.periodo[1]).format(
                                'YYYYMMDD'
                              );
                              const _dia = moment(dia).format('YYYYMMDD');
                              return _start <= _dia && _end >= _dia;
                            }
                          )
                          : [];

                      return (
                        <tr key={`${dia}_${i}`}>
                          <td
                            style={
                              deveBloquearStyle ? { pointerEvents: 'none' } : {}
                            }
                          >
                            <div className="row text-end gestao-agenda-container">
                              <div className="col-sm-1">
                                <input
                                  type="checkbox"
                                  checked={daysSelected.includes(
                                    moment(dia).format('yyyy-MM-DD')
                                  )}
                                  className="form-check-input pointer"
                                  id="btncheck1"
                                  autoComplete="off"
                                  disabled={!instituicao || hhh?.length !== 0}
                                  onChange={() => selectDay(dia)}
                                />
                              </div>
                              <div className="col-sm-1">
                                <div
                                  style={{ float: 'left', marginLeft: '20px' }}
                                >
                                  {diasSemana[moment(dia).format('e')]} <br />{' '}
                                  <small>{moment(dia).format('DD/MM')}</small>
                                </div>
                              </div>
                              <div className="col-sm-10">
                                {hhh?.length === 0 ? (
                                  <RangeDate
                                    data={context.state?.periodoAgenda?.find(
                                      (agenda) =>
                                        moment(agenda.day)
                                          ?.toDate()
                                          .getTime() === dia.getTime()
                                    )}
                                    disabled={!instituicao || deveBloquearStyle}
                                    minimalTime={Number(tempoMedioApi.state)}
                                    getChangedValue={(dayValue) =>
                                      handleDayScheduleChange(dayValue, dia)
                                    }
                                  />
                                ) : (
                                  <div className="rc-slider rc-slider-with-marks">
                                    <div
                                      className="rc-slider-rail"
                                      style={{
                                        height: '3px',
                                        backgroundColor: '#F4A460',
                                        marginTop: '2px',
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="row">
                  <div className="col-sm-12 d-flex flex-row justify-content-between">
                    <div>
                      <Button
                        disabled={
                          !instituicao ||
                          daysSelected?.length > 1 ||
                          daysSelected?.length === 0
                        }
                        onClick={() => onSaveCopyDay()}
                        className="btn btn-primary rounded-pill me-2"
                      >
                        Copiar para Dia Seguinte
                      </Button>
                      <Button
                        disabled={!instituicao || daysSelected?.length === 0}
                        onClick={() => setAbrirCopiarSemanaSeguinte(true)}
                        className="btn btn-primary rounded-pill me-2"
                      >
                        Copiar para Semana Seguinte
                      </Button>
                    </div>
                    <Button
                      disabled={
                        !instituicao ||
                        context.state?.periodoAgenda?.length === 0
                      }
                      onClick={async () => await saveWeekSchedule()}
                      className="btn btn-primary rounded-pill"
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </>
            )}
        </>
      )}

      {abrirPeriodoSemAtividade && (
        <PeriodoSemAtividade
          dadosPeriodoSemAtividade={context?.state?.dadosPeriodoSemAtividade}
          sgCliente={usuarioLogado?.sgCliente}
          sgInstSaude={instituicao}
          show={abrirPeriodoSemAtividade}
          context={context}
          parsedUserData={usuarioLogado}
          setShow={onOpenPeriodoSemAtividade}
          afterChangeData={afterChangeData}
          teleFisico={agTeleFisico}
          localDeAtendimento={FISICO ? local : 'fake'}
          psCPF={cpf}
        />
      )}

      {abrirCopiarSemanaSeguinte && (
        <CopiarSemanaSeguinte
          saveData={(week) => onSaveCopyWeek(week)}
          setShow={setAbrirCopiarSemanaSeguinte}
          show={abrirCopiarSemanaSeguinte}
        />
      )}
    </div>
  );
};

export const NNGestaoDeAgenda = (props) =>
  GestaoDeAgenda({
    returnFunction: (context) => (
      <ReturnFunction {...{ ...context, ...props }} />
    ),
    ...props,
  } as any).return();

const GestaoDeAgendaPRofissional = ({ match, ...rest }) => (
  <NNGestaoDeAgenda {...rest} />
);

export default GestaoDeAgendaPRofissional;
