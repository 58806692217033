import { Select } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'components/Loading';
import { useApiRequest, useOnlyMounted, useUser, ValueLabelType } from 'hooks';
import {
  DadosUsuarioType,
  KeyValue,
  LoadUserDataByCPFResultType,
} from 'pages/agendar-paciente/types';
import { InstType } from 'pages/cadastro-administradores/new/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  ModalFooter,
  Row,
} from 'reactstrap';
import { api } from 'services/api';
import { onlyNumber } from 'shared/util';
import { calcularIdade } from 'shared/util/calcularIdade';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';

type InstSelectedType = ValueLabelType & { grupo?: ValueLabelType };
export type FilaAtendimentoType = {
  sgInstSaude?: string;
  faCodigo?: number;
  faDescricao: string;
};

export type AtendimentoEmergencialType = {
  usuarioCPF: string;
  sgInstSaude: string;
  laDescricao: string;
  faCodigo: string;
  psCPF: string;
};

const requiredFieldText = 'Campo obrigatório';
const schema = Yup.object().shape({
  usuarioCPF: Yup.string().required(requiredFieldText),
  sgInstSaude: Yup.string().required(requiredFieldText),
  laDescricao: Yup.string().required(requiredFieldText),
  faCodigo: Yup.string().required(requiredFieldText),
});

const MySwal = withReactContent(Swal);
export const atendimentoCompartilhado = () => {
  const history = useHistory();
  const [instSelected, setInstSelected] = useState('');
  const [filasAtendimento, setFilasAtendimento] = useState([]);
  const [filaSelecionada, setFilaSelecionada] = useState('');
  const pacienteHook = useApiRequest<LoadUserDataByCPFResultType>(
    'aswagendarusuario',
    {} as LoadUserDataByCPFResultType
  );
  const usuarioLogado = useUser();
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const { onlyMounted } = useOnlyMounted();
  const [instsSelected, setInstsSelected] = useState<InstSelectedType[]>([]);
  const [paciente, setPaciente] = useState<DadosUsuarioType | null>(null);
  const instHook = useApiRequest<Array<InstType>>('aswinstsaude', []);
  const [cpfExistente, setCpfExistente] = useState<string>('');
  const [state, setState] = useState<any>({
    conveniosData: [],
    planosData: [],
    instSaudeData: [],
    especialidadesData: [],
    profissionalSaudeData: [],
  });

  const formMethods = useForm<AtendimentoEmergencialType>({
    resolver: yupResolver(schema),
  });
  const getLocaisAtendimento = async (inst: string) => {
    if (inst != '') {
      executaRequisicao(
        'aswagendalocaisatendimento',
        {
          method: 'listarAgendaLocalAtendimento',
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: inst,
        },
        ({ data, success }) => {
          if (success && Array.isArray(data)) {
            setInstsSelected(data);
          }
        }
      );
    }
  };

  const getFilasAtendimento = async (inst: string) => {
    if (inst != '') {
      await executaRequisicao(
        'aswfilasatendimento',
        {
          method: 'listarFilaAtendimento',
          sgCliente: usuarioLogado.sgCliente,
          sgInstSaude: inst,
        },
        ({ data, success }) => {
          if (success && Array.isArray(data)) {
            setFilasAtendimento(data);
          }
        }
      );
    }
  };

  const executaRequisicao = async (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    setLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setLoading(false);
        });
      });
  };

  const loadUserDataByCPF = async (value: string) => {
    try {
      const res = await pacienteHook.submit({
        method: '_findUserByCPF',
        usuarioCPF: onlyNumber(value),
        sgCliente: user?.sgCliente,
      });

      if (res.success && res.data) {
        pacienteHook.setState(res.data);
        setCpfExistente('');
        setPaciente(res.data.dadosUsuario);
        return;
      }
      setCpfExistente('Paciente não cadastrado no sistema!');

      MySwal.fire({
        title: 'Usuário não encontrado',
        html:
          res?.data?.message ||
          'Erro ao encontrar usuário, verifique se o mesmo possuí cadastro ativo',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: colors.yellow,
      });
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const handleUserCpf = (e) => {
    setState({
      ...state,
      cpf: e.target.value,
    });
    formMethods.setValue('usuarioCPF', e.target.value);
    if (onlyNumber(e.target.value).length === 11) {
      setLoading(true);
      loadUserDataByCPF(e.target.value);
    }
  };

  async function listarInst() {
    instHook.request({
      centroMedico: true,
      method: '_listIsUsuarioLogado',
    });
  }

  useEffect(() => {
    listarInst();
  }, []);

  const handleFormSubmit = async (dadosForm) => {
    setLoading(true);
    try {
      const { data } = await api.post('aswagendausuprofsaude', {
        method: '_criarAtendimentoEmergencial',
        sgCliente: user?.sgCliente,
        psCPF: user?.cpf,
        ...dadosForm,
      });
      await api.post('aswagendausuprofsaude', {
        method: '_chamarMedico',
        sgCliente: user?.sgCliente,
        sgInstSaude: instSelected,
        idConsulta: data.data.idConsulta,
        faCodigo: filaSelecionada,
        psCpfSolicitante: user?.cpf,
      });

      setLoading(false);
      if (data?.data?.idConsulta) {
        history.push(
          `/medical-center-atendimentos/${data.data.idConsulta}/${user.cpf}/${user.cpf}`
        );
      }
    } catch {
      MySwal.fire({
        title: 'Não foi possível iniciar o atendimento',
        icon: 'error',
      });
    }
  };
  return (
    <Container>
       {loading && <Loading />}
      <h4>
        <div className="d-flex justify-content-between">
          <span>Chamar fila de atendimento médico</span>
        </div>
      </h4>
      <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
        <Row style={{ marginTop: '2rem' }}>
          <Col md="6">
            <Label> Selecione a Instituição</Label>
            <Select
              onChange={(event) => {
                event.persist();
                const { value } = event.target;
                setInstSelected(value);
                formMethods.setValue('sgInstSaude', value);
                getFilasAtendimento(value);
                getLocaisAtendimento(value);
              }}
              name="sgInstSaude"
            >
              <option>Selecione a instituição</option>
              {!instHook.isLoading &&
                instHook.state.map((item, index) => {
                  return (
                    <option key={index} value={item.sgInstSaude}>
                      {item.instSaude}
                    </option>
                  );
                })}
            </Select>
            <span className="text-warning">
              <small>{formMethods.formState.errors.sgInstSaude?.message}</small>
            </span>
          </Col>
          <Col md="6">
            <Label className="label-single-line">
              <span>Selecione a Fila do especialista</span>
            </Label>
            <Select
              onChange={(event) => {
                event.persist();
                const { value } = event.target;
                setFilaSelecionada(value);
                formMethods.setValue('faCodigo', value);
              }}
              name="faCodigo"
              placeholder={'Selecione a fila'}
            >
              {filasAtendimento &&
                filasAtendimento.map((item: any, index) => {
                  return (
                    <option key={index} value={item.faCodigo}>
                      {item.faDescricao}
                    </option>
                  );
                })}
            </Select>
            <span className="text-warning">
              <small>{formMethods.formState.errors.faCodigo?.message}</small>
            </span>
          </Col>
          <Col md="6">
            <Label className="label-single-line" style={{ marginTop: '2rem' }}>
              <span>Selecione o Local do atendimento</span>
            </Label>
            <Select
              onChange={(event) => {
                event.persist();
                const { value } = event.target;
                getFilasAtendimento(value.split(',')[0]);

                formMethods.setValue('laDescricao', value.split(',')[1]);
              }}
              name="laDescricao"
              placeholder={'Local de atendimento'}
            >
              {instsSelected &&
                instsSelected
                  .filter((item: any) => item.sgInstSaude === instSelected)
                  .map((item: any, index) => {
                    return (
                      <option
                        key={index}
                        value={[item.sgInstSaude, item.laDescricao]}
                      >
                        {item.laNome}
                      </option>
                    );
                  })}
            </Select>
            <span className="text-warning">
              <small>{formMethods.formState.errors.laDescricao?.message}</small>
            </span>
          </Col>
          <Col md="6" style={{ marginTop: '2rem' }}>
            <Label htmlFor="">Informe o CPF do paciente</Label>
            <InputMask
              value={state.usuarioCPF}
              onChange={(evt) => handleUserCpf(evt)}
              id="usuario-cpf"
              name="usuarioCPF"
              className={'form-control'}
              mask="999.999.999-99"
              alwaysShowMask={true}
            />
            <span className="text-warning">
              <small>{cpfExistente == '' ? formMethods.formState.errors.usuarioCPF?.message : cpfExistente}</small>
            </span>
          </Col>
          <Col md="6">
            <Label className="mt-4 label-single-line" for="nomeSobrenome">
              Nome do Paciente
            </Label>
            <Input
              type="text"
              disabled
              className="form-control"
              name="nome"
              // required
              id="nome"
              value={paciente?.nmUsuario || ''}
              placeholder=""
            />
          </Col>
          <Col md="6">
            <Label className="mt-4 label-single-line" for="nomeSobrenome">
              Idade do Paciente
            </Label>
            <Input
              type="number"
              disabled
              className="form-control"
              name="idade"
              id="idade"
              value={
                paciente?.usuarioNasc && calcularIdade(paciente?.usuarioNasc)
              }
              placeholder="..."
            />
          </Col>
        </Row>
        <ModalFooter>
        <Button color="primary" type="submit" className="float-right" disabled={cpfExistente == '' ? false : true}>
            Iniciar Atendimento
          </Button>
        </ModalFooter>
      </Form>
    </Container>
  );
};
