import { Switch } from 'react-router-dom';
import { BASE_API_VERSION_PATH } from 'shared/util';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import { PerfilMenuRotas } from './_base/perfil-menu-rotas';

const apiUrl = BASE_API_VERSION_PATH + 'aswperfilmenurotas';

export const nPerfilMenuRotas = (props) => PerfilMenuRotas(props).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nPerfilMenuRotas} />
    </Switch>
  </>
);

export default Routes;
