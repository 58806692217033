import { useCallback } from 'react';

type UseFileSelectOptions = {
  multiple?: boolean;
  accept?: string;
};

export function useFileSelect<T = File | FileList>({
  multiple = false,
  accept,
}: UseFileSelectOptions = {}) {
  const openSelect: () => Promise<T> = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        if (multiple) {
          fileSelector.setAttribute('multiple', 'multiple');
        }
        if (accept) {
          fileSelector.setAttribute('accept', accept);
        }
        fileSelector.onchange = ({ target }) => {
          if (target) {
            const files = (target as HTMLInputElement).files as FileList;
            if (!files || files.length === 0) {
              reject(new Error('no files selected'));
            }
            if (multiple) {
              resolve(files as unknown as T);
            } else {
              resolve(files[0] as unknown as T);
            }
          }
        };
        fileSelector.click();
      }),
    [multiple, accept]
  );

  return openSelect;
}
