import { InputHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Label, Row } from 'reactstrap';
import { cepMask } from 'shared/util/cepMask';
import cpfMask from 'shared/util/cpfMask';
import phoneMask from 'shared/util/phoneMask';



type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  value: any;
};

export const InputRadio = ({ name, label, className, value, ...rest }: Props) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  const [restProps, setRestProps] = useState<Partial<Props>>({});

  const element = watch(name);

  useEffect(() => {
    if (errorMessage && element) {
      clearErrors(name);
    }
  }, [element]);

  return (
    <>
      <Row>
        <Col md="12">
          <input
            {...register(name)}
            type='radio'
            id={name}
            // className={className || 'form-control'}
            value={value}
            {...rest}
            {...restProps}
            style={{marginRight: '5px'}}
          />
          <Label className="pointer">
            {label}
          </Label>
          {!!errorMessage && (
            <span className="text-warning">
              <small>{errorMessage}</small>
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};
