import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import isValidCpf from 'shared/util/validacao-cpf';
import { useAlert, useApiRequest, useUser } from 'hooks';
import {
  ConvenioType,
  FormUsuarioSMSProps,
  getSchema,
  SMSPacienteType,
  TypePacienteAPI,
} from './types';
import { apiFind } from 'components/util/entity-utils';
import { LOG, onlyNumber } from 'shared/util';
import { TypeFile } from 'components/formUsuarioSMS/types';
import { useHistory, useParams } from 'react-router-dom';

export function useController({
  onlyView,
  findByCpf,
  perfil,
}: FormUsuarioSMSProps) {
  const user = useUser();
  const [cpfInvalido, setCpfInvalido] = useState<boolean>(false);
  const [cpfError, setCpfError] = useState<string>('');
  const [cpfSelected, setCpfSelected] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isEdit, setIsEdit] = useState(false);
  const [listaEpisodiosUsuarios, setListaEpisodioUsuarios] = useState([]);
  const cpfUrl = useParams<{ cpf: string }>().cpf;

  const alert = useAlert();

  const [openModalConv, setOpenModalConv] = useState(false);

  const [files, setFiles] = useState<TypeFile[]>([]);

  const userApi = useApiRequest<TypePacienteAPI>(
    'aswusuarios',
    {} as TypePacienteAPI
  );

  const history = useHistory();

  const convenioApi = useApiRequest<ConvenioType[]>('aswconvenioplano', []);

  const formMethods = useForm<SMSPacienteType>({
    resolver: yupResolver(getSchema(isEdit)),
    defaultValues: {},
    // defaultValues: {
    //   cpf: '49603432067',
    //   nome: 'Techtools SMS Paciente',
    //   email: `sms.paciente.${Date.now()}@techtools.com.br`,
    //   telefone: '(11) 99999-9999',
    //   nascimento: '1988-12-09',
    //   genero: 'M',
    //   cartaoSUS: '984554',
    //   identidade: '1565487',
    //   usuarioInstSaudePrefer: 'samuelLibaneo',
    //   cep: '19010-090',
    //   enderecoUsuario: 'Avenida Washington Luiz',
    //   numeroUsuario: '1000',
    //   complementoUsuario: '',
    //   bairro: 'Centro',
    //   cidadeUsuario: 'Presidente Prudente',
    //   ufUsuario: 'SP',
    // },
  });

  const handleFormSubmit = async () => {
    setOpenModalConv(true);
    return;
  };

  const handleChangeCpf = (cpf: string) => {
    setCpfError('');

    if (onlyNumber(cpf).length >= 11) {
      if (validarCPF(cpf)) {
        verificaCPF(cpf);
      }
    }
  };

  const handleChangeEmail = (email: string) => {
    let findedLetter = email?.includes('@');
    if (findedLetter) {
      verificaEmail(email);
    }
  };

  const verificaEmail = async (value: string) => {
    const res = await apiFind('aswusuarios', {
      body: { email: value },
      showMessageOnErro: false,
    });

    if (res['success'] !== false) {
      alert.warning({
        title: 'E-mail já cadastrado no sistema!',
      });

      setEmailError('Email já cadastrado');
      return false;
    } else {
      setEmailError('');
    }
  };

  const verificaCPF = async (value: string) => {
    setCpfError('');
    userApi
      .submit({
        method: '_findByCpf',
        sgCliente: user?.sgCliente,
        cpf: value,
      })
      .then((data) => {
        if (!!data?.data?.usuarioCPF) {
          // debugger
          if (!findByCpf && !onlyView) {
            alert.warning({
              title: 'CPF já cadastrado no sistema!',
            });
            setCpfError('CPF já cadastrado');
          }
          if (onlyView) {
            setCpfSelected(value);
            formMethods.setValue('nome', data.data.nmUsuario);
            formMethods.setValue('email', data.data.emailUsuario);
            formMethods.setValue('telefone', data.data.telefoneUsuario);
            formMethods.setValue('nascimento', data.data.usuarioNasc);
            formMethods.setValue('genero', data.data.usuarioGenero);
            formMethods.setValue('cep', data.data.usuarioCEP);
            formMethods.setValue('enderecoUsuario', data.data.usuarioEndereco);
            formMethods.setValue('numeroUsuario', data.data.usuarioNumero);
            formMethods.setValue('bairro', data.data.usuarioBairro);
            formMethods.setValue(
              'usuarioInstSaudePrefer',
              data.data.usuarioInstSaudePrefer
            );
            formMethods.setValue(
              'complementoUsuario',
              data.data.usuarioComplemento
            );
            formMethods.setValue('cidadeUsuario', data.data.usuarioCidade);
            formMethods.setValue('ufUsuario', data.data.usuarioUF);
            formMethods.setValue('cartaoSUS', data.data.cartaoSUS);
            formMethods.setValue('identidade', data.data.identidade);
            setListaEpisodioUsuarios(data.data.dadosEpisodio);
          }
        }
      });

    return;
  };

  const validarCPF = async (value: string) => {
    if (isValidCpf(value.match(/\d/g).join('')) == false) {
      setCpfInvalido(true);
      return true;
    } else {
      setCpfInvalido(false);
      return false;
    }
  };

  const instValue = formMethods.watch('usuarioInstSaudePrefer');
  useEffect(() => {
    if (instValue && !onlyView) {
      convenioApi
        .submit({
          sgInstSaude: instValue,
          method: '_listConvenioPlano',
        })
        .then((result) => {
          convenioApi.setState(
            result.data.convenios.map((c) => ({
              planos: c.planos,
              value: c.key,
              label: c.value,
            }))
          );
        });
    }
  }, [instValue]);

  const cpfValue = formMethods.watch('cpf');
  useEffect(() => {
    // if (onlyView || findByCpf) {
    handleChangeCpf(cpfValue);
    // }
  }, [cpfValue]);

  const emailValue = formMethods.watch('email');
  useEffect(() => {
    if (!onlyView) {
      handleChangeEmail(emailValue);
    }
  }, [emailValue]);

  useEffect(() => {
    if (cpfUrl) {
      formMethods.setValue('cpf', cpfUrl);
    }
  }, [cpfUrl]);

  // useEffect(() => {
  //   if (user && user.institucoes) {
  //     setGrupo(user.institucoes[0].grupo);
  //   }
  // }, [user]);

  const updateFiles = (
    files: Partial<TypeFile>[],
    descricao: TypeFile['descricao']
  ) => {
    setFiles((prev) => [
      ...prev.filter((f) => f.descricao !== descricao),
      ...(files.map((f) => ({
        ...f,
        descricao: descricao,
      })) as typeof prev),
    ]);
  };

  const handleRegisterUser = async () => {
    try {
      if (!cpfInvalido && !emailError) {
        const values = formMethods.getValues();
        setOpenModalConv(false);
        userApi
          .submit({
            method: '_cadastraPacienteSMS',
            sgCliente: user.sgCliente,
            sgInstSaude: instValue,

            files,

            nmUsuario: values.nome,
            emailUsuario: values.email,
            telefoneUsuario: values.telefone,
            usuarioCEP: values.cep,
            usuarioCPF: values.cpf,
            usuarioCidade: values.cidadeUsuario,
            usuarioEndereco: values.enderecoUsuario,
            usuarioGenero: values.genero,
            usuarioInstSaudePrefer: instValue,
            usuarioNasc: values.nascimento,
            usuarioNumero: values.numeroUsuario,
            usuarioUF: values.ufUsuario,
            usuarioBairro: values.bairro,
            usuarioComplemento: values.complementoUsuario,
            cartaoSUS: values.cartaoSUS,
            identidade: values.identidade,
            usuarioRG: values.identidade,
          })
          .then((result) => {
            if (result.success) {
              alert.success({ title: 'Paciente cadastrado' });
              convenioApi.setState([]);
              userApi.setState({});
              setFiles([]);
              formMethods.reset();
              return;
            }
          });
      }
    } catch (error) {
      LOG('Catch no handleRegisterUser', error);
    }
  };

  return {
    cpfInvalido,
    cpfError,
    emailError,
    handleFormSubmit,
    formMethods,
    user,
    updateFiles,
    verificaCPF,
    openModalConv,
    setOpenModalConv,
    usuarioInstSaudePrefer: instValue,
    convenioPlanoList: convenioApi.state,
    handleRegisterUser,
    isLoading: userApi.isLoading,
    files,
    onlyView,
    userApi,
    listaEpisodiosUsuarios,
    history,
    cpfValue,
    cpfSelected,
    // grupo,
    perfil,
  };
}
