import {
  Modal as ModalChakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure
} from '@chakra-ui/react';
import { useConsultaEmergencialContext } from 'contexts/ConsultaEmergencial';
import { useApiRequest } from 'hooks';
import { useRef, useState } from 'react';
import { Button, Col, Row, Table } from 'reactstrap';
import { dateTimeToScreen } from 'shared/util';

export const DocumentosExames = () => {
  const { consulta: dadosConsulta } = useConsultaEmergencialContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [img, setimg] = useState({
    img: '',
    nome: '',
  });
  const [loadId, setLoadId] = useState<number>();
  const finalRef = useRef();

  const docApi = useApiRequest('aswdocumentosexames', {});

  return (
    <>
      <Row>
        <Col md="12">
          <Table
            id="atendimento-table-list"
            responsive
            aria-describedby="atendimento-heading"
            className={
              'table-hover table-striped table-responsive-css td-atendimentos'
            }
          >
            <thead className={'thead-light'}>
              <tr>
                <th>
                  <span>Data de inclusão</span>
                </th>
                <th>
                  <span>Nome</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {(dadosConsulta?.documentos || []).map((item, i: number) => (
                <tr key={i}>
                  <td id="horario-cell">
                    {dateTimeToScreen(new Date(item.dtInclusao))}
                  </td>

                  <td id="status-cell">
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '100%' }}>{item.pdNome}</div>
                      <Button
                        color="primary"
                        className={'btn-sm'}
                        onClick={() => {
                          setLoadId(i);
                          docApi
                            .submit({
                              method: '_get',
                              link: item.pdLink,
                            })
                            .then((res) => {
                              setimg({
                                img: res.body.image,
                                nome: item.pdNome,
                              });
                              onOpen();
                            });
                        }}
                      >
                        <i className="fa fa-search" />
                        {docApi.isLoading && loadId == i && (
                          <>
                            &nbsp;
                            <Spinner size="sm" />
                          </>
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <ModalChakra finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{img.nome}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <img src={img.img} style={{ borderRadius: 10 }} />
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalChakra>
    </>
  );
};
