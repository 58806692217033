import { Grid, GridItem, Select, SimpleGrid } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { FormEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Loading } from 'components/Loading';
import { useAlert, useUser } from 'hooks';
import { useApiRequest } from 'hooks/useApi';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY } from 'shared/util';
import { onlyNumber } from 'shared/util/number';
import { colors } from 'theme/colors';

import { AvailableSchedule } from './components/AvailableSchedule';
import { FormOptions } from './components/FormOptions';
import { ConfirmScheduleContainer, ScheduleForm } from './styles';
import { Convenio, DadosUsuarioType, Instituicao, KeyValue, LoadUserDataByCPFResultType, Plano } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

const MySwal = withReactContent(Swal);

type TipoAgendaType = '' | 'E' | 'F' | 'T';
type LocalType = { laDescricao: string; laNome: string };

export const AgendarPaciente = () => {
  const user = useUser();
  const alert = useAlert();
  const [paciente, setPaciente] = useState<DadosUsuarioType | null>(null);

  const [instSelected, setInstSelected] = useState('');
  const [convenioSelected, setConvenioSelected] = useState<KeyValue>();
  const [planoSelected, setPlanoSelected] = useState<KeyValue>();

  const especialidadesHook = useApiRequest<KeyValue[]>('aswagendarusuario', []);
  const [locais, setLocais] = useState<LocalType[]>([]);
  const [laDescricao, setLaDescricao] = useState('');
  const [examTypes, setExamTypes] = useState([]);
  const [typeSelected, setTypeSelected] = useState<any>(null);
  const [examSpeciality, setExamSpeciality] = useState([]);
  const [examSpecialitySelected, setExamSpecialitySelected] =
    useState<any>(null);
  const [exams, setExams] = useState([]);
  const [examSelected, setExamSeleted] = useState<any>(null);
  const [availableHours, setAvailableHours] = useState([]);

  const profissionaisHook = useApiRequest<
    {
      psCPF: string;
      psNome: string;
      peDescricao: string;
    }[]
  >('aswagendarusuario', []);

  const pacienteHook = useApiRequest<LoadUserDataByCPFResultType>(
    'aswagendarusuario',
    {} as LoadUserDataByCPFResultType
  );

  const [state, setState] = useState<any>({
    conveniosData: [],
    planosData: [],
    instSaudeData: [],
    especialidadesData: [],
    profissionalSaudeData: [],
  });

  const [loading, setLoading] = useState(false);

  const [especialidadeSelected, setEspecialidadeSelected] = useState('');
  const [profissionalSaudeSelected, setProfissionalSaudeSelected] =
    useState('');

  const [tipoAgenda, setTipoAgenda] = useState<TipoAgendaType>();
  const selecionouTipoAtendimento = !!tipoAgenda;

  const reset = () => {
    setInstSelected('');
    setConvenioSelected(null);
    setPlanoSelected(null);
    pacienteHook.setState({});
    setPaciente(null);
    setProfissionalSaudeSelected('');
  };

  const loadUserDataByCPF = async (value: string) => {
    try {
      reset();

      const res = await pacienteHook.submit({
        method: '_findUserByCPF',
        usuarioCPF: onlyNumber(value),
        sgCliente: user?.sgCliente,
      });

      if (res.success && res.data) {
        pacienteHook.setState(res.data);

        setPaciente(res.data.dadosUsuario);

        const { usuarioInstSaudePrefer } = res.data.dadosUsuario;
        if (tipoAgenda === 'F') {
          await loadEspecialidades(res.data.dadosUsuario.usuarioCPF);
        } else {
          const { data } = await api.post('aswexame', {
            method: '_listTypes',
            Authorization: Cookies.get(AUTH_TOKEN_KEY),
          });
          const reformattedArray = data.data.map((item) => ({
            key: item.value,
            value: item.label,
          }));

          setExamTypes(reformattedArray);
        }
        if (!!usuarioInstSaudePrefer) {
          setInstSelected(usuarioInstSaudePrefer);
        }
        return;
      }

      MySwal.fire({
        title: 'Usuário não encontrado',
        html:
          res?.data?.message ||
          'Erro ao encontrar usuário, verifique se o mesmo possuí cadastro ativo',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: colors.yellow,
      });
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const loadEspecialidades = async (cpf: string) => {
    await especialidadesHook.request({
      method: '_loadEspecialidades',
      sgInstSaude: 'techtools',
      usuarioCPF: cpf,
      sgCliente: user?.sgCliente,
    });
  };

  useEffect(() => {
    if (!!planoSelected?.key) {
      loadEspecialidades(pacienteHook?.state?.dadosUsuario?.usuarioCPF);
    }
  }, [planoSelected]);

  const getHorasDisponiveisComPeriodos = async (value: any) => {
    if (tipoAgenda === 'F' && !laDescricao.trim()) {
      alert.warning({
        title: 'Selecione local',
      });

      return;
    }
    const formatedValue = moment(value).format('YYYY-MM-DD');
    if (tipoAgenda === 'F' || tipoAgenda === 'T') {
      const { data } = await api.post('/aswagendarusuario', {
        caDtCarga: formatedValue,
        psCPF: profissionalSaudeSelected,
        sgInstSaude: 'techtools',
        method: '_loadHorariosDisponiveis',
        agTeleFisico: tipoAgenda,
        laDescricao: tipoAgenda === 'T' ? 'fake' : laDescricao,
        sgCliente: user?.sgCliente,
      });

      const horariosDisponiveis = {
        madrugada: data.data.madrugada?.length > 0 ? data.data.madrugada : [],
        manha: data.data.manha?.length > 0 ? data.data.manha : [],
        tarde: data.data.tarde?.length > 0 ? data.data.tarde : [],
        noite: data.data.noite?.length > 0 ? data.data.noite : [],
      };

      setState({
        ...state,
        convenioValidade: value,
        periodoSelecionado: horariosDisponiveis.madrugada?.length
          ? 'madrugada'
          : horariosDisponiveis.manha?.length
          ? 'manha'
          : horariosDisponiveis.tarde?.length
          ? 'tarde'
          : horariosDisponiveis.tarde?.length && 'noite',
        horariosDisponiveis,
      });
    } else {
      setState({ convenioSelected: value });
      const { data } = await api.post('aswexame', {
        method: '_findDayExamSchedule',
        Authorization: Cookies.get(AUTH_TOKEN_KEY),
        exCodigo: parseInt(examSelected),
        agDataHoraAgendada: formatedValue,
      });

      console.log(data.data);

      setAvailableHours(data.data);
    }
  };

  const handleChangeEspecialidade = async (peDescricao: string) => {
    if (!paciente?.usuarioCPF) {
      return;
    }

    setLoading(true);
    setEspecialidadeSelected(peDescricao);

    await profissionaisHook.request({
      method: '_findProfissionaisSaudeByEspecialidade',
      sgInstSaude: 'techtools',
      peDescricao,
      sgCliente: user?.sgCliente,
    });
    setLoading(false);
  };

  const handleScheduleForm = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { cpf, periodoSelecionado, horarioSelecionado, convenioValidade } =
      state;
    try {
      const { data } = await api.post('/aswagendarusuario', {
        cpf,
        instSelected: 'techtools',
        profissionalSaudeSelected,
        especialidadeSelected,
        sgConvenio: 'cvtechtool',
        planoSelected: 'Convenio Techtools Demonstração',
        periodoSelecionado,
        horarioSelecionado,
        dataHoraAgenda: convenioValidade,
        method: '_agendar',
        agTeleFisico: 'F',
        laDescricao: laDescricao,
        sgCliente: 'asg',
      });
      MySwal.fire({
        title:
          data?.message ||
          (!!data?.success
            ? 'Agenda efetuada com sucesso'
            : 'Não foi possível agendar'),
        icon: !!data.success ? 'success' : 'warning',
      });

      if (!!data?.success) {
        resetForm();
      }
    } catch (err) {
      MySwal.fire({
        title: 'Houve um erro ao tentar agendar',
        icon: 'error',
      });
    } finally {
      reset();
    }
    setLoading(false);
  };

  const handleChangeProfessional = (option) => {
    setProfissionalSaudeSelected(option.target.value);
    console.log(option.target.value);
    if (option.target.value !== profissionalSaudeSelected) {
      setState({
        ...state,
        convenioValidade: '',
        periodoSelecionado: '',
        horariosDisponiveis: '',
      });
    }

    if (tipoAgenda === 'F') {
      handleChangeSelectLocal(option.target.value);
    }
  };

  const handleUserCpf = (e) => {
    setState({
      ...state,
      cpf: e.target.value,
    });
  };

  const handleChangeSelectLocal = async (psCPF: string) => {
    setLoading(true);
    const { data: listaLocal } = await api.post('aswagendalocaisatendimento', {
      method: 'listarLocalAtendimentoPs',
      sgInstSaude: 'techtools',
      psCPF,
      sgCliente: user?.sgCliente,
    });

    setLocais(listaLocal.data);
    setLoading(false);
  };

  const handleChangeRadioButton = (value: TipoAgendaType) => {
    resetForm();
    setTipoAgenda(value);
  };

  const resetForm = () => {
    setPaciente(null);
    // handleChangeEspecialidade(null);
    setLaDescricao('');
    setState({
      cpf: '',
      convenioValidade: '',
      usuarioNomeSocial: '',
      periodoSelecionado: '',
      horariosDisponiveis: '',
      conveniosData: [],
      planosData: [],
      instSaudeData: [],
      especialidadesData: [],
      profissionalSaudeData: [],
    });

    setProfissionalSaudeSelected('');
    setEspecialidadeSelected('');
    setLaDescricao('');
    profissionaisHook.setState([]);
  };

  const getConvenios = () =>
    pacienteHook?.state?.instConvPlanos?.find(
      (i) =>
        i.key === instSelected ||
        i.key === pacienteHook?.state?.dadosUsuario?.usuarioInstSaudePrefer
    )?.convenios || [];

  const getPlanos = () =>
    getConvenios()
      .find((c) => c.key === convenioSelected?.key)
      ?.planos.map((p, i) => {
        return { value: p.value, key: i.toString() };
      }) || [];

  const loadExamSpecialities = async (type) => {
    try {
      const { data } = await api.post('aswexame', {
        method: '_listSpecialitiesOfType',
        Authorization: Cookies.get(AUTH_TOKEN_KEY),
        type: parseInt(type),
      });
      console.log(data);
      const reformattedArray = data.data.map((item) => ({
        key: item.value,
        value: item.label,
      }));

      setExamSpeciality(reformattedArray);
    } catch (err) {
      console.log(err);
    }
  };

  const loadExams = async (esCodigo) => {
    try {
      const { data } = await api.post('aswexame', {
        method: '_listExamsOfSpeciality',
        Authorization: Cookies.get(AUTH_TOKEN_KEY),
        esCodigo: parseInt(esCodigo),
      });
      const reformattedArray = data.data.map((item) => ({
        key: item.value,
        value: item.label,
      }));

      setExams(reformattedArray);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {loading && <Loading />}
      <div>
        <h4 id="agendar-paciente-heading" data-cy="AgendarPacienteHeading">
          <span>Agenda de usuário</span>
        </h4>
        <ScheduleForm>
          <Row style={{ maxWidth: '20rem', marginTop: '2rem' }} xs="2">
            <Label
              className="pointer"
              onClick={() => {
                handleChangeRadioButton('E');
              }}
              check={tipoAgenda === 'E'}
            >
              <Input type="radio" name="radio1" /> Exame
            </Label>
            <Label
              className="pointer"
              onClick={() => {
                handleChangeRadioButton('F');
              }}
              check={tipoAgenda === 'F'}
            >
              <Input type="radio" name="radio1" /> Consulta
            </Label>
          </Row>
          {tipoAgenda && (
            <div>
              {selecionouTipoAtendimento && (
                <>
                  <Row className="row-agendar-paciente-first-column">
                    <Col md="6">
                      <Label className="mt-4 label-single-line" for="cpf">
                        ID Utente
                      </Label>
                      <input
                        type="text"
                        className="form-control w-100"
                        name="cpf"
                        required
                        id="usuario-cpf"
                        value={state.cpf}
                        onChange={(evt) => handleUserCpf(evt)}
                        onBlur={() => {
                          setLoading(true);
                          loadUserDataByCPF(state.cpf);
                          const naoSelecionouTipo = !tipoAgenda;
                          if (naoSelecionouTipo) {
                            alert.warning({
                              title: 'Selecione tipo de atendimento',
                            });
                            return;
                          }
                        }}
                      />
                    </Col>
                    <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        for="nomeSobrenome"
                      >
                        Nome do usuário
                      </Label>
                      <input
                        type="text"
                        className="form-control w-100"
                        name="nomeSobrenome"
                        required
                        id="nomeSobrenome"
                        value={paciente?.nmUsuario || ''}
                        disabled
                        placeholder="..."
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        for="usuarioNomeSocial"
                      >
                        Nome social
                      </Label>
                      <Input
                        disabled
                        name="usuarioNomeSocial"
                        placeholder="..."
                        value={paciente?.usuarioNomeSocial || ''}
                        required
                      />
                    </Col>
                    {/* <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        for="instSaudePref"
                      >
                        Instituição de Saúde
                      </Label>

                      <FormOptions
                        id="instSaudePref"
                        name="instituicoes"
                        value={instSelected}
                        options={pacienteHook?.state?.instConvPlanos || []}
                        onChange={(option) => {
                          handleChangeInst(
                            option.target.value,
                            pacienteHook?.state?.instConvPlanos || [],
                            paciente.usuarioCPF
                          );
                        }}
                      />
                    </Col> */}
                    {/* <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        htmlFor="convenio-nome-agendamento"
                      >
                        Selecione Convênio para consulta
                      </Label>

                      <FormOptions
                        id="convenio-nome-agendamentos"
                        name="convenio-nome"
                        placeholder="Selecione..."
                        options={getConvenios()}
                        value={convenioSelected?.key || ''}
                        onChange={(option) => {
                          const conv = getConvenios().find(
                            (i) => i.key === option.target.value
                          );
                          if (conv) {
                            setConvenioSelected({
                              key: conv.key,
                              value: conv.value,
                            });
                            // setPlanos(conv.planos);
                          }
                        }}
                      />
                    </Col> */}
                    {/* <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        htmlFor="plano-nome-agendamento"
                      >
                        Selecione Plano para consulta
                      </Label>
                      <FormOptions
                        options={getPlanos()}
                        id="plano-nome-agendamento"
                        name="plano-nome"
                        placeholder="Selecione..."
                        value={planoSelected?.key}
                        onChange={(option) => {
                          const plano = getPlanos().find(
                            (i) => i.key === option.target.value
                          );
                          if (plano) {
                            setPlanoSelected({
                              key: plano.key,
                              value: plano.value,
                            });
                          }
                        }}
                      />
                    </Col> */}
                    {tipoAgenda === 'F' && (
                      <>
                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="especialidade-profissional-agendamento"
                          >
                            Selecione a especialidade
                          </Label>

                          <FormOptions
                            options={especialidadesHook.state}
                            id="especialidade-profissional-agendamento"
                            name="especialidade-profissional-agendamento"
                            placeholder="Selecione..."
                            value={especialidadeSelected}
                            onChange={(option) => {
                              handleChangeEspecialidade(option.target.value);
                            }}
                          />
                        </Col>

                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="profissional-saude-agendamento"
                          >
                            Profissional de Saúde
                          </Label>

                          <FormOptions
                            options={profissionaisHook.state.map((item) => ({
                              key: item.psCPF,
                              value: item.psNome,
                            }))}
                            id="profissional-saude-agendamento"
                            name="profissional-saude-agendamento"
                            placeholder="Selecione..."
                            value={profissionalSaudeSelected}
                            onChange={(e) => handleChangeProfessional(e)}
                          />
                        </Col>

                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="especialidade-profissional-agendamento"
                          >
                            Selecione o local de atendimento
                          </Label>
                          <Select
                            maxH={'36px'}
                            borderRadius={'10px'}
                            value={laDescricao}
                            onChange={(event) => {
                              const { value } = event.target;
                              event.persist();
                              setLaDescricao(value);
                            }}
                            placeholder={'Selecione...'}
                          >
                            {locais &&
                              locais.map((item, index) => {
                                return (
                                  <option key={index} value={item.laDescricao}>
                                    {item.laNome}
                                  </option>
                                );
                              })}
                          </Select>
                        </Col>
                      </>
                    )}

                    {tipoAgenda === 'E' && (
                      <>
                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="especialidade-profissional-agendamento"
                          >
                            Selecione o tipo
                          </Label>

                          <FormOptions
                            options={examTypes}
                            id="especialidade-profissional-agendamento"
                            name="especialidade-profissional-agendamento"
                            placeholder="Selecione..."
                            value={typeSelected}
                            onChange={(option) => {
                              setTypeSelected(option.target.value);
                              loadExamSpecialities(option.target.value);
                            }}
                          />
                        </Col>

                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="profissional-saude-agendamento"
                          >
                            Selecione a especialidade
                          </Label>

                          <FormOptions
                            options={examSpeciality}
                            id="profissional-saude-agendamento"
                            name="profissional-saude-agendamento"
                            placeholder="Selecione..."
                            value={examSpecialitySelected}
                            onChange={(option) => {
                              setExamSpecialitySelected(option.target.value);
                              loadExams(option.target.value);
                            }}
                          />
                        </Col>

                        <Col md="6">
                          <Label
                            className="mt-4 label-single-line"
                            htmlFor="profissional-saude-agendamento"
                          >
                            Selecione o exame
                          </Label>

                          <FormOptions
                            options={exams}
                            id="profissional-saude-agendamento"
                            name="profissional-saude-agendamento"
                            placeholder="Selecione..."
                            value={examSelected}
                            onChange={(option) => {
                              setExamSeleted(option.target.value);
                            }}
                          />
                        </Col>
                      </>
                    )}
                    <Col md="6">
                      <Label
                        className="mt-4 label-single-line"
                        htmlFor="convenioValidade"
                      >
                        Data do Agendamento
                      </Label>
                      <input
                        type="date"
                        onChange={(event) => {
                          getHorasDisponiveisComPeriodos(event.target.value);
                        }}
                        value={state.convenioValidade}
                        id="convenioValidade"
                        className="form-control"
                        name="convenioValidade"
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </Col>
                  </Row>
                  {state?.horariosDisponiveis && (
                    <AvailableSchedule state={state} setState={setState} />
                  )}
                  {availableHours && (
                    <Grid templateColumns="repeat(5, 1fr)" gap={6} p={4}>
                      {availableHours.map((item, key) => (
                        <>
                          {item.available && (
                            <GridItem
                              w="100%"
                              h="10"
                              bg="blue.500"
                              borderRadius="10"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              color="white"
                              key={key}
                              cursor="pointer"
                              onClick={() => {
                                console.log(state.convenioValidade);
                              }}
                            >
                              {item.hour}
                            </GridItem>
                          )}
                        </>
                      ))}
                    </Grid>
                  )}
                  <ConfirmScheduleContainer>
                    {!state?.horarioSelecionado ? (
                      <button disabled>Confirmar agendamento</button>
                    ) : (
                      <button
                        onClick={(e) => handleScheduleForm(e)}
                        type="submit"
                      >
                        Confirmar agendamento
                      </button>
                    )}
                  </ConfirmScheduleContainer>
                </>
              )}
            </div>
          )}
        </ScheduleForm>
      </div>
    </>
  );
};

export default AgendarPaciente;
