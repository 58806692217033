import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import Page from './components/pages';

const Routes = ({ match }) => (
  <Switch>
    <ErrorBoundaryRoute path={match.path} component={Page} />
  </Switch>
);

export default Routes;
