import moment from 'moment';
import { AgendaPresencialType } from 'pages/atendimento-triagem';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Pagination } from '../../../../components/pagination';

type Props = {
  agendamentos: AgendaPresencialType[];
};

export const TabelaTriagem = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  //página
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = props.agendamentos.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const history = useHistory();

  return (
    <div className="table-responsive">
      <>
        {props.agendamentos.length > 0 ? (
          <div id="atendimento-table-list" className="table-list">
            <Table
              id="atendimento-table-list"
              responsive
              aria-describedby="atendimento-heading"
              className={
                'table-hover table-striped table-responsive-css td-atendimentos'
              }
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="text-left">Horário de agendamento</th>
                  <th className="text-left">Profissional de Saúde</th>
                  <th className="text-left">Especialidade</th>
                  <th className="text-left">Paciente</th>
                  <th className="text-left">CPF</th>
                  <th className="text-left">Idade</th>
                  <th className="text-left">Gênero</th>
                  <th className="text-left">Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((atendimento: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="horario-cell" className="text-left">
                      <b className="visible-xs">Horário de Atendimento</b>
                      {moment(atendimento.agendamento).format('HH:mm')} -{' '}
                      {moment(atendimento.agendamento)
                        .add(Number(atendimento.tempoMedioConsulta), 'minutes')
                        .format('HH:mm')}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Profissional de Saúde</b>
                      {atendimento.nome}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Especialidade</b>
                      {atendimento.descricao}
                    </td>
                    <td id="paciente-cell" className="text-left">
                      <b className="visible-xs"> Paciente </b>
                      {atendimento.usuario}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">CPF</b>
                      {atendimento.usuarioCPF}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Idade</b>
                      {atendimento.idade}
                    </td>
                    <td className="text-left">
                      <b className="visible-xs">Gênero</b>
                      {atendimento.genero == 'F' ? 'Feminino' : 'Masculino'}
                    </td>
                    <td id="status-cell" className="text-left">
                      <b className="visible-xs"> Status </b>
                      {atendimento.status ? (
                        <span
                          className={
                            atendimento.status === 'realizado'
                              ? 'atendimento-status-realizado'
                              : atendimento.status === 'cancelado'
                                ? 'atendimento-status-cancelado'
                                : atendimento.status === 'confirmado'
                                  ? 'atendimento-status-confirmado'
                                  : 'atendimento-status-sem-atendimento'
                          }
                        >
                          {atendimento.status}
                        </span>
                      ) : (
                        <> </>
                      )}
                    </td>

                    <td className="text-left">
                      <div className="btn-group flex-btn-group-container">
                        <button
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Ir para triagem"
                          className="btn btn-primary btn-sm radius-50 square-30px"
                          onClick={() => {
                            history.push(
                              `/atendimento-triagem/${atendimento.idConsulta}`
                            );
                          }}
                        >
                          <i className="fa fa-play" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {props.agendamentos.length > 10 && (
              <div>   <Pagination
                postsPerPage={postsPerPage}
                totalPosts={props.agendamentos.length}
                paginate={paginate}
              />
              </div>
            )}
          </div>
        ) : (
          <div className="alert alert-warning">
            Não foram encontrados atendimentos
          </div>
        )}
      </>
    </div>
  );
};
