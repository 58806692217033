import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prontuario, ViciosType } from 'contexts/Consulta/types';
import { useConsultaEmergencialContext } from 'contexts/ConsultaEmergencial';
import { useAlert, useApiRequest } from 'hooks';
import { useEffect, useRef } from 'react';
import { Button, Col, Label, Row, Spinner } from 'reactstrap';
import { DateInputForm, DateInputFormRefProps } from '../date-input-form';

type ButtonTextProps = {
  type: 'confirm' | 'cancel';
};

const ButtonText = ({ type }: ButtonTextProps) => (
  <>
    {' '}
    <i className={type === 'cancel' ? 'fa fa-ban' : 'fa fa-trash'} />{' '}
    {type === 'cancel' ? 'Cancelar' : 'Apagar'}{' '}
  </>
);

export const HistoricoPessoal = () => {
  const alert = useAlert();
  const { consulta: dadosConsulta } = useConsultaEmergencialContext();

  const habitosViciosApi = useApiRequest<ViciosType[]>('aswusuprontvicios', []);
  const prontuarioApi = useApiRequest<Prontuario>('aswusuarioprontuario', {
    upAntecedentesFamiliar: '',
  });

  const habitosRef = useRef<DateInputFormRefProps>(null);

  const actionModal = (
    title: string,
    text: string,
    onConfirm: () => Promise<void>
  ) => {
    alert
      .warning({
        title: title,
        html: text,
        showCancelButton: true,
        cancelButtonText: <ButtonText type="cancel" />,
        confirmButtonText: <ButtonText type="confirm" />,
      })
      .then(async (response) => {
        if (response.isConfirmed) {
          await onConfirm();
          alert.success({
            title: 'Apagado com successo',
          });
        }
      });
  };

  useEffect(() => {
    if (dadosConsulta?.dadosConsulta.usuarioCPF) {
      habitosViciosApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta?.dadosConsulta.usuarioCPF,
      });
      prontuarioApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta?.dadosConsulta.usuarioCPF,
      });
    }
  }, [dadosConsulta?.dadosConsulta]);

  return (
    <Row>
      <Col md="12">
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="p-2 label-single-line">
              Hábitos e Vícios{' '}
              {habitosViciosApi.isLoading && <Spinner size="sm" />}
            </Label>
            <Button
              onClick={() => {
                habitosRef?.current?.open();
              }}
            >
              Adicionar
              <FontAwesomeIcon icon="plus" style={{ marginLeft: '4px' }} />
            </Button>
          </Col>
          <Col md="12">
            <div className="form-control">
              {habitosViciosApi.state.map((item) => {
                return (
                  <DateInputForm
                    hideDate
                    originalData={item}
                    key={item.pvGUID}
                    data={item.pvData}
                    descricao={item.pvDescricao}
                    onDelete={({ originalData }) => {
                      actionModal(
                        'Hábito e vício',
                        `Excluir ${originalData.pvDescricao} ?`,
                        async () => {
                          await habitosViciosApi.request({
                            method: '_delete',
                            usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                            id: originalData.pvGUID,
                          });
                        }
                      );
                    }}
                    onUpdate={async ({ data, descricao, originalData }) => {
                      await habitosViciosApi.request({
                        method: '_update',
                        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                        data,
                        descricao,
                        id: originalData.pvGUID,
                      });
                    }}
                  />
                );
              })}
              <DateInputForm
                hideDate
                ref={habitosRef}
                onSave={async ({ data, descricao }) => {
                  await habitosViciosApi.request({
                    method: '_insert',
                    usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                    data,
                    descricao,
                  });
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>

      <Col md="12">
        <div>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-between align-items-center"
            >
              <Label
                className="p-2 label-single-line"
                id="antedentesFamiliaresLabel"
                for="atendimento-usuarioProntuario"
              >
                Antecedentes Familiares{' '}
                {prontuarioApi.isLoading && <Spinner size="sm" />}
              </Label>
            </Col>
            <Col md="12">
              <div className="form-control">
                <textarea
                  style={{ minHeight: '70px' }}
                  className="form-control"
                  onChange={(evt) => {
                    prontuarioApi.setState({
                      upAntecedentesFamiliar: evt.target.value,
                    });
                  }}
                  value={prontuarioApi.state.upAntecedentesFamiliar}
                />
                <button
                  onClick={() => {
                    prontuarioApi.request({
                      method: '_insert',
                      usuarioCPF: dadosConsulta?.dadosConsulta.usuarioCPF,
                      descricao: prontuarioApi.state.upAntecedentesFamiliar,
                    });
                  }}
                >
                  salvar
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
