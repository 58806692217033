// @ts-nocheck

import LinkAgenda from '../components/LinkAgenda';

import {
  Translate,
  useTranslation,
  Link,
} from '../../../components/util/translate-component';
import React, { useState, useEffect } from 'react';
import {
  useRouter,
  nextCookie,
  jsonParse,
  GetServerSideProps,
  AppProps,
} from '../../../components/util/entity-utils';

import { BASE_API_VERSION_PATH, AUTH_TOKEN_KEY } from 'shared/util';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import cookie from 'js-cookie';

import 'react-quill/dist/quill.snow.css';

import ReactPaginate from 'react-paginate';
import 'react-datepicker/dist/react-datepicker.css';
// import { pt } from 'date-fns/esm/locale';
// registerLocale('pt', pt);
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export interface IProfissionalSaudeProps extends AppProps {
  cpf?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  profissionalSaudeList?: any;
  setProfissionalSaudeList?: Function;
  profissionalSaudeEntity?: any;
  setProfissionalSaudeEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getProfissionalSaudeState?: Function;
  returnFunction?: Function;
  agendamentoConsultas?: any;
  gestaoDeAgenda?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'api/profissional-saudes';

const getEntityFetch: any = async (
  context: IProfissionalSaudeProps,
  entity: any,
  selectFields = ''
) => {
  const requestUrl = `${apiUrl}/${entity.id}`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
    },
  });
  const json = await res.json();
  context.setProfissionalSaudeEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IProfissionalSaudeProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(apiUrl, {
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IProfissionalSaudeProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(`${apiUrl}`, {
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IProfissionalSaudeProps,
  _profissionalSaudeEntity: any,
  listFiltersPage: any
) => {
  await fetch(`${apiUrl}/${_profissionalSaudeEntity.id}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (
  context: IProfissionalSaudeProps,
  offset = null
) => {
  return (
    '' +
    (context.state.cpf ? 'cpf=' + context.state.cpf + '&' : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: IProfissionalSaudeProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const { cpf, extraFilters, activePage, itemsPerPage, sort, order } =
    context.state;

  const res = await context.getEntitiesFetch(
    cpf,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${
      _fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: res.headers.get('X-Total-Count'),
  });

  const json = await res.json();
  context.setProfissionalSaudeList(json);
};

const sortEntities = (
  context: IProfissionalSaudeProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IProfissionalSaudeProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (
  context: IProfissionalSaudeProps,
  activePage: any
) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IProfissionalSaudeProps,
  _profissionalSaudeEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="profissionalSaude.delete.question"
        interpolate={{ id: _profissionalSaudeEntity.id }}
      >
        Are you sure you want to delete this ProfissionalSaude?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="profissionalSaude.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="profissionalSaude.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_profissionalSaudeEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IProfissionalSaudeProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = [
    'nome',
    'cpf',
    'cnes',
    'regConsClasse',
    'rccOrgaoEmissor',
  ];

  if (errors.length === 0) {
    const _entity = {
      ...context.profissionalSaudeEntity,
      // ...values,

      id: context.state.idFormValue,
      nome: context.state.nomeFormValue,
      cpf: context.state.cpfFormValue,
      cnes: context.state.cnesFormValue,
      regConsClasse: context.state.regConsClasseFormValue,
      rccOrgaoEmissor: context.state.rccOrgaoEmissorFormValue,
      email: context.state.emailFormValue,
      cidadeRegistro: context.state.cidadeRegistroFormValue,
      ufRegistro: context.state.ufRegistroFormValue,
      descricao: context.state.descricaoFormValue,
      telefone: context.state.telefoneFormValue,
      codigo1: context.state.codigo1FormValue,
    };

    const entity = _entity;

    const { cpf, extraFilters, activePage, itemsPerPage, sort, order } =
      context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (context: IProfissionalSaudeProps, isNew: any) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="profissionalSaude.home.createLabel"
            >
              Create a ProfissionalSaude
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="profissionalSaude.home.editLabel"
            >
              Edit a ProfissionalSaude
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IProfissionalSaudeProps,
  _profissionalSaudeEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="profissional-saude-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-profissional-saude-first-column">
                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="nomeLabel"
                          for="profissional-saude-nome"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.nome"
                          >
                            Nome
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              nomeFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.nomeFormValue
                              ? context.state.nomeFormValue
                              : ''
                          }
                          id="profissional-saude-nome"
                          type="text"
                          name="nome"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="cpfLabel"
                          for="profissional-saude-cpf"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.cpf"
                          >
                            CPF
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              cpfFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.cpfFormValue
                              ? context.state.cpfFormValue
                              : ''
                          }
                          id="profissional-saude-cpf"
                          type="text"
                          name="cpf"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="cnesLabel"
                          for="profissional-saude-cnes"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.cnes"
                          >
                            CNES
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              cnesFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.cnesFormValue
                              ? context.state.cnesFormValue
                              : ''
                          }
                          id="profissional-saude-cnes"
                          type="text"
                          name="cnes"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="regConsClasseLabel"
                          for="profissional-saude-regConsClasse"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.regConsClasse"
                          >
                            Registro Conselho de Classe
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              regConsClasseFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.regConsClasseFormValue
                              ? context.state.regConsClasseFormValue
                              : ''
                          }
                          id="profissional-saude-regConsClasse"
                          type="text"
                          name="regConsClasse"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="rccOrgaoEmissorLabel"
                          for="profissional-saude-rccOrgaoEmissor"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.rccOrgaoEmissor"
                          >
                            Órgão Emissor
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rccOrgaoEmissorFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rccOrgaoEmissorFormValue
                              ? context.state.rccOrgaoEmissorFormValue
                              : ''
                          }
                          id="profissional-saude-rccOrgaoEmissor"
                          type="text"
                          name="rccOrgaoEmissor"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="emailLabel"
                          for="profissional-saude-email"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.email"
                          >
                            E-mail
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              emailFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.emailFormValue
                              ? context.state.emailFormValue
                              : ''
                          }
                          id="profissional-saude-email"
                          type="text"
                          name="email"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="ufRegistroLabel"
                          for="profissional-saude-ufRegistro"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.ufRegistro"
                          >
                            UF de Registro
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              ufRegistroFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.ufRegistroFormValue
                              ? context.state.ufRegistroFormValue
                              : ''
                          }
                          id="profissional-saude-ufRegistro"
                          type="text"
                          name="ufRegistro"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="descricaoLabel"
                          for="profissional-saude-descricao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.descricao"
                          >
                            Especialidade
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              descricaoFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.descricaoFormValue
                              ? context.state.descricaoFormValue
                              : ''
                          }
                          id="profissional-saude-descricao"
                          type="text"
                          name="descricao"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="telefoneLabel"
                          for="profissional-saude-telefone"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.telefone"
                          >
                            Telefone
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              telefoneFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.telefoneFormValue
                              ? context.state.telefoneFormValue
                              : ''
                          }
                          id="profissional-saude-telefone"
                          type="text"
                          name="telefone"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="codigo1Label"
                          for="profissional-saude-codigo1"
                        >
                          <Translate
                            t={context.t}
                            contentKey="profissionalSaude.codigo1"
                          >
                            CBO
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              codigo1FormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.codigo1FormValue
                              ? context.state.codigo1FormValue
                              : ''
                          }
                          id="profissional-saude-codigo1"
                          type="text"
                          name="codigo1"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IProfissionalSaudeProps) => {
  return (
    <Modal
      className="profissional-saude-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="profissionalSaude.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IProfissionalSaudeProps) => {
  return context.profissionalSaudeEntity &&
    (context.profissionalSaudeEntity.id ||
      context.profissionalSaudeEntity._id) ? (
    <Modal
      className="profissional-saude-update-modal"
      size={'xl'}
      isOpen={
        context.state.showModalEdit === context.profissionalSaudeEntity.id ||
        context.state.showModalEdit === context.profissionalSaudeEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.profissionalSaudeEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="profissionalSaude.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (context: IProfissionalSaudeProps, v: any) => {
  const p: any = null;

  const agendamentoConsultasEntity = v.agendamentoConsultas
    ? v.agendamentoConsultas
        .filter((p1: any) => p1)
        .map((p1: any) => ({
          ...p1,
          value: p1.id,
          label: p1['id'] ? p1.id : '',
        }))
    : v.agendamentoConsultas;

  const gestaoDeAgendasEntity = v.gestaoDeAgendas
    ? v.gestaoDeAgendas
        .filter((p1: any) => p1)
        .map((p1: any) => ({
          ...p1,
          value: p1.id,
          label: p1['id'] ? p1.id : '',
        }))
    : v.gestaoDeAgendas;

  return {
    idFormValue: v.id, // id,
    nomeFormValue: v.nome, // nome,
    cpfFormValue: v.cpf, // cpf,
    cnesFormValue: v.cnes, // cnes,
    regConsClasseFormValue: v.regConsClasse, // regConsClasse,
    rccOrgaoEmissorFormValue: v.rccOrgaoEmissor, // rccOrgaoEmissor,
    emailFormValue: v.email, // email,
    cidadeRegistroFormValue: v.cidadeRegistro, // cidadeRegistro,
    ufRegistroFormValue: v.ufRegistro, // ufRegistro,
    descricaoFormValue: v.descricao, // descricao,
    telefoneFormValue: v.telefone, // telefone,
    codigo1FormValue: v.codigo1, // codigo1,
    agendamentoConsultasFormValue: agendamentoConsultasEntity,
    gestaoDeAgendasFormValue: gestaoDeAgendasEntity,
  };
};

const openUpdateModal = (
  context: IProfissionalSaudeProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IProfissionalSaudeProps) => {
  const newState = {
    ...context.state,
    cpf: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IProfissionalSaudeProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IProfissionalSaudeProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'cpf' ? (
            <Col md="12" className="col-filter-profissional-saude-cpf">
              <Row className="mr-1 mt-1">
                <Label id="cpfLabel" for="profissional-saude-cpf">
                  <Translate t={context.t} contentKey="profissionalSaude.cpf">
                    CPF
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="cpf"
                  id="profissional-saude-cpf"
                  value={context.state.cpf}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      cpf: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IProfissionalSaudeProps) => {
  return (
    <>
      {context.profissionalSaudeList &&
      context.profissionalSaudeList.filter &&
      context.profissionalSaudeList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div id="profissional-saude-table-list" className="table-list">
          <Table
            id="profissional-saude-table-list"
            responsive
            aria-describedby="profissional-saude-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={context.sortFunction('nome')}>
                  <Translate
                    t={context.t}
                    contentKey="profissionalSaude.nome"
                  />
                  <i
                    className={
                      context.state.sort === 'nome'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('cpf')}>
                  <Translate t={context.t} contentKey="profissionalSaude.cpf" />
                  <i
                    className={
                      context.state.sort === 'cpf'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('cnes')}>
                  <Translate
                    t={context.t}
                    contentKey="profissionalSaude.cnes"
                  />
                  <i
                    className={
                      context.state.sort === 'cnes'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('regConsClasse')}
                >
                  <Translate
                    t={context.t}
                    contentKey="profissionalSaude.regConsClasse"
                  />
                  <i
                    className={
                      context.state.sort === 'regConsClasse'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('rccOrgaoEmissor')}
                >
                  <Translate
                    t={context.t}
                    contentKey="profissionalSaude.rccOrgaoEmissor"
                  />
                  <i
                    className={
                      context.state.sort === 'rccOrgaoEmissor'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th id="LinkAgenda-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="profissionalSaude.LinkAgenda"
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.profissionalSaudeList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((profissionalSaude: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="nome-cell">
                      <b className="visible-xs"> Nome </b>

                      {profissionalSaude.nome}
                    </td>

                    <td id="cpf-cell">
                      <b className="visible-xs"> CPF </b>

                      {profissionalSaude.cpf}
                    </td>

                    <td id="cnes-cell">
                      <b className="visible-xs"> CNES </b>

                      {profissionalSaude.cnes}
                    </td>

                    <td id="regConsClasse-cell">
                      <b className="visible-xs">
                        {' '}
                        Registro Conselho de Classe{' '}
                      </b>

                      {profissionalSaude.regConsClasse}
                    </td>

                    <td id="rccOrgaoEmissor-cell">
                      <b className="visible-xs"> Órgão Emissor </b>

                      {profissionalSaude.rccOrgaoEmissor}
                    </td>
                    <td id="LinkAgenda-cell">
                      {' '}
                      <LinkAgenda
                        baseProps={context.props}
                        setState={(_state: any) => context.setState(_state)}
                        baseState={context.state}
                        entityBase={profissionalSaude}
                      />
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.openUpdateModal(profissionalSaude)
                            }
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.profissionalSaudeList &&
                context.profissionalSaudeList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate t={context.t} contentKey="profissionalSaude.home.notFound">
            No Gerenciamento Profissionais Saúde found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IProfissionalSaudeProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IProfissionalSaudeProps) => {
  return (
    <div>
      <h4 id="profissional-saude-heading" data-cy="ProfissionalSaudeHeading">
        <Translate t={context.t} contentKey="profissionalSaude.home.title">
          Gerenciamento Profissionais Saúde
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                nomeFormValue: null,
                cpfFormValue: null,
                cnesFormValue: null,
                regConsClasseFormValue: null,
                rccOrgaoEmissorFormValue: null,
                emailFormValue: null,
                cidadeRegistroFormValue: null,
                ufRegistroFormValue: null,
                descricaoFormValue: null,
                telefoneFormValue: null,
                codigo1FormValue: null,

                agendamentoConsultasStartSelectOptions: undefined,
                agendamentoConsultasFormValue: null,

                gestaoDeAgendasStartSelectOptions: undefined,
                gestaoDeAgendasFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="profissionalSaude.btnNewItem">
              Cadastrar Novo Profissional de Saude
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => {}}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getProfissionalSaudeState = (
  context: IProfissionalSaudeProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const cpf = _urlBase.searchParams.get('cpf') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    cpf,
  };
};

export const getEntitiesFetch = async (
  context: IProfissionalSaudeProps,
  cpf: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map((v: any) => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const cpfRequest = cpf ? `cpf.contains=${cpf}&` : '';

  const requestUrl = `${apiUrl}?${extraFiltersRequest}${cpfRequest}${
    sort ? `page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'
  }`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer ' +
        (authorizationBearer
          ? authorizationBearer
          : cookie.get(AUTH_TOKEN_KEY)),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
      'Get-User-Account': getAccount ? '1' : '0',
    },
  });

  return res;
};

export const ProfissionalSaude = (props: IProfissionalSaudeProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [profissionalSaudeList, setProfissionalSaudeList] = useState(
    props.profissionalSaudeList ? props.profissionalSaudeList : ([] as any)
  );
  const [profissionalSaudeEntity, setProfissionalSaudeEntity] = useState(
    props.profissionalSaudeEntity ? props.profissionalSaudeEntity : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    cpf: props.cpf ? props.cpf : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    profissionalSaudeList,
    setProfissionalSaudeList,
    profissionalSaudeEntity,
    setProfissionalSaudeEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_profissionalSaudeEntity: any, isNew: any) =>
          props['renderBodyUpdate'](context, _profissionalSaudeEntity, isNew)
      : (_profissionalSaudeEntity: any, isNew: any) =>
          renderBodyUpdate(context, _profissionalSaudeEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: props['getEntitiesFetch']
      ? (
          cpf: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          props['getEntitiesFetch'](
            context,
            cpf,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          )
      : (
          cpf: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          getEntitiesFetch(
            context,
            cpf,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          ),

    createEntityFetch: props['createEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['createEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: props['updateEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['updateEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: props['deleteEntityFetch']
      ? (id: any, listFiltersPage: any) =>
          props['deleteEntityFetch'](context, id, listFiltersPage)
      : (id: any, listFiltersPage: any) =>
          deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch:
      /* props['getEntityFetch'] ? id => props['getEntityFetch'](context, id) : */ (
        id
      ) => getEntityFetch(context, id),

    getProfissionalSaudeState: props['getProfissionalSaudeState']
      ? (location) => props['getProfissionalSaudeState'](context, location)
      : (location) => getProfissionalSaudeState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getProfissionalSaudeState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.cpf,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      profissionalSaudeList: await res.json(),
    },
  };
};

export default ProfissionalSaude;
