import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Row, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { InstType } from 'pages/gerenciamento-profissional-saude/types';
import { api } from 'services/api';
import {
  changeInputText,
  dateTimeToScreen,
  dateTimeToServer,
} from 'shared/util';
import cpfMask from 'shared/util/cpfMask';
import { colors } from 'theme/colors';
import { ConsultaItemType } from 'types/consulta';

import { ModalDetalhe } from './components/modal-detalhe';
import { Container } from './styles';

const MySwal = withReactContent(Swal);

import DatePicker from 'react-datepicker';

export const LiberacaoAgendamento = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [textoFiltro, setTextoFiltro] = useState('');
  const [sgInstSaude, setSgInstSaude] = useState('');

  const { onlyMounted } = useOnlyMounted();
  const [instList, setInstList] = useState<InstType[]>([]);

  const [consultas, setConsultas] = useState<ConsultaItemType[]>([]);
  const [consultasFilter, setConsultasFilter] = useState<ConsultaItemType[]>(
    []
  );

  const [periodoNew, setPeriodoNew] = useState([new Date()]);

  const DataFiltros = (propsDataFiltros: any) => {
    const [startDate, setStartDate] = useState(propsDataFiltros.startDate);
    const [endDate, setEndDate] = useState(propsDataFiltros.endDate);

    const onChange = (dates: any) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start && end) {
        propsDataFiltros.onChange([start, end]);
      }
    };

    return (
      <div id={'gestao-agenda-data-filtro-container'}>
        <DatePicker
          id={'liberacao-agendamento-data-filtro'}
          placeholderText="Data Inicial - Data Final"
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
        />
      </div>
    );
  };

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  const getConsultas = (params) => {
    if (!params.value) return;
    setSgInstSaude(params.value);
    executaRequisicao(
      'aswconsultas',
      {
        method: '_listConstultasAutorizacao',
        sgInstSaude: params.value,
        dataInicial: periodoNew[0]
          ? dateTimeToServer(new Date(periodoNew[0]?.setHours(0, 0, 0)))
          : periodoNew[0],
        dataFinal: periodoNew[1]
          ? dateTimeToServer(new Date(periodoNew[1]?.setHours(0, 0, 0)))
          : '',
        pacienteNome: textoFiltro,
      },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setConsultas(data);
          setConsultasFilter(data);
        }
      }
    );
  };

  const handleAction = (
    tipo: 'aprovar' | 'cancelar',
    item: ConsultaItemType
  ) => {
    MySwal.fire({
      titleText:
        tipo === 'aprovar' ? 'Aprovar agendamento?' : 'Cancelar agendamento?',
      icon: tipo === 'aprovar' ? 'info' : 'warning',
      confirmButtonText: 'Ok',
      confirmButtonColor: colors.blue,
      showDenyButton: true,
      denyButtonText: 'Cancelar',
      denyButtonColor: colors.yellow,
    }).then((res) => {
      if (res.isConfirmed) {
        const paramsCancel = {
          mcDescricao: 'Nao autorizado pelo Convenio',
          mcPSPaciente: 'C',
        };

        if (tipo === 'aprovar') {
          delete paramsCancel.mcPSPaciente;
          delete paramsCancel.mcDescricao;
        }

        executaRequisicao(
          'aswconsultas',
          {
            method: tipo === 'aprovar' ? '_aprovar' : '_cancelar',
            agDataHoraAgenda: item.dataHora,
            usuarioCPF: item.pacienteCPF,
            psCPF: item.psCPF,
            sgInstSaude: item.sgInstSaude,
            ...paramsCancel,
          },
          ({ success, message }) => {
            const _consultas = consultas.filter(
              (c) =>
                `${item.dataHora}${item.pacienteCPF}${item.psCPF}${item.sgInstSaude}` !==
                `${c.dataHora}${c.pacienteCPF}${c.psCPF}${c.sgInstSaude}`
            );
            if (success) {
              setConsultasFilter(_consultas);
              setConsultas(_consultas);
            }
            if (!!message) {
              MySwal.fire({
                icon: success ? 'success' : 'warning',
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    executaRequisicao(
      'aswinstsaude',
      { method: '_listIsUsuarioLogado' },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setInstList(data);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = consultas.filter((consulta) => {
        return Object.values(consulta).some(
          (key) =>
            String(key).toLowerCase().includes(textoFiltro.toLowerCase()) ||
            String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setConsultasFilter(filtered);
    } else {
      setConsultasFilter(consultas);
    }
  }, [textoFiltro]);

  return (
    <Container>
      <div className="breadcrumb">
        <h6>AUTORIZAÇÃO DE CONSULTAS</h6>
      </div>
      <div className="d-flex align-items-end row ml-3 mr-3">
        <Col md="12">
          <Row className="mr-1 mt-1 ">
            <Col md="9">
              <span>Instituição de Saúde</span>
              <Select
                className={'css-select-control mh-40px'}
                placeholder={'Selecione a Instituição'}
                name={'instituicao'}
                options={instList.map((inst) => ({
                  label: inst.instSaude,
                  value: inst.sgInstSaude,
                }))}
                onChange={getConsultas}
              />
            </Col>
            <Col md="3">
              <span>Data de Pesquisa</span>
              <DataFiltros
                startDate={periodoNew[0]}
                endDate={periodoNew[1]}
                onChange={setPeriodoNew}
              />
            </Col>
          </Row>
        </Col>
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col md="12" className="col-filter-cadastro-cliente-cliente">
              <Row className="mr-1 mt-1">
                <input
                  className="form-control"
                  type="text"
                  id="cadastro-cliente-cliente"
                  placeholder={'Buscar nome'}
                  value={textoFiltro}
                  onChange={(evento) => setTextoFiltro(changeInputText(evento))}
                />
              </Row>
            </Col>
          </div>
          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button
              id="search-submit"
              color="primary"
              onClick={() => {
                getConsultas({ value: sgInstSaude });
              }}
            >
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>
      </div>

      <div>
        {consultasFilter.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Agendamento</th>
                  <th>Paciente</th>
                  <th>CPF</th>
                  <th>nº da Carteirinha - Validade </th>
                  <th>Especialidade</th>
                  <th>Convênio - Plano</th>
                  <th>Tipo de consulta</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {consultasFilter
                  .sort((a, b) => {
                    return (
                      new Date(a.dataHora).valueOf() -
                      new Date(b.dataHora).valueOf()
                    );
                  })
                  .map((consulta, i: number) => (
                    <tr
                      key={`${consulta.dataHora}${consulta.psCPF}${consulta.pacienteCPF}${consulta.convenio}`}
                    >
                      <td>{dateTimeToScreen(consulta.dataHora)}</td>
                      <td>{consulta.pacienteNome}</td>
                      <td>{cpfMask(consulta.pacienteCPF)}</td>
                      <td>
                        {consulta.numeroCartao} -{' '}
                        {new Date(consulta.validadeCartao).toLocaleDateString(
                          'pt-BR'
                        )}
                      </td>
                      <td>{consulta.descricaoPS}</td>
                      <td>
                        {consulta.convenio} - {consulta.planoConvenio}
                      </td>
                      <td>{consulta.agTeleFisico}</td>
                      {console.log(consulta.validadeCartao)}
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Aprovar"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => {
                              handleAction('aprovar', consulta);
                            }}
                          >
                            <i className="fa fa-thumbs-up" />
                          </button>
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Negar"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => {
                              handleAction('cancelar', consulta);
                            }}
                          >
                            <i className="fa fa-thumbs-down" />
                          </button>
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalhes"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => {
                              MySwal.fire({
                                showConfirmButton: false,
                                width: '50%',
                                html: (
                                  <ModalDetalhe
                                    consulta={consulta}
                                    aprovar={() => {
                                      handleAction('aprovar', consulta);
                                    }}
                                    cancelar={() => {
                                      handleAction('cancelar', consulta);
                                    }}
                                  />
                                ),
                              });
                            }}
                          >
                            <i className="fa fa-solid fa-eye" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}

        {!isLoading && !consultasFilter.length && (
          <div className="mt-4 alert alert-warning">Nada encontrado</div>
        )}
      </div>

      {isLoading && <Loading />}
    </Container>
  );
};
