import { Box, Tag, useDisclosure, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Button, Col, Label, Row, Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import Pagination from 'components/pagination';
import { useUser } from 'hooks';
import { api } from 'services/api';

import { ModalNovoUtente } from './components/modal-novo-utente';

import 'react-quill/dist/quill.snow.css';

export const CadastroPaciente = () => {
  const user = useUser();

  const [loading, setLoading] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [utentes, setUtentes] = useState([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [dataToEdit, setDataToEdit] = useState<any>();
  const [isNew, setIsNew] = useState(true);
  const toast = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = utentes
    ? utentes.slice(indexOfFirstPost, indexOfLastPost)
    : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = () => {
    listUtentes();
  };

  const listUtentes = async () => {
    try {
      setLoading(true);
      const { data } = await api.post('asmuser', {
        method: '_listarUtentes',
        filter: inputFilter,
      });

      if (data.success) {
        setLoading(false);

        setUtentes(data.data.utentes);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast({
        title: 'Não foi possível carregar os utentes',
        description: 'Entre em contato com o suporte!',
        status: 'error',
      });
    }
  };

  const formatDate = (date) => {
    const newDate = date.split('-');

    return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
  };

  useEffect(() => {
    listUtentes();
  }, []);

  return (
    <div>
      {loading && <Loading />}
      <h4
        id="gerenciamento-profissional-saude-heading"
        data-cy="GerenciamentoProfissionalSaudeHeading"
      >
        <div className="d-flex justify-content-between">
          <span>GERENCIAMENTO DE UTENTES</span>
          <Button
            onClick={() => {
              setIsNew(true);
              onOpen();
            }}
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <span>Cadastrar novo utente</span>
          </Button>
        </div>
      </h4>
      <Box w="full" h="full">
        <Box>
          <div id="form-filter">
            <div className="row mt-1 ml-3 mr-3">
              <Col
                md="12"
                className="col-filter-gerenciamento-profissional-saude-nome"
              >
                <Row className="mr-1 mt-1">
                  <Label
                    style={{ backgroundColor: 'red' }}
                    id="nomeLabel"
                    for="gerenciamento-profissional-saude-nome"
                  >
                    Profissional de Saúde
                  </Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Buscar utente"
                    name="nome"
                    id="gerenciamento-profissional-saude-nome"
                    value={inputFilter}
                    onChange={(event) => setInputFilter(event.target.value)}
                    onKeyDown={(key) => {
                      if (key.key === 'Enter') {
                        listUtentes();
                      }
                    }}
                  />
                </Row>
              </Col>
            </div>

            <div className="row mb-2 mr-4 justify-content-end pull-right">
              <Button id="search-submit" color="primary" onClick={handleSearch}>
                <i className="fa fa-search" />
              </Button>
            </div>
          </div>

          {utentes.length > 0 ? (
            <div id="atendimento-table-list" className="table-list">
              <Table
                id="atendimento-table-list"
                responsive
                aria-describedby="atendimento-heading"
                className={
                  'table-hover table-striped table-responsive-css td-atendimentos'
                }
              >
                <thead className={'thead-light'}>
                  <tr>
                    <th className="align-top">Utente</th>
                    <th className="align-top">Data de nascimento</th>
                    <th className="text-center  align-top">Telefone</th>
                    <th className="align-top">Sexo</th>
                    <th className="align-top">ID utente</th>
                    <th className="align-top">Estado</th>
                    <th className="text-center">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((utente) => (
                    <tr key={utente.usuarioCPF}>
                      <td>{utente.nmUsuario}</td>
                      <td>{formatDate(utente.usuarioNasc)}</td>
                      <td>{utente.telefoneUsuario}</td>
                      <td>{utente.usuarioGenero}</td>
                      <td>{utente.usuarioCPF}</td>
                      <td>
                        {utente.senhaAtualizada === 1 ||
                        utente.usuarioSenhaNova === 1 ? (
                          <Tag bgColor="#5acc6b68">Senha atualizada</Tag>
                        ) : (
                          <Tag bgColor="#cc5a5a5f">Senha desatualizada</Tag>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          {/* <Link> */}
                          <a
                            className={'btn btn-primary btn-lg'}
                            onClick={() => {
                              setDataToEdit(utente);
                              setIsNew(false);
                              onOpen();
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar"
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {utentes.length > 10 && (
                <div>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={utentes.length}
                    paginate={paginate}
                  />
                </div>
              )}
            </div>
          ) : (
            <Box
              w="full"
              display="flex"
              bgColor="#004b0241"
              p={4}
              borderRadius="8px"
              justifyContent="center"
            >
              Nenhum utente encontrado
            </Box>
          )}
        </Box>
      </Box>
      <ModalNovoUtente
        isNew={isNew}
        isOpen={isOpen}
        onClose={onClose}
        dataToEdit={dataToEdit}
      />
    </div>
  );
};
