import React from 'react';
import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';

import { PeriodoSemAtividade } from './_base/periodo-sem-atividade';
export const nPeriodoSemAtividade = () => PeriodoSemAtividade({} as any).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nPeriodoSemAtividade} />
    </Switch>
  </>
);

export default Routes;
