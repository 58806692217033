// @ts-nocheck

import cookie from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css';
import SelectAsync from 'react-select/async';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import {
  APP_DATE_FORMAT,
  AUTH_TOKEN_KEY,
  BASE_API_VERSION_PATH,
  convertDateTimeToServer,
} from 'shared/util';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  AppProps,
  getFormFromSelect,
  getListAxios,
  GetServerSideProps,
  jsonParse,
  nextCookie,
  showFieldsSelectAsync,
  useRouter,
} from '../../../components/util/entity-utils';
import {
  Link,
  Translate,
  translate,
  useTranslation,
} from '../../../components/util/translate-component';

const MySwal = withReactContent(Swal);

export interface IAgendamentoConsultasProps extends AppProps {
  instituicaoId?: any;
  profissionalSaudeId?: any;
  dataStart?: any;
  dataEnd?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  agendamentoConsultasList?: any;
  setAgendamentoConsultasList?: Function;
  agendamentoConsultasEntity?: any;
  setAgendamentoConsultasEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getAgendamentoConsultasState?: Function;
  returnFunction?: Function;
  cadastroInstituicaos?: any;
  profissionalSaudes?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'api/agendamento-consultas';
const getEntityFetch: any = async (
  context: IAgendamentoConsultasProps,
  entity: any,
  selectFields = ''
) => {
  const requestUrl = `${apiUrl}/${entity.id}`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
    },
  });
  const json = await res.json();
  context.setAgendamentoConsultasEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IAgendamentoConsultasProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(apiUrl, {
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IAgendamentoConsultasProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(`${apiUrl}`, {
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IAgendamentoConsultasProps,
  _agendamentoConsultasEntity: any,
  listFiltersPage: any
) => {
  await fetch(`${apiUrl}/${_agendamentoConsultasEntity.id}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (
  context: IAgendamentoConsultasProps,
  offset = null
) => {
  return (
    '' +
    (context.state.instituicaoId
      ? 'instituicao=' +
        context.state.instituicaoId
          .map((v: any) => v.value + '<->' + v.label)
          .join(',') +
        '&'
      : '') +
    (context.state.profissionalSaudeId
      ? 'profissionalSaude=' +
        context.state.profissionalSaudeId
          .map((v: any) => v.value + '<->' + v.label)
          .join(',') +
        '&'
      : '') +
    (context.state.data ? 'data=' + context.state.data + '&' : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: IAgendamentoConsultasProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const {
    instituicaoId,
    profissionalSaudeId,
    dataStart,
    dataEnd,
    extraFilters,
    activePage,
    itemsPerPage,
    sort,
    order,
  } = context.state;

  const res = await context.getEntitiesFetch(
    instituicaoId,
    profissionalSaudeId,
    dataStart,
    dataEnd,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${
      _fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: res.headers.get('X-Total-Count'),
  });

  const json = await res.json();
  context.setAgendamentoConsultasList(json);
};

const sortEntities = (
  context: IAgendamentoConsultasProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IAgendamentoConsultasProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (
  context: IAgendamentoConsultasProps,
  activePage: any
) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IAgendamentoConsultasProps,
  _agendamentoConsultasEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="agendamentoConsultas.delete.question"
        interpolate={{ id: _agendamentoConsultasEntity.id }}
      >
        Are you sure you want to delete this AgendamentoConsultas?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="agendamentoConsultas.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="agendamentoConsultas.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_agendamentoConsultasEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IAgendamentoConsultasProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = [
    'id',
    'data',
    'instituicao.instSaude',
    'profissionalSaude.nome',
  ];

  if (errors.length === 0) {
    const _entity = {
      ...context.agendamentoConsultasEntity,
      // ...values,

      instituicao: getFormFromSelect(
        context.state.instituicaoFormValue,
        'many-to-one'
      ), // many-to-one - ownerSide

      profissionalSaude: getFormFromSelect(
        context.state.profissionalSaudeFormValue,
        'many-to-one'
      ), // many-to-one - ownerSide

      id: context.state.idFormValue,
      data: convertDateTimeToServer(context.state.dataFormValue),
    };

    const entity = _entity;

    const {
      instituicaoId,
      profissionalSaudeId,
      dataStart,
      dataEnd,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (
  context: IAgendamentoConsultasProps,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="agendamentoConsultas.home.createLabel"
            >
              Create a AgendamentoConsultas
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="agendamentoConsultas.home.editLabel"
            >
              Edit a AgendamentoConsultas
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IAgendamentoConsultasProps,
  _agendamentoConsultasEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="agendamento-consultas-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-agendamento-consultas-first-column">
                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className={'mt-4 label-single-line'}
                          id="idLabel"
                          for="agendamento-consultas-id"
                        >
                          <Translate
                            t={context.t}
                            contentKey="agendamentoConsultas.id"
                          >
                            ID
                          </Translate>
                        </Label>
                      </Col>

                      <Col md="9">
                        <input
                          className={'form-control'}
                          disabled={true}
                          value={
                            context.agendamentoConsultasEntity.id
                              ? context.agendamentoConsultasEntity.id
                              : ''
                          }
                          id="agendamento-consultas-id"
                          type="text"
                          name="id"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="dataLabel"
                          for="agendamento-consultas-data"
                        >
                          <Translate
                            t={context.t}
                            contentKey="agendamentoConsultas.data"
                          >
                            Data do Agendamento
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <DatePicker
                          autoComplete="off"
                          onChange={(value: any) =>
                            context.setState({
                              ...context.state,
                              dataFormValue: value,
                            })
                          }
                          id="data"
                          className="form-control react-datepicker"
                          name="data"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            context.state.dataFormValue
                              ? moment(context.state.dataFormValue).toDate()
                              : null
                          }
                          locale="pt"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          for="agendamento-consultas-instituicao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="agendamentoConsultas.instituicao"
                          >
                            Instituicao
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <SelectAsync
                          isMulti={false}
                          id="agendamento-consultas-instituicao-form"
                          name={'instituicao'}
                          instanceId="agendamento-consultas-instituicao-form"
                          placeholder={translate(
                            context.t,
                            'agendamentoConsultas.instituicao-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.instituicaoFormValue
                              ? context.state.instituicaoFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              instituicaoFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.instituicaoStartSelectOptions
                              ? context.state.instituicaoStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state.instituicaoStartSelectOptions ===
                            undefined
                              ? translate(
                                  context.t,
                                  'selectAsync.loadingMessage'
                                )
                              : translate(
                                  context.t,
                                  'selectAsync.noOptionsMessage'
                                )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state.instituicaoStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'aswinstsaude',
                                {},
                                0,
                                100,
                                'instSaude,asc',
                                'instSaude'
                              );
                              context.setState({
                                ...context.state,
                                instituicaoStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'instSaude') ||
                                        '',
                                    }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'aswinstsaude',
                                { 'instSaude.contains': inputValue },
                                0,
                                100,
                                'instSaude,asc',
                                'instSaude'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'instSaude') ||
                                        '',
                                    }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          for="agendamento-consultas-profissionalSaude"
                        >
                          <Translate
                            t={context.t}
                            contentKey="agendamentoConsultas.profissionalSaude"
                          >
                            Profissional Saude
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <SelectAsync
                          isMulti={false}
                          id="agendamento-consultas-profissionalSaude-form"
                          name={'profissionalSaude'}
                          instanceId="agendamento-consultas-profissionalSaude-form"
                          placeholder={translate(
                            context.t,
                            'agendamentoConsultas.profissionalSaude-input-placeholder'
                          )}
                          className={'css-select-control'}
                          data-type-rel="many-to-one-owner-side"
                          value={
                            context.state.profissionalSaudeFormValue
                              ? context.state.profissionalSaudeFormValue
                              : ''
                          }
                          onChange={(options) =>
                            context.setState({
                              ...context.state,
                              profissionalSaudeFormValue: options,
                            })
                          }
                          defaultOptions={
                            context.state.profissionalSaudeStartSelectOptions
                              ? context.state
                                  .profissionalSaudeStartSelectOptions
                              : []
                          }
                          loadingMessage={(input) =>
                            translate(context.t, 'selectAsync.loadingMessage')
                          }
                          noOptionsMessage={(input) =>
                            context.state
                              .profissionalSaudeStartSelectOptions === undefined
                              ? translate(
                                  context.t,
                                  'selectAsync.loadingMessage'
                                )
                              : translate(
                                  context.t,
                                  'selectAsync.noOptionsMessage'
                                )
                          }
                          onMenuOpen={async () => {
                            if (
                              context.state
                                .profissionalSaudeStartSelectOptions ===
                              undefined
                            ) {
                              const result = await getListAxios(
                                'profissional-saudes',
                                {},
                                0,
                                100,
                                'nome,asc',
                                'nome'
                              );
                              context.setState({
                                ...context.state,
                                profissionalSaudeStartSelectOptions: result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'nome') || '',
                                    }))
                                  : [],
                              });
                            }
                          }}
                          loadOptions={(inputValue, callback) => {
                            (async () => {
                              const result = await getListAxios(
                                'profissional-saudes',
                                { 'nome.contains': inputValue },
                                0,
                                100,
                                'nome,asc',
                                'nome'
                              );
                              callback(
                                result
                                  ? result.map((p: any) => ({
                                      ...p,
                                      value: p.id,
                                      label:
                                        showFieldsSelectAsync(p, 'nome') || '',
                                    }))
                                  : []
                              );
                            })();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IAgendamentoConsultasProps) => {
  return (
    <Modal
      className="agendamento-consultas-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="agendamentoConsultas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IAgendamentoConsultasProps) => {
  return context.agendamentoConsultasEntity &&
    (context.agendamentoConsultasEntity.id ||
      context.agendamentoConsultasEntity._id) ? (
    <Modal
      className="agendamento-consultas-update-modal"
      size={'xl'}
      isOpen={
        context.state.showModalEdit === context.agendamentoConsultasEntity.id ||
        context.state.showModalEdit === context.agendamentoConsultasEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.agendamentoConsultasEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="agendamentoConsultas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (
  context: IAgendamentoConsultasProps,
  v: any
) => {
  let p: any = null;

  p = v.instituicao;
  const instituicaoEntity = p
    ? { ...p, value: p.id, label: p['instSaude'] ? p.instSaude : '' }
    : p;

  p = v.profissionalSaude;
  const profissionalSaudeEntity = p
    ? { ...p, value: p.id, label: p['nome'] ? p.nome : '' }
    : p;

  return {
    idFormValue: v.id, // id,
    dataFormValue: v.data ? moment(v.data).toDate() : null, // Instant,
    instituicaoFormValue: instituicaoEntity,
    profissionalSaudeFormValue: profissionalSaudeEntity,
  };
};

const openUpdateModal = (
  context: IAgendamentoConsultasProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IAgendamentoConsultasProps) => {
  const newState = {
    ...context.state,
    instituicaoId: '',
    profissionalSaudeId: '',
    dataStart: '',
    dataEnd: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IAgendamentoConsultasProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IAgendamentoConsultasProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'instituicao' ? (
            <Col md="6">
              <Row className="mr-1 mt-1">
                <div style={{ width: '100%' }}>
                  <Label for="agendamento-consultas-instituicao">
                    <Translate
                      t={context.t}
                      contentKey="agendamentoConsultas.instituicao"
                    />
                  </Label>
                  <SelectAsync
                    id="agendamento-consultas-instituicao-filter"
                    instanceId="agendamento-consultas-instituicao-filter"
                    isMulti
                    className={'css-select-control'}
                    placeholder={'Selecione a instituição'}
                    name={'instituicao'}
                    cacheOptions
                    value={context.state.instituicaoId}
                    onChange={(options) =>
                      context.setState({
                        ...context.state,
                        instituicaoId: options,
                      })
                    }
                    defaultOptions={
                      context.state.instituicaoStartFilter
                        ? context.state.instituicaoStartFilter
                        : []
                    }
                    loadingMessage={(input) =>
                      translate(context.t, 'selectAsync.loadingMessage')
                    }
                    noOptionsMessage={(input) =>
                      context.state.instituicaoStartFilter === undefined
                        ? translate(context.t, 'selectAsync.loadingMessage')
                        : translate(context.t, 'selectAsync.noOptionsMessage')
                    }
                    onMenuOpen={async () => {
                      if (context.state.instituicaoStartFilter === undefined) {
                        const result = await getListAxios(
                          'aswinstsaude',
                          {},
                          0,
                          100,
                          'instSaude,asc',
                          'instSaude'
                        );
                        context.setState({
                          ...context.state,
                          instituicaoStartFilter: result
                            ? result.map((p: any) => ({
                                ...p,
                                value: p.id,
                                label: showFieldsSelectAsync(p, 'instSaude'),
                              }))
                            : [],
                        });
                      }
                    }}
                    loadOptions={(inputValue, callback) => {
                      (async () => {
                        const result = await getListAxios(
                          'aswinstsaude',
                          { 'instSaude.contains': inputValue },
                          0,
                          100,
                          'instSaude,asc',
                          'instSaude'
                        );
                        callback(
                          result
                            ? result.map((p: any) => ({
                                ...p,
                                value: p.id,
                                label: showFieldsSelectAsync(p, 'instSaude'),
                              }))
                            : []
                        );
                      })();
                    }}
                  />
                </div>
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'profissionalSaude' ? (
            <Col md="4">
              <Row className="mr-1 mt-1">
                <div style={{ width: '100%' }}>
                  <Label for="agendamento-consultas-profissionalSaude">
                    <Translate
                      t={context.t}
                      contentKey="agendamentoConsultas.profissionalSaude"
                    />
                  </Label>
                  <SelectAsync
                    id="agendamento-consultas-profissionalSaude-filter"
                    instanceId="agendamento-consultas-profissionalSaude-filter"
                    isMulti
                    className={'css-select-control'}
                    placeholder={translate(
                      context.t,
                      'agendamentoConsultas.profissionalSaude-filter-placeholder'
                    )}
                    name={'profissionalSaude'}
                    cacheOptions
                    value={context.state.profissionalSaudeId}
                    onChange={(options) =>
                      context.setState({
                        ...context.state,
                        profissionalSaudeId: options,
                      })
                    }
                    defaultOptions={
                      context.state.profissionalSaudeStartFilter
                        ? context.state.profissionalSaudeStartFilter
                        : []
                    }
                    loadingMessage={(input) =>
                      translate(context.t, 'selectAsync.loadingMessage')
                    }
                    noOptionsMessage={(input) =>
                      context.state.profissionalSaudeStartFilter === undefined
                        ? translate(context.t, 'selectAsync.loadingMessage')
                        : translate(context.t, 'selectAsync.noOptionsMessage')
                    }
                    onMenuOpen={async () => {
                      if (
                        context.state.profissionalSaudeStartFilter === undefined
                      ) {
                        const result = await getListAxios(
                          'profissional-saudes',
                          {},
                          0,
                          100,
                          'nome,asc',
                          'nome'
                        );
                        context.setState({
                          ...context.state,
                          profissionalSaudeStartFilter: result
                            ? result.map((p: any) => ({
                                ...p,
                                value: p.id,
                                label: showFieldsSelectAsync(p, 'nome'),
                              }))
                            : [],
                        });
                      }
                    }}
                    loadOptions={(inputValue, callback) => {
                      (async () => {
                        const result = await getListAxios(
                          'profissional-saudes',
                          { 'nome.contains': inputValue },
                          0,
                          100,
                          'nome,asc',
                          'nome'
                        );
                        callback(
                          result
                            ? result.map((p: any) => ({
                                ...p,
                                value: p.id,
                                label: showFieldsSelectAsync(p, 'nome'),
                              }))
                            : []
                        );
                      })();
                    }}
                  />
                </div>
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'data' ? (
            <Col md="2" className="col-filter-agendamento-consultas-data">
              <Row className="mr-1 mt-1">
                <Label id="dataStart" for="agendamento-consultas-data_start">
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.data_start"
                  >
                    Data do Agendamento (start)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({ ...context.state, dataStart: value })
                  }
                  id="dataStart"
                  className="form-control react-datepicker"
                  name="dataStart"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.dataStart
                      ? moment(context.state.dataStart).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}
          {context.state.baseFilters !== 'data' ? (
            <Col md="2" className="col-filter-agendamento-consultas-data-final">
              <Row className="mr-1 mt-1">
                <Label id="dataEnd" for="agendamento-consultas-data_end">
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.data_end"
                  >
                    Data do Agendamento (end)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({ ...context.state, dataEnd: value })
                  }
                  id="dataEnd"
                  className="form-control react-datepicker"
                  name="dataEnd"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.dataEnd
                      ? moment(context.state.dataEnd).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IAgendamentoConsultasProps) => {
  return (
    <>
      {context.agendamentoConsultasList &&
      context.agendamentoConsultasList.filter &&
      context.agendamentoConsultasList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div id="agendamento-consultas-table-list" className="table-list">
          <Table
            id="agendamento-consultas-table-list"
            responsive
            aria-describedby="agendamento-consultas-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={context.sortFunction('id')}>
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.id"
                  />
                  <i
                    className={
                      context.state.sort === 'id'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('data')}>
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.data"
                  />
                  <i
                    className={
                      context.state.sort === 'data'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>

                <th>
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.instituicao"
                  />
                </th>

                <th>
                  <Translate
                    t={context.t}
                    contentKey="agendamentoConsultas.profissionalSaude"
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.agendamentoConsultasList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((agendamentoConsultas: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="id-cell">
                      <b className="visible-xs"> ID </b>

                      {agendamentoConsultas.id}
                    </td>

                    <td id="data-cell">
                      <b className="visible-xs"> Data do Agendamento </b>

                      {agendamentoConsultas.data
                        ? moment(agendamentoConsultas.data).format(
                            APP_DATE_FORMAT
                          )
                        : ''}
                    </td>

                    <td id="instituicao-cell" role="many-to-one">
                      <b className="visible-xs">
                        {' '}
                        <Translate
                          t={context.t}
                          contentKey="agendamentoConsultas.instituicao"
                        />{' '}
                      </b>

                      {showFieldsSelectAsync(
                        agendamentoConsultas,
                        'instituicao.instSaude',
                        null
                      ).map((v: any, ikey: any) => (
                        <span key={ikey}> {v} </span>
                      ))}
                    </td>

                    <td id="profissionalSaude-cell" role="many-to-one">
                      <b className="visible-xs">
                        {' '}
                        <Translate
                          t={context.t}
                          contentKey="agendamentoConsultas.profissionalSaude"
                        />{' '}
                      </b>

                      {showFieldsSelectAsync(
                        agendamentoConsultas,
                        'profissionalSaude.nome',
                        null
                      ).map((v: any, ikey: any) => (
                        <span key={ikey}> {v} </span>
                      ))}
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`${context._router.asPath}/${
                            agendamentoConsultas.id
                          }?${getEntityFiltersURL(
                            context.state.itemsPerPage *
                              context.state.activePage +
                              i
                          )}`}
                          color="info"
                          size="sm"
                        >
                          <i className="fa fa-eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="agendamentoConsultas.btnView"
                            />
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${context._router.asPath}/${
                            agendamentoConsultas.id
                          }/edit?${getEntityFiltersURL(
                            context.state.itemsPerPage *
                              context.state.activePage +
                              i
                          )}`}
                          color="primary"
                          size="sm"
                        >
                          <i className="fa fa-pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="agendamentoConsultas.btnEdit"
                            />
                          </span>
                        </Button>
                        <Button
                          onClick={() =>
                            context.deleteEntityModal(agendamentoConsultas)
                          }
                          color="danger"
                          size="sm"
                        >
                          <i className="fa fa-trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="agendamentoConsultas.btnDelete"
                            />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.agendamentoConsultasList &&
                context.agendamentoConsultasList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate
            t={context.t}
            contentKey="agendamentoConsultas.home.notFound"
          >
            No Agendamento de Consultas found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IAgendamentoConsultasProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IAgendamentoConsultasProps) => {
  return (
    <div>
      <h4
        id="agendamento-consultas-heading"
        data-cy="AgendamentoConsultasHeading"
      >
        <Translate t={context.t} contentKey="agendamentoConsultas.home.title">
          Agendamento de Consultas
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                dataFormValue: null,

                instituicaoStartSelectOptions: undefined,
                instituicaoFormValue: null,

                profissionalSaudeStartSelectOptions: undefined,
                profissionalSaudeFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="agendamentoConsultas.btnNewItem"
            >
              Cadastrar
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => {}}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getAgendamentoConsultasState = (
  context: IAgendamentoConsultasProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const instituicaoId = _urlBase.searchParams.get('instituicao') || '';
  const profissionalSaudeId =
    _urlBase.searchParams.get('profissionalSaude') || '';
  const dataStart = _urlBase.searchParams.get('dataStart') || '';
  const dataEnd = _urlBase.searchParams.get('dataEnd') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    instituicaoId: instituicaoId
      ? instituicaoId.split(',').map((v: any) => ({
          value: v.split('<->')[0],
          label: v.split('<->')[1],
        }))
      : [],
    profissionalSaudeId: profissionalSaudeId
      ? profissionalSaudeId.split(',').map((v: any) => ({
          value: v.split('<->')[0],
          label: v.split('<->')[1],
        }))
      : [],
    dataStart,
    dataEnd,
  };
};

export const getEntitiesFetch = async (
  context: IAgendamentoConsultasProps,
  instituicaoId: any,
  profissionalSaudeId: any,
  dataStart: any,
  dataEnd: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map((v: any) => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const instituicaoRequest = instituicaoId
    ? `instituicao.id.in=${instituicaoId}&`
    : '';
  const profissionalSaudeRequest = profissionalSaudeId
    ? `profissionalSaude.id.in=${profissionalSaudeId}&`
    : '';
  let dataStartRequest = '';
  let dataEndRequest = '';
  if (
    typeof dataStart !== 'undefined' &&
    dataStart &&
    !(typeof dataEnd !== 'undefined' && dataEnd)
  ) {
    dataStartRequest = `data.greaterThan=${moment(dataStart).format(
      'YYYY-MM-DD'
    )}&`;
  } else if (
    !(typeof dataStart !== 'undefined' && dataStart) &&
    typeof dataEnd !== 'undefined' &&
    dataEnd
  ) {
    dataEndRequest = `data.lessThan=${moment(dataEnd).format('YYYY-MM-DD')}&`;
  } else if (
    typeof dataStart !== 'undefined' &&
    dataStart &&
    typeof dataEnd !== 'undefined' &&
    dataEnd
  ) {
    dataStartRequest = `data.between=${moment(dataStart).format(
      'YYYY-MM-DD'
    )},${moment(dataEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}?${extraFiltersRequest}${instituicaoRequest}${profissionalSaudeRequest}${dataStartRequest}${dataEndRequest}${
    sort ? `page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'
  }`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer ' +
        (authorizationBearer
          ? authorizationBearer
          : cookie.get(AUTH_TOKEN_KEY)),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
      'Get-User-Account': getAccount ? '1' : '0',
    },
  });

  return res;
};

export const AgendamentoConsultas = (props: IAgendamentoConsultasProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [agendamentoConsultasList, setAgendamentoConsultasList] = useState(
    props.agendamentoConsultasList
      ? props.agendamentoConsultasList
      : ([] as any)
  );
  const [agendamentoConsultasEntity, setAgendamentoConsultasEntity] = useState(
    props.agendamentoConsultasEntity
      ? props.agendamentoConsultasEntity
      : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    instituicaoId: props.instituicaoId ? props.instituicaoId : null,
    profissionalSaudeId: props.profissionalSaudeId
      ? props.profissionalSaudeId
      : null,
    dataStart: props.dataStart ? props.dataStart : '',
    dataEnd: props.dataEnd ? props.dataEnd : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    agendamentoConsultasList,
    setAgendamentoConsultasList,
    agendamentoConsultasEntity,
    setAgendamentoConsultasEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_agendamentoConsultasEntity: any, isNew: any) =>
          props['renderBodyUpdate'](context, _agendamentoConsultasEntity, isNew)
      : (_agendamentoConsultasEntity: any, isNew: any) =>
          renderBodyUpdate(context, _agendamentoConsultasEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: props['getEntitiesFetch']
      ? (
          instituicaoId: any,
          profissionalSaudeId: any,
          dataStart: any,
          dataEnd: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          props['getEntitiesFetch'](
            context,
            instituicaoId,
            profissionalSaudeId,
            dataStart,
            dataEnd,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          )
      : (
          instituicaoId: any,
          profissionalSaudeId: any,
          dataStart: any,
          dataEnd: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          getEntitiesFetch(
            context,
            instituicaoId,
            profissionalSaudeId,
            dataStart,
            dataEnd,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          ),

    createEntityFetch: props['createEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['createEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: props['updateEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['updateEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: props['deleteEntityFetch']
      ? (id: any, listFiltersPage: any) =>
          props['deleteEntityFetch'](context, id, listFiltersPage)
      : (id: any, listFiltersPage: any) =>
          deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: props['getEntityFetch']
      ? (id) => props['getEntityFetch'](context, id)
      : (id) => getEntityFetch(context, id),

    getAgendamentoConsultasState: props['getAgendamentoConsultasState']
      ? (location) => props['getAgendamentoConsultasState'](context, location)
      : (location) => getAgendamentoConsultasState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx: any) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getAgendamentoConsultasState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.instituicaoId,
    _fieldsBase.profissionalSaudeId,
    _fieldsBase.dataStart,
    _fieldsBase.dataEnd,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      agendamentoConsultasList: await res.json(),
    },
  };
};

export default AgendamentoConsultas;
