import { Tab, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Button, Col, Label, Row, Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { api } from 'services/api';
import { changeInputText } from 'shared/util';
import cpfMask from 'shared/util/cpfMask';

import { GestaoDeAgendaProfissionalLink } from './components/GestaoAgendaProfissionalLink';
import { GestaoDeAgendaProfissionalPresencialLink } from './components/GestaoAgendaProfissionalPresencialLink';
import { ModalForm } from './components/ModalForm';
import { Container, SpanTipoGestao } from './styles';
import { PSType, PSTypeList } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

export const GerenciamentoProfissionalSaude = () => {
  const { onlyMounted } = useOnlyMounted();
  const [psList, setPsList] = useState<PSTypeList[]>([]);
  const [ps, setPs] = useState<PSType | null>(null);
  const [inputFilter, setInputFilter] = useState('');
  const [showModalForm, setShowModalForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterAtivo, setFilterAtivo] = useState<number>(1)

  const listPS = (ativo: number) => {
    api
      .post('aswprofissionalsaude', {
        method: '_list',
        sgCliente: '',
        nome: inputFilter.replace(/[\u0300-\u036f]|[^0-9a-zA-Z\s]+/g, ''),
      })
      .then(({ data }) => {
        onlyMounted(() => {
          setPsList(data.data.filter((ps) => ps.ativo === ativo));
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  const handleSearch = () => {
    setIsLoading(true);
    listPS(filterAtivo);
  };

  const handleEditButton = (profissionalSelected: PSTypeList) => {
    const findPS = (cpf: string) => {
      setIsLoading(true);
      api
        .post('aswprofissionalsaude', {
          method: 'findPS',
          cpf,
        })
        .then(({ data }) => {
          if (data.data) {
            setPs(data.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    findPS(profissionalSelected.cpf);
  };

  useEffect(() => {
    if (ps) {
      openUpdateModal(ps);
    }
  }, [ps]);

  const openUpdateModal = (ps: PSType) => {
    setPs(ps);
    setShowModalForm(true);
  };

  useEffect(() => {
    listPS(filterAtivo);
  }, []);

  const handleFilter = (psList: PSTypeList[]): PSTypeList[] => {
    if (!!inputFilter.replaceAll(' ', '')) {
      return psList.filter((item) =>
        Object.values(item).some((key) =>
          String(key)
            .toLowerCase()
            .includes(
              inputFilter
                .toLowerCase()
                .replace(/[\u0300-\u036f]|[^0-9a-zA-Z\s]+/g, '')
            )
        )
      );
    }
    return psList;
  };

  return (
    <Container>
      <h4
        id="gerenciamento-profissional-saude-heading"
        data-cy="GerenciamentoProfissionalSaudeHeading"
      >
        <div className="d-flex justify-content-between">
          <span>GERENCIAMENTO PROFISSIONAIS DE SAÚDE</span>
          <Button
            onClick={() => setShowModalForm(true)}
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <span>Cadastrar Novo Profissional</span>
          </Button>
        </div>
      </h4>

      <div className="table-responsive">
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col
              md="12"
              className="col-filter-gerenciamento-profissional-saude-nome"
            >
              <Row className="mr-1 mt-1">
                <Label
                  style={{ backgroundColor: 'red' }}
                  id="nomeLabel"
                  for="gerenciamento-profissional-saude-nome"
                >
                  Profissional de Saúde
                </Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Buscar profissional de saúde"
                  name="nome"
                  id="gerenciamento-profissional-saude-nome"
                  value={inputFilter}
                  onChange={(event) => setInputFilter(changeInputText(event))}
                  onKeyDown={(key) => {
                    if (key.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Row>
            </Col>
          </div>

          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button id="search-submit" color="primary" onClick={handleSearch}>
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>

        <Tabs display="flex">
          <Tab
            onClick={() => {
              setFilterAtivo(1)
              listPS(1);
            }}
          >
            Profissionais Ativos
          </Tab>
          <Tab
            onClick={() => {
              setFilterAtivo(0)
              listPS(0);
            }}
          >
            Profissionais Inativos
          </Tab>
        </Tabs>

        {psList?.length > 0 && (
          <div
            id="gerenciamento-profissional-saude-table-list"
            className="table-list mt-4"
          >
            <Table
              id="gerenciamento-profissional-saude-table-list"
              responsive
              aria-describedby="gerenciamento-profissional-saude-heading"
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="align-top">Profissional de Saúde</th>
                  <th className="align-top">Especialidade</th>
                  {/* <th className="align-top">CNES</th>
                  <th className="align-top">Registro Conselho de Classe</th>
                  <th className="align-top">Orgão Emissor</th> */}
                  <th className="text-center align-top">
                    Gestão da Agenda
                    <br />
                    <SpanTipoGestao>Presencial</SpanTipoGestao>
                  </th>
                  <th className="text-center  align-top">Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {handleFilter(psList).map(
                  (profissionalSaude: any, i: number) => (
                    <tr key={`entity-${i}`}>
                      <td id="nome-cell">{profissionalSaude.nome}</td>

                      <td id="cpf-cell">
                        <b className="visible-xs"> CPF </b>
                        {profissionalSaude.peDescricao}
                      </td>

                      {/* <td>{profissionalSaude.cnes}</td> */}
                      {/* <td>{profissionalSaude.regConsClasse}</td> */}
                      {/* <td>{profissionalSaude.rccOrgaoEmissor}</td> */}
                      <td className="d-flex w-100 justify-content-around px-3">
                        <GestaoDeAgendaProfissionalPresencialLink
                          cpf={profissionalSaude.cpf}
                        />
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          {/* <Link> */}
                          <a
                            className={'btn btn-primary btn-lg'}
                            onClick={() => handleEditButton(profissionalSaude)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar"
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                          {/* </Link> */}
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        )}

        {showModalForm && (
          <ModalForm
            isNew={!ps}
            onRequestClose={() => {
              setShowModalForm(false);
              listPS(filterAtivo);
              setPs(null);
            }}
            dataPS={ps}
          />
        )}
      </div>

      {isLoading && <Loading />}
    </Container>
  );
};
