import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';

import { api } from 'services/api';
import { TipoAtendimentoType } from 'types/consulta';
import Cookie from 'js-cookie';

import { AUTH_TOKEN_KEY, BASE_API_VERSION_PATH, LOG } from 'shared/util';

import { useOnlyMounted } from './useOnlyMounted';

type ParamType = {
  method: string;
  sgCliente?: string;
  sgInstSaude?: string;
  tipoAtendimento?: TipoAtendimentoType;
  Authorization?: string;
  staleTimeOnMinutes?: number; //default 5 min
  [key: string]: any;

  config?: {
    reloadStateToInitial?: boolean;
  };
};

export const useApiOnMount = <T = any>(
  endpoint: string,
  params?: ParamType
) => {
  let staleTime = params.staleTimeOnMinutes;
  if (staleTime === 0) {
    staleTime = 0.1;
  }
  return useQuery<T>(
    [endpoint, params.method, params.sgCliente],
    async () => {
      delete params.staleTimeOnMinutes;
      const { data } = await api.post(endpoint, params);
      return 'data' in data ? data.data : data.body;
    },
    {
      staleTime: 1000 * 60 * (staleTime || 5),
    }
  );
};

const MESSAGE_ERROR =
  'Ocorreu algum erro, tente novamente mas se persistir entre em contato';

export const useApiRequest = <T = any>(
  endpoint: string,
  initialState: T = null,
  paramsToExecuteOnMount?: ParamType & {
    callback?: (value: T, setState?: React.Dispatch<Partial<T>>) => void;
  }
) => {
  const { onlyMounted } = useOnlyMounted();
  const [state, setState] = useReducer((prev: T, next: Partial<T>) => {
    if (
      prev instanceof Array ||
      typeof prev === 'string' ||
      typeof prev === 'number'
    ) {
      //pode ser que seja somente um array de algo, ou string, ou number
      return next;
    }

    return { ...prev, ...next };
  }, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  type Response<SubmitT = any> = {
    success: boolean;
    message: string;
    statusCode: number;
    data: T | SubmitT;
    body?: T | SubmitT;
  };

  const cancelToken = axios.CancelToken.source();

  const req = async (params: ParamType, retorna = false) => {
    setError('');
    try {
      params = {...params,...{ Authorization: Cookie.get(AUTH_TOKEN_KEY) }}
      setIsLoading(true);
      if (!!params?.config?.reloadStateToInitial) {
        setState(initialState);
      }
      const { data } = await api.post(endpoint, {  ...params , cancelToken });
    if (paramsToExecuteOnMount && paramsToExecuteOnMount.callback) {
      paramsToExecuteOnMount.callback(data.data, setState);
      return;
    }
    if (retorna) {
      return data;
    }
    onlyMounted(() => {
      if (data.success) {
        const state = data.data || data.body;
        setState(state);
      }

      if (data.message) {
        setError(data.message);
      }
    });
  } catch (err) {
    onlyMounted(() => {
      setError(MESSAGE_ERROR);
    });
    if (retorna) {
      return {
        success: false,
        message: MESSAGE_ERROR,
        messageError: err.message,
      };
    }
  } finally {
    onlyMounted(() => {
      setIsLoading(false);
    });
  }
};

const request = (params: ParamType): Promise<void> => req(params);
const submit = async <SubmitT = any>(
  params: ParamType
): Promise<Response<SubmitT>> => {
  return req(params, true);
};

useEffect(() => {
  if (paramsToExecuteOnMount) {
    request(paramsToExecuteOnMount);
  }
}, []);

return {
  request,
  submit,
  state,
  setState,
  isLoading,
  error,
  cancelRequest: () => cancelToken.cancel(),
};
};
