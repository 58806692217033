import { Alert } from 'reactstrap';

export const PageNotFound = () => {
  return (
    <div>
      <Alert color="warning">
        <span>Página não encontrada.</span>
      </Alert>
    </div>
  );
};
