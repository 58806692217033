import { Box, Flex } from '@chakra-ui/react'
import { forwardRef, useImperativeHandle, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { Col } from 'reactstrap'
import { dateTimeToServer, dateToScreen } from 'shared/util'

type DataDescType = {
  data: Date | string
  descricao: string
  originalData?: any
}

type Props = Partial<DataDescType> & {
  onSave?: (data: DataDescType) => void
  onDelete?: (data: DataDescType) => void
  onUpdate?: (data: DataDescType) => void
  hideDate?: boolean
}

export type DateInputFormRefProps = {
  open: () => void
  close: () => void
}

export const DateInputForm = forwardRef<DateInputFormRefProps, Props>(
  (
    {
      descricao = '',
      data = null,
      onDelete,
      originalData,
      hideDate,
    },
    ref
  ) => {
    const isNew = !descricao && !data
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => {
      return {
        open: () => setOpen(true),
        close: () => setOpen(false),
      }
    })

    const [inputDate, setInputDate] = useState<Date>(
      data ? new Date(data) : null
    )
    const [inputText, setinputText] = useState(descricao)
    const [isEdit, setIsEdit] = useState(false)

    const resetState = () => {
      setInputDate(null)
      setinputText('')
    }


    const handleClickDelete = () => {
      onDelete &&
        onDelete({
          data: dateTimeToServer(inputDate),
          descricao: inputText,
          originalData,
        })
    }

    const estaCriandoUmNovoOuCancelaCriacao = !open && isNew
    if (estaCriandoUmNovoOuCancelaCriacao) return null

    const jaFoiCriadoSoEditaOuApaga = !isNew && !isEdit
    if (jaFoiCriadoSoEditaOuApaga) {
      return (
        <div>
          {!!hideDate ? (
            <>
              <b>{descricao}</b>
            </>
          ) : (
            <>
              <span>{data ? `${dateToScreen(data)} -` : ''} </span>{' '}
              <b>{descricao}</b>
            </>
          )}
        </div>
      )
    }

    return (
      <Box>
        <Flex gap={'16px'}>
          {!hideDate && (
            <Col md="2">
              <ReactDatePicker
                onChange={(value) => setInputDate(value)}
                autoComplete="off"
                id="data-cirurgias-previas"
                className="form-control"
                name="data-cirurgias-previas"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                selected={inputDate}
                locale="pt"
              />
            </Col>
          )}
          <Col md={!hideDate ? '8' : '10'}>
            <input onChange={(evt) => setinputText(evt.target.value)} value={inputText} type="text" className={'form-control'} />
          </Col>
        </Flex>
      </Box>
    )
  }
)
