import { Box, Tab, Tabs } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BsCalendar, BsPencil } from 'react-icons/bs';
import { Button, Col, Label, Row, Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { api } from 'services/api';
import { changeInputText } from 'shared/util';

import { GestaoDeAgendaExameLink } from './components/GestaoDeAgendaExameLink';
import { ModalForm } from './components/ModalForm';
import { Container, SpanTipoGestao } from './styles';
import { ExamType, PSTypeList } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

export const GerenciamentoAgendaExames = () => {
  const { onlyMounted } = useOnlyMounted();
  const [examList, setExamsList] = useState([]);
  const [exam, setExam] = useState<ExamType | null>(null);
  const [inputFilter, setInputFilter] = useState('');
  const [showModalForm, setShowModalForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterAtivo, setFilterAtivo] = useState(1)
  const listExams = async (ativo: number, filter?: string ) => {
    try{
      setIsLoading(true);
      console.log(filterAtivo)
      const { data } = await api.post('aswexame', {
        method: '_listExams',
        Authorization: Cookies.get('AUTH_TOKEN_KEY'),
        search: filter,
        ativo
      });

      if (data.success) {
        setExamsList(data.data);
      }
      setIsLoading(false);
    } catch(err){
      setIsLoading(false);
    }

  };

  const handleSearch = () => {
    setIsLoading(true);
    listExams(1, inputFilter);
  };

  const handleEditButton = (exCodigo) => {
    const findExam = async (exCodigo: number) => {
      setIsLoading(true);
      const { data } = await api.post('aswexame', {
        method: '_findExam',
        Authorization: Cookies.get('AUTH_TOKEN_KEY'),
        exCodigo: exCodigo,
      });

      if(data.success){
        setExam(data.data)
      }
      setIsLoading(false);
    };

    findExam(exCodigo);
  };

  useEffect(() => {
    if (exam) {
      openUpdateModal(exam);
    }
  }, [exam]);

  const openUpdateModal = (exam: ExamType) => {
    setExam(exam);
    setShowModalForm(true);
  };

  useEffect(() => {
    listExams(1);
  }, []);

  return (
    <Container>
      <h4
        id="gerenciamento-profissional-saude-heading"
        data-cy="GerenciamentoProfissionalSaudeHeading"
      >
        <div className="d-flex justify-content-between">
          <span>GERENCIAMENTO DE EXAMES</span>
          <Button
            onClick={() => setShowModalForm(true)}
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <span>Cadastrar novo exame/procedimento</span>
          </Button>
        </div>
      </h4>

      <div className="table-responsive">
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col
              md="12"
              className="col-filter-gerenciamento-profissional-saude-nome"
            >
              <Row className="mr-1 mt-1">
                <Label
                  style={{ backgroundColor: 'red' }}
                  id="nomeLabel"
                  for="gerenciamento-profissional-saude-nome"
                >
                  Profissional de Saúde
                </Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Buscar exames"
                  name="nome"
                  id="gerenciamento-profissional-saude-nome"
                  value={inputFilter}
                  onChange={(event) => setInputFilter(changeInputText(event))}
                  onKeyDown={(key) => {
                    if (key.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Row>
            </Col>
          </div>

          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button id="search-submit" color="primary" onClick={handleSearch}>
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>

          <Tabs display='flex'>
            <Tab onClick={() => {listExams(1)}}>Exames Ativos</Tab>
            <Tab onClick={() => {listExams(0)}}>Exames Inativos</Tab>
          </Tabs>

        {examList?.length > 0 && (
          <div
            id="gerenciamento-profissional-saude-table-list"
            className="table-list mt-4"
          >
            <Table
              id="gerenciamento-profissional-saude-table-list"
              responsive
              aria-describedby="gerenciamento-profissional-saude-heading"
              className={'table-striped-exame table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="align-top">Especialidade</th>
                  <th className="align-top">Nome</th>
                  <th className="align-top">Ativo</th>
                  <th className="text-center align-top">
                    Gestão de agenda Presencial
                  </th>
                  <th className="text-center  align-top">Editar</th>
                </tr>
              </thead>
              <tbody>
                {examList.map((item, key) => (
                  <tr key={key}>
                    <td>{item.exEspecialidade}</td>
                    <td>{item.exNome}</td>
                    <td style={{display: 'flex', justifyContent: 'center'}}>{item.ativo === 1 ? 'Ativo' : 'Inativo'}</td>
                    <td>
                      <GestaoDeAgendaExameLink examId={item.exCodigo} />
                    </td>
                    <td>
                      <div
                        style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer'}}
                        onClick={() => handleEditButton(item.exCodigo)}
                      >
                        <BsPencil />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {showModalForm && (
          <ModalForm
            isNew={!exam}
            onRequestClose={() => {
              setShowModalForm(false);
              listExams(1);
              setExam(null);
            }}
            examData={exam}
          />
        )}
      </div>

      {isLoading && <Loading />}
    </Container>
  );
};
