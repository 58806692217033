import { useAlert, useApiRequest } from 'hooks';
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { IEpisodioConsulta } from 'types/episodio';

interface IModal {
  showModalForm: boolean;
  toggle: () => void;
  toggleModalDetalhes: () => void;
  refreshDados: () => void;
  aprovar: boolean;
  epIdentificador: string;
}

type Servico = {
  value: 'Q' | 'C' | 'R' | 'S' | 'O';
  label: string;
  comment: string;
  id: string;
};

const services: Servico[] = [
  { value: 'Q', label: 'Quimioterapia', comment: '', id: '' },
  { value: 'C', label: 'Cirurgia', comment: '', id: '' },
  { value: 'R', label: 'Radioterapia', comment: '', id: '' },
  { value: 'S', label: 'Consulta', comment: '', id: '' },
  { value: 'O', label: 'Outros', comment: '', id: '' },
];

function ModalAprovarEpisodio({
  showModalForm,
  toggle,
  aprovar,
  epIdentificador,
  refreshDados,
  toggleModalDetalhes,
}: IModal) {
  const [comentarioInput, setComentarioInput] = useState<string>('');
  const alert = useAlert();
  const episodioApi = useApiRequest<IEpisodioConsulta>(
    'aswepisodiosms',
    {} as IEpisodioConsulta
  );

  const [servicosSelecionados, seServicosSelecionados] = useState<Servico[]>(
    []
  );

  const setFieldService = ({ id, comment = '' }: Partial<Servico>) => {
    seServicosSelecionados((prev) =>
      prev.map((service) => {
        if (service.id === id) {
          service.comment = comment;
        }
        return service;
      })
    );
  };

  const serviceList = services.filter((service) =>
    servicosSelecionados.every((selected) => selected.value !== service.value)
  );

  return (
    <Modal centered size={'lg'} isOpen={showModalForm} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <h5 className="text-center">
        {aprovar
          ? `Encaminhar para serviço${
              servicosSelecionados.length > 1 ? 's' : ''
            }`
          : 'Comentar'}
      </h5>
      <ModalBody>
        <Row className="justify-content-center p-3">
          {aprovar ? (
            <>
              {serviceList.length > 0 && (
                <Col md={12} className="my-2 d-flex justify-content-center">
                  <select
                    value=""
                    onChange={(event) => {
                      if (!event.target.value) return;
                      const service = services.find(
                        (s) => s.value === event.target.value
                      );
                      service.id = String(Date.now());
                      seServicosSelecionados((prev) => [...prev, service]);
                    }}
                  >
                    <option value="">Selecione um serviço</option>
                    {serviceList.map((service, index) => (
                      <option value={service.value} key={index}>
                        {service.label}
                      </option>
                    ))}
                  </select>
                </Col>
              )}
              <Col md={12} className="my-2">
                {servicosSelecionados.map((opt) => (
                  <Row key={opt.id} className="mt-3">
                    <Col md={3} className="d-flex align-items-center">
                      <Label>{opt.label}</Label>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <div className="w-100">
                        <Label>Comentário</Label>
                        <Input
                          value={opt.comment}
                          onChange={(e) => {
                            setFieldService({
                              id: opt.id,
                              comment: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <button
                        onClick={() => {
                          seServicosSelecionados((prev) =>
                            prev.filter((s) => s.id !== opt.id)
                          );
                        }}
                        className="btn btn-primary btn-sm mx-1"
                        style={{ alignSelf: 'flex-end' }}
                      >
                        remover
                      </button>
                    </Col>
                  </Row>
                ))}
              </Col>
            </>
          ) : (
            <>
              <Row>
                <Col md={12}>
                  <Label style={{ marginTop: 20 }}>Comentário</Label>
                  <Input
                    value={comentarioInput}
                    onChange={(e) => {
                      setComentarioInput(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={episodioApi.isLoading}
          color="primary"
          className="float-right jh-create-entity"
          type="button"
          onClick={async () => {
            const result = await episodioApi.submit({
              method: aprovar
                ? 'aprovarStatusEpisodio'
                : 'reprovarStatusEpisodio',
              epIdentificador,
              epTextoTriagemMedica: comentarioInput,
              servicosSelecionados,
            });
            if (result?.statusCode === 200) {
              alert.success({
                title: aprovar ? 'Episódio Apovado!' : 'Episódio Reprovado!',
              });
              toggle();
              toggleModalDetalhes();
              refreshDados();
            } else {
              alert.warning({
                title: 'Houve ume erro ao Aprovar/Reprovar o Episódio',
              });
              toggle();
            }
            setComentarioInput('');
          }}
        >
          Concluir
          {episodioApi.isLoading && (
            <>
              &nbsp;
              <i className="fas fa-spinner fa-spin fa-1x"></i>
            </>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAprovarEpisodio;
