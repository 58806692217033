import styled, { css } from 'styled-components';
import { Button } from 'reactstrap';
import { colors } from 'theme/colors';

export const Container = styled.div`
  margin: 10px 0;
`;

export const BtnAddFile = styled(Button)`
  border-radius: 15px !important;
  margin-left: 10px;
  padding: 2px 15px !important;
`;

export const SectionFileEmpty = styled.div`
  background: #ffffff;
  border: 1px solid #cecece !important;
  color: #cecece !important;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 5px 0;
`;

export const SectionFile = styled.div<{ clicable: boolean }>`
  ${({ clicable }) =>
    clicable &&
    css`
      cursor: pointer;
    `};
  background: #56a1e108;
  border: 1px solid #e5e5e5 !important;
  color: ${colors.blue} !important;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  display: flex;
  flex-direction: row;

  .text-smoke {
    color: #5e5e5e;
  }
`;

export const Space = styled.div`
  flex: 1;
`;
