import * as Yup from 'yup';

export type SMSPacienteType = {
  cpf: string;
  nome: string;
  email: string;
  telefone: string;
  nascimento: string;
  genero: string;
  cep: string;
  enderecoUsuario: string;
  numeroUsuario: string;
  bairro: string;
  complementoUsuario: string;
  cidadeUsuario: string;
  ufUsuario: string;
  usuarioInstSaudePrefer: string;
  cartaoSUS: string;
  identidade: string;
  // instituicoes: string;
  // usuarioFilial: string;
  // usuarioSetor: string;
  // usuarioCargo: string;
  // usuarioDepartamento: string;

  documentos?: TypeFile[];
};

export type TypePacienteAPI = {
  nmUsuario: string;
  emailUsuario: string;
  sgCliente: string;
  telefoneUsuario: string;
  usuarioCEP: string;
  usuarioCNS: string;
  usuarioCPF: string;
  usuarioCidade: string;
  usuarioEndereco: string;
  usuarioGenero: string;
  usuarioInstSaudePrefer: string;
  usuarioNasc: string;
  usuarioNomeSocial: string;
  usuarioNumero: string;
  usuarioComplemento: string;
  usuarioPais: string;
  usuarioUF: string;
  dtCriacao: string;
  dtModificacao: string;
  usuarioBairro: null;
  usuarioCargo: null;
  dadosConvenio: {
    sgCliente: string;
    convenio: string;
    plano: string;
    instSaude: string;
    usuarioCPF: string;
    numeroCartao: string;
    cartaoData?: string;
  };
};

export type FileType = {
  id: number | string;
  base64?: string;
  name: string;
  type: string;
  size?: number;
  date?: Date;

  s3Caminho?: string;
};

const requiredFieldMessage = 'Campo obrigatório';
export const getSchema = (isEdit = false) =>
  Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required(requiredFieldMessage),
    ...(!isEdit
      ? {
          cpf: Yup.string()
            .required(requiredFieldMessage)
            .min(9, 'Formato inválido'),
        }
      : {}),
    nome: Yup.string()
      .required(requiredFieldMessage)
      .min(6, 'Formato inválido'),
    telefone: Yup.string()
      .required(requiredFieldMessage)
      .min(10, 'Formato inválido'),
    nascimento: Yup.string().required(requiredFieldMessage),
    genero: Yup.string().required(requiredFieldMessage),
    cep: Yup.string().required(requiredFieldMessage).min(8, 'Formato inválido'),
    enderecoUsuario: Yup.string().required(requiredFieldMessage),
    numeroUsuario: Yup.string().required(requiredFieldMessage),
    bairro: Yup.string()
      .required(requiredFieldMessage)
      .typeError('Campo Obrigatório')
      .min(2, 'Formato inválido'),
    complementoUsuario: Yup.string(),
    cidadeUsuario: Yup.string()
      .required(requiredFieldMessage)
      .min(2, 'Formato inválido'),
    ufUsuario: Yup.string().required(requiredFieldMessage),
    usuarioInstSaudePrefer: Yup.string().required(requiredFieldMessage),
    cartaoSUS: Yup.string().required(requiredFieldMessage),
    identidade: Yup.string().required(requiredFieldMessage),
  });

export type PlanoType = {
  key: string;
  value: string;
};

export type ConvenioType = {
  key: string;
  value: string;
  label?: string;
  planos: PlanoType[];
};

export type SubmitFromConvenioType = {
  convenio: { label: string; value: string };
  plano: SubmitFromConvenioType['convenio'];
  cartao: string;
  cartaoValidade: Date;
};

export type TypeFile = FileType & {
  descricao:
    | 'comprovanteResidencia'
    | 'encaminhamentoMedico'
    | 'examesEbiopsia'
    | 'cartaoSUS'
    | 'documentoPessoal';
};

export type FormUsuarioSMSProps = {
  onlyView?: boolean;
  title?: string;
  findByCpf?: boolean;
  perfil?: string;
};

export type APICEPType = {
  statusCode: number;
  message: string;
  success: boolean;
  data: CEPType;
};

export type CEPType = {
  cep: string;
  uf: string;
  cidade: string;
  bairro: string;
  endereco: string;
  complemento: any;
  nome: string;
};
