import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'

type ModalPrescricoesHistoricoProps = {
  isOpen: boolean
  onClose: () => void
  content?: any
}

type Prescription = {
  dtInclusao: string;
  hpAltoCusto: string;
  hpCidId: string;
  hpCodeReceita: string;
  hpComposicao: string;
  hpControleEspecial: string;
  hpDataSolicitacao: string;
  hpFabricante: string
  hpMedicamentoDescricao: string;
  hpMedicamentoId: string;
  hpMedicamentoNome: string;
  hpMemedDigitsPresc: string;
  hpMemedIdPresc: string;
  hpMemedLinkPresc: string;
  hpPosologia: string;
  hpPreco: string;
  hpQuantidade: string;
  hpReceituario: string;
  hpTarja: string;
  hpTipo: string;
  hpTitularidade: string;
  hpUID: string;
}

function ModalPrescricoesHistorico({
  isOpen,
  onClose,
  content,
}: ModalPrescricoesHistoricoProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack gap={4} my={'4'}>
            '
            <Heading fontSize={'lg'} fontWeight={'normal'}>
              Histórico de Prescrições
            </Heading>
            {content.listagemPrescricao?.lenght === 0 ? (
              <Heading fontSize={'sm'} color={'gray.400'} fontWeight={'normal'}>
                Não há prescrições disponíveis.
              </Heading>
            ) : (
              content.listagemPrescricao?.map(
                (item: Prescription, index: number) => {
                  return (
                    <Flex alignItems={'flex-start'} width={'100%'} key={index}>
                      <Text mr={'4'}>{index + 1}.</Text>
                      <Box flex={1}>
                        <Text size={'lg'} fontWeight={'semibold'}>
                          {item.hpMedicamentoNome}
                        </Text>
                        <Text size={'xs'}>{item.hpPosologia}</Text>
                      </Box>
                      <Text textAlign={'right'}>
                        {item.hpQuantidade} embalagem(ns)
                      </Text>
                    </Flex>
                  )
                }
              )
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { ModalPrescricoesHistorico }

