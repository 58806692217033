import {
  forwardRef,
  Input as InputCha,
  InputProps as InputPropsCha,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type InputProps = InputPropsCha & {
  placeholder: string;
  queryDeelay?: number;
  handlerChange?: (props: string) => void;
  icon?: {
    icon: any;
    size: number;
    color: string;
  };
};

/**
 * @docs
 * - https://chakra-ui.com/docs/components/input/usage
 * @returns Input
 */

const Input = forwardRef(
  (
    { placeholder, queryDeelay = 0, handlerChange, icon, ...rest }: InputProps,
    ref
  ) => {
    const [query, setQuery] = useState('');

    useEffect(() => {
      if (handlerChange === undefined) return;

      const timeOutId = setTimeout(() => handlerChange(query), queryDeelay);
      return () => clearTimeout(timeOutId);
    }, [query]);

    return (
      <InputGroup>
        <InputCha
          placeholder={placeholder}
          onChange={(e) => setQuery(e.target.value)}
          size="md"
          ref={ref}
          {...rest}
        />
        {icon && (
          <InputRightElement
            children={<icon.icon color={icon.color} size={icon.size} />}
          />
        )}
      </InputGroup>
    );
  }
);

export { Input };
