import React from 'react';
import { Link } from '../../../components/util/translate-component';

export interface ICadastroClienteProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const CadastroClienteComponentCustomize = (props: ICadastroClienteProps) => {  
  return (
    <div className="btn-group flex-btn-group-container">
      <Link href={`/cadastro-cliente/${props.entityBase.sgCliente}/cadastro-instituicao`}>
        <a className="btn btn-primary btn-sm">
          <i className="fa fa-hospital" />
        </a>
      </Link>
    </div>
  );
};

export default CadastroClienteComponentCustomize;
