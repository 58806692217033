import { Link } from '../../../components/util/translate-component';
import React from 'react';

export interface IMenuRotasProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
}

const MenuRotasComponentCustomize = (props: IMenuRotasProps) => {
  return (
    <Link href={props.entityBase.rota}>
      <a className="" style={{ color: 'blue' }}>
        {props.entityBase.rota}
      </a>
    </Link>
  );
};

export default MenuRotasComponentCustomize;
