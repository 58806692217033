import { Box, Select } from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { useUser } from 'hooks';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { FilaAtendimentoType } from 'pages/filas-atendimento';
import { useEffect, useState } from 'react';
import { Button, Col, Table } from 'reactstrap';
import { api } from 'services/api';
import { changeInputText } from 'shared/util';
import { Container } from '../../styles';
import ModalLocalAtendimento from '../modal';
import ModalQrCode from '../modalQrCode';

export type LocalAtendimentoType = {
  laNome: string;
  sgInstSaude: string;
  laDescricao: string;
  laCEP: string;
  laEndereco?: string;
  laNumero: string;
  laComplemento: string;
  laBairro: string;
  laCidade: string;
  laUF: string;
  dtInclusao: string;
  laTriagem: boolean;
  filaAtendimento: FilaAtendimentoType[];
};

function Page() {
  const usuarioLogado = useUser();
  const [instituicao, setInstituicao] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [textoFiltro, setTextoFiltro] = useState('');
  const [locaisAtendimento, setLocaisAtendimento] = useState<
    LocalAtendimentoType[]
  >([]);
  const [locaisAtendimentoFilter, setLocaisAtendimentoFilter] = useState<
    LocalAtendimentoType[]
  >([]);
  const [showModalForm, setShowModalForm] = useState<boolean>(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false);
  const [localAtendimentoSelected, setLocalAtendimentoSelected] =
    useState<LocalAtendimentoType>(null);
  const { onlyMounted } = useOnlyMounted();

  const getLocaisAtendimento = () => {
    executaRequisicao(
      'aswagendalocaisatendimento',
      {
        method: 'listarAgendaLocalAtendimento',
        sgCliente: usuarioLogado?.sgCliente,
        sgInstSaude: instituicao,
      },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setLocaisAtendimento(data);
          setLocaisAtendimentoFilter(data);
        }
      }
    );
  };

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  const handleModalClick = (localAtendimento: LocalAtendimentoType | null) => {
    setLocalAtendimentoSelected(localAtendimento);
    toggle();
  };

  const toggle = () => {
    setShowModalForm(!showModalForm);
  };

  const handleModalQrClick = (localAtendimento: LocalAtendimentoType) => {
    setLocalAtendimentoSelected(localAtendimento);
    toggleQrCodeModal();
  };

  const toggleQrCodeModal = () => {
    setShowQrCodeModal(!showQrCodeModal);
  };

  useEffect(() => {
    if (instituicao) {
      getLocaisAtendimento();
    }
  }, [instituicao]);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = locaisAtendimento.filter((local) => {
        return Object.values(local).some(
          (key) => String(key).toLowerCase().includes(textoFiltro.toLowerCase())
          // ||
          // String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setLocaisAtendimentoFilter(filtered);
    } else {
      setLocaisAtendimentoFilter(locaisAtendimento);
    }
  }, [textoFiltro]);

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>LOCAIS DE ATENDIMENTO</h6>
        </div>
        <Button
          color="primary"
          className="btn btn-primary"
          onClick={() => handleModalClick(null)}
        >
          Cadastrar Novo Local de Atendimento
        </Button>
      </div>
      <Box as="form" className="my-3">
        {!instituicao
          ? 'Selecione a instituição de saúde'
          : 'Instituição de saúde'}
        <Select
          size={'md'}
          className={'mt-1'}
          value={instituicao}
          onChange={(event) => {
            event.persist();
            const { value } = event.target;
            setInstituicao(value);
          }}
        >
          <option value="">Selecione a instituição...</option>
          {usuarioLogado?.institucoes &&
            usuarioLogado.institucoes.map((item, index) => {
              return (
                <option key={index} value={item.institucao}>
                  {item.institucaoNome}
                </option>
              );
            })}
        </Select>
      </Box>
      {locaisAtendimento.length > 0 && (
        <Col md="12 mt-1">
          <span>Buscar locais de atendimento</span>
          <input
            placeholder="Digite aqui..."
            className="form-control"
            // value={textoFiltro}
            onChange={(evento) => setTextoFiltro(changeInputText(evento))}
          />
        </Col>
      )}

      <div>
        {locaisAtendimentoFilter.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Local de Atendimento</th>
                  <th>Cidade</th>
                  <th>Endereço</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Imprimir</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {locaisAtendimentoFilter
                  .filter((i) => i.sgInstSaude == instituicao)
                  .map((localAtendimento, i: number) => (
                    <tr
                      key={`${localAtendimento.laDescricao}${localAtendimento.laCEP}${i}`}
                    >
                      <td>{localAtendimento.laNome}</td>
                      <td>{localAtendimento.laCidade}</td>
                      <td>{localAtendimento.laEndereco}</td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Editar"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => handleModalClick(localAtendimento)}
                          >
                            <i className="fa fa-pencil-alt" />
                          </button>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Imprimir"
                            className={'btn btn-primary btn-lg'}
                            onClick={() => handleModalQrClick(localAtendimento)}
                          >
                            <i className="fa fa-print" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}

        {!isLoading && !locaisAtendimentoFilter.length && (
          <div className="mt-4 alert alert-warning">Nada encontrado</div>
        )}
      </div>

      {isLoading && <Loading />}

      {showModalForm && (
        <ModalLocalAtendimento
          showModalForm={showModalForm}
          localAtendimentoSelected={localAtendimentoSelected}
          toggle={toggle}
          atualizarDados={getLocaisAtendimento}
        />
      )}
      <ModalQrCode
        showModal={showQrCodeModal}
        toggle={toggleQrCodeModal}
        localAtendimentoSelected={localAtendimentoSelected}
      />
    </Container>
  );
}

export default Page;
