import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  margin: 32px 0;
`;

export const TurnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin: 16px auto;
  text-align: center;
`;

export const TurnHoursContainer = styled.div`
  margin: 16px auto;
  display: flex;
  text-align: center;

  .hours-container {
    margin: 0 8px;
  }
`;

export const ConfirmScheduleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;

  button {
    font-size: 1.2rem;
  }
`;

export const ScheduleForm = styled.form``;

export const HoursContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  .btn-title {
    font-size: 1.1rem;
  }
`;

export const HoursColumn = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 0.2rem;
  }
`;
