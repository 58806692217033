import { Flex, Stack, Table as TableCha, Tbody, Td, Th, Tr } from '@chakra-ui/react';

import { Select } from 'components/crud/Select';

import { colors } from 'theme/colors';

import * as S from './style';

import { TableProps } from './types';
const Table = ({
  ordem,
  ordemData,
  data,
  tableParams,
  actionComponents,
  isLoading,
  handlerAction,
}: TableProps<any, string>) => {

  return (
    <S.ContentTable>
      <S.Overlay isVisible={isLoading}>
        <S.Progress isIndeterminate color={colors.blue} />
      </S.Overlay>
      <S.TableContainer mt="0">
        <TableCha variant="simple">
          <S.Thead>
            <Tr>
              {tableParams?.map((param, key) => (
                <Th key={`header-${key}`}>{param.header}</Th>
              ))}
              {actionComponents?.map((param, key) => (
                <Th key={`header-action-${key}`}>{param.header}</Th>
              ))}
            </Tr>
          </S.Thead>
          <Tbody>
            {data?.map((dataLn, key) => (
              <S.Tr $enabled={dataLn.enabled} key={key}>
                {tableParams?.map((param, key) => {
                  return (
                    <Td
                      width={param?.headerWidth || 'auto'}
                      key={`body-ln-${key}`}
                    >
                      {param.key == 'qrOrdem' || param.key == 'qpOrdem' ?
                        <Stack spacing={5}>
                          <Select
                            value={dataLn[param.key]}
                            placeholder=''
                            onChange={(e) => dataLn.qrID ? ordem({ id: dataLn.qrID, novaOrdem: parseInt(e.target.value), ordemSubstituida: dataLn[param.key] }) : ordem({ id: dataLn.qpID, novaOrdem: parseInt(e.target.value), ordemSubstituida: dataLn[param.key] })}
                            data={ordemData.map((param) => ({
                              value: param.value,
                              option: param.key,
                            }))}
                          />
                        </Stack>
                        : dataLn[param.key]}
                    </Td>
                  );
                })}
                {actionComponents?.map((action, key) => {
                  return (
                    <Td
                      key={`tdcomponent-${key}`}
                      width={action?.headerWidth || '2rem'}
                    >
                      <Flex justifyContent={'center'}>
                        {action.type === 'switch' && (
                          <action.component
                            isChecked={
                              dataLn.qcAtiva == 1 || dataLn.qcAtiva == 0
                                ? dataLn.qcAtiva
                                : dataLn.qsAtivo
                            }
                            onChange={(event) => {
                              handlerAction({
                                ...action,
                                dataLn: dataLn,
                                value: event.target.checked,
                              });
                            }}
                          />
                        )}
                        {action.type === 'icon' && action.action != 'icon-alternativas' ? (
                          <action.component
                            style={{ cursor: 'pointer' }}
                            {...action.props}
                            onClick={(event) => {
                              handlerAction({
                                ...action,
                                dataLn: dataLn,
                                value: '',
                              });
                            }}
                          />
                        ) :
                          (action.type === 'icon' && dataLn?.tipo != 'Dissertativa' && dataLn?.tipo != 'Range' &&
                            <action.component
                              style={{ cursor: 'pointer' }}
                              {...action.props}
                              onClick={(event) => {
                                handlerAction({
                                  ...action,
                                  dataLn: dataLn,
                                  value: '',
                                });
                              }}
                            />
                          )
                        }
                      </Flex>
                    </Td>
                  );
                })}
              </S.Tr>
            ))}
          </Tbody>
        </TableCha>
      </S.TableContainer>
    </S.ContentTable>
  );
};

export { Table };
