import { useApiOnMount } from 'hooks/useApi';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { ConsultaType } from './types';

type ContextType = {
  consulta: ConsultaType;
  isFetchingConsulta: boolean;
  idConsulta: string;
  tipoConsulta: '' | 'Consulta' | 'Retorno';
  setTipoConsulta: (p: ContextType['tipoConsulta']) => void;
  headerHeight: number;
  setHeaderHeight: (p: number) => void;
};

const ConsultaContext = createContext<ContextType>({} as ContextType);

export const useConsultaContext = () => useContext(ConsultaContext);

type ConsultaProviderProps = {
  children: JSX.Element;
  idConsulta?: string;
  eTriagem?: boolean;
  eHistorico?: boolean;
};

export const ConsultaProvider = ({
  children,
  idConsulta,
  eTriagem,
  eHistorico,
}: ConsultaProviderProps) => {
  const { idConsulta: idConsultaRouter } = useParams<{ idConsulta: string }>();

  let method = eTriagem ? '_buscarTriagem' : '_findById';

  const endpoint = eHistorico ? 'aswhistusuario' : 'aswagendausuprofsaude';
  if (eHistorico) {
    method = '_buscaHistorico';
  }

  const ID_CONSULTA = idConsultaRouter || idConsulta;

  const { data: consulta, isFetching: isFetchingConsulta } =
    useApiOnMount<ConsultaType>(endpoint, {
      method,
      idConsulta: ID_CONSULTA,
      staleTimeOnMinutes: 0,
    });

  const [tipoConsulta, _setTipoConsulta] =
    useState<ContextType['tipoConsulta']>('');

  const [headerHeight, _setHeaderHeight] = useState(400);

  const setTipoConsulta = useCallback((tipo) => {
    _setTipoConsulta(tipo);
  }, []);

  const setHeaderHeight = useCallback((header) => {
    _setHeaderHeight(header);
  }, []);

  return (
    <ConsultaContext.Provider
      value={{
        consulta,
        isFetchingConsulta,
        idConsulta: ID_CONSULTA,
        tipoConsulta,
        setTipoConsulta,
        headerHeight,
        setHeaderHeight,
      }}
    >
      {children}
    </ConsultaContext.Provider>
  );
};
