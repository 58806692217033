export const debounce = (func: Function, delay = 1000) => {
  let timerId;
  return function (...args) {
    const context = this;
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      timerId = null;
      func.apply(context, args);
    }, delay);
  };
};
