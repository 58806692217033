import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { BASE_API_VERSION_PATH } from 'shared/util';
import { useUser } from 'hooks';

const api = axios.create({
  baseURL: BASE_API_VERSION_PATH,
  headers: {},
});

type ModalRecuperarSenhaProps = {
  isOpen: boolean;
  onClose: () => void;
  content?: any;
};

type TFormParams = {
  email: string;
};

type TGenericResponse = {
  message: string;
  statusCode: number;
  success: boolean;
};
/**
 *
 * @param { isOpen, onClose, content}
 * @returns
 */
function ModalRecuperarSenha({
  isOpen,
  onClose,
  content,
}: ModalRecuperarSenhaProps) {
  const MySwal = withReactContent(Swal);
  const requiredFieldText = 'Campo obrigatório';
  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required(requiredFieldText),
  });
  const usuarioLogado = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormParams>({
    resolver: yupResolver(schema),
  });

  /**
   *
   * @param params
   */
  const handleEnviarSenha = async (params: TFormParams) => {
    const body = {
      method: '_forgotPass',
      sgCliente: usuarioLogado.sgCliente,
      email: params.email,
    };

    await api.post('asmuser', body).then((response) => {
      const dataResponse: TGenericResponse = response?.data || {};

      if (dataResponse.statusCode == 200 || dataResponse?.statusCode == 201) {
        onClose();

        MySwal.fire({
          title:
            dataResponse?.message || 'Senha enviada para o e-mail escolhido!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: dataResponse?.message || 'Houve um erro ao recuperar a senha!',
          icon: 'error',
        });
      }
    });
  };

  /**
   *
   * @param params
   */
  const handleFormSubmit = async (params: TFormParams) => {
    try {
      handleEnviarSenha(params);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }

      console.log(validationErrors);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>Esqueci minha senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={1} gap={8} my={1}>
              <Box>
                <Text>
                  Informe seu e-mail cadastrado para enviarmos sua nova senha.
                </Text>
              </Box>
              <Box experimental_spaceY={2}>
                <Text>E-mail</Text>
                <Input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  {...register('email', {
                    /**/
                  })}
                ></Input>
                {errors.email?.message && (
                  <Text color="red">{errors.email?.message}</Text>
                )}
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn btn-primary"
              variant="outline"
              borderColor="primary"
              type="submit"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export { ModalRecuperarSenha };
