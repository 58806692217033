type Props = {
  width?: number;
  height?: number;
};

export function RightIcon({ height = 15, width = 10 }: Props) {
  return (
    <svg
      style={{ display: 'inherit' }}
      width={width}
      height={height}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3332 0L0.158203 1.175L3.97487 5L0.158203 8.825L1.3332 10L6.3332 5L1.3332 0Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}
