import sweetalert2 from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';

const swal = withReactContent(sweetalert2);

type T = {
  title: string;
  text?: string;
  html?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string | JSX.Element;
  confirmButtonText?: string | JSX.Element;
};

export function useAlert() {
  return {
    success: ({ ...rest }: T) => {
      return swal.fire({
        ...rest,
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: colors.green,
      });
    },
    error: ({ ...rest }: T) => {
      return swal.fire({
        ...rest,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: colors.danger,
      });
    },
    warning: ({ ...rest }: T) => {
      return swal.fire({
        ...rest,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: colors.yellow,
      });
    },
  };
}
