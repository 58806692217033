import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useUser } from 'hooks';
import { CirurgiasExames } from 'pages/atendimento/components/consulta/components/cirurgias-exames';
import { HistoricoPessoal } from 'pages/atendimento/components/consulta/components/historico-pessoal';
import { Prontuario } from 'pages/atendimento/components/consulta/components/prontuario';
import { DocumentosExames } from '../documentos-exames';
import { HistoricoAtendimento } from '../historico-atendimento';
import { HistoricoTriagemSindromeGripal } from '../historico-triagem-sindrome-gripal';
import { QuestionarioSaudeMulher } from '../questionario-saude-mulher';

export function AppointmentTabs() {
  const user = useUser();

  return (
    <Tabs>
      <TabList>
        <Tab fontSize={'14px'}>Prontuário Clínico</Tab>
        <Tab fontSize={'14px'}>Cirurgias e Exames</Tab>
        <Tab fontSize={'14px'}>Histórico Pessoal</Tab>
        <Tab fontSize={'14px'}>Histórico de atendimentos</Tab>
        {/* <Tab fontSize={'14px'}>Histórico Triagem Síndrome Gripal</Tab> */}
        <Tab fontSize={'14px'}>Documentos e Exames</Tab>
        {user?.grupoEscolhido?.sgInstSaude === 'samuelLibaneo' && (
          <Tab fontSize={'14px'}>Questionário Saúde da Mulher</Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Prontuario />
        </TabPanel>
        <TabPanel>
          <CirurgiasExames />
        </TabPanel>
        <TabPanel>
          <HistoricoPessoal />
        </TabPanel>
        <TabPanel>
          <HistoricoAtendimento />
        </TabPanel>
        {/* <TabPanel>
          <HistoricoTriagemSindromeGripal />
        </TabPanel> */}
        <TabPanel>
          <DocumentosExames />
        </TabPanel>
        {user?.grupoEscolhido?.sgInstSaude === 'samuelLibaneo' && (
          <TabPanel>
            <QuestionarioSaudeMulher />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
