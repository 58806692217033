import { useDisclosure } from '@chakra-ui/react';
import { ModalRedefinirSenha } from 'components/modal-redefinir-senha';
import { useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { AUTH_USER_DATA } from 'shared/util';

export interface TUserData {
  sgCliente?: string;
  cpf?: string;
  email?: string;
  nome?: string;
  resetPassword?: number;
  institucoes: any;
}

export const ErrorBoundaryRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const userData: TUserData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));

  function buscaRotas() {
    // let found = false;

    // if (location.pathname == '/login' || location.pathname == '/') {
    //   return true;
    // }
    // userData?.institucoes?.forEach((item) =>
    //   item?.rotas?.map((p) => {
    //     if (location.pathname == p.rota || location.pathname.includes(p.rota)) {
    //       found = true;
    //     }
    //   })
    // );
    // return found;
    return true;
  }

  const updateUserLocalStorage = () => {
    userData.resetPassword = 0;
    localStorage.setItem(AUTH_USER_DATA, JSON.stringify(userData));
    onClose();
  };

  const encloseInErrorBoundary = (props) =>
    isOpen ? (
      <ModalRedefinirSenha
        isOpen={isOpen}
        userData={userData}
        onClose={onClose}
        fallBackSuccess={updateUserLocalStorage}
      />
    ) : buscaRotas() == true ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );

  useEffect(() => {
    const resetPassword = userData?.resetPassword || 0;
    if (rest.path !== '/login' && resetPassword === 1) {
      onOpen();
    }
  }, [rest, userData]);

  return <Route {...rest} render={encloseInErrorBoundary} />;
};

export default ErrorBoundaryRoute;
