import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import { CadastroTelemedicina } from './_base/cadastro-telemedicina';
import Page from './components/page';

export const nCadastroTelemedicina = (props) =>
  CadastroTelemedicina(props).return();

const Routes = ({ match }) => (
  <Switch>
    <ErrorBoundaryRoute path={match.path} component={Page} />
  </Switch>
);

export default Routes;
