import { InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Label, Row } from 'reactstrap';


type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
};

export const TextArea = ({ name, label, className, ...rest }: Props) => {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  const [restProps, setRestProps] = useState<Partial<Props>>({});


  const element = watch(name);

  return (
    <>
      <Row>
        {!!label && (
          <Col md="12">
            <Label className="mt-4 label-single-line" for={name}>
              {label}
            </Label>
          </Col>
        )}
        <Col md="12">
          <textarea
            {...register(name)}
            id={name}
            className={className || 'form-control'}
            {...rest}
            {...restProps}
          />
          {!!errorMessage && (
            <span className="text-warning">
              <small>{errorMessage}</small>
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};
