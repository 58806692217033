import { Route, Switch } from 'react-router-dom';
import { TipoAtendimentoType } from 'types/consulta';
import { Consulta } from './components/consulta';
import { ListagemAtendimentos } from './components/listagem-atendimentos';

type Props = {
  match: any; //só deus sabe
  tipoAtendimento: TipoAtendimentoType;
};

export default ({ match, tipoAtendimento }: Props) => (
  <Switch>
    <Route
      path={`${match.path}/:idConsulta`}
      render={(rest) => (
        <Consulta {...rest} tipoAtendimento={tipoAtendimento} />
      )}
    />
    <Route
      path={`${match.path}`}
      render={(rest) => (
        <ListagemAtendimentos {...rest} tipoAtendimento={tipoAtendimento} />
      )}
    />
  </Switch>
);
