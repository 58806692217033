import {
  Box,
  Button,
  Flex,
  Input,
  Heading,
  Stack,
  Tag,
  useToast,
  Select,
  Spacer,
  ButtonGroup,
} from '@chakra-ui/react';
import { WrapperLoading } from 'components/wraper-loading';
import { useApiRequest } from 'hooks';
import { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { api } from 'services/api';
import cpfMask from 'shared/util/cpfMask';
import { useUser } from 'hooks';
import { BsTrash, BsSave } from 'react-icons/bs';
import isValidCpf from 'shared/util/validacao-cpf';
import { onlyNumber } from 'shared/util';

type ItemProps = {
  label: string;
  value: string;
};

const Field = (props: ItemProps) => {
  return (
    <Box marginTop={'2'}>
      <Flex>
        <Label>{props.label}</Label>
      </Flex>
      <Input
        borderRadius={'10px'}
        maxW="60rem"
        readOnly
        value={props.value}
        style={{ pointerEvents: 'none' }}
      />
    </Box>
  );
};

type U = Partial<{
  usuarioCPF: string;
  emailUsuario: string;
  nmUsuario: string;
  telefoneUsuario: string;
  usuarioCidade: string;
  usuarioUF: string;
  usuarioInstSaudePrefer: string;
  elegivel: 'true' | 'false';
  dtCriacao: Date;
  dtModificacao: Date;
  emailUsuarioFamiliar: string;
  grauParentesco: string;
  usuarioCEP: string;
  usuarioEndereco: string;
  usuarioGenero: string;
  usuarioNasc: string;
  usuarioNumero: string;
  usuarioBairro: string;
  usuarioComplemento: string;
  usuarioFoto: string;
  usuarioGeneroIdentidade: string;
  usuarioNomeSocial: string;
  usuarioOrientacao: string;
  usuarioCNS: string;
  usuarioCPFFamiliar: string;
  usuarioCargo: string;
  usuarioDepartamento: string;
  usuarioCorRaca: string;
  usuarioRG: string;
  usuarioSetor: string;
  usuarioFilial: string;
  usuarioAtivo: 1 | 0;
}>;

const ElegibilidadePaciente = () => {
  const [inputSearch, setInputSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [paciente, setPaciente] = useState<U>({});
  const [instituicao, setInstituicao] = useState('');

  const toast = useToast();

  const usuarioLogado = useUser();

  const formSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!instituicao) {
      return;
    }

    if (!!inputSearch) {
      setLoading(true);
      api
        .post('aswusuarios', {
          method: '_pesquisaPacienteElegibilidade',
          usuario: inputSearch,
          sgCliente: usuarioLogado.sgCliente,
          sgInstSaude: instituicao,
        })
        .then(({ data: response }) => {
          setPaciente(response?.data || {});
          if (!response?.data?.usuarioCPF) {
            toast({
              title: `Paciente não encontrado`,
              position: 'top-left',
              isClosable: true,
              duration: 1500,
              variant: 'left-accent',
            });
          } else {
            setInputSearch('');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const tornarElegivel = async () => {

    api
      .post('aswusuarios', {
        method: '_addElegebilidade',
        sgCliente: usuarioLogado.sgCliente,
        usuarioCPF: paciente?.usuarioCPF,
      }).then(() => {
        setLoading(true);
        api
          .post('aswusuarios', {
            method: '_pesquisaPacienteElegibilidade',
            usuario: paciente?.usuarioCPF,
          }).then(({ data: response }) => {
            setPaciente(response?.data || {});
          })
      }).finally(() => {
        setLoading(false);
      });
  }

  const tornarNaoElegivel = async () => {
    api
      .post('aswusuarios', {
        method: '_removeElegibilidade',
        sgCliente: usuarioLogado.sgCliente,
        usuarioCPF: paciente?.usuarioCPF,
      }).then(() => {
        setLoading(true);
        api
          .post('aswusuarios', {
            method: '_pesquisaPacienteElegibilidade',
            usuario: paciente?.usuarioCPF,
          }).then(({ data: response }) => {
            setPaciente(response?.data || {});
          })
      }).finally(() => {
        setLoading(false);
      });
  }

  const elegivel = paciente?.elegivel === 'true';

  return (
    <Box>
      <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
        Usuário - Elegibilidade Paciente
      </Heading>

      <Box marginTop={'2rem'}>
        <Box marginTop={'2'}>
          <Flex>
            <Label>Instituição de saúde</Label>
          </Flex>
          <Select
            maxW="60rem"
            className="mb-4"
            onChange={(event) => {
              const { value } = event.target;
              event.persist();
              setInstituicao(value);
            }}
            placeholder={'Selecione a instituição de saúde'}
          >
            {(usuarioLogado?.institucoes || []).map((item, index) => {
              return (
                <option key={index} value={item.institucao}>
                  {item.institucaoNome}
                </option>
              );
            })}
          </Select>
        </Box>

        {!!instituicao && (
          <>
            <form onSubmit={formSubmit}>
              <Flex>
                <Label>CPF</Label>
              </Flex>
              <Input
                value={inputSearch}
                onChange={(e) => setInputSearch(cpfMask(e.target.value))}
                borderRadius={'10px'}
                maxW="60rem"
                name="usuario"
              />
              <div>
                {onlyNumber(inputSearch).length >= 11 &&
                  !isValidCpf(inputSearch) && (
                    <small className="text-warning">CPF inválido</small>
                  )}
              </div>

              <Flex maxW="60rem" alignItems="center" gap="2" marginTop={'4'}>

                <Stack marginTop={'2'} direction="row" spacing={3}>
                  <WrapperLoading isLoading={loading}>
                    <Button
                      borderRadius={'10px'}
                      color={'#56A1E1'}
                      variant="outline"
                      borderColor={'#56A1E1'}
                      type="submit"
                      disabled={!inputSearch}
                    >
                      Pesquisar
                    </Button>
                  </WrapperLoading>
                </Stack>
              </Flex>

            </form>

            {!loading && paciente?.usuarioCPF && (
              <Box marginTop={'2rem'}>
                <Tag
                  size="md"
                  variant="solid"
                  colorScheme={elegivel ? 'green' : 'yellow'}
                >
                  {elegivel ? 'Este usuário está elegível' : 'Este usuário não está elegível'}
                </Tag>
                <Field label="Nome" value={paciente?.nmUsuario} />
                <Field label="CPF" value={cpfMask(paciente?.usuarioCPF || '')} />
                <Field label="Setor" value={paciente?.usuarioSetor || ''} />
                <Field label="Filial" value={paciente?.usuarioFilial || ''} />
                <Field label="Função" value={paciente?.usuarioCargo || ''} />
              </Box>
            )}
            {!loading && paciente?.usuarioCPF && (
              <div>
                <Button
                  colorScheme={elegivel ? 'gray' : 'green'}
                  mt={10}
                  disabled={elegivel}
                  onClick={tornarElegivel}
                >Está elegível</Button>
                <Button colorScheme={elegivel ? 'yellow' : 'gray'}
                  disabled={!elegivel}
                  mt={10}
                  ml={5}
                  onClick={tornarNaoElegivel}
                >Não está elegível</Button>
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ElegibilidadePaciente;
