import { Checkbox, Spinner, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SelectAsync from 'react-select/async';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { Input } from 'components/form/input';
import { InputRadio } from 'components/form/radio';
import { Select } from 'components/form/select';
import { TextArea } from 'components/form/textarea';
import { Loading } from 'components/Loading';
import { GrupoMenuType, useFetchEstadosCidades, useFetchOptionsFormPS, ValueLabelType } from 'hooks/useFetchData';
import { api } from 'services/api';
import { colors } from 'theme/colors';

import { ExamType, InstType } from '../../types';

import 'react-datepicker/dist/react-datepicker.css';

type ModalExameProps = {
  type?: string;
  speciality: string;
  name: string;
  prepare?: string;
};

type ModalPropsType = {
  isNew: boolean;
  onRequestClose: () => void;
  examData?: ExamType;
};

const MySwal = withReactContent(Swal);

type InstSelectedType = ValueLabelType & { grupo?: ValueLabelType };

export const ModalForm = ({
  isNew = false,
  examData,
  onRequestClose,
}: ModalPropsType) => {
  const [checked, setChecked] = useState<any>();
  const requiredFieldText = 'Campo obrigatório';
  const getSchema = () =>
    Yup.object().shape({
      speciality: Yup.string().required(requiredFieldText),
      name: Yup.string().required(requiredFieldText),
      prepare: Yup.string().max(2500, 'Formato Inválido').notRequired(),
    });
  const formMethods = useForm<ModalExameProps>({
    resolver: yupResolver(getSchema()),
  });

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [especialidadeList, setEspecialidadeList] = useState([]);

  useEffect(() => {
    if (!isNew) {
      console.log(examData);
      const type = examData.tipoExCodigo;
      const speciality = examData.esCodigo.toString();
      const name = examData.exNome;
      const prepare = examData.exPreparo;
      getSpecialities(type);

      formMethods.setValue('type', type.toString());
      formMethods.setValue('speciality', speciality);
      formMethods.setValue('name', name);
      formMethods.setValue('prepare', prepare);
      setChecked(examData.ativo === 1 ? false : true);
    }
  }, [examData]);

  const handleRequestClose = () => {
    setOpen(false);
    setTimeout(onRequestClose, 500);
  };

  const handleSubmit = async () => {
    const values = formMethods.getValues();
    console.log(values);
    try {
      setIsLoading(true);
      if (isNew) {
        const { data } = await api.post('aswexame', {
          method: '_createExam',
          Authorization: Cookies.get('AUTH_TOKEN_KEY'),
          exEspecialidade: parseInt(values.speciality),
          exNome: values.name,
          exPreparo: values.prepare,
        });
        setIsLoading(false);
        if (data.success) {
          handleRequestClose();
        }
      } else {
        const { data } = await api.post('aswexame', {
          method: '_updateExam',
          Authorization: Cookies.get('AUTH_TOKEN_KEY'),
          exEspecialidade: parseInt(values.speciality),
          exNome: values.name,
          exPreparo: values.prepare,
          exCodigo: examData.exCodigo,
          ativo: checked ? 0 : 1
        });
        setIsLoading(false);
        if (data.success) {
          handleRequestClose();
        }
      }
    } catch (error) {
      MySwal.fire('Erro!', error);
    }
  };

  const getSpecialities = async (value) => {
    setDisable(false);
    setIsLoading(true);
    const { data } = await api.post('aswexame', {
      method: '_listSpecialitiesOfType',
      Authorization: Cookies.get('AUTH_TOKEN_KEY'),
      type: value,
    });
    setIsLoading(false);
    setEspecialidadeList(data.data);
    return data.data;
  };

  return (
    <div>
      <Modal
        className="gerenciamento-profissional-saude-new-modal"
        size={'md'}
        isOpen={open}
      >
        <ModalHeader toggle={handleRequestClose}>
          <div id="page-heading">
            <span className="page-header ml-3">
              {isNew ? (
                <span>CADASTRAR NOVO EXAME</span>
              ) : (
                <span>EDITAR EXAME</span>
              )}
            </span>
          </div>
        </ModalHeader>
        <div>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <ModalBody>
                <Row className="justify-content-center">
                  <div style={{ marginLeft: '30px' }}>
                    <div>
                      <Label>Selecione o tipo</Label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{ marginLeft: '15px', marginRight: '15px' }}
                        onClick={() => getSpecialities(3)}
                      >
                        <InputRadio name="type" label="Diagnóstico" value={3} />
                      </div>
                      <div
                        style={{ marginRight: '15px' }}
                        onClick={() => getSpecialities(2)}
                      >
                        <InputRadio
                          name="type"
                          label="Laboratorial"
                          value={2}
                        />
                      </div>
                      <div onClick={() => getSpecialities(1)}>
                        <InputRadio name="type" label="Outros" value={1} />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                    <div>
                      <Label>Especialidade*</Label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '15px', width: '90%' }}>
                        <Select
                          name={'speciality'}
                          placeholder="Selecione..."
                          options={especialidadeList}
                          noOptionsMessage={() => 'Sem dados'}
                          disabled={disable || isLoading}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                    <div>
                      <Label>Determine o nome</Label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '15px', width: '90%' }}>
                        <Input name="name" type="text" placeholder={'Nome'} />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                    <div>
                      <Label>Preparo</Label>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '15px', width: '90%' }}>
                        <TextArea
                          name="prepare"
                          style={{
                            marginTop: '10px',
                            height: '187px',
                          }}
                          maxLength={2500}
                          className="form-control"
                          placeholder="Detalhes do preparo"
                        />
                      </div>
                    </div>
                  </div>
                  {!isNew && (
                    <div style={{ marginLeft: '30px', marginTop: '20px' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '15px', width: '90%' }}>
                          <Checkbox
                            color="#5E5E5E"
                            isChecked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                          >
                            Desativar Exame
                          </Checkbox>
                          <Text fontSize="10px" color="#5E5E5E">
                            {
                              '(ao selecionar esta opção, o exame não será exibido como opção de agendamento)'
                            }
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  className="float-right"
                  disabled={isLoading}
                  type="submit"
                >
                  {isNew ? 'Cadastrar Exame' : 'Confirmar Alterações'}{' '}
                  {isLoading && (
                    <>
                      &nbsp;
                      <i className="fas fa-spinner fa-spin fa-1x"></i>
                    </>
                  )}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </div>
      </Modal>
    </div>
  );
};
