import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InputRadio } from 'components/form/radio';
import { Loading } from 'components/Loading';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY } from 'shared/util';

import personIncon from '../../assets/personIcon.svg';
import pinIcon from '../../assets/pinIcon.svg';
import professionalIcon from '../../assets/professionalIcon.svg';
import specialityIcon from '../../assets/specialityIcon.svg';
import timeIcon from '../../assets/timeIcon.svg';

interface cancelarAgendamentoProps {
  agendamentos: any;
  isOpen: boolean;
  onClose: () => void;
  status: string;
  action: string;
  loadAgendamentos: (status?: string) => Promise<void>;
}
const CancelarOuConfirmar = ({
  agendamentos,
  isOpen,
  onClose,
  status,
  action,
  loadAgendamentos,
}: cancelarAgendamentoProps) => {
  const [motivos, setMotivos] = useState([]);
  const formMethods = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      listMotivos();
    }
  }, [isOpen]);

  const cancelarAgendamento = async () => {
    if (action === 'cancel') {
      const values = formMethods.getValues();
      if (agendamentos.tipo === 'consulta') {
        const { data } = await api.post('aswagendausuprofsaude', {
          method: '_cancel',
          agIdConsulta: agendamentos.codigo,
          sgCliente: 'asg',
          sgInstSaude: agendamentos.sgInstSaude,
          psCPF: agendamentos.psCPF,
          usuarioCPF: agendamentos.usuarioCPF,
          agDataHoraAgenda: agendamentos.agDataHoraAgenda,
          mcDescricao: values.motivoDesc,
          mcPSPaciente: 'O',
        });
        if (data.success) {
          toast({
            title: 'Agendamento cancelado',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          });
          await loadAgendamentos(status);
          return true;
        }
      } else {
        const { data } = await api.post('aswexame', {
          method: '_cancelExamSchedule',
          agCodigo: parseInt(agendamentos.codigo),
          Authorization: Cookies.get(AUTH_TOKEN_KEY),
          mcDescricao: values.motivoDesc,
          mcPSPaciente: 'O',
        });
        if (data.success) {
          toast({
            title: 'Agendamento cancelado',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          });
          await loadAgendamentos(status);
          return true;
        }
      }
      return false;
    } else {
      if (agendamentos.tipo === 'consulta') {
        const { data } = await api.post('aswagendausuprofsaude', {
          method: '_confirmarAgendamento',
          sgCliente: 'asg',
          sgInstSaude: agendamentos.sgInstSaude,
          psCPF: agendamentos.psCPF,
          agDataHoraAgenda: agendamentos.agDataHoraAgenda,
          usuarioCPF: agendamentos.usuarioCPF,
        });
        if (data.success) {
          toast({
            title: 'Agendamento confirmado.',
            description: 'Solicitação de consulta confirmada.',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          });
          console.log('da');
          await loadAgendamentos(status);
        }
      } else {
        const { data } = await api.post('aswexame', {
          method: '_confirmExamSchedule',
          agCodigo: parseInt(agendamentos.codigo),
          Authorization: Cookies.get(AUTH_TOKEN_KEY),
        });
        if (data.success) {
          toast({
            title: 'Exame confirmado.',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          });
          await loadAgendamentos(status);
        }
      }
    }
  };

  const listMotivos = async () => {
    try {
      const { data } = await api.post('aswmotivocancelamento', {
        method: '_listRecepcao',
        status: status,
      });

      if (data.success) {
        setMotivos(data.data.results);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    if (cancelarAgendamento()) {
      formMethods.reset();
      closeModal();
    }
  };

  const closeModal = () => {
    formMethods.reset();
    onClose();
  };

  const obterDiaSemanaPorExtenso = (diaSemana) => {
    const dias = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    return dias[diaSemana];
  };

  // Função para obter o mês por extenso
  const obterMesPorExtenso = (mes) => {
    const meses = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    return meses[mes];
  };

  const getDay = (date) => {
    let data = new Date(date);

    let diaSemana = obterDiaSemanaPorExtenso(data.getDay());
    let dia = data.getDate();
    let mes = obterMesPorExtenso(data.getMonth());
    let ano = data.getFullYear();
    let dataPorExtenso = `${diaSemana}, ${dia} de ${mes} de ${ano}`; // Terça-feira, 26 de outubro de 2023

    return dataPorExtenso;
  };

  const getHour = (date) => {
    let data = new Date(date);

    // Formatação do horário para AM/PM
    let horario = data.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Verifica se é AM ou PM
    let periodo = data.getHours() < 12 ? 'AM' : 'PM';

    return `${horario} ${periodo}`;
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {action === 'cancel'
              ? status === 'Solicitadas'
                ? 'Cancelar solicitação de consulta'
                : 'Cancelar consulta'
              : 'Confirmação de agendamento'}
          </ModalHeader>
          <ModalCloseButton />
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <ModalBody>
                <Box>
                  {action === 'cancel' ? (
                    status === 'Solicitadas' ? (
                      <Text fontSize="14px">
                        Tem certeza que deseja cancelar a solicitação de
                        consulta abaixo?
                      </Text>
                    ) : (
                      <Text fontSize="14px">
                        {' '}
                        Tem certeza que deseja cancelar a consulta abaixo?
                        Selecione um dos motivo de cancelamento abaixo:
                      </Text>
                    )
                  ) : (
                    <Text fontSize="14px">
                      Tem certeza que deseja confirmar o agendamento da consulta
                      abaixo?
                    </Text>
                  )}
                </Box>
                <Box p={3} mb={4}>
                  <Box display="flex" mb={3}>
                    <Box mr={3}>
                      <img src={personIncon} alt="icon1" />
                    </Box>
                    <Box>
                      <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                        {agendamentos.nmUsuario}
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" mb={3}>
                    <Box mr={3}>
                      <img src={specialityIcon} alt="icon1" />
                    </Box>
                    <Box>
                      <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                        {agendamentos.peDescricao}
                      </Text>
                    </Box>
                  </Box>
                  {agendamentos.tipo === 'consulta' && (
                    <Box display="flex" mb={3}>
                      <Box mr={3}>
                        <img src={professionalIcon} alt="icon1" />
                      </Box>
                      <Box>
                        <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                          {agendamentos.psNome}
                        </Text>
                      </Box>
                    </Box>
                  )}
                  <Box display="flex" mb={3}>
                    <Box mr={3}>
                      <img src={timeIcon} alt="icon1" />
                    </Box>
                    <Box>
                      <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                        {getHour(agendamentos.agDataHoraAgenda)}
                      </Text>
                      <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                        {getDay(agendamentos.agDataHoraAgenda)}
                      </Text>
                    </Box>
                  </Box>
                </Box>

                {action === 'cancel' && (
                  <Box p={3}>
                    <Box>
                      <Text fontSize="14px">Motivos de cancelamento:</Text>
                    </Box>
                    <Box p={4}>
                      {motivos.map((motivo, key) => (
                        <InputRadio
                          name="motivoDesc"
                          label={motivo.mcDescricao}
                          value={motivo.mcDescricao}
                        />
                      ))}
                    </Box>
                    {loading && <Loading />}
                  </Box>
                )}
              </ModalBody>

              <ModalFooter w="full" display="flex" justifyContent="center">
                <Button
                  bgColor="rgba(86, 161, 225, 1)"
                  color="white"
                  type="submit"
                  disabled={
                    action === 'cancel' && !formMethods.getValues('motivoDesc')
                      ? true
                      : false
                  }
                >
                  {action === 'cancel'
                    ? 'Cancelar agendamento'
                    : 'Confirmar agendamento'}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelarOuConfirmar;
