import { Box, Flex, Select } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'components/Loading';
import { useUser } from 'hooks';
import { isElement } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import { FilaAtendimentoType } from './..';

const MySwal = withReactContent(Swal);

interface IPropsModalFilaAtendimento {
  showModalForm: boolean;
  toggle: Function;
  filaAtendimentoSelected?: FilaAtendimentoType | null;
  atualizarDados: Function;
}

export default function ModalFilaAtendimento({
  showModalForm,
  toggle,
  filaAtendimentoSelected,
  atualizarDados,
}: IPropsModalFilaAtendimento) {
  const usuarioLogado = useUser();
  const isEditing = filaAtendimentoSelected ? true : false;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const requiredFieldsMessage = 'Campo obrigatório';
  const [instituicao, setInstituicao] = useState('');
  const schema = Yup.object().shape<
    Partial<Record<keyof FilaAtendimentoType, Yup.AnySchema>>
  >({
    sgInstSaude: Yup.string().default('').required(requiredFieldsMessage),
    faCodigo: Yup.string().default(''),
    faDescricao: Yup.string().required(requiredFieldsMessage),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOpenModal = () => {
    if (isEditing) {
      setValue('faCodigo', filaAtendimentoSelected.faCodigo);
      setValue('faDescricao', filaAtendimentoSelected.faDescricao);
      setValue('sgInstSaude', filaAtendimentoSelected.sgInstSaude);
    }
  };

  const formValues = (data: FilaAtendimentoType) => {
    setIsLoading(true);
    api
      .post('aswfilasatendimento', {
        method: isEditing ? 'atualizarFilaAtendimento' : 'criarFilaAtendimento',
        faCodigo: data.faCodigo,
        faDescricao: data.faDescricao,
        sgCliente: usuarioLogado.sgCliente,
        sgInstSaude: data.sgInstSaude,
      })
      .then(({ data }) => {
        if (data.success) {
          MySwal.fire({
            title: isEditing
              ? 'Fila de Atendimento alterada'
              : 'Fila de Atendimento cadastrada',
            html: 'Salvo com sucesso',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
          atualizarDados();
          toggle();
        } else {
          MySwal.fire({
            title: isEditing ? 'Erro ao alterar' : 'Erro ao cadastrar',
            html: isEditing
              ? 'Não foi possível alterar a Fila de Atendimento'
              : 'Não foi possível cadastrar a Fila de Atendimento',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#008000',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="cadastro-fila-atendimento-new-modal"
      size={'lg'}
      isOpen={showModalForm}
      onClosed={reset}
      onOpened={handleOpenModal}
      toggle={() => {
        toggle();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
      >
        {!isEditing
          ? 'CADASTRAR NOVA FILA DE ATENDIMENTO'
          : 'EDITAR FILA DE ATENDIMENTO'}
      </ModalHeader>
      <form onSubmit={handleSubmit(formValues)}>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md="11">
              <Box marginTop={'2'}>
                <Flex>
                  <Label>Instituição de saúde*</Label>
                </Flex>
                <Select
                  disabled={isEditing ? true : false}
                  {...register('sgInstSaude')}
                  name={"sgInstSaude"}
                  maxW="60rem"
                  className='form-control'
                  placeholder={'Selecione a instituição de saúde'}
                >
                  {(usuarioLogado?.institucoes || []).map((item, index) => {
                    return (
                      <option key={index} value={item.institucao}>
                        {item.institucaoNome}
                      </option>
                    );
                  })}
                </Select>
                {errors?.sgInstSaude?.message && (
                  <div className="text-warning pt-2">
                    {errors?.sgInstSaude?.message}
                  </div>
                )}
              </Box>
              <Row>
                <Col md="12">
                  <Label
                    className="mt-2 label-single-line"
                    id="faDescricaoLabel"
                    for="cadastro-descricao-fa"
                  >
                    Descrição *
                  </Label>
                  <input
                    readOnly={false}
                    {...register('faDescricao')}
                    id="cadastro-descricao-fa"
                    type="text"
                    name="faDescricao"
                    className={'form-control'}
                    maxLength={100}
                  />
                  {errors?.faDescricao?.message && (
                    <div className="text-warning pt-2">
                      {errors?.faDescricao?.message}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            className="float-right jh-create-entity"
            type="submit"
          >
            {!isEditing
              ? 'Cadastrar Fila de Atendimento'
              : 'Confirmar Alterações'}
          </Button>
        </ModalFooter>
        {isLoading && <Loading />}
      </form>
    </Modal >
  );
}
