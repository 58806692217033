import { Flex, Stack, Text, Textarea } from '@chakra-ui/react';

/**
 * @docs
 * - https://chakra-ui.com/docs/components/textarea/usage
 *
 * @returns FormTextArea
 */
type TCheck = {
  title: string;
  onInput: (value: any) => void;
};

const FormTextArea = ({ title, onInput }: TCheck) => {
  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <Text fontSize="2xl">{title}</Text>
      <Stack>
        <Textarea
          placeholder="Escreva aqui..."
          onChange={(e) => onInput(e.target.value)}
        />
      </Stack>
    </Flex>
  );
};

export { FormTextArea };
