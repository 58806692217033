//@ts-nocheck
import { Link as NLink } from 'react-router-dom'
import { Button } from 'reactstrap'
import json from '../i18n/pt/index'

export const Link = ({ ...props }) => {
  const className = props.children?.props?.className
  const href = props.href
  const children = props.children?.props?.children
  const onClick = props.children?.props?.onClick
  if (href && href !== '#') {
    return (
      <NLink className={className} to={href}>
        {children}
      </NLink>
    )
  } else {
    return (
      <Button className={className} onClick={onClick ? onClick : () => { }}>
        {children}
      </Button>
    )
  }
}
export const baseTrans = (
  contentKey: string,
  interpolate?: any,
  lista?: any
) => {
  if (lista && lista[contentKey]) return lista[contentKey]
  return contentKey.split('.').slice(-1)
}
// var baseTrans1: any = null;

export const useTranslation = () => {
  const lista = json()
  return {
    t: (contentKey: string, interpolate = {}, children = '') =>
      baseTrans(contentKey, interpolate, lista),
  }
}

export const Translate = (props: {
  t?: Function | undefined
  className?: string
  contentKey: string
  interpolate?: any
  children?: string
}) => {
  return (
    <span className={props.className} trans-key={props.contentKey}>
      {' '}
      {translate(
        props.t,
        props.contentKey,
        props.interpolate,
        props.children
      )}{' '}
    </span>
  )
}

export const translate = (
  t: Function | undefined,
  contentKey: string,
  interpolate?: any,
  children?: string
) => {
  if (typeof t === 'function' && contentKey.includes('.')) {
    const transValue = t(contentKey, interpolate)
    return transValue
  } else {
    const lista = json()
    return baseTrans(contentKey, interpolate, lista)
  }
}

export const dangerouslySetInnerHTML = (contentKey: string) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: contentKey.substring(0, 150) }} />
  )
}
export const hasAnyAuthority = (
  contentKey: any,
  contentKey1?: any,
  contentKey2?: any
) => {
  return true
}
