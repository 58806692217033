import { BsCalendar } from 'react-icons/bs';
import { Link } from 'react-router-dom';

type Props = {
  examId: number;
};

export const GestaoDeAgendaExameLink = ({ examId }: Props) => {
  return (
    <div style={{display: 'flex', justifyContent: 'center' }}>
      <Link
        to={`/gerenciamento-agenda-exame/${examId}/gestao-de-agenda-exame`}
      >
        <BsCalendar />
      </Link>
    </div>
  );
};
