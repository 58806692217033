import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { api } from 'services/api';
import { changeInputText } from 'shared/util';
import { Container, SpanTipoGestao } from './styles';
import { ExamType, PSTypeList } from './types';
import { BsCalendar, BsPencil } from 'react-icons/bs';
import Cookies from 'js-cookie';

export const GerenciamentoAgendaGeral = () => {
  const { onlyMounted } = useOnlyMounted();
  const [scheduleList, setScheduleList] = useState([]);
  const [inputFilter, setInputFilter] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const listExams = async (search?: string) => {
    try{

      console.log(search);
      setIsLoading(true);
      const { data } = await api.post('aswexame', {
        method: '_listAllSchedules',
        Authorization: Cookies.get('AUTH_TOKEN_KEY'),
        search: search
      });
      console.log(data)
      if (data.success) {
        setScheduleList(data.data);
      }
      setIsLoading(false);
    } catch(err){
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    setIsLoading(true);
    listExams(inputFilter);
  };


  const formatDate = (date) => {
    const newData = new Date(date);
    const day = String(newData.getDate()).padStart(2, '0');
    const month = String(newData.getMonth() + 1).padStart(2, '0');
    const year = newData.getFullYear();
    const hour = String(newData.getHours()).padStart(2, '0');
    const minute = String(newData.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

  useEffect(() => {
    listExams();
  }, []);

  return (
    <Container>
      <h4
        id="gerenciamento-profissional-saude-heading"
        data-cy="GerenciamentoProfissionalSaudeHeading"
      >
        <div className="d-flex justify-content-between">
          <span>GERENCIAMENTO AGENDAMENTOS</span>
        </div>
      </h4>

      <div className="table-responsive">
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col
              md="12"
              className="col-filter-gerenciamento-profissional-saude-nome"
            >
              <Row className="mr-1 mt-1">
                <Label
                  style={{ backgroundColor: 'red' }}
                  id="nomeLabel"
                  for="gerenciamento-profissional-saude-nome"
                >
                  Profissional de Saúde
                </Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Buscar Agendamento"
                  name="nome"
                  id="gerenciamento-profissional-saude-nome"
                  value={inputFilter}
                  onChange={(event) => setInputFilter(changeInputText(event))}
                  onKeyDown={(key) => {
                    if (key.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Row>
            </Col>
          </div>

          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button id="search-submit" color="primary" onClick={handleSearch}>
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>

        {/* {scheduleList?.length > 0 && ( */}
          <div
            id="gerenciamento-profissional-saude-table-list"
            className="table-list mt-4"
          >
            <Table
              id="gerenciamento-profissional-saude-table-list"
              responsive
              aria-describedby="gerenciamento-profissional-saude-heading"
              className={'table-striped-exame table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th className="align-top">Paciente</th>
                  <th className="align-top">Especialidade/Exame</th>
                  <th className="text-center align-top">Profissional</th>
                  <th className="text-center  align-top">Local</th>
                  <th className="text-center  align-top">Data</th>
                </tr>
              </thead>
              <tbody>
                {scheduleList.map((item, key) => (
                  <tr key={key}>
                    <td>{item.nmUsuario}</td>
                    <td>{item.peDescricao}</td>
                    <td>{item.psNome}</td>
                    <td>{item.localAtendimento}</td>
                    <td>{formatDate(item.agDataHoraAgenda)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        {/* )} */}

        
      </div>

      {isLoading && <Loading />}
    </Container>
  );
};
