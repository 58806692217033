import React, { useState } from 'react';
import { useApiRequest, ValueLabelType } from 'hooks';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { colors } from 'theme/colors';
import { FilaType, LocalSelecionadoFilaType } from '../../types';

interface IPropsModalProfLocalFila {
  showModalForm: boolean;
  toggle: () => void;
  localSelected: LocalSelecionadoFilaType;
  updateLocais: (selecionado: LocalSelecionadoFilaType) => void;
}

export default function ModalProfLocalFila({
  showModalForm,
  toggle,
  localSelected,
  updateLocais,
}: IPropsModalProfLocalFila) {
  const filasLocalAPI = useApiRequest<FilaType[]>('aswfilasatendimento', []);
  const [filasLocalSelected, setFilasLocalSelected] = useState<FilaType[]>([]);

  const filasLocalList = filasLocalAPI.state.filter((filaLocal) =>
    filasLocalSelected.every((i) => i?.faCodigo !== filaLocal?.faCodigo)
  );

  const handleOpenModal = () => {
    filasLocalAPI
      .request({
        method: 'listarFilasLocal',
        laDescricao: localSelected.value,
      })
      .then(() => {
        if (localSelected.filas) {
          setFilasLocalSelected(localSelected.filas);
        }
      });
  };

  const handleCloseModal = () => {
    setFilasLocalSelected([]);
  };

  const retornarFilasLocal = () => {
    updateLocais({ ...localSelected, filas: filasLocalSelected });
  };

  const selecionaFA = (filaAtendimento: any) => {
    setFilasLocalSelected((stateAntigo) => {
      if (stateAntigo.every((s) => s.faCodigo !== filaAtendimento.faCodigo)) {
        return [...stateAntigo, filaAtendimento];
      }
      return stateAntigo;
    });
  };

  const removeFA = (filaAtendimento: any) => {
    setFilasLocalSelected((stateAntigo) => {
      return stateAntigo.filter((i) => i.faCodigo !== filaAtendimento.faCodigo);
    });
  };

  return (
    <Modal
      size={'lg'}
      isOpen={showModalForm}
      onOpened={() => handleOpenModal()}
      onClosed={handleCloseModal}
      toggle={() => toggle()}
    >
      <ModalHeader toggle={() => toggle()}>
        ASSOCIAR FILAS DE ATENDIMENTO
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md="11">
            <Row>
              <Col md="12">
                <span>Local de Atendimento</span>
                <Input value={localSelected.label} readOnly />
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12">
                <div className="kn-unselected_status__status___">
                  Disponíveis
                  {filasLocalAPI.isLoading && (
                    <>
                      &nbsp;
                      <i className="fas fa-spinner fa-spin fa-1x"></i>
                    </>
                  )}
                </div>

                <div className={`kn-list__list___22Wuc`}>
                  {filasLocalList.map((item, key) => (
                    <div
                      onClick={() => {
                        selecionaFA(item);
                      }}
                      key={key}
                      className="kn-item__item___kQ2Ll kn-item__selected___3tRL6"
                    >
                      <div className="kn-item_label__label___2fJ5p">
                        {item?.faDescricao}
                      </div>
                    </div>
                  ))}
                </div>
              </Col>

              <Col md="6" sm="12">
                <div className="kn-unselected_status__status___">
                  Selecionado*
                </div>
                <div className={`kn-list__list___22Wuc`}>
                  <>
                    <Col md="12">
                      {filasLocalSelected.map((filaLocal, key) => {
                        return (
                          <div key={key}>
                            <Row>
                              <div className="mt-2">
                                <span
                                  style={{
                                    color: colors.blue,
                                    fontWeight: 600,
                                  }}
                                >
                                  {filaLocal?.faDescricao}
                                </span>
                                <Button
                                  onClick={() => {
                                    removeFA(filaLocal);
                                  }}
                                  className="btn-sm"
                                  color="warning"
                                  style={{ float: 'right' }}
                                >
                                  X
                                </Button>
                              </div>
                            </Row>
                          </div>
                        );
                      })}
                    </Col>
                  </>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          id="save-entity"
          className="float-right jh-create-entity"
          type="submit"
          onClick={retornarFilasLocal}
        >
          Confirmar Alterações
        </Button>
      </ModalFooter>
    </Modal>
  );
}
