import { Box, Spinner } from '@chakra-ui/react';
import {
  DialogConfirm,
  DialogConfirmRefProps,
} from 'components/dialog-confirm';
import { SintomasType } from 'components/modal-sindrome-gripal/types';
import { useConsultaContext } from 'contexts/Consulta';
import dayjs from 'dayjs';
import { useApiRequest, useUser } from 'hooks';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { dateTimeToServer } from 'shared/util';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);
import { alturaMask } from '../../../../../shared/util/alturaMask';
import { frequenciaMask } from '../../../../../shared/util/frequenciaMask';
import { pressaoMask } from '../../../../../shared/util/pressaoArterialMask';
import { temperaturaMask } from '../../../../../shared/util/temperaturaMask';
import { RenderModalSintomasGripais } from '../../../../../components/modal-sindrome-gripal';

export const HeaderModal = () => {
  const {
    consulta: dadosConsulta,
    isFetchingConsulta: isFetchingDadosConsulta,
    idConsulta,
  } = useConsultaContext();

  const history = useHistory();
  const confirmRef = useRef<DialogConfirmRefProps>(null);
  const [headerHeight, setHeaderHeight] = useState(110);
  const [marginBottom, setMarginBottom] = useState(110);
  useState<string>('');
  const [idade, setIdade] = useState<number>(0);
  const [huDtInicioConsulta, sethuDtInicioConsulta] = useState(new Date());
  const usuarioLogado = useUser();
  const [plano, setPlano] = useState('');
  const [huTriagPressaoArterial, setHuTriagPressaoArterial] =
    useState<string>('');
  const [hrTriagFreqCardiaca, setHrTriagFreqCardiaca] = useState<string>('');
  const [huTriagFreqRespiratoria, setHuTriagFreqRespiratoria] =
    useState<string>('');
  const [huTriagSaturacao, setHuTriagSaturacao] = useState<string>('');
  const [huTriagTemperatura, setHuTriagTemperatura] = useState<string>('');
  const [huTriagPeso, setHuTriagPeso] = useState<string>('');
  const [huTriagAltura, setHuTriagAltura] = useState<string>('');
  const [sintomasGripais, setSintomasGripais] = useState(false);
  const [sintomasGripaisData, setSintomasGripaisData] =
    useState<SintomasType>();

  useEffect(() => {
    const usuarioNasc = dayjs(dadosConsulta?.dadosConsulta?.usuarioNasc);
    const hoje = dayjs();
    const idadeUsuario = Math.trunc(hoje.diff(usuarioNasc, 'year', true));
    setIdade(idadeUsuario);
  }, [dadosConsulta]);
  const agendaApi = useApiRequest('aswagendausuprofsaude', {});

  const finalizarConsulta = () => {
    agendaApi
      .submit({
        method: '_encerrarAtendimentoTriagem',
        huDtInicioConsulta: dateTimeToServer(huDtInicioConsulta),
        psTriagemCPF: usuarioLogado?.cpf,
        idConsulta,
        huTipo: 'Consulta',
        huTriagPressaoArterial,
        hrTriagFreqCardiaca,
        huTriagFreqRespiratoria,
        huTriagSaturacao,
        huTriagTemperatura,
        huTriagPeso,
        huTriagAltura,
        huTriagSGTosse: sintomasGripaisData?.tosse ? 1 : 0,
        huTriagSGCoriza: sintomasGripaisData?.coriza ? 1 : 0,
        huTriagSGDorGarganta: sintomasGripaisData?.dorGarganta ? 1 : 0,
        huTriagSGDorCabeca: sintomasGripaisData?.dorCabeca ? 1 : 0,
        huTriagSGEspirros: sintomasGripaisData?.espirros ? 1 : 0,
        huTriagSGDorCostas: sintomasGripaisData?.dorCostas ? 1 : 0,
        huTriagSGDiarreia: sintomasGripaisData?.diarreia ? 1 : 0,
        huTriagSGFebre: sintomasGripaisData?.febre ? 1 : 0,
        huTriagSGOutros: sintomasGripaisData?.sintomas,
      })
      .then((response) => {
        if (!!response?.success) {
          history.goBack();
        }
      });

    console.log('aqui', finalizarConsulta);
  };

  useEffect(() => {
    const idConsulta = sessionStorage.getItem(
      dadosConsulta?.dadosConsulta?.idConsulta
    );
    if (idConsulta) {
      sethuDtInicioConsulta(new Date(idConsulta));
      return;
    }
    sessionStorage.setItem(
      dadosConsulta?.dadosConsulta?.idConsulta,
      huDtInicioConsulta.toString()
    );
  }, []);
  return (
    <>
      <Box marginBottom={marginBottom}>
        <div
          id="atendimento-detail-view"
          className="tela-paciente"
          style={{ height: headerHeight }}
        >
          <Row className="tela-paciente-header-triagem">
            <Col md={12} >
              <Row className="justify-content-center align-items-center row">
                <Col md={5} className="text-center " id="div-paciente">
                  <span className="font-weight-bold ">
                    {dadosConsulta?.dadosConsulta.nmUsuario} - {idade} anos
                  </span>
                  {/* <span className="font-weight-light">
                    {dadosConsulta?.dadosConsulta.genero == 'F'
                      ? ' (Feminino)'
                      : ' (Masculino)'}
                  </span> */}
                  <span> | Nome Social: {dadosConsulta?.dadosConsulta.usuarioNomeSocial}</span>
                </Col>
                <Col md={2} className="d-flex justify-content-around">
                  {headerHeight === 110 ? (
                    <span
                      onClick={() => {
                        setHeaderHeight(200);
                        setMarginBottom(200);
                      }}
                    >
                      <i className="fas fa-expand"></i>
                    </span>
                  ) : headerHeight === 180 ? (
                    <span
                      onClick={() => {
                        setHeaderHeight(210);
                        setMarginBottom(210);
                      }}
                    >
                      <i className="fas fa-expand"></i>
                    </span>
                  ) : headerHeight === 280 ? (
                    <span
                      onClick={() => {
                        setHeaderHeight(110);
                        setMarginBottom(110);
                      }}
                    >
                      <i className="fas fa-expand"></i>
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setHeaderHeight(110);
                        setMarginBottom(110);
                      }}
                    >
                      <i className="fas fa-compress"></i>
                    </span>
                  )}
                  <div>
                    <span className="mr-15" id="horario-consulta">
                      {moment(
                        dadosConsulta?.dadosConsulta.agDataHoraAgenda
                      ).format('HH:mm')}{' '}
                      -{' '}
                      {moment(dadosConsulta?.dadosConsulta.agDataHoraAgenda)
                        .add(15, 'minutes')
                        .format('HH:mm')}
                    </span>
                    <span className="mr-15" id="status">
                      {Date.now() <=
                      parseInt(dadosConsulta?.dadosConsulta.agDataHoraAgenda)
                        ? ' No horário'
                        : ' Atrasado'}
                    </span>
                  </div>
                </Col>

                <Col md="4" className="text-center ">
                  <span>
                    <Button
                      className="btn btn-secondary mt-1"
                      color="warning"
                      onClick={() => {
                        confirmRef.current.open();
                      }}
                    >
                      Encerrar Atendimento
                      {agendaApi.isLoading && (
                        <>
                          &nbsp;
                          <Spinner size={'sm'} />
                        </>
                      )}
                    </Button>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Container>
            <Row className="align-items-center row col-md-10 offset-md-1 ">
              <Col md="12">
                <Row>
                  <Col md="12">
                    <div className="d-flex justify-content-around mx-5 ">
                      <span className=" col-md-2 mt-1 ">
                        <p className="small-text">Pressão Arterial</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={huTriagPressaoArterial}
                          onChange={(v) =>
                            setHuTriagPressaoArterial(
                              pressaoMask(v.target.value)
                            )
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Frequência Cardíaca</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={hrTriagFreqCardiaca}
                          onChange={(v) =>
                            setHrTriagFreqCardiaca(
                              frequenciaMask(v.target.value)
                            )
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1 ">
                        <p className="small-text">Frequência Respiratória</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={huTriagFreqRespiratoria}
                          onChange={(v) =>
                            setHuTriagFreqRespiratoria(
                              frequenciaMask(v.target.value)
                            )
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Saturação</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={huTriagSaturacao}
                          onChange={(v) =>
                            setHuTriagSaturacao(v.target.value.slice(0, 2))
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Temperatura</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={huTriagTemperatura}
                          onChange={(v) =>
                            setHuTriagTemperatura(
                              temperaturaMask(v.target.value)
                            )
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Peso</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          type="number"
                          value={huTriagPeso}
                          onChange={(v) => setHuTriagPeso(v.target.value)}
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Altura</p>
                        <input
                          style={{
                            height:
                              window.screen.width > 760
                                ? `${headerHeight / 2 - 20}px`
                                : `${headerHeight / 2 - 110}px`,
                          }}
                          className=" col-md-11 rounded"
                          value={huTriagAltura}
                          onChange={(v) =>
                            setHuTriagAltura(alturaMask(v.target.value))
                          }
                        />
                      </span>
                      <span className=" col-md-2 mt-1">
                        <p className="small-text">Sintomas Gripais</p>
                        <div onClick={() => setSintomasGripais(true)}>
                          <select
                            style={{
                              height:
                                window.screen.width > 760
                                  ? `${headerHeight / 2 - 20}px`
                                  : `${headerHeight / 2 - 110}px`,
                            }}
                            className=" col-md-11 rounded small"
                            value={plano}
                            onChange={(v) => setPlano(v.target.value)}
                            disabled
                          >
                            <option
                              value=""
                              disabled
                              selected
                              className="small"
                            >
                              Selecione
                            </option>
                          </select>
                        </div>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Box>
      {sintomasGripais && (
        <RenderModalSintomasGripais
          isEditable={true}
          dados={[]}
          close={(value: boolean) => {
            setSintomasGripais(value);
          }}
          formSintomasGripais={(value: SintomasType) => {
            setSintomasGripaisData(value);
          }}
        />
      )}
      <DialogConfirm
        ref={confirmRef}
        titulo="Encerrar atendimento"
        mensagem="Confirma o encerramento do antedimento?"
        cancelButton={{ text: 'Cancelar' }}
        buttons={[
          {
            text: 'Confirma',
            color: 'yellow',
            closeOnClick: true,
            onClick: () => {
              finalizarConsulta();
            },
          },
        ]}
      />
    </>
  );
};
