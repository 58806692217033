import { Select } from '@chakra-ui/react';
import { useApiRequest, useOnlyMounted, useUser } from 'hooks';
import { InstType } from 'pages/atendimento-recepcao';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

type Props = {
  psCpf: string;
  close: (value: boolean) => void;
};
export type FilaAtendimentoType = {
  sgInstSaude?: string;
  faCodigo?: number;
  faDescricao: string;
};
export const ModalChamarEspecialidade = (props: Props) => {
  const MySwal = withReactContent(Swal);
  const { idConsulta } = useParams<{ idConsulta: string }>();
  const user = useUser();
  const { onlyMounted } = useOnlyMounted();
  const usuarioLogado = useUser();
  const instHook = useApiRequest<Array<InstType>>('aswinstsaude', []);
  const [filaSelecionada, setFilaSelecionada] = useState('');
  const [instSelected, setInstSelected] = useState('');
  const [filasAtendimentoFilter, setFilasAtendimentoFilter] = useState<
    FilaAtendimentoType[]
  >([]);

  const executaRequisicao = async (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    api.post(url, params).then(({ data }) => {
      onlyMounted(() => {
        callback(data);
      });
    });
  };

  const chamarOutraEspecialidade = async () => {
    await api.post('aswagendausuprofsaude', {
      method: '_chamarMedico',
      sgCliente: user?.sgCliente,
      sgInstSaude: instSelected,
      idConsulta: idConsulta,
      faCodigo: filaSelecionada,
      psCpfSolicitante: props.psCpf
    });
    MySwal.fire({
      title: 'Chamar Especialidade',
      html: 'Chamado Enviado!',
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#0085c3',
    });
    props.close(false);
  };

  const getFilasAtendimento = async (inst: string) => {
    if (inst != '') {
      await executaRequisicao(
        'aswfilasatendimento',
        {
          method: 'listarFilaAtendimento',
          sgCliente: usuarioLogado.sgCliente,
          sgInstSaude: inst,
        },
        ({ data, success }) => {
          if (success && Array.isArray(data)) {
            setFilasAtendimentoFilter(data);
          }
        }
      );
    }
  };

  async function listarInst() {
    instHook.request({
      method: '_listIsUsuarioLogado',
    });
  }

  useEffect(() => {
    listarInst();
  }, []);

  return (
    <Modal isOpen={true} toggle={() => props.close(false)} size="lg">
      <ModalHeader toggle={() => props.close(false)}>
        <p className="text-secondary">Chamar Especialidade</p>
      </ModalHeader>
      <div className="d-flex justify-content-between  mx-4">
        <Row>
          <Label> Selecione a Instituição de Saúde</Label>
          <Select
            onChange={(event) => {
              event.persist();
              const { value } = event.target;
              setInstSelected(value);
              getFilasAtendimento(value);
            }}
            value={instSelected}
          >
            <option>Selecione a instituicao</option>
            {!instHook.isLoading &&
              instHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.sgInstSaude}>
                    {item.instSaude}
                  </option>
                );
              })}
          </Select>

          <Label className="label-single-line mt-4">
            <span>Selecione a Fila de atendimento</span>
          </Label>

          <Select
            disabled={instSelected ? false : true}
            onChange={(event) => {
              event.persist();
              const { value } = event.target;
              setFilaSelecionada(value);
            }}
          >
            <option>Selecione a fila</option>
            {filasAtendimentoFilter &&
              filasAtendimentoFilter.map((item: FilaAtendimentoType, index) => {
                return (
                  <option key={index} value={item.faCodigo}>
                    {item.faDescricao}
                  </option>
                );
              })}
          </Select>
        </Row>
      </div>
      <ModalFooter className="border-top">
        <Button
          type="submit"
          color="primary"
          className="btn-outline-primary"
          onClick={() => chamarOutraEspecialidade()}
        >
          <strong>Confirmar</strong>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
