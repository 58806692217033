import dayjs from 'dayjs';
import { BiMessageAltError } from "react-icons/bi";

import { formatDate } from 'shared/util/formatDateAmPm';

import { PatientProps } from '../../index'
import { CardContent, Container, MessageCard } from './styles'

interface MessageBarProps {
  patients: PatientProps[];
  setIsChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getSelectedChat: () => any;
  setPacienteCPF: React.Dispatch<React.SetStateAction<string>>;
}

export function MessageBar({
  patients,
  setIsChatVisible,
  getSelectedChat,
  setPacienteCPF,
}: MessageBarProps) {
  return (
    <>
      <Container>
          {patients.map((paciente) =>
            <div key={paciente.usuarioCPF}>
              <MessageCard
                onClick={() => {
                  setPacienteCPF(paciente.usuarioCPF);
                  setIsChatVisible(false);
                }}
                isSelected={getSelectedChat() && getSelectedChat().usuarioCPF === paciente.usuarioCPF}
              >
                <CardContent>
                  <div style={{width: '100%' ,display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <h6>
                        {paciente.nmUsuario}
                      </h6>
                    </div>
                      {paciente.fcLido === 0 ? (
                        <BiMessageAltError color='red'/>
                      ) : null}
                  </div>
                  <p>{paciente.fmMensagem}</p>
                  <span>
                    {dayjs(new Date(paciente.fmDataMens)).format(
                      'DD/MM/YYYY - HH:mm'
                    )}
                  </span>
                </CardContent>
              </MessageCard>
              <hr />
            </div>
          )}
      </Container>
    </>
  )
}
