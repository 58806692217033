import { Spinner } from '@chakra-ui/react';
import { Box, FormControl, Select } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Loading } from 'components/Loading';
import { unique } from 'components/util/entity-utils';
import { useApiRequest, useUser } from 'hooks';
import { api } from 'services/api';
import { dateIsBefore, dateToServer, LOG } from 'shared/util';

import { GestaoDeAgenda, IGestaoDeAgendaProps } from './_base/gestao-de-agenda';
import CopiarSemanaSeguinte from './components/CopiarSemanaSeguinte';
import DataFiltros from './components/DataFiltros';
import PeriodoSemAtividade from './components/PeriodoSemAtividade';
import { RangeDate } from './components/RangeDate';
import RangeDateHeader from './components/RangeDateHeader';

import 'rc-slider/assets/index.css';

type LocalType = {
  laAtivo: 1;
  laCEP: string;
  laCidade: string;
  laDescricao: string;
  laEndereco: string;
  laNumero: string;
  laQRCode?: string;
  laTriagem?: string;
  laUF: string;
  laNome: string;
};

const MySwal = withReactContent(Swal);

export const ReturnFunction = (context: IGestaoDeAgendaProps) => {
  const FISICO = context.props.teleFisico === 'F';

  const usuarioLogado = useUser();

  const diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const [startDate, setStartDate] = useState(
    moment(new Date()).add(0, 'days').toDate()
  );

  const [abrirPeriodoSemAtividade, setAbrirPeriodoSemAtividade] =
    useState(false);

  const [abrirCopiarSemanaSeguinte, setAbrirCopiarSemanaSeguinte] =
    useState(false);

  const [daysSelected, setDaysSelected] = useState([]);

  const tempoMedioApi = useApiRequest<string>('aswcargaagendaperiodos', '');

  const [local, setLocal] = useState('');
  const agendaLocaisApi = useApiRequest<LocalType[]>(
    'aswagendalocaisatendimento',
    []
  );

  const [optionsInst, setOptionsInst] = useState([]);
  const [instituicao, setInstituicao] = useState('');

  useEffect(() => {
    if (usuarioLogado?.cpf) {
      setOptionsInst(usuarioLogado.institucoes);
    }
  }, [usuarioLogado]);

  useEffect(() => {
    if (!!local || !!instituicao) {
      if (FISICO) {
        agendaLocaisApi.request({
          method: 'listarLocalAtendimentoPs',
          psCPF: usuarioLogado?.cpf,
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
        });
        if (local) {
          afterChangeData();
          loadTempoConsulta();
        }
      } else {
        afterChangeData();
        loadTempoConsulta();
      }
    }
  }, [local, instituicao]);

  useEffect(() => {
    if (usuarioLogado?.cpf) {
      // tempoMedioApi.request({
      //   method: '_getTempoMedioConsulta',
      //   sgCliente: usuarioLogado?.sgCliente,
      //   sgInstSaude: instituicao,
      //   psCPF: usuarioLogado?.cpf,
      //   localDeAtendimento: local,
      // });

      if (FISICO) {
        agendaLocaisApi.request({
          method: 'listarLocalAtendimentoPs',
          psCPF: usuarioLogado?.cpf,
          sgCliente: usuarioLogado?.sgCliente,
          sgInstSaude: instituicao,
        });
      } else {
        // afterChangeData();
      }
    }
  }, [usuarioLogado]);

  useEffect(() => {
    if (!!local) {
      afterChangeData();
    }
  }, [local]);

  const loadTempoConsulta = async () => {
    tempoMedioApi.request({
      method: '_getTempoMedioConsulta',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      localDeAtendimento: local,
    });
  };

  const loadPeriodosAgenda = async (startDate) => {
    const body = {
      method: '_list',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      dtCarga: '',
      startDate,
      agTeleFisico: context.props.teleFisico,
      localDeAtendimento: local,
    };

    const res = await api.post('aswcargaagendaperiodos', body);

    const json = await res.data;
    let periodoAgenda = [];
    if (json && json.data) {
      const dtLoaded = unique(json.data.map((item) => item.dtCarga));
      periodoAgenda = dtLoaded.map((dt) => {
        return { day: String(dt).split('T')[0], dayValue: [] };
      });
      for (let index = 0; index < json.data.length; index++) {
        if (dtLoaded.includes(json.data[index].dtCarga)) {
          const inicioHr = moment(json.data[index].inicio).hour() * 60;
          const inicioMn = moment(json.data[index].inicio).minute();
          const fimHr = moment(json.data[index].fim).hour() * 60;
          const fimMn = moment(json.data[index].fim).minute();
          periodoAgenda.map((dt) => {
            if (dt.day === json.data[index].dtCarga.split('T')[0]) {
              dt.dayValue.push(inicioHr + inicioMn, fimHr + fimMn);
            }
            return dt;
          });
        }
      }
      periodoAgenda.map((dt) => {
        if (dt.dayValue.length < 8) {
          for (let index = dt.dayValue.length; index < 8; index++) {
            dt.dayValue.unshift(0);
          }
        }
        return dt;
      });
    }

    return periodoAgenda;
  };

  const loadPeriodosSemAtividade = async (startDate) => {
    const body = {
      method: '_list',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      startDate,
      agTeleFisico: context.props.teleFisico,
      localDeAtendimento: local,
    };
    const res = await api.post(`aswperiodosematividade`, body);

    const json = await res.data;

    const periodosSemAtividade = json.data.map((vlr) => {
      return {
        periodo: [
          new Date(vlr.paInicio + ' 00:00'),
          new Date(vlr.paFim + ' 00:00'),
        ],
        descricao: vlr.paDescricao,
      };
    });

    return periodosSemAtividade;
  };

  const afterChangeData = async () => {
    context.setState({
      ...context.state,
      loading: true,
    });

    let dadosPeriodoSemAtividade = await loadPeriodosSemAtividade(startDate);
    let periodoAgenda = await loadPeriodosAgenda(startDate);

    context.setState({
      ...context.state,
      dadosPeriodoSemAtividade,
      periodoAgenda,
      instituicaoId: instituicao,
      loading: false,
    });
  };

  const selectDay = (day: Date) => {
    if (daysSelected.includes(moment(day).format('yyyy-MM-DD'))) {
      setDaysSelected(
        daysSelected.filter(
          (daySelected) => daySelected !== moment(day).format('yyyy-MM-DD')
        )
      );
    } else {
      setDaysSelected([...daysSelected, moment(day).format('yyyy-MM-DD')]);
    }
  };

  const handleDayScheduleChange = (dayValue: Array<number>, day: Date) => {
    if (
      context.state.periodoAgenda.find(
        (periodo) => periodo.day === moment(day).format('yyyy-MM-DD')
      ) !== undefined
    ) {
      const periodoAgenda = context.state?.periodoAgenda.map((periodo) => {
        if (periodo.day === moment(day).format('yyyy-MM-DD')) {
          periodo.dayValue = dayValue.map((value) => value);
        }
        return periodo;
      });
      context.setState({ ...context.state, periodoAgenda });
    } else {
      context.setState({
        ...context.state,
        periodoAgenda: [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(day).format('yyyy-MM-DD'),
            dayValue: dayValue.map((value) => value),
          },
        ],
      });
    }
  };

  const onChangeMinimalTime = (value: string) => {
    tempoMedioApi.setState(value);

    tempoMedioApi.submit({
      method: '_updateTempoMedioConsulta',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      psTempoMedioConsulta: value,
      agTeleFisico: context.props.teleFisico,
      localDeAtendimento: local,
    });
  };

  const timeConvert = (mark) => {
    const hours = mark / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours.toString().length === 1 ? `0${rhours}` : rhours}:${rminutes.toString().length === 1 ? `${rminutes}0` : rminutes
      }:00`;
  };

  const saveWeekSchedule = async () => {
    try {
      const periodos = [];
      const weekDays: string[] = [];

      const endDate = moment(startDate)
        .startOf('week')
        .isoWeekday(7)
        .add(6, 'days')
        .toDate();

      context.state?.periodoAgenda.map((schedule) => {
        let periodo = 0;

        if (
          moment(new Date(schedule.day)).add(1, 'days') >=
          moment(new Date()).add(0, 'days') &&
          moment(new Date(schedule.day)).add(1, 'days') <=
          moment(endDate).add(1, 'days')
        ) {
          weekDays.push(schedule.day);
          console.log('schedule.day->', schedule);
          for (let index = 0; index < schedule.dayValue.length; index += 2) {
            if (schedule.dayValue[index + 1] !== 0) {
              periodos.push({
                periodo,
                dtCarga: schedule.day,
                inicio: timeConvert(schedule.dayValue[index]),
                fim: timeConvert(schedule.dayValue[index + 1]),
              });
            }
            periodo++;
          }
        }
      });

      weekDays.forEach((day) => {
        if (!periodos.find((p) => p.dtCarga === day)) {
          periodos.push({
            periodo: 0,
            dtCarga: day,
            inicio: '00:00:00',
            fim: '00:00:00',
          });
        }
      });

      LOG({ periodos });
      const body = {
        method: '_updateList',
        sgCliente: usuarioLogado?.sgCliente,
        sgInstSaude: instituicao,
        psCPF: usuarioLogado?.cpf,
        periodos,
        agTeleFisico: context.props.teleFisico,
        startDate: dateToServer(startDate),
        endDate: dateToServer(endDate),
        localDeAtendimento: local,
      };

      const result = await api.post(`aswcargaagendaperiodos`, body);

      const json = await result.data;
      if (json.statusCode !== 500) {
        setDaysSelected([]);
        MySwal.fire({
          title: 'Agenda salva',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      } else {
        MySwal.fire({
          title: 'Erro ao salvar',
          html: json.message || '',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e70015',
        });
      }
    } catch (err) {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: err.message || '',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
      console.error(err.message);
    }
  };

  const onOpenPeriodoSemAtividade = (show: boolean, resetData?: boolean) => {
    setAbrirPeriodoSemAtividade(show);
    if (resetData === true)
      context.setState({ ...context.state, dadosPeriodoSemAtividade: [] });
  };

  const onChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const onSaveCopyWeek = async (weeks: any) => {
    const body = {
      method: '_insertNextWeek',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      dtCarga: daysSelected,
      semanas: weeks,
      startDate,
      agTeleFisico: context.props.teleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      const diasPlanejados =
        json.data?.diasPlanejados.length > 0 ? json.data.diasPlanejados : [];
      const periodoSemAtividade =
        json.data?.periodoSemAtividade.length > 0
          ? json.data.periodoSemAtividade
          : [];
      setDaysSelected([]);
      await afterChangeData();

      if (diasPlanejados.length > 0 && periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados e ${periodoSemAtividade.length} dia(s) sem atividades, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (diasPlanejados.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${periodoSemAtividade.length} dia(s) sem atividade, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else {
        MySwal.fire({
          title: 'Semanas copiadas',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar as datas selecionadas',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  const onSaveCopyDay = async () => {
    context.setState({ ...context.state, loading: true });
    const actualDay = context.state?.periodoAgenda.find(
      (periodo) => periodo.day === daysSelected[0]
    );
    const body = {
      method: '_insertNextDay',
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: instituicao,
      psCPF: usuarioLogado?.cpf,
      dtCarga: daysSelected[0],
      startDate: dateToServer(startDate),
      agTeleFisico: context.props.teleFisico,
      localDeAtendimento: FISICO ? local : 'fake',
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      if (
        json.data?.diasPlanejados.length > 0 ||
        json.data?.periodoSemAtividade.length > 0
      ) {
        MySwal.fire({
          title: 'Aviso',
          html: 'Existem horários programados, ou uma data sem atividade após o dia selecionado para cópia',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
        context.setState({
          ...context.state,
          loading: false,
        });
      } else {
        const copyDay = [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(actualDay.day).add(1, 'days').format('yyyy-MM-DD'),
            dayValue: actualDay.dayValue,
          },
        ];
        setDaysSelected([]);
        context.setState({
          ...context.state,
          periodoAgenda: copyDay,
          loading: false,
        });
        MySwal.fire({
          title: 'Dia copiado',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar a data selecionada',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  return (
    <div id="view-gestao-da-agenda-profissional">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center jh-create-entity">
          <b className="fw-bold">
            GESTÃO DE AGENDA - {FISICO ? 'PRESENCIAL' : 'TELESAÚDE'}
          </b>
        </div>
      </div>

      <Box as="form" className="my-3">
        <h3>{!instituicao ? 'Selecione a instituição' : 'Instituição'}</h3>
        <Select
          size={'lg'}
          value={instituicao}
          onChange={(event) => {
            event.persist();
            const { value } = event.target;
            context.setState({
              ...context.state,
              instituicaoId: value,
            });
            setInstituicao(value);
          }}
        >
          <option value="">Selecione a instituição...</option>
          {usuarioLogado?.institucoes &&
            usuarioLogado.institucoes.map((item, index) => {
              return (
                <option key={index} value={item.institucao}>
                  {item.institucaoNome}
                </option>
              );
            })}
        </Select>
      </Box>

      {instituicao && (
        <>
          <div className="row">
            {FISICO && (
              <div className="mb-1">
                <Box as="form">
                  <p>
                    <small>Local de atendimento</small>
                  </p>
                  <FormControl>
                    <Select
                      disabled={instituicao ? false : true}
                      size={'lg'}
                      value={local}
                      onChange={(event) => {
                        event.persist();
                        const { value } = event.target;
                        setLocal(value);
                      }}
                    >
                      <option value="">
                        Selecione o Local de Atendimento...
                      </option>

                      {Array.isArray(agendaLocaisApi.state) &&
                        agendaLocaisApi.state.map((item, index) => {
                          return (
                            <option key={index} value={item.laDescricao}>
                              {item.laNome}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            )}

            <div
              className="col-sm-3 agenda-filtro-data"
              style={{ marginTop: '-3px' }}
            >
              <DataFiltros
                disabled={!context.state?.instituicaoId}
                startDate={startDate}
                setStartDate={onChangeStartDate}
              />
            </div>

            <div className="col-sm-1 prev-next">
              <Button
                disabled={!context.state?.instituicaoId}
                onClick={() =>
                  onChangeStartDate(moment(startDate).add(-7, 'days').toDate())
                }
                className="btn btn-primary"
              >
                {'<'}
              </Button>
              <Button
                disabled={!context.state?.instituicaoId}
                onClick={() =>
                  onChangeStartDate(moment(startDate).add(7, 'days').toDate())
                }
                className="btn btn-primary"
              >
                {'>'}
              </Button>
            </div>

            <div
              className="col-sm-4 d-flex align-items-center"
              style={{ textAlign: 'left' }}
            >
              <span style={{ width: '100px', float: 'left', color: '#666666' }}>
                Tempo médio <br /> de consulta
              </span>
              <span style={{ width: '110px', float: 'left' }}>
                <select
                  disabled={!context.state?.instituicaoId}
                  onChange={(event) => onChangeMinimalTime(event.target.value)}
                  style={{ width: '100px', float: 'left' }}
                  className="form-control"
                  value={tempoMedioApi.state}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={45}>45</option>
                  <option value={50}>50</option>
                  <option value={60}>60</option>
                </select>
              </span>
              {tempoMedioApi.isLoading ||
                (agendaLocaisApi.isLoading && (
                  <span>
                    &nbsp;
                    <Spinner size="xs" />
                    &nbsp;
                  </span>
                ))}
              <span style={{ width: '60px', float: 'left', color: '#666666' }}>
                Minutos
              </span>
            </div>

            <div className="col-sm-4 d-flex flex-row justify-content-end">
              <Button
                disabled={
                  FISICO
                    ? local
                      ? false
                      : true
                    : !context.state?.instituicaoId
                }
                onClick={() => setAbrirPeriodoSemAtividade(true)}
                className="btn btn-primary"
              >
                <i className="fa fa-lock me-1" /> Períodos Sem Atividade
              </Button>
            </div>
          </div>

          {context.state.loading ? (
            <div className="d-flex align-items-center justify-content-center m-5">
              <Loading />
            </div>
          ) : (
            <table className="table-responsive-css table">
              <thead>
                <tr>
                  <th>
                    <div className="row text-end">
                      <div className="col-sm-2">
                        <></>
                      </div>
                      <div className="col-sm-10">
                        <RangeDateHeader />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[0, 1, 2, 3, 4, 5, 6].map((v: number, i: number) => {
                  const dia = moment(startDate)
                    .startOf('week')
                    .isoWeekday(v)
                    .add(7, 'days')
                    .toDate();
                  const hhh =
                    context.state?.dadosPeriodoSemAtividade?.length > 0
                      ? context.state?.dadosPeriodoSemAtividade?.filter(
                        (psa) => {
                          const _start = moment(psa.periodo[0]).format(
                            'YYYYMMDD'
                          );
                          const _end = moment(psa.periodo[1]).format(
                            'YYYYMMDD'
                          );
                          const _dia = moment(dia).format('YYYYMMDD');
                          return _start <= _dia && _end >= _dia;
                        }
                      )
                      : [];

                  const deveBloquearStyle = dateIsBefore(dia);

                  return (
                    <tr key={`${dia}_${i}`}>
                      <td
                        style={
                          deveBloquearStyle ? { pointerEvents: 'none' } : {}
                        }
                      >
                        <div className="row text-end gestao-agenda-container">
                          <div className="col-sm-1">
                            <input
                              type="checkbox"
                              checked={daysSelected.includes(
                                moment(dia).format('yyyy-MM-DD')
                              )}
                              className="form-check-input pointer"
                              id="btncheck1"
                              autoComplete="off"
                              disabled={
                                !context.state.instituicaoId ||
                                hhh?.length !== 0
                              }
                              onChange={() => {
                                selectDay(dia);
                              }}
                            />
                          </div>
                          <div className="col-sm-1">
                            <div style={{ float: 'left', marginLeft: '20px' }}>
                              {diasSemana[moment(dia).format('e')]} <br />{' '}
                              <small>{moment(dia).format('DD/MM')}</small>
                            </div>
                          </div>
                          <div className="col-sm-10">
                            {hhh?.length === 0 ? (
                              <RangeDate
                                data={context.state?.periodoAgenda?.find(
                                  (agenda) =>
                                    moment(agenda.day)?.toDate().getTime() ===
                                    dia.getTime()
                                )}
                                disabled={
                                  !context.state.instituicaoId ||
                                  deveBloquearStyle
                                }
                                minimalTime={Number(tempoMedioApi.state)}
                                getChangedValue={(dayValue) =>
                                  handleDayScheduleChange(dayValue, dia)
                                }
                              />
                            ) : (
                              <div className="rc-slider rc-slider-with-marks">
                                <div
                                  className="rc-slider-rail"
                                  style={{
                                    height: '3px',
                                    backgroundColor: '#F4A460',
                                    marginTop: '2px',
                                  }}
                                ></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="row">
            <div className="col-sm-12 d-flex flex-row justify-content-between">
              <div>
                <Button
                  disabled={
                    !context.state.instituicaoId ||
                    daysSelected?.length > 1 ||
                    daysSelected?.length === 0
                  }
                  onClick={() => onSaveCopyDay()}
                  className="btn btn-primary rounded-pill me-2"
                >
                  Copiar para Dia Seguinte
                </Button>
                <Button
                  disabled={
                    !context.state.instituicaoId || daysSelected?.length === 0
                  }
                  onClick={() => setAbrirCopiarSemanaSeguinte(true)}
                  className="btn btn-primary rounded-pill me-2"
                >
                  Copiar para Semana Seguinte
                </Button>
              </div>
              <Button
                disabled={
                  !context.state.instituicaoId ||
                  context.state?.periodoAgenda?.length === 0
                }
                onClick={async () => await saveWeekSchedule()}
                className="btn btn-primary rounded-pill"
              >
                Salvar
              </Button>
            </div>
          </div>

          {abrirPeriodoSemAtividade && (
            <PeriodoSemAtividade
              sgCliente={usuarioLogado?.sgCliente}
              sgInstSaude={instituicao}
              show={abrirPeriodoSemAtividade}
              context={context}
              parsedUserData={usuarioLogado}
              setShow={onOpenPeriodoSemAtividade}
              afterChangeData={afterChangeData}
              teleFisico={context.props.teleFisico}
              localDeAtendimento={local}
            />
          )}

          {abrirCopiarSemanaSeguinte && (
            <CopiarSemanaSeguinte
              saveData={(week) => onSaveCopyWeek(week)}
              setShow={setAbrirCopiarSemanaSeguinte}
              show={abrirCopiarSemanaSeguinte}
            />
          )}
        </>
      )}
    </div>
  );
};

export const NNGestaoDeAgenda = (props) =>
  GestaoDeAgenda({
    returnFunction: (context) => (
      <ReturnFunction {...{ ...context, ...props }} />
    ),
    ...props,
  } as any).return();

export default function GestaoDeAgendaMain({ match, ...rest }) {
  return <NNGestaoDeAgenda {...rest} />;
}
