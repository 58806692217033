import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

export const NavDropdown = (props) => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      <i
        className={'fa ' + props.icon}
        style={{ marginRight: '5px', marginTop: '-5px' }}
      />{' '}
      <span>{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
