const isValidCpf = (cpf: string | null | undefined | object): boolean => {
  if (typeof cpf !== 'string') {
    return false;
  }

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
    return false;
  }

  if (
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  ) {
    return false;
  }

  const cpfs = cpf.split('').map((el) => +el);
  const rest = (count: number) =>
    ((cpfs
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;

  return rest(10) === cpfs[9] && rest(11) === cpfs[10];
};

export default isValidCpf;
