export const formatDate = (date: string) => {
  var dataOriginal = new Date(date);

  // Extrair elementos da data
  var dia = dataOriginal.getDate();
  var mes = dataOriginal.getMonth() + 1;
  var ano = dataOriginal.getFullYear();

  var hora = dataOriginal.getHours();
  var minuto = dataOriginal.getMinutes();

  // Verificar se é AM ou PM
  var periodo = hora < 12 ? 'AM' : 'PM';

  // Ajustar a hora para o formato 12 horas
  hora = hora % 12 || 12;

  // Formatar os elementos extraídos em uma nova string
  var dataFormatada =
      (dia < 10 ? '0' : '') +
      dia +
      '/' +
      (mes < 10 ? '0' : '') +
      mes +
      '/' +
      ano +
      ' - ' +
      (hora < 10 ? '0' : '') +
      hora +
      ':' +
      (minuto < 10 ? '0' : '') +
      minuto +
      ' ' +
      periodo;

  return dataFormatada;
};
