import React from 'react';
import { render } from 'react-dom';

import AppComponent from './app';
import { loadIcons } from './config/icon-loader';

loadIcons();

render(
  // <React.StrictMode>
  <AppComponent />,
  // </React.StrictMode>
  document.getElementById('root')
);
