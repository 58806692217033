import {
  Modal as ModalChakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, Col, Label, Row } from 'reactstrap';
import { FileType } from 'components/formUsuarioSMS/types';
import { useEffect, useRef, useState } from 'react';
import { dateToScreen, fileToBase64, LOG } from 'shared/util';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as S from './styles';
import { useApiRequest, useFileSelect, useUser } from 'hooks';
import { WrapperLoading } from 'components/wraper-loading';
import { Iframe } from 'components/iframe';
const MySwal = withReactContent(Swal);

type Props = {
  filesAccept: string;
  filesDefault?: FileType[];
  label: string;
  callBack: (files: FileType[]) => void;
  onlyView?: boolean;
};

export function InputFile({
  filesAccept,
  filesDefault,
  label,
  callBack,
  onlyView,
}: Props) {
  const user = useUser();
  const [state, setState] = useState<FileType[]>(filesDefault || []);
  const [fileSelected, setFileSelected] = useState<FileType>();

  const finalRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const api = useApiRequest('aswusuarios', {});

  useEffect(() => {
    callBack(state);
  }, [state]);

  const selectFile = async (file: File) => {
    if (file && !file.name) {
      MySwal.fire({
        title: 'Por favor selecione um arquivo',
        icon: 'warning',
      });
      return false;
    }
    if (file.size > 10e6) {
      MySwal.fire({
        title: 'Por favor selecione um arquivo menor que 10 MB',
        icon: 'warning',
      });
      return false;
    }

    try {
      const base64 = await fileToBase64(file);

      const date = new Date();

      setState((prev) => [
        ...prev,
        {
          base64,
          name: file.name,
          type: file.type,
          id: date.getTime(),
          size: file.size,
          date: date,
        },
      ]);
    } catch (error) {
      LOG('Catch no uploadImage()->fileToBase64()', error);
    }
  };

  const removeFile = async (id: number) => {
    setState((prev) => prev.filter((p) => p.id !== id));
  };

  const file = useFileSelect<File>({
    accept: filesAccept,
  });

  const viewDocument = async (doc: FileType) => {
    if (!doc.s3Caminho) return;
    if (!!fileSelected?.base64 && fileSelected?.id === doc.id) {
      onOpen();
      return;
    }
    setFileSelected(doc);
    try {
      const result = await api.submit({
        method: '_findDocumentBase64SMS',
        link: doc.s3Caminho,
        type: doc.type,
        sgCliente: user?.sgCliente,
      });

      if (result.data) {
        setFileSelected((prev) => ({ ...prev, base64: result.data }));
        onOpen();
      }
    } catch (error) {}
  };

  return (
    <S.Container>
      <Row>
        <Col md="12">
          <div className="w-100 d-flex justify-content-between align-items-end">
            <Label className="mt-4 label-single-line">{label}</Label>
            {!onlyView && (
              <S.BtnAddFile
                color="primary"
                className="btn-sm ml-4"
                onClick={async () => {
                  selectFile(await file());
                }}
              >
                Adicionar arquivo
              </S.BtnAddFile>
            )}
          </div>
        </Col>

        <Col md="12">
          {!state.length && (
            <S.SectionFileEmpty>
              <span>Nenhum arquivo carregado</span>
            </S.SectionFileEmpty>
          )}
          {state.map((c) => {
            return (
              <WrapperLoading
                isLoading={api.isLoading && fileSelected.id === c.id}
                key={c.id}
              >
                <S.SectionFile
                  key={c.id}
                  clicable={!!c.s3Caminho}
                  onClick={() => {
                    viewDocument(c);
                  }}
                >
                  <span>{c.name}</span>
                  <S.Space />
                  <span className="mx-4 text-smoke">{c.size} kb</span>
                  <span className="mx-4 text-smoke">
                    {dateToScreen(c.date)}
                  </span>
                  {!onlyView && !c.s3Caminho && (
                    <button
                      onClick={() => {
                        removeFile(Number(c.id));
                      }}
                    >
                      <i className="fa fa-trash text-smoke" />
                    </button>
                  )}
                </S.SectionFile>
              </WrapperLoading>
            );
          })}
        </Col>
      </Row>

      {fileSelected?.base64 && (
        <ModalChakra
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          size={'6xl'}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{fileSelected?.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {fileSelected?.type.includes('pdf') && (
                <Iframe src={fileSelected?.base64} />
              )}
              {fileSelected?.type.includes('image') && (
                <img
                  src={fileSelected?.base64}
                  style={{ borderRadius: 10 }}
                  width="100%"
                  height="auto"
                />
              )}
            </ModalBody>

            <ModalFooter>
              <Button color="primary" onClick={onClose}>
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalChakra>
      )}
    </S.Container>
  );
}
