import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Spinner } from '@chakra-ui/react';

/* eslint-disable no-console */
import { Loading } from 'components/Loading';
import { api } from 'services/api';

import { AUTH_USER_DATA } from 'shared/util';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import {
  GestaoDeAgenda,
  IGestaoDeAgendaProps,
  loadPeriodosAgenda,
  loadPeriodosSemAtividade,
} from './_base/gestao-de-agenda';
import CopiarSemanaSeguinte from './components/CopiarSemanaSeguinte';
import DataFiltros from './components/DataFiltros';
import PeriodoSemAtividade from './components/PeriodoSemAtividade';
import { RangeDate } from './components/RangeDate';
import RangeDateHeader from './components/RangeDateHeader';

import 'rc-slider/assets/index.css';
import { dateToServer } from 'shared/util';
import { useApiRequest, useUser } from 'hooks';

// interface DaySelected {
//   day: string;
//   dayValue: Array<number>;
// }

// interface DiaSemAtividade {
//   periodo: Array<Date>;
//   descricao: string;
// }

const MySwal = withReactContent(Swal);

export const ReturnFunction = (context: IGestaoDeAgendaProps) => {
  const d = new Date();
  const diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const [parsedUserData, setParsedUserData] = useState<any>({});
  const [startDate, setStartDate] = useState(moment(d).add(0, 'days').toDate());
  const [abrirPeriodoSemAtividade, setAbrirPeriodoSemAtividade] =
    useState(false);
  const [abrirCopiarSemanaSeguinte, setAbrirCopiarSemanaSeguinte] =
    useState(false);
  const [daysSelected, setDaysSelected] = useState([]);
  const [semanasForm, setSemanasForm] = useState('');
  // const [scheduleDays, setScheduleDays] = useState<Array<DaySelected>>([]);
  const [minimalTime, setMinimalTime] = useState('10');

  const tempoMedioApi = useApiRequest<string>('aswcargaagendaperiodos', '');
  const user = useUser();

  useEffect(() => {
    const userData = localStorage.getItem(AUTH_USER_DATA) || '';
    if (userData !== '') {
      const parsedData = JSON.parse(userData);
      setParsedUserData(parsedData);

      tempoMedioApi.request({
        method: '_getTempoMedioConsulta',
        sgCliente: user?.sgCliente,
        sgInstSaude: '',
        psCPF: parsedData?.cpf,
      });
    }

    afterChangeData();
  }, []);

  const afterChangeData = async () => {
    context.setState({
      ...context.state,
      loading: true,
    });

    let dadosPeriodoSemAtividade = await loadPeriodosSemAtividade(startDate);
    let periodoAgenda = await loadPeriodosAgenda(startDate);

    context.setState({
      ...context.state,
      dadosPeriodoSemAtividade,
      periodoAgenda,
      instituicaoId: '',
      loading: false,
    });
  };

  const selectDay = (day: Date) => {
    if (daysSelected.includes(moment(day).format('yyyy-MM-DD'))) {
      setDaysSelected(
        daysSelected.filter(
          (daySelected) => daySelected !== moment(day).format('yyyy-MM-DD')
        )
      );
    } else {
      setDaysSelected([...daysSelected, moment(day).format('yyyy-MM-DD')]);
    }
  };

  const handleDayScheduleChange = (dayValue: Array<number>, day: Date) => {
    if (
      context.state.periodoAgenda.find(
        (periodo) => periodo.day === moment(day).format('yyyy-MM-DD')
      ) !== undefined
    ) {
      const periodoAgenda = context.state?.periodoAgenda.map((periodo) => {
        if (periodo.day === moment(day).format('yyyy-MM-DD')) {
          periodo.dayValue = dayValue.map((value) => value);
        }
        return periodo;
      });
      context.setState({ ...context.state, periodoAgenda });
    } else {
      context.setState({
        ...context.state,
        periodoAgenda: [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(day).format('yyyy-MM-DD'),
            dayValue: dayValue.map((value) => value),
          },
        ],
      });
    }
  };

  const onChangeMinimalTime = (value: string) => {
    // setMinimalTime(value);
    tempoMedioApi.setState(value);

    tempoMedioApi.submit({
      method: '_updateTempoMedioConsulta',
      sgCliente: user?.sgCliente,
      sgInstSaude: '',
      psCPF: parsedUserData?.cpf,
      psTempoMedioConsulta: value,
    });
  };

  const timeConvert = (mark) => {
    const hours = mark / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours.toString().length === 1 ? `0${rhours}` : rhours}:${
      rminutes.toString().length === 1 ? `${rminutes}0` : rminutes
    }:00`;
  };

  const saveWeekSchedule = async () => {
    try {
      const periodos = [];
      context.state?.periodoAgenda.map((schedule) => {
        let periodo = 0;
        for (let index = 0; index < schedule.dayValue.length; index++) {
          if (index % 2 === 0 && schedule.dayValue[index + 1] !== 0) {
            periodos.push({
              periodo,
              dtCarga: schedule.day,
              inicio: timeConvert(schedule.dayValue[index]),
              fim: timeConvert(schedule.dayValue[index + 1]),
            });
            periodo++;
          }
        }
      });
      const body = {
        method: '_updateList',
        sgCliente: user?.sgCliente,
        sgInstSaude: '',
        psCPF: parsedUserData?.cpf,
        periodos,
        agTeleFisico: 'T',
        startDate: dateToServer(startDate),
      };
      const result = await api.post(`aswcargaagendaperiodos`, body);

      const json = await result.data;
      if (json.statusCode !== 500) {
        setDaysSelected([]);
        MySwal.fire({
          title: 'Agenda salva',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      } else {
        MySwal.fire({
          title: 'Erro ao salvar',
          html: json.message || '',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e70015',
        });
      }
    } catch (err) {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: err.message || '',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
      console.error(err.message);
    }
  };

  const onOpenPeriodoSemAtividade = (show: boolean, resetData?: boolean) => {
    setAbrirPeriodoSemAtividade(show);
    if (resetData === true)
      context.setState({ ...context.state, dadosPeriodoSemAtividade: [] });
  };

  const onChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const onSaveCopyWeek = async (weeks: any) => {
    const body = {
      method: '_insertNextWeek',
      sgCliente: user?.sgCliente,
      sgInstSaude: '',
      psCPF: parsedUserData?.cpf,
      dtCarga: daysSelected,
      semanas: weeks,
      agTeleFisico: 'T',
      startDate,
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      const diasPlanejados =
        json.data?.diasPlanejados.length > 0 ? json.data.diasPlanejados : [];
      const periodoSemAtividade =
        json.data?.periodoSemAtividade.length > 0
          ? json.data.periodoSemAtividade
          : [];
      setDaysSelected([]);
      await afterChangeData();

      if (diasPlanejados.length > 0 && periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados e ${periodoSemAtividade.length} dia(s) sem atividades, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (diasPlanejados.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${diasPlanejados.length} dia(s) planejados, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else if (periodoSemAtividade.length > 0) {
        MySwal.fire({
          title: 'Aviso',
          html: `No período selecionado para cópia, existe ${periodoSemAtividade.length} dia(s) sem atividade, o(s) qual(is) não foi/foram alterado(s)`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
      } else {
        MySwal.fire({
          title: 'Semanas copiadas',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar as datas selecionadas',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  const onSaveCopyDay = async () => {
    context.setState({ ...context.state, loading: true });
    const actualDay = context.state?.periodoAgenda.find(
      (periodo) => periodo.day === daysSelected[0]
    );
    const body = {
      method: '_insertNextDay',
      sgCliente: user?.sgCliente,
      sgInstSaude: '',
      psCPF: parsedUserData?.cpf,
      dtCarga: daysSelected[0],
      agTeleFisico: 'T',
      startDate: dateToServer(startDate),
    };
    const result = await api.post(`aswcargaagendaperiodos`, body);

    const json = await result.data;
    if (json.statusCode !== 500) {
      if (
        json.data?.diasPlanejados.length > 0 ||
        json.data?.periodoSemAtividade.length > 0
      ) {
        MySwal.fire({
          title: 'Aviso',
          html: 'Existem horários programados, ou uma data sem atividade após o dia selecionado para cópia',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#ff9100',
        });
        context.setState({
          ...context.state,
          loading: false,
        });
      } else {
        const copyDay = [
          ...(context.state?.periodoAgenda ?? {}),
          {
            day: moment(actualDay.day).add(1, 'days').format('yyyy-MM-DD'),
            dayValue: actualDay.dayValue,
          },
        ];
        setDaysSelected([]);
        context.setState({
          ...context.state,
          periodoAgenda: copyDay,
          loading: false,
        });
        MySwal.fire({
          title: 'Dia copiado',
          html: json.message || 'Salvo com sucesso',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#008000',
        });
      }
    } else {
      MySwal.fire({
        title: 'Erro ao salvar',
        html: json.message || 'Não foi possível copiar a data selecionada',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e70015',
      });
    }
  };

  return (
    <div id="view-gestao-da-agenda-profissional">
      {/* {context.renderFilter()} antigo select de instituição*/}
      <div className="row">
        <h4>GESTÃO DE AGENDA - TELESAÚDE</h4>
        <br />
        <br />
        {abrirPeriodoSemAtividade ? (
          <PeriodoSemAtividade
            show={abrirPeriodoSemAtividade}
            context={context}
            parsedUserData={parsedUserData}
            setShow={onOpenPeriodoSemAtividade}
            // initialData={context.state?.dadosPeriodoSemAtividade || []}
            sgCliente={user?.sgCliente}
            sgInstSaude={''}
            afterChangeData={afterChangeData}
          />
        ) : (
          <></>
        )}
        {abrirCopiarSemanaSeguinte ? (
          <CopiarSemanaSeguinte
            saveData={(week) => onSaveCopyWeek(week)}
            setShow={setAbrirCopiarSemanaSeguinte}
            show={abrirCopiarSemanaSeguinte}
          />
        ) : (
          <></>
        )}
        <div
          className="col-sm-3 agenda-filtro-data"
          style={{ marginTop: '-3px' }}
        >
          <DataFiltros
            disabled={!context.state?.instituicaoId}
            startDate={startDate}
            setStartDate={onChangeStartDate}
          />
        </div>
        <div className="col-sm-1 prev-next">
          <Button
            disabled={!context.state?.instituicaoId}
            onClick={() =>
              onChangeStartDate(moment(startDate).add(-7, 'days').toDate())
            }
            className="btn btn-primary"
          >
            {'<'}
          </Button>
          <Button
            disabled={!context.state?.instituicaoId}
            onClick={() =>
              onChangeStartDate(moment(startDate).add(7, 'days').toDate())
            }
            className="btn btn-primary"
          >
            {'>'}
          </Button>
        </div>
        <div
          className="col-sm-4 d-flex align-items-center"
          style={{ textAlign: 'left' }}
        >
          <span style={{ width: '100px', float: 'left', color: '#666666' }}>
            Tempo médio <br /> de consulta
          </span>
          <span style={{ width: '110px', float: 'left' }}>
            <select
              disabled={!context.state?.instituicaoId}
              onChange={(event) => onChangeMinimalTime(event.target.value)}
              style={{ width: '100px', float: 'left' }}
              className="form-control"
              value={tempoMedioApi.state}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={45}>45</option>
              <option value={50}>50</option>
              <option value={60}>60</option>
            </select>
          </span>
          {tempoMedioApi.isLoading && (
            <span>
              &nbsp;
              <Spinner size="xs" />
              &nbsp;
            </span>
          )}
          <span style={{ width: '60px', float: 'left', color: '#666666' }}>
            Minutos
          </span>
        </div>
        <div className="col-sm-4 d-flex flex-row justify-content-end">
          <Button
            disabled={!context.state?.instituicaoId}
            onClick={() => setAbrirPeriodoSemAtividade(true)}
            className="btn btn-primary"
          >
            <i className="fa fa-lock me-1" /> Períodos Sem Atividade
          </Button>
        </div>
      </div>
      {context.state.loading ? (
        <div className="d-flex align-items-center justify-content-center m-5">
          <Loading />
        </div>
      ) : (
        <table className="table-hover table-striped table-responsive-css table">
          <tr>
            <th>
              <div className="row text-end">
                <div className="col-sm-2">
                  <></>
                </div>
                <div className="col-sm-10">
                  <RangeDateHeader />
                </div>
              </div>
            </th>
          </tr>
          {[0, 1, 2, 3, 4, 5, 6].map((v: number, i: number) => {
            const dia = moment(startDate)
              .startOf('week')
              .isoWeekday(v)
              .add(7, 'days')
              .toDate();
            const hhh =
              context.state?.dadosPeriodoSemAtividade?.length > 0
                ? context.state?.dadosPeriodoSemAtividade?.filter((psa) => {
                    const _start = moment(psa.periodo[0]).format('YYYYMMDD');
                    const _end = moment(psa.periodo[1]).format('YYYYMMDD');
                    const _dia = moment(dia).format('YYYYMMDD');
                    return _start <= _dia && _end >= _dia;
                  })
                : [];
            return (
              <tr key={`${dia}_${i}`}>
                <td>
                  <div className="row text-end">
                    <div className="col-sm-1">
                      <input
                        type="checkbox"
                        checked={daysSelected.includes(
                          moment(dia).format('yyyy-MM-DD')
                        )}
                        className="form-check-input"
                        id="btncheck1"
                        autoComplete="off"
                        disabled={
                          !context.state.instituicaoId || hhh?.length !== 0
                        }
                        onChange={() => selectDay(dia)}
                      />
                    </div>
                    <div className="col-sm-1">
                      <div style={{ float: 'left', marginLeft: '20px' }}>
                        {diasSemana[moment(dia).format('e')]} <br />{' '}
                        <small>{moment(dia).format('DD/MM')}</small>
                      </div>
                    </div>
                    <div className="col-sm-10">
                      {hhh?.length === 0 ? (
                        <RangeDate
                          data={context.state?.periodoAgenda?.find(
                            (agenda) =>
                              moment(agenda.day)?.toDate().getTime() ===
                              dia.getTime()
                          )}
                          disabled={!context.state.instituicaoId}
                          minimalTime={Number(tempoMedioApi.state)}
                          getChangedValue={(dayValue) =>
                            handleDayScheduleChange(dayValue, dia)
                          }
                        />
                      ) : (
                        <div className="rc-slider rc-slider-with-marks">
                          <div
                            className="rc-slider-rail"
                            style={{
                              height: '3px',
                              backgroundColor: '#EA797E',
                              marginTop: '2px',
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </table>
      )}
      <div className="row">
        <div className="col-sm-12 d-flex flex-row justify-content-between">
          <div>
            <Button
              disabled={
                !context.state.instituicaoId ||
                daysSelected?.length > 1 ||
                daysSelected?.length === 0
              }
              onClick={() => onSaveCopyDay()}
              className="btn btn-primary rounded-pill me-2"
            >
              Copiar para Dia Seguinte
            </Button>
            <Button
              disabled={
                !context.state.instituicaoId || daysSelected?.length === 0
              }
              onClick={() => setAbrirCopiarSemanaSeguinte(true)}
              className="btn btn-primary rounded-pill me-2"
            >
              Copiar para Semana Seguinte
            </Button>
          </div>
          <Button
            disabled={
              !context.state.instituicaoId ||
              context.state?.periodoAgenda?.length === 0
            }
            onClick={async () => await saveWeekSchedule()}
            className="btn btn-primary rounded-pill"
          >
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
};

export const nGestaoDeAgenda = () =>
  GestaoDeAgenda({
    returnFunction: ReturnFunction,
  } as any).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={match.path} component={nGestaoDeAgenda} />
    </Switch>
  </>
);

export default Routes;
