// @ts-nocheck

import {
  Translate,
  useTranslation,
  Link,
} from '../../../components/util/translate-component';
import React, { useState, useEffect } from 'react';
import {
  useRouter,
  nextCookie,
  jsonParse,
  GetServerSideProps,
  AppProps,
} from '../../../components/util/entity-utils';

import { BASE_API_VERSION_PATH, AUTH_TOKEN_KEY } from 'shared/util';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import cookie from 'js-cookie';

import 'react-quill/dist/quill.snow.css';

import ReactPaginate from 'react-paginate';
import 'react-datepicker/dist/react-datepicker.css';
// import { pt } from 'date-fns/esm/locale';
// registerLocale('pt', pt);
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export interface IDetalhesLiberacaoAgendamentoProps extends AppProps {
  id?: any;
  dataAgendamento?: any;
  usuario?: any;
  usuarioCPF?: any;
  numeroCartao?: any;
  validadeCartao?: any;
  convenio?: any;
  planoConvenio?: any;
  nome?: any;
  descricao?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  detalhesLiberacaoAgendamentoList?: any;
  setDetalhesLiberacaoAgendamentoList?: Function;
  detalhesLiberacaoAgendamentoEntity?: any;
  setDetalhesLiberacaoAgendamentoEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getDetalhesLiberacaoAgendamentoState?: Function;
  returnFunction?: Function;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'api/detalhes-liberacao-agendamentos';
const getEntityFetch: any = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  entity: any,
  selectFields = ''
) => {
  const requestUrl = `${apiUrl}/${entity.id}`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
    },
  });
  const json = await res.json();
  context.setDetalhesLiberacaoAgendamentoEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(apiUrl, {
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(`${apiUrl}`, {
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  _detalhesLiberacaoAgendamentoEntity: any,
  listFiltersPage: any
) => {
  await fetch(`${apiUrl}/${_detalhesLiberacaoAgendamentoEntity.id}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (
  context: IDetalhesLiberacaoAgendamentoProps,
  offset = null
) => {
  return (
    '' +
    (context.state.id ? 'id=' + context.state.id + '&' : '') +
    (context.state.dataAgendamento
      ? 'dataAgendamento=' + context.state.dataAgendamento + '&'
      : '') +
    (context.state.usuario ? 'usuario=' + context.state.usuario + '&' : '') +
    (context.state.usuarioCPF
      ? 'usuarioCPF=' + context.state.usuarioCPF + '&'
      : '') +
    (context.state.numeroCartao
      ? 'numeroCartao=' + context.state.numeroCartao + '&'
      : '') +
    (context.state.validadeCartao
      ? 'validadeCartao=' + context.state.validadeCartao + '&'
      : '') +
    (context.state.convenio ? 'convenio=' + context.state.convenio + '&' : '') +
    (context.state.planoConvenio
      ? 'planoConvenio=' + context.state.planoConvenio + '&'
      : '') +
    (context.state.nome ? 'nome=' + context.state.nome + '&' : '') +
    (context.state.descricao
      ? 'descricao=' + context.state.descricao + '&'
      : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const {
    id,
    dataAgendamento,
    usuario,
    usuarioCPF,
    numeroCartao,
    validadeCartao,
    convenio,
    planoConvenio,
    nome,
    descricao,
    extraFilters,
    activePage,
    itemsPerPage,
    sort,
    order,
  } = context.state;

  const res = await context.getEntitiesFetch(
    id,
    dataAgendamento,
    usuario,
    usuarioCPF,
    numeroCartao,
    validadeCartao,
    convenio,
    planoConvenio,
    nome,
    descricao,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${
      _fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: res.headers.get('X-Total-Count'),
  });

  const json = await res.json();
  context.setDetalhesLiberacaoAgendamentoList(json);
};

const sortEntities = (
  context: IDetalhesLiberacaoAgendamentoProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction =
  (context: IDetalhesLiberacaoAgendamentoProps, p: any) => () => {
    context.setState({
      ...context.state,
      order:
        p === context.state.sort && context.state.order === 'asc'
          ? 'desc'
          : 'asc',
      sort: p,
    });
    context.getAllEntities({
      order:
        p === context.state.sort && context.state.order === 'asc'
          ? 'desc'
          : 'asc',
      sort: p,
    });
  };

const handlePagination = (
  context: IDetalhesLiberacaoAgendamentoProps,
  activePage: any
) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IDetalhesLiberacaoAgendamentoProps,
  _detalhesLiberacaoAgendamentoEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="detalhesLiberacaoAgendamento.delete.question"
        interpolate={{ id: _detalhesLiberacaoAgendamentoEntity.id }}
      >
        Are you sure you want to delete this DetalhesLiberacaoAgendamento?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate
          t={context.t}
          contentKey="detalhesLiberacaoAgendamento.btnCancel"
        >
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate
          t={context.t}
          contentKey="detalhesLiberacaoAgendamento.btnDelete"
        >
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_detalhesLiberacaoAgendamentoEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  isNew: any
) => {
  const errors = [];

  const selectFieldsList = [
    'dataAgendamento',
    'usuario',
    'usuarioCPF',
    'validadeCartao',
    'descricao',
    'planoConvenio',
  ];

  if (errors.length === 0) {
    const _entity = {
      ...context.detalhesLiberacaoAgendamentoEntity,
      // ...values,

      id: context.state.idFormValue,
      dataAgendamento: context.state.dataAgendamentoFormValue,
      usuario: context.state.usuarioFormValue,
      usuarioCPF: context.state.usuarioCPFFormValue,
      numeroCartao: context.state.numeroCartaoFormValue,
      validadeCartao: context.state.validadeCartaoFormValue,
      convenio: context.state.convenioFormValue,
      planoConvenio: context.state.planoConvenioFormValue,
      nome: context.state.nomeFormValue,
      descricao: context.state.descricaoFormValue,
    };

    const entity = _entity;

    const {
      id,
      dataAgendamento,
      usuario,
      usuarioCPF,
      numeroCartao,
      validadeCartao,
      convenio,
      planoConvenio,
      nome,
      descricao,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (
  context: IDetalhesLiberacaoAgendamentoProps,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="detalhesLiberacaoAgendamento.home.createLabel"
            >
              Create a DetalhesLiberacaoAgendamento
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="detalhesLiberacaoAgendamento.home.editLabel"
            >
              Edit a DetalhesLiberacaoAgendamento
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IDetalhesLiberacaoAgendamentoProps,
  _detalhesLiberacaoAgendamentoEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="detalhes-liberacao-agendamento-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-detalhes-liberacao-agendamento-first-column">
                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="usuarioLabel"
                          for="detalhes-liberacao-agendamento-usuario"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.usuario"
                          >
                            Nome do Paciente
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              usuarioFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.usuarioFormValue
                              ? context.state.usuarioFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-usuario"
                          type="text"
                          name="usuario"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="usuarioCPFLabel"
                          for="detalhes-liberacao-agendamento-usuarioCPF"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.usuarioCPF"
                          >
                            CPF
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              usuarioCPFFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.usuarioCPFFormValue
                              ? context.state.usuarioCPFFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-usuarioCPF"
                          type="text"
                          name="usuarioCPF"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="numeroCartaoLabel"
                          for="detalhes-liberacao-agendamento-numeroCartao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.numeroCartao"
                          >
                            Nº da Carteirinha
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              numeroCartaoFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.numeroCartaoFormValue
                              ? context.state.numeroCartaoFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-numeroCartao"
                          type="text"
                          name="numeroCartao"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="validadeCartaoLabel"
                          for="detalhes-liberacao-agendamento-validadeCartao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.validadeCartao"
                          >
                            Validade da Carteirinha
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              validadeCartaoFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.validadeCartaoFormValue
                              ? context.state.validadeCartaoFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-validadeCartao"
                          type="text"
                          name="validadeCartao"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="convenioLabel"
                          for="detalhes-liberacao-agendamento-convenio"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.convenio"
                          >
                            Convênio
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              convenioFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.convenioFormValue
                              ? context.state.convenioFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-convenio"
                          type="text"
                          name="convenio"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="4">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="planoConvenioLabel"
                          for="detalhes-liberacao-agendamento-planoConvenio"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.planoConvenio"
                          >
                            Plano
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              planoConvenioFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.planoConvenioFormValue
                              ? context.state.planoConvenioFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-planoConvenio"
                          type="text"
                          name="planoConvenio"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="nomeLabel"
                          for="detalhes-liberacao-agendamento-nome"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.nome"
                          >
                            Nome do Profissional de Saúde
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              nomeFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.nomeFormValue
                              ? context.state.nomeFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-nome"
                          type="text"
                          name="nome"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="descricaoLabel"
                          for="detalhes-liberacao-agendamento-descricao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="detalhesLiberacaoAgendamento.descricao"
                          >
                            Especialidade
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              descricaoFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.descricaoFormValue
                              ? context.state.descricaoFormValue
                              : ''
                          }
                          id="detalhes-liberacao-agendamento-descricao"
                          type="text"
                          name="descricao"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IDetalhesLiberacaoAgendamentoProps) => {
  return (
    <Modal
      className="detalhes-liberacao-agendamento-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="detalhesLiberacaoAgendamento.btnSave"
            >
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IDetalhesLiberacaoAgendamentoProps) => {
  return context.detalhesLiberacaoAgendamentoEntity &&
    (context.detalhesLiberacaoAgendamentoEntity.id ||
      context.detalhesLiberacaoAgendamentoEntity._id) ? (
    <Modal
      className="detalhes-liberacao-agendamento-update-modal"
      size={'xl'}
      isOpen={
        context.state.showModalEdit ===
          context.detalhesLiberacaoAgendamentoEntity.id ||
        context.state.showModalEdit ===
          context.detalhesLiberacaoAgendamentoEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(
            context.detalhesLiberacaoAgendamentoEntity,
            false
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="detalhesLiberacaoAgendamento.btnSave"
            >
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (
  context: IDetalhesLiberacaoAgendamentoProps,
  v: any
) => {
  const p: any = null;

  return {
    idFormValue: v.id, // id,
    dataAgendamentoFormValue: v.dataAgendamento, // dataAgendamento,
    usuarioFormValue: v.usuario, // usuario,
    usuarioCPFFormValue: v.usuarioCPF, // usuarioCPF,
    numeroCartaoFormValue: v.numeroCartao, // numeroCartao,
    validadeCartaoFormValue: v.validadeCartao, // validadeCartao,
    convenioFormValue: v.convenio, // convenio,
    planoConvenioFormValue: v.planoConvenio, // planoConvenio,
    nomeFormValue: v.nome, // nome,
    descricaoFormValue: v.descricao, // descricao,
  };
};

const openUpdateModal = (
  context: IDetalhesLiberacaoAgendamentoProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IDetalhesLiberacaoAgendamentoProps) => {
  const newState = {
    ...context.state,

    id: '',

    dataAgendamento: '',

    usuario: '',

    usuarioCPF: '',

    numeroCartao: '',

    validadeCartao: '',

    convenio: '',

    planoConvenio: '',

    nome: '',

    descricao: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IDetalhesLiberacaoAgendamentoProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IDetalhesLiberacaoAgendamentoProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'id' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-id"
            >
              <Row className="mr-1 mt-1">
                <Label id="idLabel" for="detalhes-liberacao-agendamento-id">
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.id"
                  >
                    ID
                  </Translate>
                </Label>
                <input
                  className="form-control"
                  type="number"
                  name="id"
                  id="detalhes-liberacao-agendamento-id"
                  value={context.state.id}
                  onChange={(evt: any) =>
                    context.setState({ ...context.state, id: evt.target.value })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'dataAgendamento' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-dataAgendamento"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="dataAgendamentoLabel"
                  for="detalhes-liberacao-agendamento-dataAgendamento"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.dataAgendamento"
                  >
                    Agendamento
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="dataAgendamento"
                  id="detalhes-liberacao-agendamento-dataAgendamento"
                  value={context.state.dataAgendamento}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      dataAgendamento: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'usuario' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-usuario"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="usuarioLabel"
                  for="detalhes-liberacao-agendamento-usuario"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.usuario"
                  >
                    Nome do Paciente
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="usuario"
                  id="detalhes-liberacao-agendamento-usuario"
                  value={context.state.usuario}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      usuario: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'usuarioCPF' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-usuarioCPF"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="usuarioCPFLabel"
                  for="detalhes-liberacao-agendamento-usuarioCPF"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.usuarioCPF"
                  >
                    CPF
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="usuarioCPF"
                  id="detalhes-liberacao-agendamento-usuarioCPF"
                  value={context.state.usuarioCPF}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      usuarioCPF: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'numeroCartao' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-numeroCartao"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="numeroCartaoLabel"
                  for="detalhes-liberacao-agendamento-numeroCartao"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.numeroCartao"
                  >
                    Nº da Carteirinha
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="numeroCartao"
                  id="detalhes-liberacao-agendamento-numeroCartao"
                  value={context.state.numeroCartao}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      numeroCartao: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'validadeCartao' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-validadeCartao"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="validadeCartaoLabel"
                  for="detalhes-liberacao-agendamento-validadeCartao"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.validadeCartao"
                  >
                    Validade da Carteirinha
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="validadeCartao"
                  id="detalhes-liberacao-agendamento-validadeCartao"
                  value={context.state.validadeCartao}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      validadeCartao: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'convenio' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-convenio"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="convenioLabel"
                  for="detalhes-liberacao-agendamento-convenio"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.convenio"
                  >
                    Convênio
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="convenio"
                  id="detalhes-liberacao-agendamento-convenio"
                  value={context.state.convenio}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      convenio: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'planoConvenio' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-planoConvenio"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="planoConvenioLabel"
                  for="detalhes-liberacao-agendamento-planoConvenio"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.planoConvenio"
                  >
                    Plano
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="planoConvenio"
                  id="detalhes-liberacao-agendamento-planoConvenio"
                  value={context.state.planoConvenio}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      planoConvenio: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'nome' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-nome"
            >
              <Row className="mr-1 mt-1">
                <Label id="nomeLabel" for="detalhes-liberacao-agendamento-nome">
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.nome"
                  >
                    Nome do Profissional de Saúde
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="nome"
                  id="detalhes-liberacao-agendamento-nome"
                  value={context.state.nome}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      nome: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'descricao' ? (
            <Col
              md="3"
              className="col-filter-detalhes-liberacao-agendamento-descricao"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="descricaoLabel"
                  for="detalhes-liberacao-agendamento-descricao"
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.descricao"
                  >
                    Especialidade
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="descricao"
                  id="detalhes-liberacao-agendamento-descricao"
                  value={context.state.descricao}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      descricao: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IDetalhesLiberacaoAgendamentoProps) => {
  return (
    <>
      {context.detalhesLiberacaoAgendamentoList &&
      context.detalhesLiberacaoAgendamentoList.filter &&
      context.detalhesLiberacaoAgendamentoList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div
          id="detalhes-liberacao-agendamento-table-list"
          className="table-list"
        >
          <Table
            id="detalhes-liberacao-agendamento-table-list"
            responsive
            aria-describedby="detalhes-liberacao-agendamento-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th
                  className="hand"
                  onClick={context.sortFunction('dataAgendamento')}
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.dataAgendamento"
                  />
                  <i
                    className={
                      context.state.sort === 'dataAgendamento'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('usuario')}>
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.usuario"
                  />
                  <i
                    className={
                      context.state.sort === 'usuario'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('usuarioCPF')}
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.usuarioCPF"
                  />
                  <i
                    className={
                      context.state.sort === 'usuarioCPF'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('validadeCartao')}
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.validadeCartao"
                  />
                  <i
                    className={
                      context.state.sort === 'validadeCartao'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('descricao')}
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.descricao"
                  />
                  <i
                    className={
                      context.state.sort === 'descricao'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('planoConvenio')}
                >
                  <Translate
                    t={context.t}
                    contentKey="detalhesLiberacaoAgendamento.planoConvenio"
                  />
                  <i
                    className={
                      context.state.sort === 'planoConvenio'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.detalhesLiberacaoAgendamentoList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((detalhesLiberacaoAgendamento: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="dataAgendamento-cell">
                      <b className="visible-xs"> Agendamento </b>

                      {detalhesLiberacaoAgendamento.dataAgendamento}
                    </td>

                    <td id="usuario-cell">
                      <b className="visible-xs"> Nome do Paciente </b>

                      {detalhesLiberacaoAgendamento.usuario}
                    </td>

                    <td id="usuarioCPF-cell">
                      <b className="visible-xs"> CPF </b>

                      {detalhesLiberacaoAgendamento.usuarioCPF}
                    </td>

                    <td id="validadeCartao-cell">
                      <b className="visible-xs"> Validade da Carteirinha </b>

                      {detalhesLiberacaoAgendamento.validadeCartao}
                    </td>

                    <td id="descricao-cell">
                      <b className="visible-xs"> Especialidade </b>

                      {detalhesLiberacaoAgendamento.descricao}
                    </td>

                    <td id="planoConvenio-cell">
                      <b className="visible-xs"> Plano </b>

                      {detalhesLiberacaoAgendamento.planoConvenio}
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.openUpdateModal(
                                detalhesLiberacaoAgendamento
                              )
                            }
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.openUpdateModal(
                                detalhesLiberacaoAgendamento
                              )
                            }
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() =>
                              context.openUpdateModal(
                                detalhesLiberacaoAgendamento
                              )
                            }
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.detalhesLiberacaoAgendamentoList &&
                context.detalhesLiberacaoAgendamentoList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate
            t={context.t}
            contentKey="detalhesLiberacaoAgendamento.home.notFound"
          >
            No Agendamentos found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IDetalhesLiberacaoAgendamentoProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IDetalhesLiberacaoAgendamentoProps) => {
  return (
    <div>
      <h4
        id="detalhes-liberacao-agendamento-heading"
        data-cy="DetalhesLiberacaoAgendamentoHeading"
      >
        <Translate
          t={context.t}
          contentKey="detalhesLiberacaoAgendamento.home.title"
        >
          Agendamentos
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                dataAgendamentoFormValue: null,
                usuarioFormValue: null,
                usuarioCPFFormValue: null,
                numeroCartaoFormValue: null,
                validadeCartaoFormValue: null,
                convenioFormValue: null,
                planoConvenioFormValue: null,
                nomeFormValue: null,
                descricaoFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="detalhesLiberacaoAgendamento.btnNewItem"
            >
              Detalhes Liberacao Agendamento
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => {}}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getDetalhesLiberacaoAgendamentoState = (
  context: IDetalhesLiberacaoAgendamentoProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const id = _urlBase.searchParams.get('id') || '';
  const dataAgendamento = _urlBase.searchParams.get('dataAgendamento') || '';
  const usuario = _urlBase.searchParams.get('usuario') || '';
  const usuarioCPF = _urlBase.searchParams.get('usuarioCPF') || '';
  const numeroCartao = _urlBase.searchParams.get('numeroCartao') || '';
  const validadeCartao = _urlBase.searchParams.get('validadeCartao') || '';
  const convenio = _urlBase.searchParams.get('convenio') || '';
  const planoConvenio = _urlBase.searchParams.get('planoConvenio') || '';
  const nome = _urlBase.searchParams.get('nome') || '';
  const descricao = _urlBase.searchParams.get('descricao') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    id,
    dataAgendamento,
    usuario,
    usuarioCPF,
    numeroCartao,
    validadeCartao,
    convenio,
    planoConvenio,
    nome,
    descricao,
  };
};

export const getEntitiesFetch = async (
  context: IDetalhesLiberacaoAgendamentoProps,
  id: any,
  dataAgendamento: any,
  usuario: any,
  usuarioCPF: any,
  numeroCartao: any,
  validadeCartao: any,
  convenio: any,
  planoConvenio: any,
  nome: any,
  descricao: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map((v: any) => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const idRequest = id
    ? Array.isArray(window.status)
      ? `id.in=${id.join(',')}&`
      : `id.contains=${id}&`
    : '';
  const dataAgendamentoRequest = dataAgendamento
    ? Array.isArray(window.status)
      ? `dataAgendamento.in=${dataAgendamento.join(',')}&`
      : `dataAgendamento.contains=${dataAgendamento}&`
    : '';
  const usuarioRequest = usuario
    ? Array.isArray(window.status)
      ? `usuario.in=${usuario.join(',')}&`
      : `usuario.contains=${usuario}&`
    : '';
  const usuarioCPFRequest = usuarioCPF
    ? Array.isArray(window.status)
      ? `usuarioCPF.in=${usuarioCPF.join(',')}&`
      : `usuarioCPF.contains=${usuarioCPF}&`
    : '';
  const numeroCartaoRequest = numeroCartao
    ? Array.isArray(window.status)
      ? `numeroCartao.in=${numeroCartao.join(',')}&`
      : `numeroCartao.contains=${numeroCartao}&`
    : '';
  const validadeCartaoRequest = validadeCartao
    ? Array.isArray(window.status)
      ? `validadeCartao.in=${validadeCartao.join(',')}&`
      : `validadeCartao.contains=${validadeCartao}&`
    : '';
  const convenioRequest = convenio
    ? Array.isArray(window.status)
      ? `convenio.in=${convenio.join(',')}&`
      : `convenio.contains=${convenio}&`
    : '';
  const planoConvenioRequest = planoConvenio
    ? Array.isArray(window.status)
      ? `planoConvenio.in=${planoConvenio.join(',')}&`
      : `planoConvenio.contains=${planoConvenio}&`
    : '';
  const nomeRequest = nome
    ? Array.isArray(window.status)
      ? `nome.in=${nome.join(',')}&`
      : `nome.contains=${nome}&`
    : '';
  const descricaoRequest = descricao
    ? Array.isArray(window.status)
      ? `descricao.in=${descricao.join(',')}&`
      : `descricao.contains=${descricao}&`
    : '';

  const requestUrl = `${apiUrl}?${extraFiltersRequest}${idRequest}${dataAgendamentoRequest}${usuarioRequest}${usuarioCPFRequest}${numeroCartaoRequest}${validadeCartaoRequest}${convenioRequest}${planoConvenioRequest}${nomeRequest}${descricaoRequest}${
    sort ? `page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'
  }`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer ' +
        (authorizationBearer
          ? authorizationBearer
          : cookie.get(AUTH_TOKEN_KEY)),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
      'Get-User-Account': getAccount ? '1' : '0',
    },
  });

  return res;
};

export const DetalhesLiberacaoAgendamento = (
  props: IDetalhesLiberacaoAgendamentoProps
) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [
    detalhesLiberacaoAgendamentoList,
    setDetalhesLiberacaoAgendamentoList,
  ] = useState(
    props.detalhesLiberacaoAgendamentoList
      ? props.detalhesLiberacaoAgendamentoList
      : ([] as any)
  );
  const [
    detalhesLiberacaoAgendamentoEntity,
    setDetalhesLiberacaoAgendamentoEntity,
  ] = useState(
    props.detalhesLiberacaoAgendamentoEntity
      ? props.detalhesLiberacaoAgendamentoEntity
      : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    id: props.id ? props.id : '',
    dataAgendamento: props.dataAgendamento ? props.dataAgendamento : '',
    usuario: props.usuario ? props.usuario : '',
    usuarioCPF: props.usuarioCPF ? props.usuarioCPF : '',
    numeroCartao: props.numeroCartao ? props.numeroCartao : '',
    validadeCartao: props.validadeCartao ? props.validadeCartao : '',
    convenio: props.convenio ? props.convenio : '',
    planoConvenio: props.planoConvenio ? props.planoConvenio : '',
    nome: props.nome ? props.nome : '',
    descricao: props.descricao ? props.descricao : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    detalhesLiberacaoAgendamentoList,
    setDetalhesLiberacaoAgendamentoList,
    detalhesLiberacaoAgendamentoEntity,
    setDetalhesLiberacaoAgendamentoEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_detalhesLiberacaoAgendamentoEntity: any, isNew: any) =>
          props['renderBodyUpdate'](
            context,
            _detalhesLiberacaoAgendamentoEntity,
            isNew
          )
      : (_detalhesLiberacaoAgendamentoEntity: any, isNew: any) =>
          renderBodyUpdate(context, _detalhesLiberacaoAgendamentoEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: props['getEntitiesFetch']
      ? (
          id: any,
          dataAgendamento: any,
          usuario: any,
          usuarioCPF: any,
          numeroCartao: any,
          validadeCartao: any,
          convenio: any,
          planoConvenio: any,
          nome: any,
          descricao: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          props['getEntitiesFetch'](
            context,
            id,
            dataAgendamento,
            usuario,
            usuarioCPF,
            numeroCartao,
            validadeCartao,
            convenio,
            planoConvenio,
            nome,
            descricao,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          )
      : (
          id: any,
          dataAgendamento: any,
          usuario: any,
          usuarioCPF: any,
          numeroCartao: any,
          validadeCartao: any,
          convenio: any,
          planoConvenio: any,
          nome: any,
          descricao: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          getEntitiesFetch(
            context,
            id,
            dataAgendamento,
            usuario,
            usuarioCPF,
            numeroCartao,
            validadeCartao,
            convenio,
            planoConvenio,
            nome,
            descricao,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          ),

    createEntityFetch: props['createEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['createEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: props['updateEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['updateEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: props['deleteEntityFetch']
      ? (id: any, listFiltersPage: any) =>
          props['deleteEntityFetch'](context, id, listFiltersPage)
      : (id: any, listFiltersPage: any) =>
          deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: props['getEntityFetch']
      ? (id) => props['getEntityFetch'](context, id)
      : (id) => getEntityFetch(context, id),

    getDetalhesLiberacaoAgendamentoState: props[
      'getDetalhesLiberacaoAgendamentoState'
    ]
      ? (location) =>
          props['getDetalhesLiberacaoAgendamentoState'](context, location)
      : (location) => getDetalhesLiberacaoAgendamentoState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getDetalhesLiberacaoAgendamentoState(
    null,
    ctx.resolvedUrl
  );

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.id,
    _fieldsBase.dataAgendamento,
    _fieldsBase.usuario,
    _fieldsBase.usuarioCPF,
    _fieldsBase.numeroCartao,
    _fieldsBase.validadeCartao,
    _fieldsBase.convenio,
    _fieldsBase.planoConvenio,
    _fieldsBase.nome,
    _fieldsBase.descricao,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      detalhesLiberacaoAgendamentoList: await res.json(),
    },
  };
};

export default DetalhesLiberacaoAgendamento;
