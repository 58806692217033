import { Switch } from 'react-router-dom';
import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import CadastroInstituicao from '../cadastro-instituicao/index';
import { CadastroCliente } from './components/cadastroCliente';

export const nCadastroCliente = (props) => CadastroCliente();

const Routes = ({ match }) => (
  <Switch>
    <ErrorBoundaryRoute
      path={`${match.path}/:idCadastroCliente/cadastro-instituicao`}
      component={CadastroInstituicao}
    />
    <ErrorBoundaryRoute path={match.path} component={nCadastroCliente} />
  </Switch>
);

export default Routes;
