import { FormUsuarioSMS } from 'components/formUsuarioSMS';
import React from 'react';
import { Button } from 'reactstrap';

export function SmsCadastroEpisodio() {
  return (
    <>
      <FormUsuarioSMS
        onlyView
        findByCpf
        title="CADASTRO DE EPISÓDIO"
        perfil="sms-admin"
      />
    </>
  );
}
