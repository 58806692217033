import { useEffect, useState } from 'react';
import { useApiRequest, useUrlParams, useUser } from 'hooks';
import ReactSelect from 'react-select';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import {
  changeInputText,
  dateTimeToScreen,
  dateToScreen,
  LOG,
} from 'shared/util';
import { useHistory } from 'react-router-dom';
import { DocumentViewicon } from 'components/icons';
import { Loading } from 'components/Loading';
import { IEpisodioConsulta } from 'types/episodio';
import cpfMask from 'shared/util/cpfMask';

export function SmsConsultaPaciente() {
  const user = useUser();
  const history = useHistory();
  const urlParams = useUrlParams();

  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState<string>(
    urlParams.get('inst')
  );

  const [textoFiltro, setTextoFiltro] = useState<string>(
    urlParams.get('search') ? urlParams.get('search') : ''
  );

  const episodioAPI = useApiRequest<IEpisodioConsulta[]>('aswepisodiosms', []);

  const listarEpisodiosUsuario = async (sgInstSaude: string) => {
    await episodioAPI.request({
      method: 'listarEpisodioCompleto',
      sgCliente: user?.sgCliente,
      sgInstSaude,
      search: textoFiltro?.replace(/\.|\-/g, ''),
    });
  };

  const instList = (user?.institucoes || []).map((i) => ({
    ...i,
    value: i.institucao,
    label: i.institucaoNome,
  }));

  const handleKeyDown = (event) => {
    // if (textoFiltro !== '') {
      if (event.key === 'Enter') {
        urlParams.set('search', textoFiltro);
        listarEpisodiosUsuario(instituicaoSelecionada);
      }
    // }
  };

  const handleSearchClick = () => {
    // if (textoFiltro !== '') {
      urlParams.set('search', textoFiltro);
      listarEpisodiosUsuario(instituicaoSelecionada);
    // }
  };

  useEffect(() => {
    if (instituicaoSelecionada) {
      listarEpisodiosUsuario(instituicaoSelecionada);
    }
  }, [instituicaoSelecionada]);

  return (
    <>
      <div className="breadcrumb">
        <h6>CONSULTA DE USUÁRIOS</h6>
      </div>

      <div className="d-flex align-items-end row ml-3 mr-3">
        <Col md="12">
          <Row className="mr-1 mt-1 ">
            <Col md="12">
              <span>Instituição de Saúde</span>
              <ReactSelect
                classNamePrefix="react-select"
                className={'css-select-control'}
                options={instList}
                isMulti={false}
                placeholder="Selecione..."
                value={{
                  value: instituicaoSelecionada,
                  label:
                    instList.find((i) => i.value === instituicaoSelecionada)
                      ?.label || '',
                }}
                onChange={(option) => {
                  if (option.value) {
                    urlParams.set('inst', option.value);
                    setInstituicaoSelecionada(option.value);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col md="12" className="col-filter-cadastro-cliente-cliente">
              <Row className="mr-1 mt-1">
                <input
                  disabled={!instituicaoSelecionada}
                  className="form-control"
                  type="text"
                  id="cadastro-cliente-cliente"
                  placeholder={'Buscar CPF ou Nome'}
                  value={textoFiltro}
                  onChange={(evento) => setTextoFiltro(changeInputText(evento))}
                  onKeyDown={handleKeyDown}
                />
              </Row>
            </Col>
          </div>
          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button
              id="search-submit"
              color="primary"
              onClick={handleSearchClick}
              disabled={!instituicaoSelecionada}
            >
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>
      </div>

      {episodioAPI.state?.length > 0 && (
        <div className="table-list mt-4">
          <Table
            responsive
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>Data</th>
                <th>Paciente</th>
                <th>CPF</th>
                <th>Especialidade</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!episodioAPI.error &&
                episodioAPI.state.map((a) => {
                  return (
                    <tr key={a.usuarioCPF + a.dtInclusao}>
                      <td>{dateToScreen(new Date(a.epData))}</td>
                      <td>{a.nmUsuario}</td>
                      <td>{cpfMask(a.usuarioCPF)}</td>
                      <td>{a.epEspecialidade}</td>
                      <td>{a.epStTriagemMedicaTexto}</td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <a
                            className="btn-sm"
                            onClick={() => {
                              history.push(
                                `${history.location.pathname}/${a.usuarioCPF}/${a.epIdentificador}`
                              );
                            }}
                          >
                            <DocumentViewicon />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      )}
      {!episodioAPI.isLoading && !episodioAPI.state?.length && (
        <div className="mt-4 alert alert-warning">Nada encontrado</div>
      )}
      {episodioAPI.isLoading && <Loading />}
    </>
  );
}
