import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

type SelecionarPerfilProps = {
    perfis?: any;
    close: (value: boolean) => void;
    perfilEscolhido?: (value: string) => void;
};

export const ModalSelecionarPerfil = (props: SelecionarPerfilProps) => {
    const [perfil, setPerfil] = useState<string>('');
    
    return (
        <Modal
            isOpen={true}
            className=""
            toggle={() => props.close(false)}
        >
            <ModalHeader toggle={() => props.close(false)}><p className="text-secondary">Selecione um perfil</p></ModalHeader>
            <ModalBody>
                <Row className='align-items-center row mx-3'>
                    <select defaultValue={'DEFAULT'} className="form-select" aria-label="Default select example"
                        onChange={(e) => setPerfil(e.target.value)}>
                        <option value="DEFAULT" disabled>Perfis</option>
                        {props?.perfis?.map((p, index) => {
                            return (
                                <option key={index} value={p.perfil}>{p.perfil}</option>
                            )
                        })}
                    </select>
                </Row>
            </ModalBody>
            <ModalFooter className='border-top'>
                <div >
                    <Button
                        onClick={() => {
                            props.perfilEscolhido(perfil)
                            props.close(false);
                        }}
                        color="primary"
                        className='btn-outline-primary fit-space'
                        disabled={perfil == ''}
                    >
                        <strong>
                            Confirmar
                        </strong>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}
