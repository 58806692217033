import { Spinner } from '@chakra-ui/react';
import {
  DialogConfirm,
  DialogConfirmRefProps,
} from 'components/dialog-confirm';
import { useConsultaContext } from 'contexts/Consulta';
import { CID10ItemType } from 'contexts/Consulta/types';
import dayjs from 'dayjs';
import { useApiRequest, useFileSelect, useMemed, useUser } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectAsync from 'react-select/async';
import { Button, Col, Container, Row } from 'reactstrap';
import { api } from 'services/api';
import { MemedPrescricaoResultType } from 'shared/model/memed-pacient';
// import { VideoComponent } from 'components/video';
import {
  dateTimeToScreen,
  dateTimeToServer,
  debounce,
  estouAtrasado,
  LOG,
} from 'shared/util';
import { TipoAtendimentoType } from 'types/consulta';
import { RenderModalSintomasGripais } from '../../../../../../components/modal-sindrome-gripal';
import { SintomasType } from '../../../../../../components/modal-sindrome-gripal/types';

type Props = {
  tipoAtendimento: TipoAtendimentoType;
};

export const Header = ({ tipoAtendimento }: Props) => {
  const history = useHistory();

  const selectFile = useFileSelect({ accept: 'pdf' });
  const typePrecription: 'pdf' | 'memed' = 'memed';
  const { consulta, idConsulta, headerHeight, setHeaderHeight } =
    useConsultaContext();

  const usuarioLogado = useUser();
  const confirmRef = useRef<DialogConfirmRefProps>(null);
  const retornoOuConsultaRef = useRef<DialogConfirmRefProps>(null);
  const [huDtInicioConsulta, sethuDtInicioConsulta] = useState(new Date());
  const [subjetivo, setSubjetivo] = useState<string>('');
  const [objetivo, setObjetivo] = useState<string>('');
  const [avaliacao, setAvaliacao] = useState<string>('');
  const [plano, setPlano] = useState<string>('');
  const [huQueixa, setHuQueixa] = useState<string>('');
  const [huHistQueixa, setHuHistQueixa] = useState<string>('');
  const [sintomasGripaisData, setSintomasGripaisData] =
    useState<SintomasType>();
  const [cid10StartFilter, setCid10StartFilter] = useState<CID10ItemType[]>([]);
  const [cid10, setCid10] = useState<CID10ItemType[]>([]);
  const [cid10Secundario, setCid10Secundario] = useState<CID10ItemType[]>([]);
  const [triagemDadosApi, setTriagemDadosApi] = useState<any>([]);
  const [idade, setIdade] = useState<number>(0);
  const opcoesParaCID10 = cid10StartFilter.filter((item) =>
    cid10Secundario.every((c) => c.value !== item.value)
  );
  const [sintomasGripais, setSintomasGripais] = useState(false);
  const opcoesParaCID10Secundario = cid10StartFilter.filter((item) =>
    cid10.every((c) => c.value !== item.value)
  );

  useEffect(() => {
    const usuarioNasc = dayjs(consulta?.dadosConsulta?.usuarioNasc);
    const hoje = dayjs();
    const idadeUsuario = Math.trunc(hoje.diff(usuarioNasc, 'year', true));
    setIdade(idadeUsuario);
  }, [consulta]);

  const atendimentoStatus = estouAtrasado(
    new Date(consulta.dadosConsulta.agDataHoraAgenda)
  )
    ? 'Atrasado'
    : 'No horário';

  const handleLoadCID10Options = debounce(
    (inputValue: string, callback: Function) => {
      if (!inputValue) return;
      api
        .post('aswcid10', {
          method: '_list',
          cdCategoria: inputValue,
          cdDescricao: inputValue,
          page: 0,
          size: 100,
          sort: 'cdDescricao,asc',
        })
        .then(({ data }) => {
          const values = data.data
            ? data.data.map((p: any) => ({
                ...p,
                value: p.cdCategoria,
                label: `${p.cdCategoria} - ${p.cdDescricao}`,
              }))
            : [];
          callback(values);
          setCid10StartFilter(values);
        });
    },
    250
  );

  const carregarPrecricao = async () => {
    try {
      const file = await selectFile();
      LOG({ file });
    } catch (error) {}
  };

  const agendaApi = useApiRequest('aswagendausuprofsaude', {});

  const encerrarAtendimento = () => {
    agendaApi
      .submit({
        method: '_encerrarAtendimento',
        subjetivo,
        objetivo,
        avaliacao,
        plano,
        cid10,
        cid10Secundario,
        //  tipoConsulta,
        huDtInicioConsulta: dateTimeToServer(huDtInicioConsulta),
        ...consulta.dadosConsulta,
        huQueixa,
        huHistQueixa,
        huTriagPressaoArterial: triagemDadosApi?.huTriagPressaoArterial,
        hrTriagFreqCardiaca: triagemDadosApi?.hrTriagFreqCardiaca,
        huTriagFreqRespiratoria: triagemDadosApi?.huTriagFreqRespiratoria,
        huTriagSaturacao: triagemDadosApi?.huTriagSaturacao,
        huTriagTemperatura: triagemDadosApi?.huTriagTemperatura,
        huTriagPeso: triagemDadosApi?.huTriagPeso,
        huTriagAltura: triagemDadosApi?.huTriagAltura,
        huTriagSGTosse: triagemDadosApi?.huTriagSGTosse,
        huTriagSGCoriza: triagemDadosApi?.huTriagSGCoriza,
        huTriagSGDorGarganta: triagemDadosApi?.huTriagSGDorGarganta,
        huTriagSGDorCabeca: triagemDadosApi?.huTriagSGDorCabeca,
        huTriagSGEspirros: triagemDadosApi?.huTriagSGEspirros,
        huTriagSGDorCostas: triagemDadosApi?.huTriagSGDorCostas,
        huTriagSGDiarreia: triagemDadosApi?.huTriagSGDiarreia,
        huTriagSGFebre: triagemDadosApi?.huTriagSGFebre,
        huTriagSGOutros: triagemDadosApi?.huTriagSGOutros,
      })
      .finally(() => {
        const [, path] = history.location.pathname.split('/');

        history.replace(`/${path}`);
      });
  };

  const salvarPrescricao = async (prescription: MemedPrescricaoResultType) => {
    LOG({ prescription });
    await api.post('aswprescricao', {
      method: '_registraPrescricaoMemed',
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: consulta.dadosConsulta.sgInstSaude,
      psCPF: consulta.dadosConsulta.psCPF,
      usuarioCPF: consulta.dadosConsulta.usuarioCPF,
      agIdConsulta: consulta.dadosConsulta.idConsulta,
      prescricao: prescription.prescricao,
      huDataHoraAgenda: consulta.dadosConsulta.agDataHoraAgenda,
    });
  };

  const [ano, mes, dia] = (consulta?.dadosConsulta?.usuarioNasc || '').split(
    '-'
  );

  const precricaoMemed = useMemed({
    token: consulta.dadosConsulta.psCadastroMEMED,
    psCPF: consulta.dadosConsulta.psCPF,
    sgCliente: usuarioLogado?.sgCliente,
    sgInstSaude: consulta?.dadosConsulta?.sgInstSaude,
    paciente: {
      idExterno: consulta.dadosConsulta.usuarioCPF,
      cpf: consulta.dadosConsulta.usuarioCPF,
      nome: consulta.dadosConsulta.nmUsuario,
      telefone: consulta.dadosConsulta.telefoneUsuario,
      endereco: consulta.dadosConsulta.usuarioEndereco,
      cidade: consulta.dadosConsulta.usuarioCidade,
      data_nascimento: `${dia}/${mes}/${ano}`,
    },
    onGeneratePrescription: salvarPrescricao,
  });

  useEffect(() => {
    (() => {
      const idConsulta = sessionStorage.getItem(
        consulta?.dadosConsulta?.idConsulta
      );
      if (idConsulta) {
        sethuDtInicioConsulta(new Date(idConsulta));
        return;
      }
      sessionStorage.setItem(
        consulta?.dadosConsulta?.idConsulta,
        huDtInicioConsulta.toString()
      );
    })();

    return () => {
      sessionStorage.removeItem(consulta?.dadosConsulta?.idConsulta);
    };
  }, []);

  const buscaDadosTriagem = async () => {
    const res = await api.post('aswhistusuario', {
      method: '_list',
      usuarioCPF: consulta?.dadosConsulta?.usuarioCPF,
    });
    const json = await res.data;
    if (consulta?.dadosConsulta?.agTriagemExecutada == 1) {
      const idTriagem = json.data.filter(
        (p: any) =>
          p.agIdConsulta === consulta?.dadosConsulta?.agIdConsultaTriagem
      );
      setTriagemDadosApi(idTriagem[0]);
    } else {
      setTriagemDadosApi([]);
    }
  };

  useEffect(() => {
    if (consulta?.dadosConsulta.idConsulta == idConsulta) {
      buscaDadosTriagem();
    } else {
      location.reload();
    }
  }, [consulta?.dadosConsulta.idConsulta]);

  const [, link] = String(consulta?.dadosConsulta?.linkProfSaude).includes('?')
    ? consulta?.dadosConsulta?.linkProfSaude?.split('?')
    : [];

  const urlLink = location.origin + '/video/?' + link;
  const validValuesToSendToMemed = ['CRM', 'CRP'];

  return (
    <>
      <div className="table-responsive">
        <div
          id="atendimento-detail-view"
          className="tela-paciente"
          style={{ height: headerHeight }}
        >
          <Row className="tela-paciente-header">
            <Col md={12}>
              <Row>
                <Col md={4} className="text-right" id="div-paciente">
                  {consulta?.dadosConsulta?.nmUsuario} - {idade} anos | Nome Social: {consulta?.dadosConsulta?.usuarioNomeSocial}
                </Col>
                <Col md={4}>
                  {headerHeight === 400 ? (
                    <span onClick={() => setHeaderHeight(530)}>
                      <i className="fas fa-expand"></i>
                    </span>
                  ) : (
                    <span onClick={() => setHeaderHeight(400)}>
                      <i className="fas fa-compress"></i>
                    </span>
                  )}

                  {/*<span onClick={() => setTipoConsulta('Retorno')}>
                    <input type="radio" checked={tipoConsulta === 'Retorno'} />
                    <label>Retorno</label>
                  </span>
                  <span onClick={() => setTipoConsulta('Consulta')}>
                    <input type="radio" checked={tipoConsulta === 'Consulta'} />
                    <label>Consulta</label>
                  </span>*/}

                  <span className="mr-15" id="horario-consulta">
                    {dateTimeToScreen(consulta?.dadosConsulta?.agendamento)}
                  </span>
                  <span className="mr-15" id="status">
                    Status: {atendimentoStatus}
                  </span>
                </Col>

                <Col md="4">
                  {/* {typePrecription === 'pdf' && (
                    <span>
                      <Button color="primary" onClick={carregarPrecricao}>
                        Carregar Prescrição
                      </Button>
                    </span>
                  )} */}
                  {typePrecription === 'memed' &&
                    consulta.dadosConsulta.psCadastroMEMED &&
                    validValuesToSendToMemed.includes(
                      usuarioLogado?.professional?.rccOrgaoEmissor
                    ) && (
                      <span>
                        <Button
                          color="primary"
                          disabled={precricaoMemed.loading}
                          onClick={() => precricaoMemed.open()}
                        >
                          Fazer Prescrição
                          {precricaoMemed.loading && (
                            <>
                              &nbsp;
                              <Spinner size="sm" />
                            </>
                          )}
                        </Button>
                      </span>
                    )}

                  <span>
                    <Button
                      color="warning"
                      onClick={() => {
                        confirmRef.current.open();
                      }}
                    >
                      Encerrar Atendimento
                      {agendaApi.isLoading && (
                        <>
                          &nbsp;
                          <Spinner size="sm" />
                        </>
                      )}
                    </Button>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Container>
            <Row>
              {tipoAtendimento === 'telessaude' && (
                <Col md="4">
                  {/* <VideoChat /> */}
                  <iframe
                    style={{
                      height: `${headerHeight / 1.3}px`,
                    }}
                    src={urlLink}
                    height="255"
                    width="350"
                    allow="camera *;microphone *"
                  ></iframe>
                  {/* <VideoComponent
                    {...{
                      sessionId: l.get('sid'),
                      apiKey: l.get('apiKey'),
                      token: l.get('token'),
                    }}
                  /> */}
                </Col>
              )}
              <Col md={tipoAtendimento === 'presencial' ? '12' : '8'}>
                <Row className="mt-2">
                  {/* {consulta.dadosConsulta.isTipoProntuario === 'N' && ( */}
                  <Col md="12">
                    <span>
                      <textarea
                        style={{
                          marginTop: '10px',
                          height:
                            tipoAtendimento == 'presencial'
                              ? `${headerHeight / 2 - 121}px`
                              : `${headerHeight / 2 - 105}px`,
                        }}
                        className="form-control"
                        placeholder="Queixa Principal"
                        value={huQueixa}
                        onChange={(v) => setHuQueixa(v.target.value)}
                      />
                    </span>
                    <span>
                      <textarea
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          height:
                            tipoAtendimento == 'presencial'
                              ? `${headerHeight / 2 - 121}px`
                              : `${headerHeight / 2 - 105}px`,
                        }}
                        className="form-control"
                        placeholder="Notas Clínicas"
                        value={huHistQueixa}
                        onChange={(v) => setHuHistQueixa(v.target.value)}
                      />
                    </span>
                  </Col>
                  {/* )} */}
                  {/* {consulta.dadosConsulta.isTipoProntuario === 'S' && (
                    <Row>
                      <Col md="6">
                        <span>
                          <textarea
                            style={{
                              marginTop: '10px',
                              height:
                                tipoAtendimento == 'presencial'
                                  ? `${headerHeight / 2 - 121}px`
                                  : `${headerHeight / 2 - 90}px`,
                            }}
                            className="form-control"
                            placeholder="S (subjetivo)"
                            value={subjetivo}
                            onChange={(v) => setSubjetivo(v.target.value)}
                          />
                        </span>
                        <span>
                          <textarea
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                              height:
                                tipoAtendimento == 'presencial'
                                  ? `${headerHeight / 2 - 121}px`
                                  : `${headerHeight / 2 - 90}px`,
                            }}
                            className="form-control"
                            placeholder="A (avaliação)"
                            value={avaliacao}
                            onChange={(v) => setAvaliacao(v.target.value)}
                          />
                        </span>
                      </Col>
                      <Col md="6">
                        <span>
                          <textarea
                            style={{
                              marginTop: '10px',
                              height:
                                tipoAtendimento == 'presencial'
                                  ? `${headerHeight / 2 - 121}px`
                                  : `${headerHeight / 2 - 90}px`,
                            }}
                            className="form-control"
                            placeholder="O (objetivo)"
                            value={objetivo}
                            onChange={(v) => setObjetivo(v.target.value)}
                          />
                        </span>
                        <span>
                          <textarea
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                              height:
                                tipoAtendimento == 'presencial'
                                  ? `${headerHeight / 2 - 121}px`
                                  : `${headerHeight / 2 - 90}px`,
                            }}
                            className="form-control"
                            placeholder="P (plano)"
                            value={plano}
                            onChange={(v) => setPlano(v.target.value)}
                          />
                        </span>
                      </Col>
                    </Row>
                  )} */}
                  <Col md="12">
                    <span>
                      <SelectAsync
                        id="atendimento-cid10-filter"
                        instanceId="atendimento-cid10-filter"
                        className={'css-select-control mt-1'}
                        placeholder="CID-2019-Principal"
                        name={'cid10'}
                        value={cid10}
                        onChange={(options) => setCid10([options])}
                        defaultOptions={opcoesParaCID10}
                        options={opcoesParaCID10}
                        loadOptions={handleLoadCID10Options}
                        noOptionsMessage={() => 'Nada encontrado'}
                        loadingMessage={() => 'Buscando...'}
                      />
                    </span>
                    <span>
                      <SelectAsync
                        isMulti
                        id="atendimento-cid10-filter"
                        instanceId="atendimento-cid10-filter"
                        className={'css-select-control mt-3'}
                        placeholder="CID-2019-Secundário"
                        name={'cid10'}
                        value={cid10Secundario}
                        onChange={(options) =>
                          setCid10Secundario(options as CID10ItemType[])
                        }
                        defaultOptions={opcoesParaCID10Secundario}
                        options={opcoesParaCID10Secundario}
                        loadOptions={handleLoadCID10Options}
                        noOptionsMessage={() => 'Nada encontrado'}
                        loadingMessage={() => 'Buscando...'}
                      />
                    </span>
                  </Col>
                  <Col xs="6">
                    {tipoAtendimento == 'presencial' && (
                      <div className="d-flex ">
                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Pressão Arterial</p>
                          <input
                            value={
                              triagemDadosApi?.huTriagPressaoArterial
                                ? triagemDadosApi?.huTriagPressaoArterial
                                : ''
                            }
                            disabled
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className=" col-md-11 rounded"
                          />
                        </span>
                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Freq. Cardíaca</p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.hrTriagFreqCardiaca
                                ? triagemDadosApi?.hrTriagFreqCardiaca
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded"
                          />
                        </span>
                        <span className="col-md-3">
                          <p className="smaller-text mt-1">
                            Freq. Respiratória
                          </p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.huTriagFreqRespiratoria
                                ? triagemDadosApi?.huTriagFreqRespiratoria
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded"
                          />
                        </span>
                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Saturação</p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.huTriagSaturacao
                                ? triagemDadosApi?.huTriagSaturacao
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded"
                          />
                        </span>

                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Temperatura</p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.huTriagTemperatura
                                ? triagemDadosApi?.huTriagTemperatura
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded"
                          />
                        </span>

                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Peso</p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.huTriagPeso
                                ? triagemDadosApi?.huTriagPeso
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded"
                          />
                        </span>
                        <span className="col-md-3">
                          <p className="smaller-text mt-1">Altura</p>
                          <input
                            disabled
                            value={
                              triagemDadosApi?.huTriagAltura
                                ? triagemDadosApi?.huTriagAltura
                                : ''
                            }
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className=" col-md-11 rounded"
                          />
                        </span>
                        <span
                          className="col-md-4"
                          onClick={() => setSintomasGripais(true)}
                        >
                          <p className="smaller-text mt-1">Sintomas Gripais</p>
                          <select
                            disabled
                            style={{
                              height: `${headerHeight / 16}px`,
                            }}
                            className="col-md-11 rounded small"
                            value={plano}
                            onChange={(v) => setPlano(v.target.value)}
                          >
                            <option
                              value=""
                              disabled
                              selected
                              className="small"
                            >
                              Visualizar
                            </option>
                          </select>
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {sintomasGripais && (
        <RenderModalSintomasGripais
          isEditable={false}
          dados={triagemDadosApi}
          close={(value: boolean) => {
            setSintomasGripais(value);
          }}
          formSintomasGripais={(value: SintomasType) => {
            setSintomasGripaisData(value);
          }}
        />
      )}
      <DialogConfirm
        ref={confirmRef}
        titulo="Encerrar atendimento"
        mensagem="Confirma o encerramento do antedimento?"
        cancelButton={{ text: 'Cancelar' }}
        buttons={[
          {
            text: 'Confirma',
            color: 'yellow',
            closeOnClick: true,
            onClick: () => {
              encerrarAtendimento();
            },
          },
        ]}
      />
    </>
  );
};
