import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useUser } from 'hooks';
import { CirurgiasExames } from 'pages/historico-de-atendimentos/informacoes/cirurgias-exames';
import { DocumentosExames } from 'pages/historico-de-atendimentos/informacoes/documentos-exames';
import { HistoricoAtendimento } from 'pages/historico-de-atendimentos/informacoes/historico-atendimento';
import { HistoricoPessoal } from 'pages/historico-de-atendimentos/informacoes/historico-pessoal';
import { HistoricoTriagemSindromeGripal } from 'pages/historico-de-atendimentos/informacoes/historico-triagem-sindrome-gripal';
import { Prontuario } from 'pages/historico-de-atendimentos/informacoes/prontuario';

import { QuestionarioSaudeMulher } from '../questionario-saude-mulher';

export function AppointmentTabs() {
  const user = useUser();

  return (
    <Tabs>
      <TabList style={{ overflow: 'auto hidden' }}>
        <Tab fontSize={'14px'}>Prontuário Clínico</Tab>
        <Tab fontSize={'14px'}>Cirurgias e Exames</Tab>
        <Tab fontSize={'14px'}>Histórico Pessoal</Tab>
        <Tab fontSize={'14px'}>Histórico de atendimentos</Tab>
        {/* <Tab fontSize={'14px'}>Histórico Triagem Síndrome Gripal</Tab> */}
        <Tab fontSize={'14px'}>Documentos e Exames</Tab>
        {user?.grupoEscolhido?.sgInstSaude === 'samuelLibaneo' && (
          <Tab fontSize={'14px'}>Questionário Saúde da Mulher</Tab>
        )}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Prontuario />
        </TabPanel>
        <TabPanel>
          <CirurgiasExames />
        </TabPanel>
        <TabPanel>
          <HistoricoPessoal />
        </TabPanel>
        <TabPanel>
          <HistoricoAtendimento />
        </TabPanel>
        {/* <TabPanel>
          <HistoricoTriagemSindromeGripal />
        </TabPanel> */}
        <TabPanel>
          <DocumentosExames />
        </TabPanel>
        {user?.grupoEscolhido?.sgInstSaude === 'samuelLibaneo' && (
          <TabPanel>
            <QuestionarioSaudeMulher />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
