import { useDisclosure } from '@chakra-ui/react';
import { useRouter } from 'components/util/entity-utils';
import { useState } from 'react';
import { Button, Table } from 'reactstrap';

import ModalCriarInstituicao from '../modal-criar-instituicao';

type InstSaudeprops = {
  bairro: string;
  cep: string;
  instSaude: string;
  sgInstSaude: string;
  complemento: string;
  email: string;
  municipio: string;
  nomeContato: string;
  numero: string;
  rua: string;
  sgCliente: string;
  telefoneContato: string;
  endereco: string;
  gapAgendamento: string;
};

type Props = {
  dadosInst: InstSaudeprops[];
  reloadList?: () => void;
};

const ListaDeInstituicoes = ({ dadosInst, reloadList }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isNew, setIsNew] = useState(true);
  const [dataToEdit, setDataToEdit] = useState<any>(null);
  const [buscaList, setBuscaList] = useState<any[]>([]);
  const [busca, setBusca] = useState('');

  const handleEditUser = (itemToEdit: any) => {
    setIsNew(false);
    setDataToEdit(itemToEdit);
    onOpen();
  };

  const filtrarDados = (busca: string) => {
    if (busca.trim()) {
      const buscaFiltrada = dadosInst.filter((d) =>
        d?.instSaude?.toLowerCase().includes(busca.toLocaleLowerCase())
      );
      buscaFiltrada.sort(function (a, b) {
        if (a.instSaude > b.instSaude) {
          return 1;
        }
        if (a.instSaude < b.instSaude) {
          return -1;
        }
        return 0;
      });
      setBuscaList(buscaFiltrada);
    } else {
      dadosInst.sort(function (a, b) {
        if (a.instSaude > b.instSaude) {
          return 1;
        }
        if (a.instSaude < b.instSaude) {
          return -1;
        }
        return 0;
      });
      setBuscaList(dadosInst);
    }
  };
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          <input
            className="form-control"
            type="text"
            name="cliente"
            id="cadastro-cliente-cliente"
            value={busca}
            onChange={(e) => {
              setBusca(e.target.value);
              filtrarDados(e.target.value);
            }}
            placeholder={'Buscar instituição de saúde'}
          />
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => filtrarDados(busca)}
          >
            <i className="fa fa-search" />
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        {dadosInst.length > 0 ? (
          <div id="cadastro-cliente-table-list" className="table-list">
            <Table className="table-hover table-striped table-responsive-css">
              <thead className={'thead-light'}>
                <tr>
                  <th>Instituição de Saúde</th>
                  <th>Sigla</th>
                  <th>Editar</th>
                </tr>
              </thead>
              <tbody>
                {buscaList.length > 0
                  ? buscaList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.instSaude}</td>
                          <td>{item.sgInstSaude}</td>
                          <td className="text-center">
                            <div
                              className="btn btn-primary btn-lg"
                              onClick={() => handleEditUser(item)}
                            >
                              <i className="fa fa-pencil-alt" />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : dadosInst &&
                    dadosInst.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.instSaude}</td>
                          <td>{item.sgInstSaude}</td>
                          <td className="text-center">
                            <div
                              className="btn btn-primary btn-lg"
                              onClick={() => handleEditUser(item)}
                            >
                              <i className="fa fa-pencil-alt" />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="alert alert-warning">
            Nenhuma instituição encontrada
          </div>
        )}
      </div>
      {dataToEdit && (
        <ModalCriarInstituicao
          isNew={isNew}
          isOpen={isOpen}
          onClose={(reload) => {
            if (reload) {
              reloadList();
            }
            onClose();
          }}
          dataToEdit={dataToEdit}
        />
      )}
    </>
  );
};
export default ListaDeInstituicoes;
