import { Box, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from 'react';

import { useUser } from 'hooks';
import { api } from 'services/api';
import { formatDate } from 'shared/util/formatDateAmPm';

import { PatientProps } from '../../index';
import { ChatContent, ChatPanel, ContactContent, ContactInfo, Container, InputContainer, MessageContent } from './styles';

interface ChatProps {
  patient: PatientProps;
}

export function Chat({ patient }: ChatProps) {
  const listarConversasInterval = useRef<any>();
  // const controller = new AbortController();

  const [intervaloBusca, setIntervaloBusca] = useState(true);
  const [existeMaisAntiga, setExisteMaisAntiga] = useState(true);
  const [conversas, setConversas] = useState([]);
  const [input, setInput] = useState('');
  const user = useUser();

  const containerChatContentRef = useRef<any>();
  const scrollToBottom = () => {
    if (containerChatContentRef.current) {
      containerChatContentRef.current.scrollTop =
        containerChatContentRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const checkAndReadMessage = async () => {
      console.log(patient);
      if (!patient.fcLido) {
        await lerMensagem();
      }
    };

    checkAndReadMessage();
  },[patient])

  const lerMensagem = async () => {
    try {
      await api.post('asmuser', {
        method: "_faleConosco",
        action: "lerMensagem",
        sgInstSaude: patient.sgInstSaude,
        usuarioCPF: patient.usuarioCPF,
        fcDataInicioConversa: patient.fcDataInicioConversa
      });

    } catch (e){
      console.log(e)
    }
  }

  const listarConversas = () => {
    const maxDate = conversas
      .filter((c) => !c.temporaria)
      .reduce((accumulator, curVal) => {
        if (!accumulator.fmDataMens) {
          accumulator = curVal;
        }
        if (dayjs(curVal.fmDataMens) > dayjs(accumulator.fmDataMens)) {
          accumulator = curVal;
        }
        return accumulator;
      }, {});

    const options = {
      method: '_faleConosco',
      action: 'listarConversasWeb',
      usuarioCPF: patient.usuarioCPF,
      psCPF: '',
      fcDataInicioConversa: patient.fcDataInicioConversa,
      maiorQueData: maxDate.fmDataMens || '',
    };

    return api
      .post('asmuser', options, {
        // signal: controller.signal
      })
      .then(({ data }) => {
        if (data.success) {
          setConversas((old) =>
            [...old, ...data.body].filter((c) => !c.temporaria)
          );
          scrollToBottom();
          setIntervaloBusca(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (patient && patient.usuarioCPF) {
      listarConversas();
    }
  }, [patient]);

  useEffect(() => {
    if (!intervaloBusca) {
      setIntervaloBusca(true);
      clearTimeout(listarConversasInterval.current);
      // controller.abort();
      listarConversasInterval.current = setTimeout(
        () => listarConversas(),
        5000
      );
    }
  }, [intervaloBusca]);

  if (!patient.nmUsuario) {
    return <div className="col-md-8"></div>;
  }

  const enviarMensagem = async (e?: any) => {
    try {
      dayjs.extend(utc);
      dayjs.extend(timezone);

      e.preventDefault();
      if (!String(input).trim()) {
        return;
      }
      await api.post('asmuser', {
        method: '_faleConosco',
        action: 'enviarMensagemWeb',
        usuarioCPF: patient.usuarioCPF,
        psCPF: user?.cpf,
        fcDataInicioConversa: patient.fcDataInicioConversa,
        fmMensagem: input,
        sgCliente: user?.sgCliente,
        sgInstSaude: user?.grupoEscolhido.sgInstSaude,
      });

      setConversas((prev) => [
        ...prev,
        {
          ...prev[prev.length - 1],
          fmDataMens: dayjs().tz('Europe/Lisbon').format('YYYY-MM-DD HH:mm:ss'),
          fmMensagem: input,
          fmInOut: 'O',
          temporaria: true,
        },
      ]);

      scrollToBottom();
      setInput('');
    } catch (error) {
      console.log(error.message);
    }
  };

  const onScroll = (event) => {
    if (
      conversas.length > 0 &&
      existeMaisAntiga &&
      event.target.scrollTop === 0
    ) {
      api
        .post('asmuser', {
          method: '_faleConosco',
          action: 'listarConversasWeb',
          usuarioCPF: patient.usuarioCPF,
          psCPF: '',
          fcDataInicioConversa: patient.fcDataInicioConversa,
          menorQueData: conversas[0].fmDataMens,
        })
        .then(({ data }) => {
          if (data.success) {
            if (data.naoExisteMaisAntigas) {
              setExisteMaisAntiga(false);
            }
            setConversas((old) => [...data.body, ...old]);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  return (
    <Container>
      <ContactInfo>
        <ContactContent>
          <h6>{patient.nmUsuario}</h6>
          <p className="text-muted">
            ID do utente : {patient.usuarioCPF}, Telefone:{' '}
            {patient.telefoneUsuario}
          </p>
        </ContactContent>
      </ContactInfo>
      <ChatPanel>
        <ChatContent ref={containerChatContentRef} onScroll={onScroll}>
          {conversas.map((cv) => {
            return (
              <div key={cv.fmDataMens}>
                <div>
                  <MessageContent type={cv.fmInOut}>
                    <div style={{ overflowWrap: 'break-word' }}>
                      {cv.fmMensagem}
                    </div>
                    <div style={{ textAlign: 'right', fontSize: 12 }}>
                      {formatDate(cv.fmDataMens)}
                    </div>
                  </MessageContent>
                </div>
              </div>
            );
          })}
        </ChatContent>

        {/* {!IS_ADMIN && ( */}
        <InputContainer>
          <Box w="full">
            <form onSubmit={enviarMensagem}>
              <Box display="flex" justifyContent="space-between">
                <input
                  type="text"
                  placeholder="Mensagem..."
                  className="w-100"
                  value={input}
                  onChange={(event) => {
                    setInput(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      enviarMensagem();
                    }
                  }}
                />
                <Button type="submit">
                  <a href="#" onClick={enviarMensagem}>
                    <i className="material-icons hover">send</i>
                  </a>
                </Button>
              </Box>
            </form>
          </Box>
        </InputContainer>
        {/* )} */}
      </ChatPanel>
    </Container>
  );
}
