import { useState } from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as S from './styles';

type Props = {
  sgCliente: string;
  usuarioCPF: string;
  link: string;
};

export function Nonagon(p: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Nonagon
      </Button>

      <Modal size={'xl'} isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader toggle={() => setModalOpen(false)}></ModalHeader>
        <div>
          <ModalBody>
            <S.IframeContainer>
              <iframe src={p.link} frameBorder="0"></iframe>
            </S.IframeContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}
