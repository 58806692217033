import { useEffect, useState } from 'react';

import { api } from 'services/api';

import { Chat } from './components/Chat';
import { MessageBar } from './components/MessageBar';

import './style.css';

export interface PatientProps {
  usuarioCPF: string;
  nmUsuario: string;
  isNew: boolean;
  fmMensagem: string;
  fmDataMens: string;
  fcDataInicioConversa: string;
  fcLido: number;
  sgInstSaude: string;
  telefoneUsuario: string;
}

function FaleConosco() {
  const [showChat, setShowChat] = useState(true);
  const [pacientes, setPacientes] = useState([]);
  const [pacienteCPF, setPacienteCPF] = useState('');

  console.log('teste');

  const listarPacientes = () => {
    return api
      .post('asmuser', {
        method: '_faleConosco',
        action: 'listarPacientes',
      })
      .then(({ data }) => {
        if (data.success) {
          setPacientes((previous) => {
            return data.body.map((item) => {
              const old = previous.find(
                (p) => p.usuarioCPF === item.usuarioCPF
              );

              return {
                ...item,
                isNew: old && old.fmDataMens !== item.fmDataMens,
              };
            });
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    listarPacientes().then(() => {
      setInterval(() => {
        listarPacientes();
      }, 10000);
    });
  }, []);

  useEffect(() => {
    //desmontar o componente Chat para poder executar o clearInterval
    if (!showChat) {
      setShowChat(true);
    }
  }, [showChat]);

  const getSelecionado = () => {
    return pacientes.find((p) => p.usuarioCPF === pacienteCPF);
  };

  return (
    <div className="chat-container" style={{ height: '100%'}}>
      <div className="row no-gutters">
        <MessageBar
          patients={pacientes}
          setIsChatVisible={setShowChat}
          getSelectedChat={getSelecionado}
          setPacienteCPF={setPacienteCPF}
        />

        {showChat && getSelecionado() && <Chat patient={getSelecionado()} />}
      </div>
    </div>
  );
}

export default FaleConosco;
