import { CircularProgress } from '@chakra-ui/react';
import { TableContainer as TableContainerChakra, Thead as TheadChakra, Tr as TrChakra } from '@chakra-ui/react';
import styled, { css } from 'styled-components';

const borderColor = '#E5E5E5';
const headColor = '#f8f8f8';
const textColor = '#1A202C';

type TrProps = {
  $enabled: boolean; // https://styled-components.com/docs/api#transient-props
};

type OverlayProps = {
  isVisible: boolean;
};

export const TableContainer = styled(TableContainerChakra)`
  border: 1px solid ${borderColor};
  border-radius: 0.625rem;

  tbody > tr:last-child td {
    border-bottom: 0;
  }
`;

export const Thead = styled(TheadChakra)`
  border-bottom: 1px solid red;
  background-color: ${headColor};
  height: 2.875rem;

  th {
    border-color: ${borderColor};
    font-size: 1rem;
    text-transform: none;
  }
`;

export const Tr = styled(TrChakra)<TrProps>`
  background-color: ${(props) => (props.$enabled ? '#fff' : '#FAFAFA')};

  td {
    color: ${(props) => (props.$enabled ? textColor : '#a4a4a4')};
    border-color: ${borderColor};
  }
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);

  height: 30vh;
  width: 100%;

  z-index: 98;
`;

export const Progress = styled(CircularProgress)`
  z-index: 99;
`;
