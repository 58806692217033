import { Button, Flex, Select, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useApiOnMount } from 'hooks';
import { api } from 'services/api';

import ListaAdmin from './components/lista-admnistradores';
import { ModalCriarAdministrador } from './components/modal-cadastrar-administradores';

const CadastroAdministradores = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [dataToList, setDataToList] = useState<any[]>([]);
  const [inputFilter, setInputFilter] = useState('');
  const [sgClient, setSgClient] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const { data, isLoading } = useApiOnMount('aswcliente', {
    method: '_list',
    page: 0,
    size: 100,
    sort: 'cliente,asc',
  });

  const handleChangeSelectCliente = async (value: string) => {
    if (!value) {
      setSgClient('');
      return;
    }
    setLoading(true);
    setSgClient(value);
    const { data: listaUsuarios } = await api.post('aswusuariosweb', {
      method: '_list',
      sgCliente: value,
      nome: '',
    });
    setDataToList(listaUsuarios.data);
    setLoading(false);
  };

  const requestWithFilter = async () => {
    setLoading(true);
    const { data: listaUsuarios } = await api.post('aswusuariosweb', {
      method: '_list',
      sgCliente: sgClient,
      nome: inputFilter,
    });
    setDataToList(listaUsuarios.data);
    setInputFilter('');
    setLoading(false);
  };

  return (
    <>
      <div
        id="cadastro-administradores-heading"
        data-cy="CadastroAdministradoresHeading"
      >
        <h2 className="mb-4">Administradores</h2>
        <label>Cliente</label>
        <Flex w="full">
          <Select
            name={'grupoMenu'}
            className={'css-select-control'}
            onChange={(e) => handleChangeSelectCliente(e.target.value)}
          >
            <option value="">Selecione um cliente</option>
            {data &&
              data.map((item, index) => {
                return (
                  <option key={index} value={item.sgCliente}>
                    {item.cliente}
                  </option>
                );
              })}
          </Select>
        </Flex>
        {sgClient && (
          <div className="mt-3 d-flex justify-content-end">
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={onOpen}
            >
              Cadastrar Novo Administrador
            </Button>
          </div>
        )}
        <div id="form-filter" className="mt-2">
          <div className="row mt-1 ml-3 mr-3">
            <Col
              md="12"
              className="col-filter-gerenciamento-profissional-saude-nome"
            >
              <Row className="mr-1 mt-1">
                <input
                  className="form-control"
                  type="text"
                  name="nome"
                  id="gerenciamento-profissional-saude-nome"
                  disabled={dataToList.length > 0 ? false : true}
                  value={inputFilter}
                  onChange={(event) => setInputFilter(event.target.value)}
                  onKeyDown={(key) => {
                    if (key.key === 'Enter') {
                      requestWithFilter();
                    }
                  }}
                />
              </Row>
            </Col>
          </div>

          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button
              id="search-submit"
              color="primary"
              onClick={() => requestWithFilter()}
            >
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>
        <div>
          <ListaAdmin
            requestOnEditOrInsert={() => {
              handleChangeSelectCliente(sgClient);
            }}
            sgCliente={sgClient}
            users={dataToList ?? []}
          />
        </div>
      </div>
      {isOpen && (
        <ModalCriarAdministrador
          isNew={isNew}
          onClose={onClose}
          sgCliente={sgClient}
          requestOnEditOrInsert={() => {
            handleChangeSelectCliente(sgClient);
          }}
        />
      )}
      {isLoading || loading ? <Loading /> : <></>}
    </>
  );
};

export default CadastroAdministradores;
