import { Box, Flex, Heading, Select, Tab, TabList, Tabs } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Col, Row } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { api } from 'services/api';
import { changeInputText, dateTimeToServer } from 'shared/util';
import { getDataValue } from 'shared/util/pegar-hora-consultas';

import { TabelaRecepcao } from './components/tabela/tabelaRecepcao';

export type InstType = {
  instSaude: string;
  sgInstSaude: string;
};

const AtendimentoRecepcao = () => {
  const usuarioLogado = useUser();
  const listarConversasInterval = useRef(null);
  const dataHoje = new Date();
  const controller = new AbortController();
  const [intervaloBusca, setIntervaloBusca] = useState(true);
  const [loading, setLoading] = useState(false);
  const agendamentosHook = useApiRequest('aswagendausuprofsaude', []);
  const [dataToList, setDataToList] = useState<any[]>([]);
  const [local, setLocal] = useState('');
  const [instituicao, setInstituicao] = useState('');
  const [busca, setBusca] = useState('');
  const [agendamentosToList, setAgendamentosToList] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(null); // Defina um valor inicial
  const [endDate, setEndDate] = useState(null);

  const [agendamentos, setAgendamentos] = useState<any[]>([]);
  const [status, setStatus] = useState('Solicitadas');

  const instHook = useApiRequest<Array<InstType>>('aswinstsaude', []);

  async function listarInst() {
    instHook.request({
      method: '_listIsUsuarioLogado',
    });
  }

  const handleChangeSelectLocal = async () => {
    setLoading(true);
    const { data: listaLocal } = await api.post('aswagendalocaisatendimento', {
      method: 'listarAgendaLocalAtendimento',
      laDescricao: '',
      listarTodos: true,
      psCpf: usuarioLogado?.cpf,
      sgCliente: usuarioLogado.sgCliente,
      sgInstSaude: instituicao,
    });
    setDataToList(listaLocal.data);
    setLoading(false);
  };

  const loadAgendamentos = async (statusSelected?: string, start?: Date, end?: Date) => {
    setLoading(true)
    if (statusSelected === 'Solicitadas' || !statusSelected) {
      const { data } = await api.post('aswagendausuprofsaude', {
        method: '_listarConsultasSolicitadasRecepcao',
        search: busca,
      });
      if (data.success) {
        setAgendamentos(data.data);
      }
    } else if (statusSelected === 'Confirmadas') {
      const { data } = await api.post('aswagendausuprofsaude', {
        method: '_listarConsultasConfirmadasRecepcao',
        startDate: start,
        endDate: end,
        search: busca,
      });
      if (data.success) {
        setAgendamentos(data.data);
      }
    } else {
      const { data } = await api.post('aswagendausuprofsaude', {
        method: '_listarConsultasCanceladasRecepcao',
        startDate: start ? start : startDate,
        endDate: end ? end : endDate,
        search: busca,
      });
      if (data.success) {
        setAgendamentos(data.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!instituicao) {
      listarInst();
    } else {
      handleChangeSelectLocal();
    }
  }, [instituicao]);

  useEffect(() => {
    if (local !== '') {
      loadAgendamentos();
    }
  }, [local]);

  useEffect(() => {
    if (!busca) {
      filtrarDados('');
    } else {
      setAgendamentos(agendamentosHook.state);
      setAgendamentosToList(agendamentosHook.state);
    }
  }, [agendamentosHook.state]);

  document.addEventListener('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  });

  const filtrarDados = (busca: string) => {
    if (busca.trim()) {
      const buscaFiltrada = agendamentosHook.state.filter(
        (paciente) =>
          paciente.usuario.toLowerCase().includes(busca.toLocaleLowerCase()) |
          paciente.usuarioCPF.toLowerCase().includes(busca.toLocaleLowerCase())
      );
      buscaFiltrada.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(buscaFiltrada);
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    // Atualize as datas selecionadas
    setStartDate(start);
    setEndDate(end);

    if(start && end){
      loadAgendamentos(status, start, end);
    }
  };

  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
          GERENCIAMENTO DE SOLICITAÇÃO DE CONSULTAS
        </Heading>

        <Box as="form" margin={'16px 0'}>
          <Box>
            <Select
              className="mb-2"
              borderRadius={'1rem'}
              size={'lg'}
              onChange={(event) => {
                const { value } = event.target;
                event.persist();
                setInstituicao(value);
              }}
              placeholder={'Selecione a instituição de saúde'}
            >
              {instHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.sgInstSaude}>
                    {item.instSaude}
                  </option>
                );
              })}
            </Select>
            <Select
              disabled={!instituicao}
              borderRadius={'1rem'}
              size={'lg'}
              onChange={(event) => {
                const { value } = event.target;
                event.persist();
                setLocal(value);
              }}
              placeholder={'Local de atendimento'}
            >
              {dataToList &&
                dataToList.map((item, index) => {
                  return (
                    <option key={index} value={item.laDescricao}>
                      {item.laNome}
                    </option>
                  );
                })}
            </Select>
            <div id="form-filter">
              <div className="row mt-1 ml-3 mr-3">
                <Col md="12" className="col-filter-cadastro-cliente-cliente">
                  <Row className="mr-1 mt-1">
                    <input
                      className="form-control"
                      type="text"
                      style={{ borderRadius: '1rem' }}
                      id="cadastro-cliente-cliente"
                      value={busca}
                      onChange={(e) => setBusca(changeInputText(e))}
                      placeholder="Buscar nome ou Código"
                    />
                  </Row>
                </Col>
              </div>
              <div className="row mb-2 mr-4 justify-content-end pull-right">
                <Button
                  id="search-submit"
                  color="primary"
                  onClick={() => loadAgendamentos(status)}
                >
                  <i className="fa fa-search" />
                  &nbsp;
                </Button>
              </div>
            </div>
            {local && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Tabs display="flex">
                  <TabList>
                    <Tab
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setBusca('');
                        setStatus('Solicitadas');
                        loadAgendamentos('Solicitadas');
                      }}
                    >
                      Solicitadas
                    </Tab>
                    <Tab
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setBusca('');
                        setStatus('Confirmadas');
                        loadAgendamentos('Confirmadas');
                      }}
                    >
                      Confirmadas
                    </Tab>
                    <Tab
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                        setBusca('');
                        setStatus('Canceladas');
                        loadAgendamentos('Canceladas');
                      }}
                    >
                      Canceladas
                    </Tab>
                  </TabList>
                </Tabs>
                {status != 'Solicitadas' && (
                  <Box className="agenda-filtro-data">
                    <Box
                      id={'gestao-agenda-data-filtro-container'}
                      color="#56a1e1"
                    >
                      <i className="fa fa-calendar" />
                      <DatePicker
                        id={'gestao-agenda-data-filtro'}
                        selected={startDate}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Selecione o intervalo"
                        dateFormat={'dd/MM/yyyy'}
                        onChange={onChange}
                        locale="pt"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Flex>
        <Box mx="auto" w="full">
          <TabelaRecepcao
            agendamentos={agendamentos}
            loadAgendamentos={loadAgendamentos}
            status={status}
          />
        </Box>
      </Flex>
      {loading && <Loading />}
    </Box>
  );
};

export default AtendimentoRecepcao;
