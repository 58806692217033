import React, { useEffect, useImperativeHandle, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from 'components/form/input';
import { Select } from 'components/form/select';
import { InputFile } from 'components/formUsuarioSMS/components/input-file';
import {
  FileType,
  TypeFile,
  TypePacienteAPI,
} from 'components/formUsuarioSMS/types';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { LOG } from 'shared/util';
import { useAlert, useApiRequest, useUser } from 'hooks';
import * as S from './styles';
import { IEpisodioConsulta } from 'types/episodio';

interface IFormEpisodioSMS {
  identificador?: string; //onlyView
  cpf: string;
  setStatus?: React.Dispatch<React.SetStateAction<string>>;
  emmitFiles?: (files: FileType[]) => void;
  refreshDados: () => void;
  action?: string;
}

const filesAccept = 'image/*,.doc,.docx,.pdf';

export const FormEpisodioSMS = ({
  identificador,
  setStatus,
  cpf,
  emmitFiles,
  refreshDados,
  action
}: IFormEpisodioSMS) => {
  const user = useUser();
  const alert = useAlert();
  const requiredFieldMessage = 'Campo obrigatório';
  const [onlyView, setOnlyView] = useState<boolean>(true);
  const [epPrimResp, setEpPrimResp] = useState<boolean>(true);
  const [epSecResp, setEpSecResp] = useState<boolean>(true);
  const [epTercResp, setEpTercResp] = useState<boolean>(true);
  const [disableResp, setDisableResp] = useState<boolean>(false);
  const [inputFormError, setInputFormError] = useState(false);

  const epPrimOptions = [
    {
      value: null,
      label: 'Selecione'
    },
    {
      value: 'UBS Cidade Jardim',
      label: 'UBS Cidade Jardim'
    }
  ];

  const epSecOptions = [
    {
      value: null,
      label: 'Selecione'
    },
    {
      value: 'Regulação/Triagem',
      label: 'Regulação/Triagem'
    }
  ];

  const epTercOptions = [
    {
      value: null,
      label: 'Selecione'
    },
    {
      value: 'Samuel Libânio',
      label: 'Samuel Libânio'
    }
  ];

  const epPrimStatusOptions = [
    {
      'value': 'Consulta',
      'label': 'Consulta'
    },
    {
      'value': 'Consulta retorno',
      'label': 'Consulta retorno'
    },
    {
      'value': 'Exames',
      'label': 'Exames'
    }
  ];

  const epSecStatusOptions = [
    {
      'value': 'Pendente',
      'label': 'Pendente'
    },
    {
      'value': 'Aprovado',
      'label': 'Aprovado'
    },
  ];

  const epTercStatusOptions = [
    {
      'value': 'Quimioterapia',
      'label': 'Quimioterapia'
    },
    {
      'value': 'Radioterapia',
      'label': 'Radioterapia'
    }
  ];


  useEffect(() => {
    if (user?.sgCliente) {
      const grupo = user?.grupoEscolhido.grupo;
      if (!identificador || grupo === 'sms-pa' || grupo === 'sms-admin') {
        setOnlyView(false);
      }
    }
  }, [user, identificador]);

  const especialidadeApi = useApiRequest<{ label: string; value: string }[]>(
    'aswepisodiosms',
    []
  );

  const userApi = useApiRequest<TypePacienteAPI>(
    'aswusuarios',
    {} as TypePacienteAPI
  );
  const episodioApi = useApiRequest<IEpisodioConsulta>(
    'aswepisodiosms',
    {} as IEpisodioConsulta
  );

  useEffect(() => {
    if (userApi.error && userApi.error !== '')
      alert.warning({
        title: 'Erro ao procurar por este CPF',
      });
  }, [userApi]);

  const getSchema = () =>
    Yup.object().shape({
      epData: Yup.string().required(requiredFieldMessage),
      epNecessidadePaciente: Yup.string().required(requiredFieldMessage),
      epEspecialidade: Yup.string().required(requiredFieldMessage),
      epHipoteseDiagnostica: Yup.string().required(requiredFieldMessage),
    });

  const formMethods = useForm<IEpisodioConsulta>({
    resolver: yupResolver(getSchema()),
    // defaultValues
  });

  const [files, setFiles] = useState<TypeFile[]>([]);
  useEffect(() => {
    if (emmitFiles) {
      emmitFiles(files);
    }
  }, [files]);
  const [showInputFiles, setShowInputFiles] = useState(true);

  const updateFiles = (
    files: Partial<TypeFile>[],
    descricao: TypeFile['descricao']
  ) => {
    setFiles((prev) => [
      ...prev.filter((f) => f.descricao !== descricao),
      ...(files.map((f) => ({
        ...f,
        descricao: descricao,
      })) as typeof prev),
    ]);
  };

  const handleFormSubmit = () => {
    // if (
    //   !files.length ||
    //   !files.some((item) => item.descricao === 'comprovanteResidencia') ||
    //   !files.some((item) => item.descricao === 'encaminhamentoMedico') ||
    //   !files.some((item) => item.descricao === 'cartaoSUS') ||
    //   !files.some((item) => item.descricao === 'documentoPessoal')
    // ) {
    //   setInputFormError(true);
    //   return;
    // }

    handleCadastrarEpisodio();
  };

  const handleChange = (campo) => {
    const values = formMethods.getValues();

    if(campo === 'prim'){
      if(epPrimResp && values.epPrimResp){
        setEpPrimResp(false);
      } else {
        setEpPrimResp(true);
        formMethods.setValue(
          'epPrimStatus',
          null
        );
      }
    }
    if(campo === 'sec'){
      if(epSecResp && values.epSecResp){
        setEpSecResp(false);
      } else {
        setEpSecResp(true);
        formMethods.setValue(
          'epSecStatus',
          null
        );
      }
    }
    if(campo === 'terc'){
      if(epTercResp && values.epTercResp){
        setEpTercResp(false);
      } else {
        setEpTercResp(true);
        formMethods.setValue(
          'epTercStatus',
          null
        );
      }
    }

  }

  const handleCadastrarEpisodio = async () => {
    try {
      const values = formMethods.getValues();
      if(action === 'new'){
        episodioApi
          .submit({
            method: 'criarEpisodioSMS',
            sgCliente: user.sgCliente,
            sgInstSaude: userApi.state.usuarioInstSaudePrefer,
            files: files.filter((a) => !a?.s3Caminho),
            epData: values.epData,
            epNecessidadePaciente: values.epNecessidadePaciente,
            epEspecialidade: values.epEspecialidade,
            epHipoteseDiagnostica: values.epHipoteseDiagnostica,
            epPrimResp : values.epPrimResp,
            epPrimStatus : values.epPrimStatus,
            epSecResp : values.epSecResp,
            epSecStatus : values.epSecStatus,
            epTercResp : values.epTercResp,
            epTercStatus : values.epTercStatus,
            usuarioCPF: cpf,
          })
          .then((result) => {
            if (result.success) {
              alert.success({ title: 'Episódio cadastrado' });
              episodioApi.setState({});
              setFiles([]);
              setShowInputFiles(false);
              formMethods.reset();
              setInputFormError(false);
              refreshDados();
              return;
            } else {
              alert.warning({ title: 'Erro ao cadastrar episódio' });
            }
          });
      } else {
        episodioApi
        .submit({
          method: 'atualizarEpisodioSMS',
          epIdentificador: identificador,
          epPrimResp: values.epPrimResp,
          epPrimStatus: values.epPrimStatus,
          epSecResp: values.epSecResp,
          epSecStatus: values.epSecStatus,
          epTercResp: values.epTercResp,
          epTercStatus: values.epTercStatus,
        })
        .then((result) => {
          if (result.success) {
            alert.success({ title: 'Episódio atualizado' });
            episodioApi.setState({});
            setFiles([]);
            setShowInputFiles(false);
            formMethods.reset();
            setInputFormError(false);
            refreshDados();
            return;
          } else {
            alert.warning({ title: 'Erro ao atualizar episódio' });
          }
        });
      }
    } catch (error) {
      LOG('Catch no handleCadastrarEpisodio', error);
    }
  };

  useEffect(() => {
    if (!!user.sgCliente) {
      especialidadeApi.request({
        method: 'listarEspecialidade',
        sgCliente: user?.sgCliente,
      });

      if (!identificador) {
        userApi.request({
          method: '_findByCpf',
          sgCliente: user.sgCliente,
          cpf,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (!!identificador) {
      episodioApi.request({
        method: 'consultarEpisodioSMS',
        epIdentificador: identificador,
      });
      if(action === 'view'){
        setDisableResp(true);
      } else {
        setDisableResp(false);
      }
    }
  }, [identificador]);


  useEffect(() => {
    if (episodioApi.state?.sgCliente) {
      formMethods.setValue(
        'epData',
        new Date(episodioApi.state.epData).toISOString().split('T')[0]
      );
      formMethods.setValue(
        'epNecessidadePaciente',
        episodioApi.state.epNecessidadePaciente
      );
      formMethods.setValue(
        'epEspecialidade',
        episodioApi.state.epEspecialidade
      );
      formMethods.setValue(
        'epHipoteseDiagnostica',
        episodioApi.state.epHipoteseDiagnostica
      );

      formMethods.setValue(
        'epPrimResp',
        episodioApi.state.epPrimResp
      );
      if(episodioApi.state.epPrimResp && action != 'view'){
        setEpPrimResp(false);
      }
      formMethods.setValue(
        'epPrimStatus',
        episodioApi.state.epPrimStatus
      );

      formMethods.setValue(
        'epSecResp',
        episodioApi.state.epSecResp
      );
      if(episodioApi.state.epSecResp && action != 'view'){
        setEpSecResp(false);
      }
      formMethods.setValue(
        'epSecStatus',
        episodioApi.state.epSecStatus
      );

      formMethods.setValue(
        'epTercResp',
        episodioApi.state.epTercResp
      );
      if(episodioApi.state.epTercResp && action != 'view'){
        setEpTercResp(false);
      }
      formMethods.setValue(
        'epTercStatus',
        episodioApi.state.epTercStatus
      );
    }

    if ((episodioApi.state?.documentos || []).length > 0) {
      setShowInputFiles(false);
    }
  }, [episodioApi.state]);

  useEffect(() => {
    if (!showInputFiles) {
      setShowInputFiles(true);
    }
  }, [showInputFiles]);

  useEffect(() => {
    if (episodioApi.state.epStTriagemMedica) {
      setStatus(episodioApi.state.epStTriagemMedica);
    }
  }, [episodioApi.state]);

  const documentos = episodioApi.state?.documentos || [];

  const getDocsFiltered = (group: string) =>
    documentos
      .filter((d) => d.edGrupoDoc === group)
      .map(
        (doc) =>
        ({
          id: `${doc.sgCliente}${doc.usuarioCPF}${doc.sgInstSaude}${doc.usuarioTipo}${doc.epData}${doc.edDataTriagemDoc}${doc.edGrupoDoc}${doc.diNomeDocumento}`,
          name: doc.diNomeDocumento,
          type: doc.diTipo,
          base64: '',
          date: doc.dateDoc,
          s3Caminho: doc.diS3Caminho,
          size: doc.diSize,
        } as unknown as TypeFile)
      );

  return (
    <>
      {(!identificador && action === 'new') && (
        <S.Container>
          <S.Row>
            <div>
              <Button
                color="primary"
                type="submit"
                form="formEpisodio"
                disabled={episodioApi.isLoading}
              >
                Cadastrar
                {episodioApi.isLoading && (
                  <>
                    &nbsp;
                    <i className="fas fa-spinner fa-spin fa-1x"></i>
                  </>
                )}
              </Button>

              
            </div>
          </S.Row>
        </S.Container>
      )}

      {action === 'edit' && (
        <S.Container>
          <S.Row>
            <div>
              <Button
                color="primary"
                type="submit"
                form="formEpisodio"
                disabled={episodioApi.isLoading}
              >
                Salvar
                {episodioApi.isLoading && (
                  <>
                    &nbsp;
                    <i className="fas fa-spinner fa-spin fa-1x"></i>
                  </>
                )}
              </Button>
            </div>
          </S.Row>
        </S.Container>
      )}
      <FormProvider {...formMethods}>
        <form
          id="formEpisodio"
          onSubmit={formMethods.handleSubmit(handleFormSubmit)}
        >
          <Row className="">
            <Col md="4">
              <Input
                disabled={true}
                label="&nbsp;"
                name="epEtapa"
                value=""
                placeholder={'Atenção Primária'}
              />
            </Col>
            <Col md="4">
              <Select
                disabled={disableResp}
                label="Responsável  "
                name="epPrimResp"
                options={epPrimOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
                onChange={() => handleChange('prim')}
              />
            </Col>
            <Col md="4">
              <Select
                disabled={epPrimResp}
                label="Status "
                name="epPrimStatus"
                options={epPrimStatusOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Input
                disabled={true}
                label=""
                name="epEtapa"
                value=""
                placeholder={'Atenção Secundária'}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Select
                disabled={disableResp}
                label=""
                name="epSecResp"
                options={epSecOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
                onChange={() => handleChange('sec')}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Select
                disabled={epSecResp}
                name="epSecStatus"
                options={epSecStatusOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Input
                disabled={true}
                label=""
                name="epEtapa"
                value=""
                placeholder={'Atenção Terciária'}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Select
                disabled={disableResp}
                label=""
                name="epTercResp"
                options={epTercOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
                onChange={() => handleChange('terc')}
              />
            </Col>
            <Col md="4" className={"mt-4"}>
              <Select
                disabled={epTercResp}
                label=""
                name="epTercStatus"
                options={epTercStatusOptions}
                placeholder={'Selecione...'}
                isLoading={false}
                loadingMessage={(obj) => 'Carregando...'}
              />
            </Col>


            <Col md="4">
              <Input
                disabled={!!identificador}
                type="date"
                name="epData"
                label="Data *"
                max={new Date().toISOString().split('T')[0]}
              />
            </Col>
            <Col md="4">
              <Input
                disabled={!!identificador}
                name="epNecessidadePaciente"
                label="Necessidade do Paciente *"
              />
            </Col>
            <Col md="4">
              <Select
                disabled={!!identificador}
                label="Especialidade *"
                name="epEspecialidade"
                options={especialidadeApi.state}
                placeholder={'Selecione...'}
                isLoading={especialidadeApi.isLoading}
                loadingMessage={(obj) => 'Carregando...'}
              />
            </Col>
            <Col md="12">
              <Input
                disabled={!!identificador}
                name="epHipoteseDiagnostica"
                label="Hipótese Diagnóstica *"
              />
            </Col>
          </Row>

          {showInputFiles && (
            <>
              <InputFile
                callBack={(files) =>
                  updateFiles(files, 'comprovanteResidencia')
                }
                filesAccept={filesAccept}
                label="Comprovante de residência (tamanho máximo 10mb)"
                filesDefault={getDocsFiltered('comprovanteResidencia')}
                onlyView={onlyView}
              />
              {/* {inputFormError &&
                !files.some(
                  (item) => item.descricao === 'comprovanteResidencia'
                ) && (
                  <span className="text-warning">
                    <small>{requiredFieldMessage}</small>
                  </span>
                )} */}

              <InputFile
                callBack={(files) => updateFiles(files, 'encaminhamentoMedico')}
                filesAccept={filesAccept}
                label="Encaminhamento médico (tamanho máximo 10mb)"
                filesDefault={getDocsFiltered('encaminhamentoMedico')}
                onlyView={onlyView}
              />
              {/* {inputFormError &&
                !files.some(
                  (item) => item.descricao === 'encaminhamentoMedico'
                ) && (
                  <span className="text-warning">
                    <small>{requiredFieldMessage}</small>
                  </span>
                )} */}
              <InputFile
                callBack={(files) => updateFiles(files, 'cartaoSUS')}
                filesAccept={filesAccept}
                label="Foto cartão SUS (tamanho máximo 10mb)"
                filesDefault={getDocsFiltered('cartaoSUS')}
                onlyView={onlyView}
              />
              {/* {inputFormError &&
                !files.some((item) => item.descricao === 'cartaoSUS') && (
                  <span className="text-warning">
                    <small>{requiredFieldMessage}</small>
                  </span>
                )} */}
              <InputFile
                callBack={(files) => updateFiles(files, 'documentoPessoal')}
                filesAccept={filesAccept}
                label="Documento pessoal  com foto (tamanho máximo 10mb)"
                filesDefault={getDocsFiltered('documentoPessoal')}
                onlyView={onlyView}
              />
              {/* {inputFormError &&
                !files.some(
                  (item) => item.descricao === 'documentoPessoal'
                ) && (
                  <span className="text-warning">
                    <small>{requiredFieldMessage}</small>
                  </span>
                )} */}
              <InputFile
                callBack={(files) => updateFiles(files, 'examesEbiopsia')}
                filesAccept={filesAccept}
                label="Exames e biópsia (tamanho máximo 10mb)"
                filesDefault={getDocsFiltered('examesEbiopsia')}
                onlyView={onlyView}
              />
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};
