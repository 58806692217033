import styled from 'styled-components';

export const IframeContainer = styled.div`
  position: relative;
  padding-bottom: 65.25%;
  height: 0;

  iframe {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
  }
`;
