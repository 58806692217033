import { Box, CloseButton, FormControl, FormLabel, Grid, GridItem, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import SelectAsync from 'react-select/async';
import { Button, Col, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { Loading } from 'components/Loading';
import { useApiOnMount, useUser } from 'hooks';
import { api } from 'services/api';
import { onlyNumber } from 'shared/util';
import { colors } from 'theme/colors';

import { InstType, OptionsFormAdmin } from '../../types';

type CriarAdministradorProps = {
  isNew: boolean;
  onClose: () => void;
  dataToEdit?: {
    cpf: string;
    email: string;
    nome: string;
    telefone: string;
    cargo?: string;
    departamento?: string;
    instituicoes: InstType[];
  };
  sgCliente: string;
  requestOnEditOrInsert: () => void;
};

type CreateAdminFormData = {
  cpf: string;
  nome: string;
  email: string;
  telefone: string;
  cargo: string;
  departamento: string;
};

const requiredFieldText = 'Campo obrigatório';

const getSchema = (isNew: boolean) =>
  Yup.object().shape({
    ...(isNew
      ? {

      }
      : {}),

    nome: Yup.string()
      .min(8, 'Informe nome e sobrenome')
      .required(requiredFieldText),
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required(requiredFieldText),
    telefone: Yup.string()
      .min(11, 'Informe um número válido')
      .required(requiredFieldText),
    cargo: Yup.string().required(requiredFieldText),
    departamento: Yup.string().required(requiredFieldText),
  });

export function ModalCriarAdministrador({
  isNew,
  onClose,
  dataToEdit,
  sgCliente,
  requestOnEditOrInsert,
}: CriarAdministradorProps) {
  const [open, setOpen] = useState(true);
  const [instsSelected, setInstsSelected] = useState<InstType[]>([]);
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [cpfError, setCpfError] = useState(true);
  const toast = useToast();

  const user = useUser();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = useForm<CreateAdminFormData>({
    resolver: yupResolver(getSchema(isNew)),
    defaultValues: dataToEdit,
  });

  const getParamsToRequest = useCallback(
    (method: string) => ({
      method,
      sgCliente,
    }),
    [sgCliente]
  );

  const { data: instList } = useApiOnMount<InstType[]>(
    'aswinstsaude',
    getParamsToRequest('_list')
  );

  const { data: grupoMenuList } = useApiOnMount<OptionsFormAdmin>(
    'aswformularioopcoes',
    getParamsToRequest('_optionsFormAdmin')
  );

  const filterInstList =
    instList &&
    instList.filter((inst) =>
      instsSelected.every((i) => i.instSaude !== inst.instSaude)
    );

  const selecionaInst = (inst: InstType) => {
    setInstsSelected((stateAntigo) => {
      if (stateAntigo.every((s) => s.instSaude !== inst.instSaude)) {
        return [...stateAntigo, inst];
      }
      return stateAntigo;
    });
  };

  const removeInstSelected = (inst: InstType) => {
    if (inst) {
      setInstsSelected((stateAntigo) => {
        return stateAntigo.filter((i) => i.instSaude !== inst.instSaude);
      });
    }
  };

  const handleChangeGroup = (id, value) => {
    let newInst = [];
    instsSelected.map((inst, key: number) => {
      if (key == id) {
        inst.grupo = value;
      }
      newInst.push(inst);
    });
    setInstsSelected(newInst);
  };

  const handleRegisterAdmin: SubmitHandler<CreateAdminFormData> = async (
    formData
  ) => {
    if (cpfError === false || isNew === false) {
      setLoading(true);
      formData.telefone = onlyNumber(formData.telefone);
      try {
        const { data } = await api.post('aswusuariosweb', {
          method: isNew ? '_insert' : '_update',
          sgCliente,
          tipo: 'A',
          ...formData,
          instituicoes: instsSelected.map((item) => {
            return {
              gmNome: item?.grupo?.value,
              instituicao: item?.sgInstSaude,
              label: item?.instSaude,
            };
          }),
        });

        setLoading(false);
        onCloseEditModal();

        MySwal.fire({
          title:
            data?.message ||
            (!!data?.success
              ? 'Cadastro efetuado com sucesso'
              : 'Não foi possível cadastar'),
          icon: !!data.success ? 'success' : 'warning',
        });
      } catch (err) {
        // console.log('Error ', err);
        MySwal.fire({
          title: isNew
            ? 'Houve um erro ao tentar cadastar'
            : 'Não foi possível editar',
          icon: 'error',
        });
      }

      requestOnEditOrInsert();
    }
  };

  const verificaCpf = async (value: string) => {
    const cpf = onlyNumber(value);

    if (isNew) {
      try {
        setLoading(true);
        const { data } = await api.post('aswusuariosweb', {
          method: '_isAdmin',
          cpf: value,
          sgCliente: user?.sgCliente,
        });
        console.log(data);
        if (data['message'] === 'Usuário não encontrado') {
          setValue('cpf', value);
          if (data.data) {
            setValue('nome', data?.data?.nmUsuario ? data?.data?.nmUsuario : '');
            setValue('email', data?.data?.emailUsuario ? data?.data?.emailUsuario : '');
            setValue('telefone', data?.data?.telefoneUsuario ? data?.data?.telefoneUsuario : '');
            setValue('cargo', data?.data?.usuarioCargo ? data?.data?.usuarioCargo : '');
            setValue('departamento', data?.data?.usuarioDepartamento ? data?.data?.usuarioDepartamento : '');
          }
          setLoading(false);
          setCpfError(false);
          return false;
        } else if (data['success'] === false) {
          setLoading(false);
          setCpfError(true);
          toast({
            title: data.message,
            status: 'warning',
          });
        } else {
          setLoading(false);
          setCpfError(true);
          toast({
            title: data.message,
            status: 'warning',
          });
          return false;
        }
      } finally {
        setLoading(false);
      }
    }


  };

  const onCloseEditModal = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  useEffect(() => {
    const loadDataToEdit = () => {
      reset({ ...dataToEdit });
      setValue('cpf', dataToEdit.cpf);
      setInstsSelected(dataToEdit.instituicoes);
    };
    if (!isNew && !!dataToEdit) {
      loadDataToEdit();
    }
  }, []);

  useEffect(() => {
    if (
      !isNew &&
      Array.isArray(dataToEdit?.instituicoes) &&
      dataToEdit?.instituicoes?.length > 0
    ) {
      const selectedInst = dataToEdit?.instituicoes.map((i) => {
        return {
          ...i,
          instSaude: i.instSaude,
          sgInstSaude: i.sgInstSaude,
          grupo: {
            label: i.grupoMenuDesc,
            value: i.grupoMenu,
          },
        };
      });
      setInstsSelected(selectedInst);
    }
  }, [dataToEdit, isNew]);

  return (
    <Modal
      isOpen={open}
      onClose={onCloseEditModal}
      motionPreset="slideInBottom"
      size={'4xl'}
    >
      <ModalOverlay />
      <ModalContent tabIndex={150}>
        <ModalHeader>
          <Grid templateColumns="repeat(5, 1fr)" gap={1}>
            <GridItem colStart={6} colEnd={6} h="10">
              <CloseButton size="sm" onClick={onCloseEditModal} />
            </GridItem>
          </Grid>
          <Heading fontSize={'md'} fontWeight={400}>
            {isNew ? 'Cadastrar novo administrador' : 'Editar administrador'}
          </Heading>
        </ModalHeader>
        <Box as="form" onSubmit={handleSubmit(handleRegisterAdmin)}>
          <ModalBody>
            <SimpleGrid columns={3} spacingX="15px" spacingY="15px">
              <FormControl
                style={
                  !isNew
                    ? {
                      pointerEvents: 'none',
                      cursor: 'not-allowed',
                    }
                    : {}
                }
              >
                <FormLabel>ID Utente*</FormLabel>
                {!isNew && <input type="hidden" {...register('cpf')} />}
                <Input

                  {...register('cpf', {
                    // onChange: (e) => verificaCpf(e.target.value),
                    onBlur: (e) => verificaCpf(e.target.value)
                  })}
                />

                <span className="text-warning">
                  <small>{errors.cpf?.message}</small>
                </span>
              </FormControl>
              <FormControl>
                <FormLabel>Nome do Administrador*</FormLabel>
                <Input
                  disabled={isNew ? cpfError : false}
                  id="cadastro-administradores-nome"
                  type="text"
                  {...register('nome')}
                />
                <span className="text-warning">
                  <small>{errors.nome?.message}</small>
                </span>
              </FormControl>
              <FormControl>
                <FormLabel>E-mail*</FormLabel>
                <Input
                  disabled={isNew ? cpfError : false}
                  id="cadastro-administradores-email"
                  type="email"
                  {...register('email')}
                />
                <span className="text-warning">
                  <small>{errors.email?.message}</small>
                </span>
              </FormControl>
              <FormControl>
                <FormLabel>Telefone*</FormLabel>
                <Input
                  disabled={isNew ? cpfError : false}
                  as={InputMask}
                  id="cadastro-administradores-telefone"
                  type="tel"
                  mask="(99) 99999-9999"
                  {...register('telefone')}
                />
                <span className="text-warning">
                  <small>{errors.telefone?.message}</small>
                </span>
              </FormControl>
              <FormControl>
                <FormLabel>Cargo*</FormLabel>
                <Input
                  disabled={isNew ? cpfError : false}
                  id="cadastro-administradores-cargo"
                  type="text"
                  {...register('cargo')}
                />
                <span className="text-warning">
                  <small>{errors.cargo?.message}</small>
                </span>
              </FormControl>
              <FormControl>
                <FormLabel>Departamento*</FormLabel>
                <Input
                  disabled={isNew ? cpfError : false}
                  id="cadastro-administradores-departamento"
                  type="text"
                  {...register('departamento')}
                />
                <span className="text-warning">
                  <small>{errors.departamento?.message}</small>
                </span>
              </FormControl>
            </SimpleGrid>
            <br />
            <br />
            <FormLabel>Instituições de Saúde*</FormLabel>
            <SimpleGrid columns={2} spacingX="15px" spacingY="15px">
              <FormControl>
                <div className="kn-unselected_status__status___">
                  Disponíveis
                </div>
                <div className={`kn-list__list___22Wuc`}>
                  {filterInstList &&
                    filterInstList.map((item, key) => (
                      <div
                        onClick={() => {
                          selecionaInst(item);
                        }}
                        key={key}
                        className="kn-item__item___kQ2Ll kn-item__selected___3tRL6"
                      >
                        <div className="kn-item_label__label___2fJ5p">
                          {item.instSaude} - {item.sgInstSaude}
                        </div>
                      </div>
                    ))}
                </div>
              </FormControl>
              <FormControl>
                <div className="kn-unselected_status__status___">
                  Selecionado*
                </div>
                <div className={`kn-list__list___22Wuc`}>
                  <>
                    <Col md="12">
                      {instsSelected &&
                        instsSelected.map((inst, key: number) => {
                          return (
                            <div key={key}>
                              <Row>
                                <div className="mb-1">
                                  <span
                                    style={{
                                      color: colors.blue,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {inst.instSaude}
                                    <Button
                                      onClick={() => {
                                        removeInstSelected(inst);
                                      }}
                                      className="btn-sm"
                                      color="warning"
                                      style={{ float: 'right' }}
                                    >
                                      X
                                    </Button>
                                  </span>
                                </div>
                                <Col md="12">
                                  <Label className="label-single-line">
                                    <span>Perfil / Grupo vinculado</span>
                                  </Label>
                                </Col>
                                <Col md="12">
                                  <SelectAsync
                                    value={inst.grupo}
                                    isMulti={false}
                                    placeholder={'Selecione'}
                                    className="css-select-control"
                                    onChange={(value) => {
                                      handleChangeGroup(key, value);
                                    }}
                                    defaultOptions={
                                      grupoMenuList?.grupoMenu || []
                                    }
                                    noOptionsMessage={() => 'Nada encontrado'}
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                    </Col>
                  </>
                </div>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" className="float-right" disabled={instsSelected.some((item) => !item.grupo?.value) || !instsSelected.length}>
              {isNew ? 'Cadastrar Adminstrador' : 'Salvar alterações'}
            </Button>
          </ModalFooter>
        </Box>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}
