import {
  Box,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { RenderModalSintomasGripais } from 'components/modal-sindrome-gripal';
import { SintomasType } from 'components/modal-sindrome-gripal/types';
import { useEffect, useState } from 'react';

type ModalSOAPHistoricoProps = {
  isOpen: boolean;
  onClose: () => void;
  content?: any;
};

type CIDItem = {
  agIdConsulta: string;
  cid10: string;
  descricao: string;
  dtInclusao: string;
  huDataHoraAgenda: string;
  secundario: number;
  usuarioCPF: string;
};

function ModalSOAPHistorico({
  isOpen,
  onClose,
  content,
}: ModalSOAPHistoricoProps) {
  const [primaryCID, setPrimaryCID] = useState('');
  const [primaryCIDId, setPrimaryCIDId] = useState('');
  const [secondaryCID, setSecondaryCID] = useState([]);
  const [sintomasGripaisData, setSintomasGripaisData] =
    useState<SintomasType>();
  const [sintomasGripais, setSintomasGripais] = useState(false);

  function handlePrimaryCID(): void {
    content?.listagemCID10?.filter((item: CIDItem) => {
      if (item.secundario === 0) {
        setPrimaryCID(item.descricao);
        setPrimaryCIDId(item.cid10);
      }
    });
  }

  function handleSecondaryCID(): void {
    const result = content?.listagemCID10?.filter((item: CIDItem) => {
      return item.secundario === 1;
    });
    setSecondaryCID(result);
  }

  useEffect(() => {
    handlePrimaryCID();
    handleSecondaryCID();
  }, [isOpen]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="tela-paciente">
            <SimpleGrid columns={2} gap={4} my={4}>
              <Box>
                <Text className="text-white">Queixa Principal</Text>
                <Textarea
                  className="bg-light"
                  defaultValue={content?.huQueixa}
                  disabled
                  _disabled={{
                    color: 'gray.600',
                  }}
                ></Textarea>
              </Box>
              <Box>
                <Text className="text-white">Notas Clínicas</Text>
                <Textarea
                  className="bg-light"
                  defaultValue={content?.huHistQueixa}
                  disabled
                  _disabled={{
                    color: 'gray.600',
                  }}
                ></Textarea>
              </Box>
              <Box>
                <div className="d-flex ">
                  <span className="col-md-3">
                    <p className="smaller-text mt-1 ">Pressão Arterial</p>
                    <input
                      disabled
                      value={content?.huTriagPressaoArterial}
                      className=" col-md-11 rounded bg-light"
                    />
                  </span>
                  <span className="col-md-3">
                    <p className="smaller-text mt-1 ">Freq. Cardíaca</p>
                    <input
                      value={content?.hrTriagFreqCardiaca}
                      disabled
                      className="col-md-11 rounded bg-light"
                    />
                  </span>

                  <span>
                    <p className="smaller-text mt-1">Freq. Respiratória</p>
                    <input
                      value={content?.huTriagFreqRespiratoria}
                      disabled
                      className="col-md-11 rounded bg-light"
                    />
                  </span>
                  <span>
                    <p className="smaller-text mt-1">Saturação</p>
                    <input
                      value={content?.huTriagSaturacao}
                      disabled
                      className="col-md-11 rounded bg-light"
                    />
                  </span>
                </div>
                <Text className="text-white">CID10-2019 - Principal</Text>
                <Input
                  className="bg-light"
                  defaultValue={primaryCIDId + ' - ' + primaryCID}
                  disabled
                  _disabled={{
                    color: 'gray.600',
                  }}
                />
              </Box>
              <Box>
                <div className="d-flex ">
                  <span className="col-md-3">
                    <p className="smaller-text mt-1">Temperatura</p>
                    <input
                      disabled
                      value={content?.huTriagTemperatura}
                      className="col-md-11 rounded bg-light"
                    />
                  </span>

                  <span className="col-md-3">
                    <p className="smaller-text mt-1">Peso</p>
                    <input
                      disabled
                      value={content?.huTriagPeso}
                      className="col-md-11 rounded bg-light"
                    />
                  </span>
                  <span className="col-md-3">
                    <p className="smaller-text mt-1">Altura</p>
                    <input
                      disabled
                      value={content?.huTriagAltura}
                      className=" col-md-11 rounded bg-light"
                    />
                  </span>
                  <span
                    className="col-md-3"
                    onClick={() => {
                      setSintomasGripais(true);
                      onClose();
                    }}
                  >
                    <p className="smaller-text mt-1">Sintomas Gripais</p>
                    <select disabled className="col-md-11 rounded small">
                      <option value="" disabled selected className="small">
                        Visualizar
                      </option>
                    </select>
                  </span>
                </div>
                <Text className="text-white">CID10-2019 - Secundário</Text>
                <Stack>
                  {secondaryCID?.map((item, index) => {
                    return (
                      <Input
                        className="bg-light"
                        key={index}
                        defaultValue={item.descricao}
                        disabled
                        _disabled={{
                          color: 'gray.600',
                        }}
                      />
                    );
                  })}
                </Stack>
              </Box>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
      {sintomasGripais && (
        <RenderModalSintomasGripais
          isEditable={false}
          dados={content}
          close={(value: boolean) => {
            setSintomasGripais(value);
          }}
          formSintomasGripais={(value: SintomasType) => {
            setSintomasGripaisData(value);
          }}
        />
      )}
    </div>
  );
}

export { ModalSOAPHistorico };
