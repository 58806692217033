import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, ModalFooter, Row } from 'reactstrap';
import { colors } from 'theme/colors';

import { ModalRecuperarSenha } from './components/modal-recuperar-senha';

export interface ILoginFormProps {
  loginError: boolean;
  handleLogin: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => void;
  handleClose: () => void;
}

function LoginForm(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!username || !password) {
      return;
    }
    props.handleLogin(username, password);
  };
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <div id="login-page">
      <form onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col md="12">
              <label>Usuário</label>
              <input
                name="username"
                className="form-control"
                placeholder={'Seu usuário'}
                required
                value={username.replace(/\D/g, '')}
                onChange={(e) => setUsername(e.target.value)}
                style={{ borderWidth: '1px' }}
                autoFocus
                data-cy="username"
              />
              <label>Senha</label>
              <input
                name="password"
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={'Sua senha'}
                required
                style={{ borderWidth: '1px' }}
                data-cy="password"
              />
            </Col>
          </Row>
        </div>
        <ModalFooter className="footer-login p-0 d-flex flex-row align-items-center justify-content-between">
          <div>
            <Link
              to={'#'}
              className=""
              style={{ color: colors.blue }}
              onClick={() => onOpen()}
            >
              Esqueci minha senha
            </Link>
          </div>
          <Button
            type="submit"
            color="primary"
            onClick={handleSubmit}
            disabled={!username || !password}
          >
            Entrar
          </Button>
        </ModalFooter>
      </form>
      <ModalRecuperarSenha isOpen={isOpen} onClose={onClose} content={null} />
    </div>
  );
}

export default LoginForm;
