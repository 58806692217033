import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

export interface ILiberacaoAgendamentoProps {
  baseState?: any;
  baseProps?: any;
  setState?: any;
  entityBase?: any;
  disabled?: boolean;
}

const DataFiltros = (props: any) => {
  const startDate = moment(props.startDate).startOf('week').isoWeekday(7).add(0, 'days').toDate();
  const endDate = moment(props.startDate).startOf('week').isoWeekday(7).add(6, 'days').toDate();

  return (
    <div id={'gestao-agenda-data-filtro-container'}>
      <i className="fa fa-calendar" />
      <DatePicker
        id={'gestao-agenda-data-filtro'}
        placeholderText={moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')}
        onChange={dates => props.setStartDate(dates)}
        startDate={startDate}
        endDate={endDate}
        disabled={props.disabled}
        minDate={new Date()}
      />
    </div>
  );
};

export default DataFiltros;
