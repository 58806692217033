import { FormEpisodioSMS } from 'components/formEpisodioSMS';
import { FileType } from 'components/formUsuarioSMS/types';
import { useAlert, useApiRequest, useUser } from 'hooks';
import { useRef, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { IEpisodioConsulta } from 'types/episodio';
import ModalAprovarEpisodio from './components/modal-aprovar-episodio';
import ModalPousoAlegre from './components/modal-pouso-alegre';
import { BotaoAprovar, ContainerBotoes } from './styles';

interface IModalDetalheEpisodio {
  showModalForm: boolean;
  identificadorEpisodio: string;
  cpf: string;
  toggle: () => void;
  refreshDados: () => void;
  action?: string;
}

function ModalDetalheEpisodio({
  showModalForm,
  toggle,
  identificadorEpisodio,
  cpf,
  refreshDados,
  action
}: IModalDetalheEpisodio) {
  const user = useUser();
  const grupo = user?.grupoEscolhido?.grupo;
  const alert = useAlert();
  const [statusEpisodio, setStatusEpisodio] = useState<string>('AAA');
  const [aprovar, setAprovar] = useState<boolean>(false);
  const [showModalPousoAlegre, setShowModalPousoAlegre] =
    useState<boolean>(false);
  const [showModalAprovarEpisodio, setShowModalAprovarEpisodio] =
    useState<boolean>(false);

  const [filesState, setFilesState] = useState<FileType[]>([]);
  const newFilesState = filesState.filter((f) => !f.s3Caminho);

  const episodioApi = useApiRequest<IEpisodioConsulta>(
    'aswepisodiosms',
    {} as IEpisodioConsulta
  );

  const toggleModalPousoAlegre = () => {
    setShowModalPousoAlegre(!showModalPousoAlegre);
  };

  const toggleModalAprovarEpisodio = () => {
    setShowModalAprovarEpisodio(!showModalAprovarEpisodio);
  };

  const buttonEncaminharMedicoHandler = async () => {
    const result = await episodioApi.submit({
      method: 'encaminharMedico',
      epIdentificador: identificadorEpisodio,
    });
    if (result?.statusCode === 200) {
      alert.success({ title: 'Encaminhado com sucesso' });
      toggle();
    } else {
      alert.warning({ title: 'Ocorreu um erro' });
    }
  };

  const buttonSalvarDocumentosHandler = async () => {
    const result = await episodioApi.submit({
      method: 'addArquivosEpisodio',
      epIdentificador: identificadorEpisodio,
      files: newFilesState,
    });
    if (result?.success) {
      if (result.data.some((a) => a.success === false)) {
        alert.warning({ title: 'Ocorreu um erro' });
        return;
      }
      alert.success({ title: 'Documentos adicionados com sucesso' });
      toggle();
    } else {
      alert.warning({ title: 'Ocorreu um erro' });
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        centered
        size={'xl'}
        isOpen={showModalForm}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Episódio</ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md={11}>
              <FormEpisodioSMS
                emmitFiles={setFilesState}
                cpf={cpf}
                identificador={identificadorEpisodio}
                setStatus={setStatusEpisodio}
                refreshDados={refreshDados}
                action={action}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <ContainerBotoes>
            {grupo === 'sms-pa' && (
              <>
                <Button
                  disabled={statusEpisodio === '' || statusEpisodio !== 'P'}
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={toggleModalPousoAlegre}
                >
                  Solicitar Novos Documentos
                </Button>

                <Button
                  disabled={statusEpisodio === '' || statusEpisodio !== 'P'}
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={buttonEncaminharMedicoHandler}
                >
                  Encaminhar Médico
                </Button>
              </>
            )}
            {(grupo === 'sms-pa' ||
              (grupo === 'sms-admin' && identificadorEpisodio)) &&
              newFilesState.length > 0 && (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={buttonSalvarDocumentosHandler}
                >
                  Salvar Novos Documentos
                </Button>
              )}
            {grupo === 'medico-cmo' && (
              <>
                <BotaoAprovar
                  aprovar={true}
                  disabled={
                    !statusEpisodio || !['P', 'R'].includes(statusEpisodio)
                  }
                  type="button"
                  onClick={() => {
                    setAprovar(true);
                    toggleModalAprovarEpisodio();
                  }}
                >
                  <i className="fa fa-thumbs-up" />
                  Aprovar
                </BotaoAprovar>

                <BotaoAprovar
                  aprovar={false}
                  disabled={statusEpisodio === '' || statusEpisodio !== 'P'}
                  type="button"
                  onClick={() => {
                    setAprovar(false);
                    toggleModalAprovarEpisodio();
                  }}
                >
                  <i className="fa fa-thumbs-down" />
                  Reprovar
                </BotaoAprovar>
              </>
            )}
          </ContainerBotoes>
        </ModalFooter>
      </Modal>
      <ModalPousoAlegre
        showModalForm={showModalPousoAlegre}
        toggle={toggleModalPousoAlegre}
        epIdentificador={identificadorEpisodio}
      />
      <ModalAprovarEpisodio
        showModalForm={showModalAprovarEpisodio}
        toggle={toggleModalAprovarEpisodio}
        toggleModalDetalhes={toggle}
        aprovar={aprovar}
        epIdentificador={identificadorEpisodio}
        refreshDados={refreshDados}
      />
    </>
  );
}

export default ModalDetalheEpisodio;
