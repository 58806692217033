// @ts-nocheck

import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import 'react-quill/dist/quill.snow.css';
import { Loading } from 'components/Loading';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import { api } from 'services/api';
// import { pt } from 'date-fns/esm/locale';
// registerLocale('pt', pt);
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AUTH_TOKEN_KEY, BASE_API_VERSION_PATH } from 'shared/util';
import {
  AppProps,
  GetServerSideProps,
  jsonParse,
  nextCookie,
  useRouter,
} from '../../../components/util/entity-utils';
import {
  Link,
  Translate,
  useTranslation,
} from '../../../components/util/translate-component';
import RotaLinkComponent from '../components/RotaLinkComponent';

const MySwal = withReactContent(Swal);

export interface IMenuRotasProps extends AppProps {
  nome?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  menuRotasList?: any;
  setMenuRotasList?: Function;
  menuRotasEntity?: any;
  setMenuRotasEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getMenuRotasState?: Function;
  returnFunction?: Function;
  perfilMenuRotas?: any;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'aswmenurotas';

export const getEntitiesFetch = async (
  context: IMenuRotasProps,
  nome: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const res = await api.post(apiUrl, {
    method: '_list',
    nome,
  });
  const _json = await res.data;
  res.json = async () =>
    new Promise((resolve) =>
      resolve(
        _json['data']
          ? [
            ..._json['data'].map((v: any) => ({
              ...v,
              id: Math.floor((1 + Math.random()) * 0x10000),
            })),
          ]
          : []
      )
    );
  context.setLoad(false);
  return res;
};

const getEntityFetch: any = async (
  context: IMenuRotasProps,
  entity: any,
  selectFields = ''
) => {
  const res = await api.post(apiUrl, {
    method: '_find',
    nome: entity.nome,
  });

  const _json = await res.data;
  const json = { ..._json['data'], id: entity.id };
  context.setMenuRotasEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IMenuRotasProps,
  entity: any,
  listFiltersPage: any
) => {
  const res = await api.post(apiUrl, {
    method: '_insert',
    ...entity,
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IMenuRotasProps,
  entity: any,
  listFiltersPage: any
) => {
  const res = await api.post(apiUrl, {
    method: '_update',
    ...entity,
  });
  const json = await res.data;
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IMenuRotasProps,
  entity: any,
  listFiltersPage: any
) => {
  const res = await fetch(apiUrl, {
    method: '_delete',
    nome: entity.nome,
  });
  const json = await res.data;
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (context: IMenuRotasProps, offset = null) => {
  return (
    '' +
    (context.state.nome ? 'nome=' + context.state.nome + '&' : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
      encodeURI(JSON.stringify(context.state.extraFilters)) +
      '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (context: IMenuRotasProps, _fieldsBase: any) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const { nome, extraFilters, activePage, itemsPerPage, sort, order } =
    context.state;

  const res = await context.getEntitiesFetch(
    nome,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${_fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: 0,
  });

  const json = await res.json();
  context.setMenuRotasList(json);
};

const sortEntities = (context: IMenuRotasProps, _newState = undefined) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IMenuRotasProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (context: IMenuRotasProps, activePage: any) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (context: IMenuRotasProps, _menuRotasEntity: any) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="menuRotas.delete.question"
        interpolate={{ id: _menuRotasEntity.id }}
      >
        Are you sure you want to delete this MenuRotas?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ffc107',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="menuRotas.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="menuRotas.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_menuRotasEntity, { reloadList: false });
      context.getAllEntities();
      MySwal.fire({
        title: 'Confirmado !',
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IMenuRotasProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = ['nome', 'nomeTela'];

  if (errors.length === 0) {
    const _entity = {
      ...context.menuRotasEntity,
      // ...values,

      id: context.state.idFormValue,
      nome: context.state.nomeFormValue,
      nomeTela: context.state.nomeTelaFormValue,
      rota: context.state.rotaFormValue,
    };

    const entity = _entity;

    const { nome, extraFilters, activePage, itemsPerPage, sort, order } =
      context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (context: IMenuRotasProps, isNew: any) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate t={context.t} contentKey="menuRotas.home.createLabel">
              Create a MenuRotas
            </Translate>
          ) : (
            <Translate t={context.t} contentKey="menuRotas.home.editLabel">
              Edit a MenuRotas
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IMenuRotasProps,
  _menuRotasEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="menu-rotas-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-menu-rotas-first-column">
                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="nomeLabel"
                          for="menu-rotas-nome"
                        >
                          <Translate t={context.t} contentKey="menuRotas.nome">
                            Nome
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          disabled={!isNew}
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              nomeFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.nomeFormValue
                              ? context.state.nomeFormValue
                              : ''
                          }
                          id="menu-rotas-nome"
                          type="text"
                          name="nome"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="6">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="nomeTelaLabel"
                          for="menu-rotas-nomeTela"
                        >
                          <Translate
                            t={context.t}
                            contentKey="menuRotas.nomeTela"
                          >
                            Tela
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              nomeTelaFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.nomeTelaFormValue
                              ? context.state.nomeTelaFormValue
                              : ''
                          }
                          id="menu-rotas-nomeTela"
                          type="text"
                          name="nomeTela"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="12">
                        <Label
                          className="mt-4 label-single-line"
                          id="rotaLabel"
                          for="menu-rotas-rota"
                        >
                          <Translate t={context.t} contentKey="menuRotas.rota">
                            Rota
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="12">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              rotaFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.rotaFormValue
                              ? context.state.rotaFormValue
                              : ''
                          }
                          id="menu-rotas-rota"
                          type="text"
                          name="rota"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IMenuRotasProps) => {
  return (
    <Modal
      className="menu-rotas-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="menuRotas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IMenuRotasProps) => {
  return context.menuRotasEntity &&
    (context.menuRotasEntity.id || context.menuRotasEntity._id) ? (
    <Modal
      className="menu-rotas-update-modal"
      size={'lg'}
      isOpen={
        context.state.showModalEdit === context.menuRotasEntity.id ||
        context.state.showModalEdit === context.menuRotasEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.menuRotasEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="menuRotas.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (context: IMenuRotasProps, v: any) => {
  const p: any = null;

  const perfilMenuRotasEntity = v.perfilMenuRotas
    ? v.perfilMenuRotas
      .filter((p1: any) => p1)
      .map((p1: any) => ({
        ...p1,
        value: p1.id,
        label: p1['id'] ? p1.id : '',
      }))
    : v.perfilMenuRotas;

  return {
    idFormValue: v.id, // id,
    nomeFormValue: v.nome, // nome,
    nomeTelaFormValue: v.nomeTela, // nomeTela,
    rotaFormValue: v.rota, // rota,
    perfilMenuRotasFormValue: perfilMenuRotasEntity,
  };
};

const openUpdateModal = (context: IMenuRotasProps, v: any, edit = true) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IMenuRotasProps) => {
  const newState = {
    ...context.state,
    nome: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IMenuRotasProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IMenuRotasProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'nome' ? (
            <Col md="12" className="col-filter-menu-rotas-nome">
              <Row className="mr-1 mt-1">
                <Label id="nomeLabel" for="menu-rotas-nome">
                  <Translate t={context.t} contentKey="menuRotas.nome">
                    Nome
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="nome"
                  id="menu-rotas-nome"
                  value={context.state.nome}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      nome: evt.target.value,
                    })
                  }
                  placeholder={'Buscar'}
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IMenuRotasProps) => {
  return (
    <>
      {context.load && <Loading />}
      {context.menuRotasList &&
        context.menuRotasList.filter &&
        context.menuRotasList.filter(
          (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
        ).length > 0 ? (
        <div id="menu-rotas-table-list" className="table-list">
          <Table
            id="menu-rotas-table-list"
            responsive
            aria-describedby="menu-rotas-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th className="hand" onClick={context.sortFunction('nome')}>
                  <Translate t={context.t} contentKey="menuRotas.nome" />
                  <i
                    className={
                      context.state.sort === 'nome'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th className="hand" onClick={context.sortFunction('nomeTela')}>
                  <Translate t={context.t} contentKey="menuRotas.nomeTela" />
                  <i
                    className={
                      context.state.sort === 'nomeTela'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th id="RotaLinkComponent-cell-header">
                  <Translate
                    t={context.t}
                    contentKey="menuRotas.RotaLinkComponent"
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.menuRotasList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((menuRotas: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="nome-cell">
                      <b className="visible-xs"> Nome </b>

                      {menuRotas.nome}
                    </td>

                    <td id="nomeTela-cell">
                      <b className="visible-xs"> Tela </b>

                      {menuRotas.nomeTela}
                    </td>
                    <td id="RotaLinkComponent-cell">
                      {' '}
                      <RotaLinkComponent
                        baseProps={context.props}
                        setState={(_state: any) => context.setState(_state)}
                        baseState={context.state}
                        entityBase={menuRotas}
                      />
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() => context.openUpdateModal(menuRotas)}
                          >
                            <i className="fa fa-pencil-alt" />
                          </a>
                        </Link>
                        <Link href={'#'}>
                          <a
                            className={'btn btn-primary btn-sm'}
                            onClick={() => context.deleteEntityModal(menuRotas)}
                          >
                            <i size="2x" className="fa fa-trash" />
                          </a>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.menuRotasList && context.menuRotasList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate t={context.t} contentKey="menuRotas.home.notFound">
            No Rotas found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IMenuRotasProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IMenuRotasProps) => {
  return (
    <div>
      <h4 id="menu-rotas-heading" data-cy="MenuRotasHeading">
        <Translate t={context.t} contentKey="menuRotas.home.title">
          Rotas
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                nomeFormValue: null,
                nomeTelaFormValue: null,
                rotaFormValue: null,

                perfilMenuRotasStartSelectOptions: undefined,
                perfilMenuRotasFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="menuRotas.btnNewItem">
              Cadastro de Rota
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => { }}
        {context.renderTable ? context.renderTable() : () => { }}
        {context.renderNewModal ? context.renderNewModal() : () => { }}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => { }}
      </div>
    </div>
  );
};

export const getMenuRotasState = (context: IMenuRotasProps, location: any) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const nome = _urlBase.searchParams.get('nome') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    nome,
  };
};

export const MenuRotas = (props: IMenuRotasProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [menuRotasList, setMenuRotasList] = useState(
    props.menuRotasList ? props.menuRotasList : ([] as any)
  );
  const [menuRotasEntity, setMenuRotasEntity] = useState(
    props.menuRotasEntity ? props.menuRotasEntity : ({} as any)
  );
  const [load, setLoad] = useState(true);

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    nome: props.nome ? props.nome : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    menuRotasList,
    setMenuRotasList,
    menuRotasEntity,
    setMenuRotasEntity,
    load,
    setLoad,
    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
        props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
        deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_menuRotasEntity: any, isNew: any) =>
        props['renderBodyUpdate'](context, _menuRotasEntity, isNew)
      : (_menuRotasEntity: any, isNew: any) =>
        renderBodyUpdate(context, _menuRotasEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: (
      nome: any,
      extraFilters: any,
      page: any,
      size: any,
      sort: any,
      selectFields = '',
      authorizationBearer = ''
    ) =>
      getEntitiesFetch(
        context,
        nome,
        extraFilters,
        page,
        size,
        sort,
        selectFields,
        authorizationBearer
      ),

    createEntityFetch: (entity: any, listFiltersPage: any) =>
      createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: (entity: any, listFiltersPage: any) =>
      updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: (id: any, listFiltersPage: any) =>
      deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: (id) => getEntityFetch(context, id),

    getMenuRotasState: props['getMenuRotasState']
      ? (location) => props['getMenuRotasState'](context, location)
      : (location) => getMenuRotasState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getMenuRotasState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.nome,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      menuRotasList: await res.json(),
    },
  };
};

export default MenuRotas;
