import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  Switch,
  useDisclosure
} from '@chakra-ui/react';
import { ConfirmarExclusaoModal } from 'components/confirmar-exclusao';
import { Select } from 'components/crud/Select';
import { useApiRequest, useUser } from 'hooks';
import { useEffect, useState } from 'react';
import { BsSearch, BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { Link as ReactLink, useHistory } from 'react-router-dom';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import { Input } from '../../../components/crud/Input';
import { Table } from '../../../components/crud/Table';
import {
  ActionComponents,
  HandleActionProps,
  TableParamsProps
} from '../../../components/crud/Table/types';
import { ICampanhaDTO } from '../gestao-campanhas/dtos/IGestaoCampanha.dto';
import { ModalCrud } from './components/modal-crud';
import { IQuestionarioDTO } from './types';

const GestaoQuestionarios = () => {
  const [showDeletarModal, setShowDeletarModal] = useState<boolean>(false);
  const user = useUser();
  const [instituicao, setInstituicao] = useState<string>();
  const [campanha, setCampanha] = useState<string>();
  const [campanhaList, setCampanhaList] = useState<ICampanhaDTO[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [originParams, setOriginParams] = useState(null);
  const history = useHistory();
  const [acoes, setAcoes] = useState([]);
  const MySwal = withReactContent(Swal);
  const dataQuizHook = useApiRequest<IQuestionarioDTO[]>('aswquestionario', []);
  const [inputFilter, setInputFilter] = useState('');
  const [qsId, setQsId] = useState<string>('');

  const tableParams: TableParamsProps[] = [
    {
      header: 'Questionários',
      key: 'qsNome',
    },
    {
      header: 'Qtd. perguntas',
      key: 'qsQtdPerguntas',
    },
    {
      header: 'Visibilidade',
      key: 'qsVisualizacao',
    },
    {
      header: 'Data início',
      key: 'qsDataInicio',
    },
    {
      header: 'Data fim',
      key: 'qsDataFim',
    },
  ];

  const actionComponents: ActionComponents[] = [
    {
      component: Switch,
      type: 'switch',
      action: 'switch-ativar',
      header: 'Ativo',
      props: { isReadOnly: true },
    },
    {
      component: RiQuestionnaireLine,
      type: 'icon',
      action: 'icon-perguntas',
      header: 'Perguntas',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: FiEdit,
      type: 'icon',
      action: 'icon-edit',
      header: 'Editar',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: BsTrash,
      type: 'icon',
      action: 'icon-remover-questionario',
      header: 'Remover',
      props: {
        size: 25,
        color: colors.gray_2,
      },
    },
  ];

  const handlerAction = async (props: HandleActionProps<IQuestionarioDTO>) => {
    if (props.action === 'switch-ativar') {
      await api
        .post('aswquestionario', {
          method: 'updateQuiz',
          cliente: user?.sgCliente,
          qsNome: props.dataLn.qsNome,
          qcID: props.dataLn.qcID,
          qsID: props.dataLn.qsID,
          qsVisualizacao: String(props.dataLn.qsVisualizacao),
          qsQtdPerguntas: props.dataLn.qsQtdPerguntas,
          qsDescricao: props.dataLn.qsDescricao,
          qsDataInicio: props.dataLn.qsDataInicio,
          qsDataFim: props.dataLn.qsDataFim,
          qsAtivo: props.value == true ? String(1) : String(0),
        })
        .then(() => {
          listDataQuiz(campanha);
        });
    }

    if (props.action === 'icon-edit') {
      setOriginParams(props.dataLn);
      onOpen();
    }

    if (props.action === 'icon-perguntas') {
      history.push(
        `/gestao-perguntas/${props.dataLn.qsID}?slugQuestao=${props.dataLn.qsNome}`
      );
    }

    if (props.action === 'icon-remover-questionario') {
      setQsId(props.dataLn.qsID);
      setShowDeletarModal(true);
    }
  };

  const ListCampanha = async () => {
    api
      .post('aswquestionario', {
        method: 'listCampaigns',
        sgCliente: user.sgCliente,
        sgInstSaude: instituicao,
      })
      .then(({ data }) => {
        let dataDados = [];
        data.data.map((e) => {
          acoes.map((a) => {
            if (a.value == e.qaID) {
              dataDados.push({
                acao: a.option,
                ...e,
              });
            }
          });
        });
        setCampanhaList(data.data);
        setIsLoading(false);
      });
  };

  const filterQuestoes =
    inputFilter.length > 0
      ? dataQuizHook.state?.filter((questionario) =>
        questionario.qsNome?.includes(inputFilter)
      )
      : dataQuizHook.state;

  const deleteQuiz = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'deleteQuiz',
      qsID: qsId,
    });
    if (data.success == true) {
      MySwal.fire({
        title: data?.message || 'Apagado com sucesso!',
        icon: 'success',
      });
      listDataQuiz(campanha);
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar apagar!',
        icon: 'error',
      });
    }
  };

  const tipoVisibilidade = (sigla: string) => {
    switch (sigla) {
      case 'W':
        return 'Web';
      case 'A':
        return 'App';
      case 'T':
        return 'Tablet';
      default:
        return '-';
    }
  };
  const listDataQuiz = async (qcid: string) => {
    setIsLoading(true);
    if (qcid) {
      await dataQuizHook.request({
        method: 'listQuizzes',
        qcID: qcid,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ListCampanha();
  }, [instituicao]);

  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <ModalCrud
        isOpen={isOpen}
        onClose={onClose}
        configParams={{ instituicao }}
        originParams={originParams}
        handlerChange={() => listDataQuiz(campanha)}
        qcid={campanha}
      />
      <Breadcrumb spacing="8px" separator={<IoIosArrowForward />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={ReactLink} to="/gestao-questionario">
            Questionários
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider color={colors.gray_blue} />
      <Flex gap="2rem" justifyContent="space-between" flexDirection="row">
        <Heading as="h3" size="md">
          Questionário:
        </Heading>
        {!!instituicao && !!campanha && (
          <Button
            variant="outline"
            border="1px"
            color={colors.blue}
            borderColor={colors.blue}
            _hover={{ bg: colors.blue, color: '#fff' }}
            _active={{ bg: colors.textBlue }}
            type="submit"
            onClick={() => {
              setOriginParams(null);
              onOpen();
            }}
          >
            Cadastrar questionário
          </Button>
        )}
      </Flex>
      <Flex gap="1rem" justifyContent="space-between" flexDirection="column">
        <Flex gap="1rem">
          <Select
            data={user.institucoes?.map((param) => ({
              value: param.institucao,
              option: param.institucaoNome,
            }))}
            placeholder="Selecione a instituição de saúde"
            handlerChange={(value) => {
              setInstituicao(value);
              setCampanha('');
              dataQuizHook.setState([]);
            }}
          />
          <Select
            value={campanha}
            data={campanhaList?.map((item) => ({
              value: item.qcID,
              option: item.qcNome,
            }))}
            placeholder="Selecione a campanha de satisfação"
            handlerChange={(value) => {
              !!instituicao.length && (setCampanha(value), listDataQuiz(value));
            }}
          />
        </Flex>
        <Input
          icon={{ icon: BsSearch, size: 20, color: colors.blue }}
          queryDeelay={1000}
          placeholder="Digite sua pesquisa aqui"
          handlerChange={(e) => setInputFilter(e)}
        />
      </Flex>
      <Table
        isLoading={isLoading}
        data={
          filterQuestoes.map((e) => {
            return {
              ...e,
              qsVisualizacao: tipoVisibilidade(e.qsVisualizacao),
            };
          }) ?? []
        }
        tableParams={tableParams}
        actionComponents={actionComponents}
        handlerAction={handlerAction}
      />

      {showDeletarModal && (
        <ConfirmarExclusaoModal
          close={(value: boolean) => {
            setShowDeletarModal(value);
          }}
          confirmar={() => {
            deleteQuiz();
          }}
        />
      )}
    </Flex>
  );
};

export { GestaoQuestionarios };

