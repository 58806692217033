import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IModalCrudDTO } from 'components/crud/dtos/IModalDto';
import { Input } from 'components/crud/Input';
import { Select } from 'components/crud/Select';
import { useUser } from 'hooks';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import * as Yup from 'yup';
import { ICampanhaDTO, IConfigParamsDTO } from '../../dtos/IGestaoCampanha.dto';

function ModalCrud({
  isOpen,
  onClose,
  instituicao,
  originParams,
  handlerChange,
}: IModalCrudDTO<ICampanhaDTO, IConfigParamsDTO>) {
  const user = useUser();
  const MySwal = withReactContent(Swal);
  const [acoes, setAcoes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [obrigatorio, setObrigatorio] = useState<boolean>(false);
  const [ativo, setAtivo] = useState<boolean>(false);

  const requiredFieldText = 'Campo obrigatório*';
  const schema = Yup.object().shape({
    qcNome: Yup.string().required(requiredFieldText),
    qcDescricao: Yup.string().required(requiredFieldText),
    qaID: Yup.number().required(requiredFieldText),
    qtID: Yup.string().required(requiredFieldText),
    qcRespQuestObrigatoria: Yup.boolean(),
    qcAtiva: Yup.boolean(),
  });
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICampanhaDTO>({
    resolver: yupResolver(schema),
  });

  const buscarTipos = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'listAnswerTypes',
    });

    let tipolist = [];
    data.data.map((element) => {
      tipolist.push({
        value: element.qtID,
        option: element.qtDescricao,
      });
    });
    setTipos(tipolist);
  };

  const buscarAcoes = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'listQuizActions',
    });

    let acoeslist = [];
    data.data.map((element) => {
      acoeslist.push({
        value: element.qaID,
        option: element.qaDescricao,
      });
    });
    setAcoes(acoeslist);
  };
  const cleanFilds = () => {
    setValue('qcNome', '');
    setValue('qcDescricao', '');
    setValue('qaID', '');
    setValue('qtID', '');
    setValue('qcRespQuestObrigatoria', false);
    setValue('qcAtiva', false);
    setAtivo(false);
  };
  useEffect(() => {
    buscarAcoes();
    buscarTipos();
    if (originParams === null) {
      cleanFilds();
    } else {
      setValue('qcNome', originParams.qcNome);
      setValue('qcDescricao', originParams.qcDescricao);
      setValue('qaID', originParams.qaID);
      setValue('qtID', originParams.qtID);
      setValue(
        'qcRespQuestObrigatoria',
        originParams.qcRespQuestObrigatoria == 1 ? true : false
      );
      setValue('qcAtiva', originParams.qcAtiva == 1 ? true : false);
      setAtivo(originParams.qcAtiva == 1 ? true : false);
    }
  }, [originParams]);

  const handleFormSubmit = async (params: ICampanhaDTO) => {
    if (originParams) {
      const { data } = await api.post('aswquestionario', {
        method: 'updateCampaing',
        qcID: originParams.qcID,
        qcNome: params.qcNome,
        qcDescricao: params.qcDescricao,
        qcRespQuestObrigatoria: params.qcRespQuestObrigatoria == true ? 1 : 0,
        qaID: params.qaID,
        qtID: params.qtID,
        qcAtiva: params.qcAtiva == true ? 1 : 0,
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        MySwal.fire({
          title: data?.message || 'Editado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar editar!',
          icon: 'error',
        });
      }
    } else {
      const { data } = await api.post('aswquestionario', {
        method: 'createCampaing',
        sgCliente: user?.sgCliente,
        sgInstSaude: instituicao,
        qcNome: params.qcNome,
        qcDescricao: params.qcDescricao,
        qcRespQuestObrigatoria: params.qcRespQuestObrigatoria == true ? 1 : 0,
        qaID: params.qaID,
        qtID: params.qtID,
        qcAtiva: params.qcAtiva == true ? 1 : 0,
      });

      if (data.success == true) {
        cleanFilds();
        handlerChange();
        onClose();
        MySwal.fire({
          title: data?.message || 'Criado com sucesso!',
          icon: 'success',
        });
      } else {
        MySwal.fire({
          title: data?.message || 'Houve um erro ao tentar criar!',
          icon: 'error',
        });
      }
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>
            {originParams ? 'Editar' : 'Criar'} campanha
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={1} gap={4} my={1}>
              <Box experimental_spaceY={2}>
                <Text>Nome</Text>
                <Input
                  type="text"
                  name="qcNome"
                  placeholder="Nome"
                  {...register('qcNome', {
                    /**/
                  })}
                />
                {errors.qcNome?.message && (
                  <Text color={colors.orange}>{errors.qcNome?.message}</Text>
                )}
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Descrição</Text>
                <Textarea
                  name="qcDescricao"
                  placeholder="Descrição"
                  {...register('qcDescricao', {
                    /**/
                  })}
                />
                {errors.qcDescricao?.message && (
                  <Text color={colors.orange}>
                    {errors.qcDescricao?.message}
                  </Text>
                )}
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} gap={4} my={4}>
              <Box experimental_spaceY={2}>
                <Text>Ponto de ação</Text>
                <Select
                  data={acoes}
                  placeholder="Selecione o ponto de ação"
                  {...register('qaID', {})}
                />
                {errors.qaID?.message && (
                  <Text color={colors.orange}>{errors.qaID?.message}</Text>
                )}
              </Box>
              <Box experimental_spaceY={2}>
                <Text>Tipo de resposta</Text>
                <Select
                  data={tipos}
                  placeholder="Selecione o tipo de resposta"
                  {...register('qtID', {
                    /**/
                  })}
                />
                {errors.qtID?.message && (
                  <Text color={colors.orange}>{errors.qtID?.message}</Text>
                )}
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={1} gap={4} my={4}>
              <Box experimental_spaceY={4} mt={4}>
                <Stack>
                  <Controller
                    name="qcRespQuestObrigatoria"
                    control={control}
                    render={({ field: { ref, value, ...rest } }) => {
                      return (
                        <CheckboxGroup
                          {...rest}
                          colorScheme="blue"
                          defaultValue={value}
                        >
                          <Stack spacing={[1, 5]} direction={['column', 'row']}>
                            <Checkbox
                              defaultChecked={obrigatorio}
                              onChange={(e) => {
                                setValue(
                                  'qcRespQuestObrigatoria',
                                  !obrigatorio
                                );
                                setObrigatorio(!obrigatorio);
                              }}
                            >
                              Campanha obrigatória
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      );
                    }}
                  />
                </Stack>
                {errors.qcRespQuestObrigatoria?.message && (
                  <Text color={colors.orange}>
                    {errors.qcRespQuestObrigatoria?.message}
                  </Text>
                )}
              </Box>
            </SimpleGrid>
          </ModalBody>
          <Divider color={colors.gray_blue} mt={'1rem'} />
          <ModalFooter>
            <Flex
              gap="2rem"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              width={'100%'}
            >
              <Flex gap={4}>
                <Switch
                  style={{ lineHeight: 1.8 }}
                  defaultChecked={ativo}
                  onChange={() => {
                    setAtivo(!ativo);
                    setValue('qcAtiva', !ativo);
                  }}
                  name="qcAtiva"
                  placeholder="Descrição"
                  {...register('qcAtiva', {
                    /**/
                  })}
                />
                <Text>Campanha Ativa</Text>
              </Flex>
              <Button
                variant="outline"
                border="1px"
                color={colors.blue}
                borderColor={colors.blue}
                _hover={{ bg: colors.blue, color: '#fff' }}
                _active={{ bg: colors.textBlue }}
                type="submit"
              >
                {originParams ? 'Editar' : 'Criar'}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export { ModalCrud };
