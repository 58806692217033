import { Link } from 'react-router-dom';

type Props = {
  cpf: string;
};

export const GestaoDeAgendaProfissionalPresencialLink = ({ cpf }: Props) => {
  return (
    <div className="btn-group flex-btn-group-container">
      <Link
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Presencial"
        className="btn btn-primary btn-sm"
        to={`/gerenciamento-profissional-saude/${cpf}/gestao-de-agenda-profissional?tipo=presencial`}
      >
        <i className="fa fa-map-marker" />
      </Link>
    </div>
  );
};
