import { useAlert, useApiRequest } from 'hooks';
import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { IEpisodioConsulta } from 'types/episodio';

interface IModalPousoAlegre {
  showModalForm: boolean;
  epIdentificador: string;
  toggle: () => void;
}

function ModalPousoAlegre({
  showModalForm,
  epIdentificador,
  toggle,
}: IModalPousoAlegre) {
  const [comentarioInput, setComentarioInput] = useState<string>('');
  const alert = useAlert();
  const episodioApi = useApiRequest<IEpisodioConsulta>(
    'aswepisodiosms',
    {} as IEpisodioConsulta
  );

  return (
    <Modal
      centered
      size={'lg'}
      isOpen={showModalForm}
      toggle={() => {
        toggle();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
      >
        Solicitar Novos Documentos
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md={11}>
            <Input
              value={comentarioInput}
              onChange={(e) => {
                setComentarioInput(e.target.value);
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="float-right jh-create-entity"
          type="submit"
          onClick={async () => {
            const result = await episodioApi.submit({
              method: 'solicitarNovosDocumentos',
              epIdentificador,
              comentario: comentarioInput,
            });
            if (result?.statusCode === 200) {
              alert.success({ title: 'Documentos solicitados com sucesso' });
              toggle();
            } else {
              alert.warning({ title: 'Erro ao solicitar documentos' });
            }
          }}
        >
          Concluir
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalPousoAlegre;
