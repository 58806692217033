import React, { useState } from 'react';
import { Col, Label, Row, Table } from 'reactstrap';
import { useApiRequest, useUser } from 'hooks';
import ReactDatePicker from 'react-datepicker';
import { SearchButton } from 'pages/liberacao-agendamento/styles';
import dayjs from 'dayjs';
import { Modaltrigem } from 'pages/atendimento/components/modal-triagem';

interface IHistoricoAtendimento {
  usuarioNomeSocial:string;
  pacienteNome: string;
  peDescricao: string;
  usuarioCPF: string;
  huDataHoraAgenda: string;
  huTipo: string;
  huDescricao: string;
  huDtInicioConsulta: string;
  huDtFimConsulta: string;
  huRetorno: string;
  dtInclusao: string;
  agIdConsulta: string;
  huSubjetivo: string;
  huObjetivo: string;
  huAvaliacao: string;
  huPlano: string;
  psNome: string;
  huTriagPressaoArterial?: string;
  hrTriagFreqCardiaca?: string;
  huTriagFreqRespiratoria?: string;
  huTriagSaturacao?: string;
  huTriagTemperatura?: string;
  huTriagPeso?: string;
  huTriagAltura?: string;
  huTriagSGTosse?: number;
  huTriagSGCoriza?: number;
  huTriagSGDorGarganta?: number;
  huTriagSGDorCabeca?: number;
  huTriagSGEspirros?: number;
  huTriagSGDorCostas?: number;
  huTriagSGDiarreia?: number;
  huTriagSGFebre?: number;
  huTriagSGOutros?: string;
  listagemCID10?: any[];
  listagemPrescricao?: any[];
}

export default function HistoricoDeAtendimentos() {
  const [openModalWithIdConsulta, setOpenModalWithIdConsulta] = useState('');
  const [cpfPaciente, setCpfPaciente] = useState<string>('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const user = useUser();

  const historicoAtendimentoAPI = useApiRequest<IHistoricoAtendimento[]>(
    'aswhistusuario',
    []
  );

  const handleSearchButton = () => {
    historicoAtendimentoAPI.request({
      method: '_list',
      sgCliente: user?.sgCliente,
      psCpf: user?.cpf,
      usuarioCPF: cpfPaciente,
      dataAgendaStart: startDate
        ? dayjs(startDate).format('YYYY-MM-DD 00:00:00')
        : '',
      dataAgendaEnd: endDate
        ? dayjs(endDate).format('YYYY-MM-DD 23:59:59')
        : '',
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="breadcrumb">
          <h6>HISTÓRICO DE ATENDIMENTOS</h6>
        </div>
      </div>
      <Row>
        <Col md="6">
          <Label for="historico-atendimento-paciente">Buscar</Label>
          <input
            id="historico-atendimento-paciente"
            placeholder="CPF ou Usuário"
            type="text"
            className="form-control"
            value={cpfPaciente}
            onChange={(evt) => setCpfPaciente(evt.target.value)}
          />
        </Col>
        <Col md="2">
          <span>Data início</span>
          <ReactDatePicker
            className="form-control"
            placeholderText="Data Inicial"
            selected={startDate}
            onChange={(date: Date) => {
              setStartDate(date);
            }}
            value={startDate as unknown as string}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md="2">
          <span>Data Final</span>
          <ReactDatePicker
            className="form-control"
            placeholderText="Data Final"
            selected={endDate}
            onChange={(date: Date) => {
              setEndDate(date);
            }}
            value={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
        <Col md="2">
          <SearchButton
            style={{ marginTop: 5 }}
            color="primary"
            onClick={() => {
              handleSearchButton();
            }}
          >
            {historicoAtendimentoAPI.isLoading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i>
              </>
            ) : (
              <i className="fa fa-search" color="warning" />
            )}
          </SearchButton>
        </Col>
      </Row>

      <div>
        {historicoAtendimentoAPI.state.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Profissional de Saúde</th>
                  <th>Especialidade</th>
                  <th>Paciente</th>
                  <th>Horário de Atendimento</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {historicoAtendimentoAPI.state.map(
                  (historico: any, i: number) => (
                    <tr key={`${historico.psNome + i}`}>
                      <td>{historico.psNome}</td>
                      <td>{historico.peDescricao}</td>
                      <td>{historico.pacienteNome}</td>
                      <td>
                        {dayjs(historico.huDataHoraAgenda).format(
                          'DD-MM-YYYY HH:mm'
                        )}
                      </td>
                      <td className="text-center">
                        <div className="btn-group flex-btn-group-container">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Detalhes"
                            className={'btn btn-primary btn-lg'}
                            onClick={() =>
                              setOpenModalWithIdConsulta(historico.agIdConsulta)
                            }
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        )}

        {!historicoAtendimentoAPI.isLoading &&
          !historicoAtendimentoAPI.state.length && (
            <div className="mt-4 alert alert-warning">Nada encontrado</div>
          )}
      </div>

      {openModalWithIdConsulta.length > 0 && (
        <Modaltrigem
          eHistorico
          idConsulta={openModalWithIdConsulta}
          nomeUsuario={historicoAtendimentoAPI.state[0].pacienteNome}
          usuarioNomeSocial={historicoAtendimentoAPI?.state[1]?.usuarioNomeSocial}
          close={() => {
            setOpenModalWithIdConsulta('');
          }}
        />
      )}
    </>
  );
}
