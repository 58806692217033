import { Box, Checkbox, CheckboxGroup, CloseButton, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, propNames, Radio, RadioGroup, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ImageUploading from 'react-images-uploading';
import InputMask from 'react-input-mask';
import { Button, FormGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { Loading } from 'components/Loading';
import { useRouter } from 'components/util/entity-utils';
import { useApiOnMount, useApiRequest } from 'hooks';
import { api } from 'services/api';

import { apiFetch } from '../../../../components/util/entity-utils';

type ModalCriarInstituicaoProps = {
  isNew: boolean;
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
  dataToEdit?: any;
};
type ISCentroMedico = '' | 0 | 1;
type CriarInstFormData = {
  sgInstSaude: string;
  instSaude: string;
  nomeContato: string;
  telefoneContato: string;
  email: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  municipio: string;
  bairro: string;
  altura: string;
  gapAgendamento: string;
  telemedicina: string;
  logo: string;
  largura: string;
  senha: string;
  zoom: string;
  logoBase64: string;
  centroMedico: number;
};

const requiredFieldText = 'Campo obrigatório';

const schema = Yup.object().shape({
  sgInstSaude: Yup.string().required(requiredFieldText),
  instSaude: Yup.string().required(requiredFieldText),
  nomeContato: Yup.string().required(requiredFieldText),
  telefoneContato: Yup.string().required(requiredFieldText),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required(requiredFieldText),
  cep: Yup.string().required(requiredFieldText),
  endereco: Yup.string().required(requiredFieldText),
  numero: Yup.string().required(requiredFieldText),
  municipio: Yup.string().required(requiredFieldText),
  bairro: Yup.string().required(requiredFieldText),
  // telemedicina: Yup.string().required(requiredFieldText),
  gapAgendamento: Yup.string().required(requiredFieldText),
  zoom: Yup.number(),
  largura: Yup.number(),
  altura: Yup.number(),
  centroMedico: Yup.number(),
  // .transform((value) => (isNaN(value) ? 0 : value)),
});
function ModalCriarInstituicao({
  isNew,
  isOpen,
  onClose,
  dataToEdit,
}: ModalCriarInstituicaoProps) {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [telemedicina, setTelemedicina] = useState('');
  const maxNumber = 69;
  const MySwal = withReactContent(Swal);
  const { asPath } = useRouter();
  const [cliente, setCliente] = useState('');
  const [logoBase64, setLogoBase64] = useState('');
  const [logoFileName, setLogoFileName] = useState('');
  const [logoFileType, setLogoFileType] = useState('');
  const [logo, setLogo] = useState('');
  const imagemBase64 = useApiRequest<any>('aswinstsaude', []);
  const [sgIndisponivel, setSgIndisponivel] = useState('');
  const [rSelected, setRSelected] = useState<'cell' | 'phone'>('cell');
  const [centroMedicoSelected, setCentroMedicoSelected] =
    useState<ISCentroMedico>();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm<CriarInstFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let arrayCliente = (asPath || '').split('/', 3);
    setCliente(arrayCliente[2]);
  }, []);

  useEffect(() => {
    setImages([]);
  }, [onClose]);

  const handleRegisterInst: SubmitHandler<CriarInstFormData> = async (
    formData
  ) => {
    try {
      const { data } = await api.post('aswinstsaude', {
        method: isNew ? '_insert' : '_update',
        sgCliente: cliente,
        logo,
        logoBase64,
        logoFileName,
        logoFileType,
        ...formData,
      });

      console.log('aqui', formData.centroMedico);

      setLoading(false);
      // if (data.message == 'Nada encontrado') {
      //   MySwal.fire({
      //     title: 'Email já cadastrado',
      //     icon: 'warning',
      //   });
      //   // setEmailIndisponivel('Email indisponível');
      // } else {
      onCloseEditModal(true);
      MySwal.fire({
        title:
          data?.message ||
          (!!data?.success
            ? 'Cadastro efetuado com sucesso'
            : 'Não foi possível cadastar'),
        icon: !!data.success ? 'success' : 'warning',
      });
      // setTimeout(() => {
      //   location.reload();
      // }, 2000);
      // }
    } catch (err) {
      onCloseEditModal();
      MySwal.fire({
        title: isNew
          ? 'Houve um erro ao tentar cadastar'
          : 'Não foi possível editar',
        icon: 'error',
      });
      // setTimeout(() => {
      //   location.reload();
      // }, 2000);
    }
  };

  const onCloseEditModal = (reload = false) => {
    // formMethods.reset();
    // setEmailIndisponivel(' ');
    setImages([]);
    setSgIndisponivel('');
    reset();
    onClose(reload);
  };

  useEffect(() => {
    const loadDataToEdit = () => {
      reset({ ...dataToEdit });
      setValue('sgInstSaude', dataToEdit?.sgInstSaude);
      TrocaCentroMedico(dataToEdit?.centroMedico);
    };
    if (!isNew && dataToEdit) {
      let newCliente = (asPath || '').split('/', 3);
      imagemBase64.request({
        method: '_find',
        sgCliente: newCliente[2],
        sgInstSaude: dataToEdit?.sgInstSaude,
      });
      loadDataToEdit();
    }
  }, [dataToEdit, isNew]);

  useEffect(() => {
    let file = {
      name: imagemBase64?.state?.logo?.replace('logos/', ''),
      type: imagemBase64?.state?.logo?.replace('logos/', '').replace('.', '/'),
    };
    let imagemObj = [
      {
        data_url: imagemBase64?.state?.logoBase64,
        file: file,
      },
    ];
    setImages(imagemObj);
  }, [imagemBase64.state]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setLogo(imageList[0].name);
    setLogoBase64(imageList[0].data_url);
    setLogoFileName(imageList[0].file.name);
    setLogoFileType(imageList[0].file.type);
  };

  const { data, isLoading } = useApiOnMount('aswtelemedicina', {
    method: '_list',
    page: 0,
    size: 100,
    sort: 'asc',
  });

  const verificarSg = async (value) => {
    const res: {
      data: any;
      success?: boolean;
      message?: string;
    } = await apiFetch('aswinstsaude', {
      method: '_find',
      body: {
        sgInstSaude: value,
        sgCliente: cliente,
      },
    });
    if (res.success == true) {
      setSgIndisponivel('Sigla indisponível');
    } else {
      setSgIndisponivel(' ');
    }
  };

  const TrocaCentroMedico = (value: ISCentroMedico) => {
    setCentroMedicoSelected(value);
    setValue('centroMedico', +value );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size={'6xl'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Grid templateColumns="repeat(5, 1fr)" gap={1}>
            <GridItem colStart={6} colEnd={6} h="10">
              <CloseButton size="sm" onClick={() => onClose()} />
            </GridItem>
          </Grid>
          <Heading fontSize={'md'} fontWeight={400}>
            {isNew ? 'Cadastrar nova instituição' : 'Editar instituição'}
          </Heading>
        </ModalHeader>
        <Box as="form" onSubmit={handleSubmit(handleRegisterInst)}>
          <ModalBody>
            <Grid templateColumns={'repeat(3, 1fr)'} gap={'16px'}>
              <GridItem colSpan={2}>
                <Grid templateColumns={'repeat(2, 1fr)'} gap={'12px'}>
                  <FormControl>
                    <FormLabel>Instituição de saúde</FormLabel>
                    <Input
                      id="cadastro-instituicao-instSaude"
                      type="text"
                      {...register('instSaude')}
                    />
                    <span className="text-warning">
                      <small>{errors.instSaude?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="sgInstSaude">Sigla</FormLabel>
                    <Input
                      disabled={!isNew}
                      type="text"
                      name="sgInstSaude"
                      {...register('sgInstSaude', {
                        onChange: (e) => verificarSg(e.target.value),
                      })}
                    />
                    <p className="text-warning">
                      <small>{sgIndisponivel}</small>
                    </p>
                    <span className="text-warning">
                      <small>{errors.sgInstSaude?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="nomeContato">Nome do Contato</FormLabel>
                    <Input
                      type="text"
                      name="nomeContato"
                      {...register('nomeContato')}
                    />
                    <span className="text-warning">
                      <small>{errors.nomeContato?.message}</small>
                    </span>
                  </FormControl>
                  <Flex gap={'14px'} w={'100%'} justify={'space-between'}>
                    <Box marginTop={'-22px'}>
                      <Label
                        className="mt-4 label-single-line"
                        id="telefoneContatoLabel"
                        for="cadastro-instituicao-telefoneContato"
                      >
                        Tipo
                      </Label>
                      <Flex>
                        <input
                          type="radio"
                          value="cell"
                          name="phonemask"
                          id="phonemask1"
                          style={{ marginRight: '4px' }}
                          onClick={() => setRSelected('cell')}
                        />
                        <label>Telefone</label>
                      </Flex>
                      <Flex>
                        <input
                          type="radio"
                          value="phone"
                          id="phonemask2"
                          name="phonemask"
                          style={{ marginRight: '4px' }}
                          onClick={() => setRSelected('phone')}
                        />
                        <label>Telefone</label>
                      </Flex>
                    </Box>

                    <FormControl>
                      <FormLabel htmlFor="telefoneContato">
                        Telefone do Contato
                      </FormLabel>
                      <Input
                        type="text"
                        name="telefoneContato"
                        as={InputMask}
                        mask={
                          rSelected === 'cell'
                            ? '(99) 99999-9999'
                            : '(99) 9999-9999'
                        }
                        {...register('telefoneContato')}
                      />
                      <span className="text-warning">
                        <small>{errors.telefoneContato?.message}</small>
                      </span>
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel htmlFor="email">Email do Contato</FormLabel>
                    <Input type="text" name="email" {...register('email')} />
                    <span className="text-warning">
                      <small>{errors.email?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="cep">CEP</FormLabel>
                    <Input
                      type="text"
                      name="cep"
                      as={InputMask}
                      mask="99999-999"
                      placeholder="99999-999"
                      {...register('cep')}
                    />
                    <span className="text-warning">
                      <small>{errors.cep?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="endereco">Rua</FormLabel>
                    <Input
                      type="text"
                      name="endereco"
                      {...register('endereco')}
                    />
                    <span className="text-warning">
                      <small>{errors.endereco?.message}</small>
                    </span>
                  </FormControl>
                  <Flex gap={'16px'} w={'100%'} justify={'space-between'}>
                    <FormControl>
                      <FormLabel htmlFor="numero">Número</FormLabel>
                      <Input
                        type="text"
                        name="numero"
                        {...register('numero')}
                      />
                      <span className="text-warning">
                        <small>{errors.numero?.message}</small>
                      </span>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="complemento">Complemento</FormLabel>
                      <Input
                        type="text"
                        name="complemento"
                        {...register('complemento')}
                      />
                      <span className="text-warning">
                        <small>{errors.complemento?.message}</small>
                      </span>
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel htmlFor="municipio">Município</FormLabel>
                    <Input
                      type="text"
                      name="municipio"
                      {...register('municipio')}
                    />
                    <span className="text-warning">
                      <small>{errors.municipio?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="bairro">Bairro</FormLabel>
                    <Input type="text" name="bairro" {...register('bairro')} />
                    <span className="text-warning">
                      <small>{errors.bairro?.message}</small>
                    </span>
                  </FormControl>
                  {/* <FormControl>
                    <FormLabel htmlFor="telemedicina">Telemedicina</FormLabel>
                    <Select
                      name="telemedicina"
                      placeholder="Selecione..."
                      // onChange={(options) => {
                      //   optionsTelemedicina.map((p: any) => {
                      //     if (p.value == options) {
                      //       setTelemedicinaSelecionada(p.label);
                      //     }
                      //   });
                      //   // getTelemedicina(options);
                      // }}
                      {...register('telemedicina')}
                    >
                      {data &&
                        data.map((item, index) => {
                          return (
                            <option key={index} value={item.telemedicina}>
                              {item.telemedicina}
                            </option>
                          );
                        })}
                    </Select>
                    <span className="text-warning">
                      <small>{errors.telemedicina?.message}</small>
                    </span>
                  </FormControl> */}
                  <FormControl>
                    <FormLabel htmlFor="gapAgendamento">
                      Tempo minímo de atendimento (em minutos)
                    </FormLabel>
                    <Input
                      type="number"
                      name="gapAgendamento"
                      {...register('gapAgendamento')}
                    />
                    <span className="text-warning">
                      <small>{errors.gapAgendamento?.message}</small>
                    </span>
                  </FormControl>
                  <FormControl>
                    <Box marginTop={'-22px'}>
                      <Label
                        className="mt-4 label-single-line"
                        id="telefoneContatoLabel"
                        for="cadastro-instituicao-telefoneContato"
                      >
                        Centro Médico
                      </Label>
                      <FormGroup>
                        <Flex>
                          <input
                            {...register('centroMedico')}
                            type="radio"
                            value={1}
                            name="centroMedico"
                            id="sim"
                            style={{ marginRight: '4px' }}
                            checked={centroMedicoSelected == 1 ? true : false}
                            onClick={() => {
                              TrocaCentroMedico(1);
                            }}
                          />
                          <label style={{ marginRight: '2rem' }}>Sim</label>

                          <input
                            {...register('centroMedico')}
                            type="radio"
                            value={0}
                            id="nao"
                            name="isCentroMedico"
                            checked={centroMedicoSelected == 0 ? true : false}
                            style={{ marginRight: '4px' }}
                            onClick={() => {
                              TrocaCentroMedico(0);
                            }}
                          />
                          <label>Não</label>
                        </Flex>
                      </FormGroup>
                    </Box>

                    {errors.centroMedico?.message && (
                      <Text color={'orange'}>
                        {errors.centroMedico?.message}
                      </Text>
                    )}
                  </FormControl>
                </Grid>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl>
                  <FormLabel htmlFor="gapAgendamento">Logotipo</FormLabel>
                  <Flex
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="10px"
                    h="full"
                    w="full"
                    align="center"
                    justify="center"
                  >
                    <ImageUploading
                      multiple={false}
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        //// imagemBase64.state.logoBase64
                        <div className="upload__image-wrapper">
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageRemove(index)}>
                                  Remover
                                </button>
                              </div>
                            </div>
                          ))}
                          <div>
                            <Button
                              className="upload__image-upload"
                              color="primary"
                              onClick={onImageUpload}
                            >
                              <i className="fa fa-upload" />
                              <span>Enviar imagem </span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </Flex>
                  <Flex
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="10px"
                    h="full"
                    w="full"
                    align="center"
                    justify="center"
                  >
                    <SimpleGrid columns={3}>
                      <Box>
                        <Label
                          className="mt-4 label-single-line"
                          id="alturaLabel"
                          for="cadastro-instituicao-altura"
                        >
                          <span>Altura (pixel)</span>
                        </Label>
                        <Input
                          id="cadastro-instituicao-altura"
                          name="altura"
                          className="form-control"
                          {...register('altura')}
                        />
                      </Box>
                      <Box>
                        <Label
                          className="mt-4 label-single-line"
                          id="larguraLabel"
                          for="cadastro-instituicao-largura"
                        >
                          <span>Largura (pixel)</span>
                        </Label>
                        <Input
                          id="cadastro-instituicao-largura"
                          name="largura"
                          className="form-control"
                          {...register('largura')}
                        />
                      </Box>
                      <Box>
                        <Label
                          className="mt-4 label-single-line"
                          id="zoomLabel"
                          for="cadastro-instituicao-zoom"
                        >
                          <span>Zoom (pixel)</span>
                        </Label>
                        <Input
                          id="cadastro-instituicao-zoom"
                          name="zoom"
                          className="form-control"
                          {...register('zoom')}
                        />
                      </Box>
                    </SimpleGrid>
                  </Flex>
                </FormControl>
              </GridItem>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Flex width={'100%'} justify={'flex-end'}>
              <Button color="primary" type="submit" className="float-right">
                {isNew ? 'Cadastrar Instituição' : 'Salvar alterações'}
              </Button>
            </Flex>
          </ModalFooter>
        </Box>
        {loading && <Loading />}
      </ModalContent>
    </Modal>
  );
}

export default ModalCriarInstituicao;
