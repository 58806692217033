import { Container, ContainerAbsolute } from './styles';

export const Loading = () => {
  return (
    <ContainerAbsolute>
      <Container>
        <div>
          <i className="fas fa-spinner fa-spin fa-5x"></i>
        </div>
      </Container>
    </ContainerAbsolute>
  );
};
