import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from '../../shared/error/error-boundary-route';
import GestaoDeAgenda from '../gestao-de-agenda-telessaude/index';
import { ProfissionalSaude } from './_base/profissional-saude';

export const nProfissionalSaude = () => ProfissionalSaude({} as any).return();

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute path={`${match.path}/:idProfissionalSaude/gestao-de-agenda`} component={GestaoDeAgenda} />
      <ErrorBoundaryRoute path={match.path} component={nProfissionalSaude} />
    </Switch>

    <Switch>{/* <ErrorBoundaryRoute path={`${match.path}/:idProfissionalSaude/gestao-de-agenda`} component={GestaoDeAgenda} /> */}</Switch>
  </>
);

export default Routes;
