import styled from 'styled-components';

export const TitleContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleEpisódio = styled.h2`
  font-weight: 800;
  size: 18px;
  color: #898989;
  text-transform: capitalize;
`;

export const BotaoAdicionarEpisodio = styled.button`
  background: rgba(86, 161, 225, 0.1);
  border: 1px solid #56a1e1;
  border-radius: 25px;
  color: #56a1e1;
  padding: 2px 10px;

  :disabled {
    opacity: 0.6;
  }
`;
