import { useEffect, useState } from 'react';
import { Button, Col, Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useOnlyMounted } from 'hooks/useOnlyMounted';
import { api } from 'services/api';
import { changeInputText } from 'shared/util';

import { Container } from '../../styles';
import ModalTelemedicina from '../createModal';

export type TelemedicinaType = {
  id?: number;
  telemedicina: string;
  emailTokenTelemedicina: string;
  // tokenTelemedicina: string;
  // methodTelemedicina: string;
  // host: string;
  // path: string;
  // port: string;
  // queryGroup: string;
};

function Page() {
  const [isLoading, setIsLoading] = useState(false);
  const [textoFiltro, setTextoFiltro] = useState('');
  const [telemedicinas, setTelemedicinas] = useState<TelemedicinaType[]>([]);
  const [telemedicinasFilter, setTelemedicinasFilter] = useState<
    TelemedicinaType[]
  >([]);
  const [showModalForm, setShowModalForm] = useState<boolean>(false);
  const [telemedicinaSelected, setTelemedicinaSelected] =
    useState<TelemedicinaType>(null);
  const { onlyMounted } = useOnlyMounted();

  const getTelemedicinas = () => {
    executaRequisicao(
      'aswtelemedicina',
      {
        method: '_list',
      },
      ({ data, success }) => {
        if (success && Array.isArray(data)) {
          setTelemedicinas(data);
          setTelemedicinasFilter(data);
        }
      }
    );
  };

  const executaRequisicao = (
    url = 'aswinstsaude',
    params: object,
    callback: Function
  ) => {
    setIsLoading(true);
    api
      .post(url, params)
      .then(({ data }) => {
        onlyMounted(() => {
          callback(data);
        });
      })
      .finally(() => {
        onlyMounted(() => {
          setIsLoading(false);
        });
      });
  };

  const toggle = () => {
    setShowModalForm(!showModalForm);
  };

  useEffect(() => {
    getTelemedicinas();
  }, []);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = telemedicinas.filter((consulta) => {
        return Object.values(consulta).some(
          (key) => String(key).toLowerCase().includes(textoFiltro.toLowerCase())
          // ||
          // String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setTelemedicinasFilter(filtered);
    } else {
      setTelemedicinasFilter(telemedicinas);
    }
  }, [textoFiltro]);

  const handleModalClick = (telemedicina: TelemedicinaType | null) => {
    setTelemedicinaSelected(telemedicina);
    toggle();
  };

  return (
    <Container>
      <div className="breadcrumb">
        <h6>TELEMEDICINAS</h6>
      </div>

      <div className="d-flex justify-content-end">
        <Button
          color="primary"
          className="btn btn-primary float-right jh-create-entity"
          onClick={() => handleModalClick(null)}
        >
          Cadastrar Nova Telemedicina
        </Button>
      </div>
      {telemedicinas.length > 0 && (
        <Col md="12">
          <span>Pesquisar</span>
          <input
            placeholder="Digite aqui..."
            className="form-control"
            // value={textoFiltro}
            onChange={(evento) => setTextoFiltro(changeInputText(evento))}
          />
        </Col>
      )}

      <div>
        {telemedicinasFilter.length > 0 && (
          <div className="table-list mt-4">
            <Table
              responsive
              className={'table-hover table-striped table-responsive-css'}
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Nome da Telemedicina</th>
                  <th>E-mail Token Telemedicina</th>
                  {/* <th>Host</th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {telemedicinasFilter.map((telemedicina, i: number) => (
                  <tr
                    key={`${telemedicina.telemedicina}${telemedicina.emailTokenTelemedicina}${i}`}
                  >
                    <td>{telemedicina.telemedicina}</td>
                    <td>{telemedicina.emailTokenTelemedicina}</td>
                    {/* <td>{telemedicina.host}</td> */}
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <button
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Detalhes"
                          className={'btn btn-primary btn-lg'}
                          onClick={() => handleModalClick(telemedicina)}
                        >
                          <i className="fa fa-pencil-alt" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {!isLoading && !telemedicinasFilter.length && (
          <div className="mt-4 alert alert-warning">Nada encontrado</div>
        )}
      </div>

      {isLoading && <Loading />}

      <ModalTelemedicina
        showModalForm={showModalForm}
        telemedicinaSelected={telemedicinaSelected}
        toggle={toggle}
        atualizarDados={getTelemedicinas}
      />
    </Container>
  );
}

export default Page;
