import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Input } from 'components/form/input';

import { TUserData } from 'shared/error/error-boundary-route';
import { useAlert, useApiRequest, useUser } from 'hooks';
import { LOG } from 'shared/util';
import { Button, Spinner } from 'reactstrap';

type ModalRedefinirSenha = {
  isOpen: boolean;
  onClose: () => void;
  userData?: TUserData;
  fallBackSuccess: Function;
};

type TFormParams = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};

export function ModalRedefinirSenha({
  isOpen,
  onClose,
  userData,
  fallBackSuccess,
}: ModalRedefinirSenha) {
  const usuarioLogado = useUser();
  const schema = Yup.object().shape({
    password: Yup.string().required('A senha antiga é obrigatória!'),
    newPassword: Yup.string()
      .min(6, 'A senha deve conter no mínimo 6 caracteres')
      .required('Uma nova senha é obrigatória!'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'A senha de confirmação não confere!'
    ),
  });

  const formMethods = useForm<TFormParams>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, register } = formMethods;

  const alert = useAlert();

  const api = useApiRequest('asmuser', {});

  const handleEnviarSenha = async (params: TFormParams) => {
    const body = {
      method: '_updatePass',
      sgCliente: usuarioLogado.sgCliente,
      oldPassword: params?.password,
      password: params?.newPassword,
      confirmPassword: params?.confirmNewPassword,
    };

    await api.submit(body).then((response) => {
      if (response?.statusCode == 200 || response?.statusCode == 201) {
        alert.success({
          title: 'Senha atualizada com sucesso!',
        });

        fallBackSuccess();
      } else {
        alert.error({
          title: response?.message || 'Houve um erro ao atualizar a senha!',
        });
      }
    });
  };

  const handleFormSubmit = async (params: TFormParams) => {
    try {
      handleEnviarSenha(params);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      LOG(validationErrors);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <ModalHeader>Redefinir minha senha:</ModalHeader>
            <ModalBody experimental_spaceY={3}>
              <Box experimental_spaceY={1}>
                <Text color="textGray">CPF: {userData?.cpf}</Text>
                <Text color="textGray">E-mail: {userData?.email}</Text>
              </Box>
              <SimpleGrid columns={1} gap={8} my={1}>
                <Box experimental_spaceY={3}>
                  <Text>Senha atual: *</Text>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Senha atual"
                  />
                </Box>
                <Box experimental_spaceY={2}>
                  <Text>Nova senha: *</Text>
                  <Input
                    type="password"
                    name="newPassword"
                    placeholder="Nova senha"
                  />
                </Box>
                <Box experimental_spaceY={2}>
                  <Text>Confirme a nova senha: *</Text>
                  <Input
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirmar nova senha"
                  />
                </Box>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={api.isLoading}>
                Confirmar{' '}
                {api.isLoading && (
                  <>
                    &nbsp;
                    <Spinner size={'sm'} />
                  </>
                )}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
