import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { colors } from 'theme/colors';

/**
 * @docs
 * - https://chakra-ui.com/docs/components/checkbox/usage
 *
 * @returns FormMultipleOptions
 */
type TCheck = {
  options: {
    title: string;
    value: string;
  }[];
  title: string;
  selected: (param: any[]) => void;
};

const FormMultipleOptions = ({ options, title, selected }: TCheck) => {
  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <Text fontSize="2xl">{title}</Text>
      <Stack>
        <CheckboxGroup
          colorScheme="blue"
          defaultValue={[]}
          onChange={(e) => selected(e)}
        >
          <Stack spacing={[1, 5]} direction={['row', 'column']}>
            {options.map((param, key) => (
              <Checkbox
                key={key}
                value={param.value.toLowerCase().replace(/\s/g, '')}
              >
                {param.title}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
      </Stack>
    </Flex>
  );
};

export { FormMultipleOptions };
