// @ts-nocheck
/* eslint no-console: off */

import {
  Translate,
  useTranslation,
  Link,
} from '../../../components/util/translate-component';
import React, { useState, useEffect } from 'react';
import {
  useRouter,
  nextCookie,
  jsonParse,
  GetServerSideProps,
  AppProps,
} from '../../../components/util/entity-utils';

import {
  APP_DATE_FORMAT,
  BASE_API_VERSION_PATH,
  AUTH_TOKEN_KEY,
} from 'shared/util';
import { ITEMS_PER_PAGE } from '../../../components/util/pagination.constants';
import {
  Button,
  Col,
  Row,
  Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import cookie from 'js-cookie';

import 'react-quill/dist/quill.snow.css';

import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { pt } from 'date-fns/esm/locale';
import moment from 'moment';
// registerLocale('pt', pt);
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { convertDateTimeToServer } from 'shared/util';

const MySwal = withReactContent(Swal);

export interface IPeriodoSemAtividadeProps extends AppProps {
  id?: any;
  descricao?: any;
  periodoAteStart?: any;
  periodoAteEnd?: any;
  periodoDeStart?: any;
  periodoDeEnd?: any;
  extraFilters?: any;
  activePage?: any;
  sort?: any;
  _router: any;
  props: any;
  order?: any;
  itemsPerPage?: any;
  totalItems?: any;
  state?: any;
  getEntityFiltersURL?: Function;
  setState?: Function;
  t?: Function;
  periodoSemAtividadeList?: any;
  setPeriodoSemAtividadeList?: Function;
  periodoSemAtividadeEntity?: any;
  setPeriodoSemAtividadeEntity?: Function;

  getAllEntities?: Function;
  sortEntities?: Function;
  sortFunction?: Function;
  handlePagination?: Function;
  deleteEntityModal?: Function;
  saveEntity?: Function;
  renderHeaderUpdate?: Function;
  renderBodyUpdate?: Function;
  renderNewModal?: Function;
  renderUpdateModal?: Function;
  fieldsBase?: any;
  getFullEntityFormValue?: Function;
  openUpdateModal?: Function;
  cancelFilters?: Function;
  filterEntity?: Function;
  renderFilter?: Function;
  renderTable?: Function;
  handleSyncList?: Function;
  getEntitiesFetch?: Function;
  deleteEntityFetch?: Function;
  getEntityFetch?: Function;
  createEntityFetch?: Function;
  updateEntityFetch?: Function;
  getPeriodoSemAtividadeState?: Function;
  returnFunction?: Function;
  userAccount?: any;
  loading?: any;
}

const apiUrl = BASE_API_VERSION_PATH + 'api/periodo-sem-atividades';
const getEntityFetch: any = async (
  context: IPeriodoSemAtividadeProps,
  entity: any,
  selectFields = ''
) => {
  const requestUrl = `${apiUrl}/${entity.id}`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
    },
  });
  const json = await res.json();
  context.setPeriodoSemAtividadeEntity(json);

  return json;
};

const createEntityFetch: any = async (
  context: IPeriodoSemAtividadeProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(apiUrl, {
    method: 'post',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const updateEntityFetch: any = async (
  context: IPeriodoSemAtividadeProps,
  entity: any,
  listFiltersPage: any
) => {
  const nEntity = { ...entity };
  const res = await fetch(`${apiUrl}`, {
    method: 'put',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(nEntity),
  });
  const json = await res.json();
  context.getAllEntities();
  return json;
};

const deleteEntityFetch: any = async (
  context: IPeriodoSemAtividadeProps,
  _periodoSemAtividadeEntity: any,
  listFiltersPage: any
) => {
  await fetch(`${apiUrl}/${_periodoSemAtividadeEntity.id}`, {
    method: 'delete',
    headers: {
      Authorization: 'Bearer ' + cookie.get(AUTH_TOKEN_KEY),
      'Content-Type': 'application/json;charset=utf-8',
    },
  });
  context.getAllEntities();
  return true;
};

const getEntityFiltersURL = (
  context: IPeriodoSemAtividadeProps,
  offset = null
) => {
  return (
    '' +
    (context.state.id ? 'id=' + context.state.id + '&' : '') +
    (context.state.descricao
      ? 'descricao=' + context.state.descricao + '&'
      : '') +
    (context.state.periodoAte
      ? 'periodoAte=' + context.state.periodoAte + '&'
      : '') +
    (context.state.periodoDe
      ? 'periodoDe=' + context.state.periodoDe + '&'
      : '') +
    (context.state.baseFilters
      ? 'baseFilters=' + context.state.baseFilters + '&'
      : '') +
    (context.state.extraFilters
      ? 'extraFilters=' +
        encodeURI(JSON.stringify(context.state.extraFilters)) +
        '&'
      : '') +
    'page=' +
    context.state.activePage +
    '&' +
    'size=' +
    context.state.itemsPerPage +
    '&' +
    (offset !== null ? 'offset=' + offset + '&' : '') +
    'sort=' +
    context.state.sort +
    ',' +
    context.state.order +
    '&' +
    ''
  );
};

const getAllEntities = async (
  context: IPeriodoSemAtividadeProps,
  _fieldsBase: any
) => {
  _fieldsBase = _fieldsBase ? _fieldsBase : {};
  const {
    id,
    descricao,
    periodoAteStart,
    periodoAteEnd,
    periodoDeStart,
    periodoDeEnd,
    extraFilters,
    activePage,
    itemsPerPage,
    sort,
    order,
  } = context.state;

  const res = await context.getEntitiesFetch(
    id,
    descricao,
    periodoAteStart,
    periodoAteEnd,
    periodoDeStart,
    periodoDeEnd,
    typeof _fieldsBase.extraFilters !== undefined
      ? _fieldsBase.extraFilters
      : extraFilters,
    typeof _fieldsBase.activePage !== undefined
      ? _fieldsBase.activePage
      : context.state.activePage,
    typeof _fieldsBase.itemsPerPage !== undefined
      ? _fieldsBase.itemsPerPage
      : context.state.itemsPerPage,
    `${_fieldsBase.sort ? _fieldsBase.sort : context.state.sort},${
      _fieldsBase.order ? _fieldsBase.order : context.state.order
    }`
  );

  context.setState({
    ...context.state,
    totalItems: res.headers.get('X-Total-Count'),
  });

  const json = await res.json();
  context.setPeriodoSemAtividadeList(json);
};

const sortEntities = (
  context: IPeriodoSemAtividadeProps,
  _newState = undefined
) => {
  context.getAllEntities();
  const endURL = `?page=${context.state.activePage}&sort=${context.state.sort},${context.state.order}`;
  context._router.push(`?${context.getEntityFiltersURL()}`);
};

const sortFunction = (context: IPeriodoSemAtividadeProps, p: any) => () => {
  context.setState({
    ...context.state,
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
  context.getAllEntities({
    order:
      p === context.state.sort && context.state.order === 'asc'
        ? 'desc'
        : 'asc',
    sort: p,
  });
};

const handlePagination = (
  context: IPeriodoSemAtividadeProps,
  activePage: any
) => {
  context.setState({
    ...context.state,
    activePage,
  });
  context.getAllEntities({ activePage });
};

const deleteEntityModal = (
  context: IPeriodoSemAtividadeProps,
  _periodoSemAtividadeEntity: any
) => {
  MySwal.fire({
    title: (
      <Translate t={context.t} contentKey="entity.delete.title">
        Confirm delete operation
      </Translate>
    ),
    html: (
      <Translate
        t={context.t}
        contentKey="periodoSemAtividade.delete.question"
        interpolate={{ id: _periodoSemAtividadeEntity.id }}
      >
        Are you sure you want to delete this PeriodoSemAtividade?
      </Translate>
    ),
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: '#e70015',
    cancelButtonText: (
      <>
        {' '}
        <i className="fa fa-ban" />
        &nbsp;{' '}
        <Translate t={context.t} contentKey="periodoSemAtividade.btnCancel">
          Cancel
        </Translate>
      </>
    ),
    confirmButtonText: (
      <>
        {' '}
        <i className="fa fa-trash" />
        &nbsp;
        <Translate t={context.t} contentKey="periodoSemAtividade.btnDelete">
          Delete
        </Translate>
      </>
    ),
  }).then(async (response) => {
    if (response.isConfirmed) {
      await context.deleteEntityFetch(_periodoSemAtividadeEntity, {
        reloadList: false,
      });
      context.getAllEntities();
      MySwal.fire({
        title: (
          <Translate t={context.t} contentKey="entity.delete.title.confirmed">
            Confirmed delete operation
          </Translate>
        ),
        icon: 'success',
      });
    }
  });
};

const saveEntity = async (context: IPeriodoSemAtividadeProps, isNew: any) => {
  const errors = [];

  const selectFieldsList = ['descricao', 'periodoAte', 'periodoDe'];

  if (errors.length === 0) {
    const _entity = {
      ...context.periodoSemAtividadeEntity,
      // ...values,

      id: context.state.idFormValue,
      descricao: context.state.descricaoFormValue,
      periodoAte: convertDateTimeToServer(context.state.periodoAteFormValue),
      periodoDe: convertDateTimeToServer(context.state.periodoDeFormValue),
    };

    const entity = _entity;

    const {
      id,
      descricao,
      periodoAteStart,
      periodoAteEnd,
      periodoDeStart,
      periodoDeEnd,
      extraFilters,
      activePage,
      itemsPerPage,
      sort,
      order,
    } = context.state;

    if (isNew) {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.createEntityFetch(entity);
      context.getAllEntities();
    } else {
      context['state'] = {
        ...context.state,
        showModalForm: false,
        showModalEdit: null,
      };
      await context.updateEntityFetch(entity);
      context.getAllEntities();
    }
  }
};

const renderHeaderUpdate = (context: IPeriodoSemAtividadeProps, isNew: any) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <div id="page-heading">
        <span className="page-header ml-3">
          {isNew ? (
            <Translate
              t={context.t}
              contentKey="periodoSemAtividade.home.createLabel"
            >
              Create a PeriodoSemAtividade
            </Translate>
          ) : (
            <Translate
              t={context.t}
              contentKey="periodoSemAtividade.home.editLabel"
            >
              Edit a PeriodoSemAtividade
            </Translate>
          )}
        </span>
      </div>
    </>
  );
};

const renderBodyUpdate = (
  context: IPeriodoSemAtividadeProps,
  _periodoSemAtividadeEntity: any,
  isNew: any
) => {
  const baseFilters =
    context.state && context.state['baseFilters']
      ? context.state['baseFilters']
      : null;

  return (
    <>
      <Row className="justify-content-center">
        <Col md="11">
          {context.loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {!isNew ? (
                <div>
                  <Row>
                    <Col md="12">
                      <input
                        id="periodo-sem-atividade-id"
                        type="hidden"
                        className="form-control"
                        name="id"
                        required
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row className="row-periodo-sem-atividade-first-column">
                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className={'mt-4 label-single-line'}
                          id="idLabel"
                          for="periodo-sem-atividade-id"
                        >
                          <Translate
                            t={context.t}
                            contentKey="periodoSemAtividade.id"
                          >
                            ID
                          </Translate>
                        </Label>
                      </Col>

                      <Col md="9">
                        <input
                          className={'form-control'}
                          disabled={true}
                          value={
                            context.periodoSemAtividadeEntity.id
                              ? context.periodoSemAtividadeEntity.id
                              : ''
                          }
                          id="periodo-sem-atividade-id"
                          type="text"
                          name="id"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="descricaoLabel"
                          for="periodo-sem-atividade-descricao"
                        >
                          <Translate
                            t={context.t}
                            contentKey="periodoSemAtividade.descricao"
                          >
                            Descrição
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <input
                          onChange={(evt) =>
                            context.setState({
                              ...context.state,
                              descricaoFormValue: evt.target.value,
                            })
                          }
                          value={
                            context.state.descricaoFormValue
                              ? context.state.descricaoFormValue
                              : ''
                          }
                          id="periodo-sem-atividade-descricao"
                          type="text"
                          name="descricao"
                          className={'form-control'}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="periodoAteLabel"
                          for="periodo-sem-atividade-periodoAte"
                        >
                          <Translate
                            t={context.t}
                            contentKey="periodoSemAtividade.periodoAte"
                          >
                            Período Até
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <DatePicker
                          autoComplete="off"
                          onChange={(value: any) =>
                            context.setState({
                              ...context.state,
                              periodoAteFormValue: value,
                            })
                          }
                          id="periodoAte"
                          className="form-control react-datepicker"
                          name="periodoAte"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            context.state.periodoAteFormValue
                              ? moment(
                                  context.state.periodoAteFormValue
                                ).toDate()
                              : null
                          }
                          locale="pt"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md="12">
                  <div>
                    <Row>
                      <Col md="3">
                        <Label
                          className="mt-4 label-single-line"
                          id="periodoDeLabel"
                          for="periodo-sem-atividade-periodoDe"
                        >
                          <Translate
                            t={context.t}
                            contentKey="periodoSemAtividade.periodoDe"
                          >
                            Período De
                          </Translate>
                        </Label>
                      </Col>
                      <Col md="9">
                        <DatePicker
                          autoComplete="off"
                          onChange={(value: any) =>
                            context.setState({
                              ...context.state,
                              periodoDeFormValue: value,
                            })
                          }
                          id="periodoDe"
                          className="form-control react-datepicker"
                          name="periodoDe"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            context.state.periodoDeFormValue
                              ? moment(
                                  context.state.periodoDeFormValue
                                ).toDate()
                              : null
                          }
                          locale="pt"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

const renderNewModal = (context: IPeriodoSemAtividadeProps) => {
  return (
    <Modal
      className="periodo-sem-atividade-new-modal"
      size={'xl'}
      isOpen={context.state.showModalForm}
      toggle={() =>
        context.setState({ ...context.state, showModalForm: false })
      }
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalForm: false })
        }
      >
        {context.renderHeaderUpdate(true)}
      </ModalHeader>
      <div>
        <ModalBody>{context.renderBodyUpdate({}, true)}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={async (evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              await context.saveEntity(true);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="periodoSemAtividade.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

const renderUpdateModal = (context: IPeriodoSemAtividadeProps) => {
  return context.periodoSemAtividadeEntity &&
    (context.periodoSemAtividadeEntity.id ||
      context.periodoSemAtividadeEntity._id) ? (
    <Modal
      className="periodo-sem-atividade-update-modal"
      size={'xl'}
      isOpen={
        context.state.showModalEdit === context.periodoSemAtividadeEntity.id ||
        context.state.showModalEdit === context.periodoSemAtividadeEntity._id
      }
      toggle={() => context.setState({ ...context.state, showModalEdit: null })}
    >
      <ModalHeader
        toggle={() =>
          context.setState({ ...context.state, showModalEdit: null })
        }
      >
        {context.renderHeaderUpdate(false)}
      </ModalHeader>
      <div>
        <ModalBody>
          {context.renderBodyUpdate(context.periodoSemAtividadeEntity, false)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            id="save-entity"
            onClick={(evt) => {
              const target = evt.currentTarget;
              target.disabled = true;
              context.saveEntity(false);
              target.disabled = false;
            }}
            className="float-right jh-create-entity"
          >
            <Translate t={context.t} contentKey="periodoSemAtividade.btnSave">
              Save
            </Translate>
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  ) : (
    <> </>
  );
};

const getFullEntityFormValue = (context: IPeriodoSemAtividadeProps, v: any) => {
  const p: any = null;

  return {
    idFormValue: v.id, // id,
    descricaoFormValue: v.descricao, // descricao,
    periodoAteFormValue: v.periodoAte ? moment(v.periodoAte).toDate() : null, // Instant,
    periodoDeFormValue: v.periodoDe ? moment(v.periodoDe).toDate() : null, // Instant,
  };
};

const openUpdateModal = (
  context: IPeriodoSemAtividadeProps,
  v: any,
  edit = true
) => {
  if (v.id) {
    context.getEntityFetch(v);
  } else {
    context.setExampleEmbebedWithEnumEntity(v);
  }
  context.setState({
    ...context.state,
    showModalEdit: v._id || v.id,
    showModalEditView: edit,
    ...getFullEntityFormValue(context, v),
  });
};

const cancelFilters = (context: IPeriodoSemAtividadeProps) => {
  const newState = {
    ...context.state,

    id: '',

    descricao: '',

    periodoAteStart: '',
    periodoAteEnd: '',

    periodoDeStart: '',
    periodoDeEnd: '',
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const filterEntity = (context: IPeriodoSemAtividadeProps) => {
  const newState = {
    ...context.state,
    activePage: 0,
  };
  context.setState(newState);
  context.sortEntities(newState);
};

const renderFilter = (context: IPeriodoSemAtividadeProps) => {
  return (
    <>
      <div id="form-filter">
        <div className="row mt-1 ml-3 mr-3">
          {context.state.baseFilters !== 'id' ? (
            <Col md="3" className="col-filter-periodo-sem-atividade-id">
              <Row className="mr-1 mt-1">
                <Label id="idLabel" for="periodo-sem-atividade-id">
                  <Translate t={context.t} contentKey="periodoSemAtividade.id">
                    ID
                  </Translate>
                </Label>
                <input
                  className="form-control"
                  type="number"
                  name="id"
                  id="periodo-sem-atividade-id"
                  value={context.state.id}
                  onChange={(evt: any) =>
                    context.setState({ ...context.state, id: evt.target.value })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'descricao' ? (
            <Col md="3" className="col-filter-periodo-sem-atividade-descricao">
              <Row className="mr-1 mt-1">
                <Label
                  id="descricaoLabel"
                  for="periodo-sem-atividade-descricao"
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.descricao"
                  >
                    Descrição
                  </Translate>
                </Label>

                <input
                  className="form-control"
                  type="text"
                  name="descricao"
                  id="periodo-sem-atividade-descricao"
                  value={context.state.descricao}
                  onChange={(evt: any) =>
                    context.setState({
                      ...context.state,
                      descricao: evt.target.value,
                    })
                  }
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'periodoAte' ? (
            <Col md="3" className="col-filter-periodo-sem-atividade-periodoAte">
              <Row className="mr-1 mt-1">
                <Label
                  id="periodoAteStart"
                  for="periodo-sem-atividade-periodoAte_start"
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoAte_start"
                  >
                    Período Até (start)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({
                      ...context.state,
                      periodoAteStart: value,
                    })
                  }
                  id="periodoAteStart"
                  className="form-control react-datepicker"
                  name="periodoAteStart"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.periodoAteStart
                      ? moment(context.state.periodoAteStart).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}
          {context.state.baseFilters !== 'periodoAte' ? (
            <Col
              md="3"
              className="col-filter-periodo-sem-atividade-periodoAte-final"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="periodoAteEnd"
                  for="periodo-sem-atividade-periodoAte_end"
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoAte_end"
                  >
                    Período Até (end)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({ ...context.state, periodoAteEnd: value })
                  }
                  id="periodoAteEnd"
                  className="form-control react-datepicker"
                  name="periodoAteEnd"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.periodoAteEnd
                      ? moment(context.state.periodoAteEnd).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}

          {context.state.baseFilters !== 'periodoDe' ? (
            <Col md="3" className="col-filter-periodo-sem-atividade-periodoDe">
              <Row className="mr-1 mt-1">
                <Label
                  id="periodoDeStart"
                  for="periodo-sem-atividade-periodoDe_start"
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoDe_start"
                  >
                    Período De (start)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({
                      ...context.state,
                      periodoDeStart: value,
                    })
                  }
                  id="periodoDeStart"
                  className="form-control react-datepicker"
                  name="periodoDeStart"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.periodoDeStart
                      ? moment(context.state.periodoDeStart).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}
          {context.state.baseFilters !== 'periodoDe' ? (
            <Col
              md="3"
              className="col-filter-periodo-sem-atividade-periodoDe-final"
            >
              <Row className="mr-1 mt-1">
                <Label
                  id="periodoDeEnd"
                  for="periodo-sem-atividade-periodoDe_end"
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoDe_end"
                  >
                    Período De (end)
                  </Translate>
                </Label>
                <DatePicker
                  onChange={(value: any) =>
                    context.setState({ ...context.state, periodoDeEnd: value })
                  }
                  id="periodoDeEnd"
                  className="form-control react-datepicker"
                  name="periodoDeEnd"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    context.state.periodoDeEnd
                      ? moment(context.state.periodoDeEnd).toDate()
                      : null
                  }
                  locale="pt"
                />
              </Row>
            </Col>
          ) : null}
        </div>

        <div className="row mb-2 mr-4 justify-content-end pull-right">
          <Button
            id="search-submit"
            color="primary"
            onClick={() => context.filterEntity()}
          >
            <i className="fa fa-search" />
            &nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

export const renderTable = (context: IPeriodoSemAtividadeProps) => {
  return (
    <>
      {context.periodoSemAtividadeList &&
      context.periodoSemAtividadeList.filter &&
      context.periodoSemAtividadeList.filter(
        (v: any) => typeof v.deletedAt === 'undefined' || v.deletedAt === null
      ).length > 0 ? (
        <div id="periodo-sem-atividade-table-list" className="table-list">
          <Table
            id="periodo-sem-atividade-table-list"
            responsive
            aria-describedby="periodo-sem-atividade-heading"
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th
                  className="hand"
                  onClick={context.sortFunction('descricao')}
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.descricao"
                  />
                  <i
                    className={
                      context.state.sort === 'descricao'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('periodoAte')}
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoAte"
                  />
                  <i
                    className={
                      context.state.sort === 'periodoAte'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>
                <th
                  className="hand"
                  onClick={context.sortFunction('periodoDe')}
                >
                  <Translate
                    t={context.t}
                    contentKey="periodoSemAtividade.periodoDe"
                  />
                  <i
                    className={
                      context.state.sort === 'periodoDe'
                        ? context.state.order === 'asc'
                          ? 'fa fa-sort-up'
                          : 'fa fa-sort-down'
                        : 'fa fa-sort'
                    }
                  />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {context.periodoSemAtividadeList
                .filter(
                  (v: any) =>
                    typeof v.deletedAt === 'undefined' || v.deletedAt === null
                )
                .map((periodoSemAtividade: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td id="descricao-cell">
                      <b className="visible-xs"> Descrição </b>

                      {periodoSemAtividade.descricao}
                    </td>

                    <td id="periodoAte-cell">
                      <b className="visible-xs"> Período Até </b>

                      {periodoSemAtividade.periodoAte
                        ? moment(periodoSemAtividade.periodoAte).format(
                            APP_DATE_FORMAT
                          )
                        : ''}
                    </td>

                    <td id="periodoDe-cell">
                      <b className="visible-xs"> Período De </b>

                      {periodoSemAtividade.periodoDe
                        ? moment(periodoSemAtividade.periodoDe).format(
                            APP_DATE_FORMAT
                          )
                        : ''}
                    </td>

                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`${context._router.asPath}/${
                            periodoSemAtividade.id
                          }?${getEntityFiltersURL(
                            context.state.itemsPerPage *
                              context.state.activePage +
                              i
                          )}`}
                          color="info"
                          size="sm"
                        >
                          <i className="fa fa-eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="periodoSemAtividade.btnView"
                            />
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`${context._router.asPath}/${
                            periodoSemAtividade.id
                          }/edit?${getEntityFiltersURL(
                            context.state.itemsPerPage *
                              context.state.activePage +
                              i
                          )}`}
                          color="primary"
                          size="sm"
                        >
                          <i className="fa fa-pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="periodoSemAtividade.btnEdit"
                            />
                          </span>
                        </Button>
                        <Button
                          onClick={() =>
                            context.deleteEntityModal(periodoSemAtividade)
                          }
                          color="danger"
                          size="sm"
                        >
                          <i className="fa fa-trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate
                              t={context.t}
                              contentKey="periodoSemAtividade.btnDelete"
                            />
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {context.state.totalItems > context.state.itemsPerPage ? (
            <div
              className={
                context.periodoSemAtividadeList &&
                context.periodoSemAtividadeList.length > 0
                  ? ''
                  : 'd-none'
              }
            >
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                breakClassName={'page-item'}
                pageClassName={'page-item'}
                nextLinkClassName={'btn btn-primary'}
                previousLinkClassName={'btn btn-primary'}
                breakLinkClassName={'btn btn-primary'}
                pageLinkClassName={'btn btn-default'}
                activeLinkClassName={'btn btn-primary'}
                pageCount={
                  context.state.totalItems / context.state.itemsPerPage
                }
                breakLabel={'...'}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(v: any) => {
                  if (v.selected >= 0) context.handlePagination(v.selected);
                }}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ) : !context.loading ? (
        <div className="alert alert-warning">
          <Translate
            t={context.t}
            contentKey="periodoSemAtividade.home.notFound"
          >
            No Gestão de Agenda found
          </Translate>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

const handleSyncList = (context: IPeriodoSemAtividadeProps) => {
  context.sortEntities();
};

export const returnFunction = (context: IPeriodoSemAtividadeProps) => {
  return (
    <div>
      <h4
        id="periodo-sem-atividade-heading"
        data-cy="PeriodoSemAtividadeHeading"
      >
        <Translate t={context.t} contentKey="periodoSemAtividade.home.title">
          Gestão de Agenda
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() =>
              context.setState({
                ...context.state,
                showModalForm: true,
                idFormValue: null,
                descricaoFormValue: null,
                periodoAteFormValue: null,
                periodoDeFormValue: null,
                isNew: true,
              })
            }
            color="primary"
            className="btn btn-primary float-right jh-create-entity"
          >
            <Translate
              t={context.t}
              contentKey="periodoSemAtividade.btnNewItem"
            >
              Cadastrar
            </Translate>
          </Button>{' '}
          &nbsp;
        </div>
      </h4>
      <div className="table-responsive">
        {context.renderFilter ? context.renderFilter() : () => {}}
        {context.renderTable ? context.renderTable() : () => {}}
        {context.renderNewModal ? context.renderNewModal() : () => {}}
        {context.renderUpdateModal ? context.renderUpdateModal() : () => {}}
      </div>
    </div>
  );
};

export const getPeriodoSemAtividadeState = (
  context: IPeriodoSemAtividadeProps,
  location: any
) => {
  const _urlBase = new URL(`http://localhost${location}`); // using a dummy url for parsing
  const extraFilters = jsonParse(
    _urlBase.searchParams.get('extraFilters') || '{}'
  );
  const baseFilters = _urlBase.searchParams.get('baseFilters') || '';
  const offset: any = _urlBase.searchParams.get('offset') || 0;
  const activePage: any = _urlBase.searchParams.get('page') || 0;
  const itemsPerPage: any = _urlBase.searchParams.get('size') || 20;
  const sortOrder: any = (_urlBase.searchParams.get('sort') || 'id,asc').split(
    ','
  );

  const id = _urlBase.searchParams.get('id') || '';
  const descricao = _urlBase.searchParams.get('descricao') || '';
  const periodoAteStart = _urlBase.searchParams.get('periodoAte_start') || '';
  const periodoAteEnd = _urlBase.searchParams.get('periodoAte_end') || '';
  const periodoDeStart = _urlBase.searchParams.get('periodoDe_start') || '';
  const periodoDeEnd = _urlBase.searchParams.get('periodoDe_end') || '';
  return {
    activePage,
    itemsPerPage,
    sort: sortOrder[0] ? sortOrder[0] : 'id',
    order: sortOrder[1] ? sortOrder[1] : 'asc',
    baseFilters,
    extraFilters,
    offset,
    id,
    descricao,
    periodoAteStart,
    periodoAteEnd,
    periodoDeStart,
    periodoDeEnd,
  };
};

export const getEntitiesFetch = async (
  context: IPeriodoSemAtividadeProps,
  id: any,
  descricao: any,
  periodoAteStart: any,
  periodoAteEnd: any,
  periodoDeStart: any,
  periodoDeEnd: any,
  extraFilters: any,
  page: any,
  size: any,
  sort: any,
  selectFields = '',
  authorizationBearer = '',
  getAccount = false
) => {
  const extraFiltersRequest = extraFilters
    ? Object.keys(extraFilters).length > 0
      ? encodeURI(
          Object.keys(extraFilters)
            .map((v: any) => v + '=' + extraFilters[v])
            .join('&') + '&'
        )
      : ''
    : '';
  const idRequest = id
    ? Array.isArray(window.status)
      ? `id.in=${id.join(',')}&`
      : `id.contains=${id}&`
    : '';
  const descricaoRequest = descricao
    ? Array.isArray(window.status)
      ? `descricao.in=${descricao.join(',')}&`
      : `descricao.contains=${descricao}&`
    : '';
  let periodoAteStartRequest = '';
  let periodoAteEndRequest = '';
  if (
    typeof periodoAteStart !== 'undefined' &&
    periodoAteStart &&
    !(typeof periodoAteEnd !== 'undefined' && periodoAteEnd)
  ) {
    periodoAteStartRequest = `periodoAte.greaterThan=${moment(
      periodoAteStart
    ).format('YYYY-MM-DD')}&`;
  } else if (
    !(typeof periodoAteStart !== 'undefined' && periodoAteStart) &&
    typeof periodoAteEnd !== 'undefined' &&
    periodoAteEnd
  ) {
    periodoAteEndRequest = `periodoAte.lessThan=${moment(periodoAteEnd).format(
      'YYYY-MM-DD'
    )}&`;
  } else if (
    typeof periodoAteStart !== 'undefined' &&
    periodoAteStart &&
    typeof periodoAteEnd !== 'undefined' &&
    periodoAteEnd
  ) {
    periodoAteStartRequest = `periodoAte.between=${moment(
      periodoAteStart
    ).format('YYYY-MM-DD')},${moment(periodoAteEnd).format('YYYY-MM-DD')}&`;
  }
  let periodoDeStartRequest = '';
  let periodoDeEndRequest = '';
  if (
    typeof periodoDeStart !== 'undefined' &&
    periodoDeStart &&
    !(typeof periodoDeEnd !== 'undefined' && periodoDeEnd)
  ) {
    periodoDeStartRequest = `periodoDe.greaterThan=${moment(
      periodoDeStart
    ).format('YYYY-MM-DD')}&`;
  } else if (
    !(typeof periodoDeStart !== 'undefined' && periodoDeStart) &&
    typeof periodoDeEnd !== 'undefined' &&
    periodoDeEnd
  ) {
    periodoDeEndRequest = `periodoDe.lessThan=${moment(periodoDeEnd).format(
      'YYYY-MM-DD'
    )}&`;
  } else if (
    typeof periodoDeStart !== 'undefined' &&
    periodoDeStart &&
    typeof periodoDeEnd !== 'undefined' &&
    periodoDeEnd
  ) {
    periodoDeStartRequest = `periodoDe.between=${moment(periodoDeStart).format(
      'YYYY-MM-DD'
    )},${moment(periodoDeEnd).format('YYYY-MM-DD')}&`;
  }

  const requestUrl = `${apiUrl}?${extraFiltersRequest}${idRequest}${descricaoRequest}${periodoAteStartRequest}${periodoAteEndRequest}${periodoDeStartRequest}${periodoDeEndRequest}${
    sort ? `page=${page >= 0 ? page : 0}&size=${size}&sort=${sort}&` : '?'
  }`;

  const res = await fetch(requestUrl, {
    method: 'get',
    headers: {
      Authorization:
        'Bearer ' +
        (authorizationBearer
          ? authorizationBearer
          : cookie.get(AUTH_TOKEN_KEY)),
      'Content-Type': 'application/json;charset=utf-8',
      'Select-Fields': selectFields,
      'Get-User-Account': getAccount ? '1' : '0',
    },
  });

  return res;
};

export const PeriodoSemAtividade = (props: IPeriodoSemAtividadeProps) => {
  const { t } = useTranslation();
  const _router = useRouter();
  const [periodoSemAtividadeList, setPeriodoSemAtividadeList] = useState(
    props.periodoSemAtividadeList ? props.periodoSemAtividadeList : ([] as any)
  );
  const [periodoSemAtividadeEntity, setPeriodoSemAtividadeEntity] = useState(
    props.periodoSemAtividadeEntity
      ? props.periodoSemAtividadeEntity
      : ({} as any)
  );

  const { loading, totalItems } = props;

  const [state, setState] = useState({
    id: props.id ? props.id : '',
    descricao: props.descricao ? props.descricao : '',
    periodoAteStart: props.periodoAteStart ? props.periodoAteStart : '',
    periodoAteEnd: props.periodoAteEnd ? props.periodoAteEnd : '',
    periodoDeStart: props.periodoDeStart ? props.periodoDeStart : '',
    periodoDeEnd: props.periodoDeEnd ? props.periodoDeEnd : '',
    extraFilters: props.extraFilters ? props.extraFilters : '',
    activePage: props.activePage ? props.activePage : '0',
    itemsPerPage: props.itemsPerPage,
    totalItems: props.totalItems ? props.totalItems : 0,
    sort: props.sort ? props.sort : 'id',
    order: props.order ? props.order : 'asc',
  } as any);

  useEffect(() => {
    context.getAllEntities();
  }, [_router.asPath]);

  const context = {
    t,
    props,
    _router,
    state,
    setState,
    periodoSemAtividadeList,
    setPeriodoSemAtividadeList,
    periodoSemAtividadeEntity,
    setPeriodoSemAtividadeEntity,

    getAllEntities: props['getAllEntities']
      ? (_fieldsBase) => props['getAllEntities'](context, _fieldsBase)
      : (_fieldsBase) => getAllEntities(context, _fieldsBase),
    sortEntities: props['sortEntities']
      ? () => props['sortEntities'](context)
      : () => sortEntities(context),
    sortFunction: props['sortFunction']
      ? (p) => props['sortFunction'](context, p)
      : (p) => sortFunction(context, p),
    handlePagination: props['handlePagination']
      ? (activePage) => props['handlePagination'](context, activePage)
      : (activePage) => handlePagination(context, activePage),
    deleteEntityModal: props['deleteEntityModal']
      ? (_cadastroTelemedicinaEntity) =>
          props['deleteEntityModal'](context, _cadastroTelemedicinaEntity)
      : (_cadastroTelemedicinaEntity) =>
          deleteEntityModal(context, _cadastroTelemedicinaEntity),
    saveEntity: props['saveEntity']
      ? (isNew) => props['saveEntity'](context, isNew)
      : (isNew) => saveEntity(context, isNew),
    getFullEntityFormValue: props['getFullEntityFormValue']
      ? (v: any) => props['getFullEntityFormValue'](context, v)
      : (v: any) => getFullEntityFormValue(context, v),
    openUpdateModal: props['openUpdateModal']
      ? (v: any, edit = true) => props['openUpdateModal'](context, v, edit)
      : (v: any, edit = true) => openUpdateModal(context, v, edit),
    renderBodyUpdate: props['renderBodyUpdate']
      ? (_periodoSemAtividadeEntity: any, isNew: any) =>
          props['renderBodyUpdate'](context, _periodoSemAtividadeEntity, isNew)
      : (_periodoSemAtividadeEntity: any, isNew: any) =>
          renderBodyUpdate(context, _periodoSemAtividadeEntity, isNew),
    renderUpdateModal: props['renderUpdateModal']
      ? () => props['renderUpdateModal'](context)
      : () => renderUpdateModal(context),
    renderNewModal: props['renderNewModal']
      ? () => props['renderNewModal'](context)
      : () => renderNewModal(context),

    renderFilter: props['renderFilter']
      ? () => props['renderFilter'](context)
      : () => renderFilter(context),
    cancelFilters: props['cancelFilters']
      ? () => props['cancelFilters'](context)
      : () => cancelFilters(context),
    filterEntity: props['filterEntity']
      ? () => props['filterEntity'](context)
      : () => filterEntity(context),
    renderHeaderUpdate: props['renderHeaderUpdate']
      ? (isNew) => props['renderHeaderUpdate'](context, isNew)
      : (isNew) => renderHeaderUpdate(context, isNew),
    renderTable: props['renderTable']
      ? () => props['renderTable'](context)
      : () => renderTable(context),
    handleSyncList: props['handleSyncList']
      ? () => props['handleSyncList'](context)
      : () => handleSyncList(context),
    getEntitiesFetch: props['getEntitiesFetch']
      ? (
          id: any,
          descricao: any,
          periodoAteStart: any,
          periodoAteEnd: any,
          periodoDeStart: any,
          periodoDeEnd: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          props['getEntitiesFetch'](
            context,
            id,
            descricao,
            periodoAteStart,
            periodoAteEnd,
            periodoDeStart,
            periodoDeEnd,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          )
      : (
          id: any,
          descricao: any,
          periodoAteStart: any,
          periodoAteEnd: any,
          periodoDeStart: any,
          periodoDeEnd: any,
          extraFilters: any,
          page: any,
          size: any,
          sort: any,
          selectFields = '',
          authorizationBearer = ''
        ) =>
          getEntitiesFetch(
            context,
            id,
            descricao,
            periodoAteStart,
            periodoAteEnd,
            periodoDeStart,
            periodoDeEnd,
            extraFilters,
            page,
            size,
            sort,
            selectFields,
            authorizationBearer
          ),

    createEntityFetch: props['createEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['createEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          createEntityFetch(context, entity, listFiltersPage),
    updateEntityFetch: props['updateEntityFetch']
      ? (entity: any, listFiltersPage: any) =>
          props['updateEntityFetch'](context, entity, listFiltersPage)
      : (entity: any, listFiltersPage: any) =>
          updateEntityFetch(context, entity, listFiltersPage),
    deleteEntityFetch: props['deleteEntityFetch']
      ? (id: any, listFiltersPage: any) =>
          props['deleteEntityFetch'](context, id, listFiltersPage)
      : (id: any, listFiltersPage: any) =>
          deleteEntityFetch(context, id, listFiltersPage),
    getEntityFetch: props['getEntityFetch']
      ? (id) => props['getEntityFetch'](context, id)
      : (id) => getEntityFetch(context, id),

    getPeriodoSemAtividadeState: props['getPeriodoSemAtividadeState']
      ? (location) => props['getPeriodoSemAtividadeState'](context, location)
      : (location) => getPeriodoSemAtividadeState(context, location),
    getEntityFiltersURL: props['getEntityFiltersURL']
      ? () => props['getEntityFiltersURL'](context)
      : () => getEntityFiltersURL(context),
    return: props['returnFunction']
      ? () => props['returnFunction'](context)
      : () => returnFunction(context),
  };

  return context;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const q = nextCookie(ctx);
  const _fieldsBase = getPeriodoSemAtividadeState(null, ctx.resolvedUrl);

  const res = await getEntitiesFetch(
    null,
    _fieldsBase.id,
    _fieldsBase.descricao,
    _fieldsBase.periodoAteStart,
    _fieldsBase.periodoAteEnd,
    _fieldsBase.periodoDeStart,
    _fieldsBase.periodoDeEnd,
    _fieldsBase.extraFilters,
    _fieldsBase.activePage,
    _fieldsBase.itemsPerPage,
    `${_fieldsBase.sort},${_fieldsBase.order}`,
    '',
    q[AUTH_TOKEN_KEY],
    true
  );

  if (!q[AUTH_TOKEN_KEY] || q[AUTH_TOKEN_KEY] === '' || res.status === 401) {
    ctx.res.setHeader('Location', `/login`);
    ctx.res.statusCode = 302;
    ctx.res.end();
    return { props: {} };
  }

  const userAccount = JSON.parse(res.headers.get('User-Account-Data'));

  return {
    props: {
      ..._fieldsBase,
      userAccount,
      totalItems: res.headers.get('X-Total-Count'),
      periodoSemAtividadeList: await res.json(),
    },
  };
};

export default PeriodoSemAtividade;
