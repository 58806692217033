import { Box, Button, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InputRadio } from 'components/form/radio';
import { Loading } from 'components/Loading';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY } from 'shared/util';

import personIncon from '../../assets/personIcon.svg';
import pinIcon from '../../assets/pinIcon.svg';
import professionalIcon from '../../assets/professionalIcon.svg';
import specialityIcon from '../../assets/specialityIcon.svg';
import timeIcon from '../../assets/timeIcon.svg';

interface cancelarAgendamentoProps {
  agendamentos: any;
  isOpen: boolean;
  onClose: () => void;
  status: string;
  action: string;
  loadAgendamentos: (status?: string) => Promise<void>;
}
const RemarcarAgendamento = ({
  agendamentos,
  isOpen,
  onClose,
  status,
  action,
  loadAgendamentos,
}: cancelarAgendamentoProps) => {
  const formMethods = useForm();
  const toast = useToast();
  const [horariosDisponiveisAgenda, setHorariosDisponiveisAgenda] =
    useState<any>();
  const [loading, setLoading] = useState(false);
  const [horarioSelecionado, setHorarioSelecionado] = useState<string>();
  const [horariosDisponiveisExame, setHorariosDisponiveisExame] = useState([]);
  const [dataEscolhida, setDataEscolhida] = useState<string>();

  const closeModal = () => {
    formMethods.reset();
    setHorarioSelecionado(null);
    setHorariosDisponiveisAgenda(null);
    setHorariosDisponiveisExame([]);
    setDataEscolhida(null);
    loadAgendamentos();
    onClose();
  };

  const obterDiaSemanaPorExtenso = (diaSemana) => {
    const dias = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    return dias[diaSemana];
  };

  // Função para obter o mês por extenso
  const obterMesPorExtenso = (mes) => {
    const meses = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    return meses[mes];
  };

  const getDay = (date) => {
    let data = new Date(date);

    let diaSemana = obterDiaSemanaPorExtenso(data.getDay());
    let dia = data.getDate();
    let mes = obterMesPorExtenso(data.getMonth());
    let ano = data.getFullYear();
    let dataPorExtenso = `${diaSemana}, ${dia} de ${mes} de ${ano}`; // Terça-feira, 26 de outubro de 2023

    return dataPorExtenso;
  };


  const horariosDisponiveis = async (caDtCarga) => {
    setLoading(true);
    setDataEscolhida(caDtCarga);
    if (agendamentos.tipo !== 'exame') {
      const { data } = await api.post('/aswagendarusuario', {
        method: '_loadHorariosDisponiveis',
        caDtCarga,
        psCPF: agendamentos.psCPF,
        sgInstSaude: 'techtools',
        agTeleFisico: 'F',
        laDescricao: 'Antonio Lopes',
        sgCliente: 'asg',
      });

      if (data.success) {
        setHorariosDisponiveisAgenda(data.data);
        setLoading(false);
      }
      setLoading(false);
    } else {
      const { data } = await api.post('aswexame', {
        method: '_findDayExamSchedule',
        exCodigo: agendamentos.exCodigo,
        agDataHoraAgendada: caDtCarga,
        Authorization: Cookies.get('AUTH_TOKEN_KEY'),
      });
      if (data.success) {
        setHorariosDisponiveisExame(data.data);
      }
    }
    setLoading(false);
  };

  const remarcar = async () => {
    setLoading(true);
    if (agendamentos.tipo === 'consulta') {
      const { data } = await api.post('aswagendarusuario', {
        method: '_remarcarConsulta',
        sgInstSaude: agendamentos.sgInstSaude,
        psCPF: agendamentos.psCPF,
        usuarioCPF: agendamentos.usuarioCPF,
        agDataHoraAgendaAntiga: agendamentos.agDataHoraAgenda,
        agDataHoraAgendaNova: `${dataEscolhida} ${horarioSelecionado}:00`,
      });

      if (data.success) {
        toast({ title: 'Agendamento Remarcado', status: 'success' });
        setLoading(false);
        closeModal();
      }
    } else {
      const { data } = await api.post('aswagendarusuario', {
        method: '_remarcarExame',
        agCodigo: agendamentos.codigo,
        usuarioCPF: agendamentos.usuarioCPF,
        agDataHoraAgendaAntiga: agendamentos.agDataHoraAgenda,
        agDataHoraAgendaNova: `${dataEscolhida} ${horarioSelecionado}:00`,
      });

      if (data.success) {
        toast({ title: 'Exame Remarcado', status: 'success' });
        setLoading(false);
        closeModal();
      }
    }
  };

  const getHour = (date) => {
    let data = new Date(date);

    // Formatação do horário para AM/PM
    let horario = data.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Verifica se é AM ou PM
    let periodo = data.getHours() < 12 ? 'AM' : 'PM';

    return `${horario} ${periodo}`;
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remarcação de atendimento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize="14px">
                Tem certeza que deseja reagendar{' '}
                {agendamentos.tipo === 'consulta' ? 'a consulta' : 'o exame'}{' '}
                abaixo? Selecione uma data e horário disponíveis na agenda.
              </Text>
            </Box>
            <Box p={3} mb={4}>
              <Box display="flex" mb={3}>
                <Box mr={3}>
                  <img src={personIncon} alt="icon1" />
                </Box>
                <Box>
                  <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                    {agendamentos.nmUsuario}
                  </Text>
                </Box>
              </Box>
              <Box display="flex" mb={3}>
                <Box mr={3}>
                  <img src={specialityIcon} alt="icon1" />
                </Box>
                <Box>
                  <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                    {agendamentos.peDescricao}
                  </Text>
                </Box>
              </Box>
              {agendamentos.tipo === 'consulta' && (
                <Box display="flex" mb={3}>
                  <Box mr={3}>
                    <img src={professionalIcon} alt="icon1" />
                  </Box>
                  <Box>
                    <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                      {agendamentos.psNome}
                    </Text>
                  </Box>
                </Box>
              )}

              <Box>
                <Box display="flex" mb={3}>
                  <Box mr={3}>
                    <img src={timeIcon} alt="icon1" />
                  </Box>
                  <Box>
                    <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                      Data*
                    </Text>
                    <Input
                      name="dataAgenda"
                      type="date"
                      onChange={(e) => horariosDisponiveis(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
              {agendamentos.tipo === 'consulta' &&
                horariosDisponiveisAgenda && (
                  <Tabs>
                    <TabList>
                      {horariosDisponiveisAgenda?.madrugada.length > 0 && (
                        <Tab>Madrugada</Tab>
                      )}
                      {horariosDisponiveisAgenda?.manha.length > 0 && (
                        <Tab>Manhã</Tab>
                      )}
                      {horariosDisponiveisAgenda?.tarde.length > 0 && (
                        <Tab>Tarde</Tab>
                      )}
                      {horariosDisponiveisAgenda?.noite.length > 0 && (
                        <Tab>Noite</Tab>
                      )}
                    </TabList>

                    <TabPanels>
                      {horariosDisponiveisAgenda?.madrugada.length > 0 && (
                        <TabPanel>
                          <SimpleGrid columns={4} spacingX={3} spacingY={3}>
                            {horariosDisponiveisAgenda.madrugada.map(
                              (horario: string[]) => (
                                <>
                                  <Box
                                    textAlign="center"
                                    bgColor={
                                      horarioSelecionado === horario[0]
                                        ? '#63ADEC'
                                        : '#63ADEC4F'
                                    }
                                    borderRadius="10px"
                                    cursor="pointer"
                                    onClick={() =>
                                      setHorarioSelecionado(horario[0])
                                    }
                                  >
                                    {getHour('2024-01-01 ' + horario[0])}
                                  </Box>
                                  {horario.length > 1 && (
                                    <Box
                                      textAlign="center"
                                      bgColor={
                                        horarioSelecionado === horario[1]
                                          ? '#63ADEC'
                                          : '#63ADEC4F'
                                      }
                                      borderRadius="10px"
                                      cursor="pointer"
                                      onClick={() =>
                                        setHorarioSelecionado(horario[1])
                                      }
                                    >
                                      {getHour('2024-01-01 ' + horario[1])}
                                    </Box>
                                  )}
                                </>
                              )
                            )}
                          </SimpleGrid>
                        </TabPanel>
                      )}
                      {horariosDisponiveisAgenda?.manha.length > 0 && (
                        <TabPanel>
                          <SimpleGrid columns={4} spacingX={3} spacingY={3}>
                            {horariosDisponiveisAgenda.manha.map(
                              (horario: string[]) => (
                                <>
                                  <Box
                                    textAlign="center"
                                    bgColor={
                                      horarioSelecionado === horario[0]
                                        ? '#63ADEC'
                                        : '#63ADEC4F'
                                    }
                                    borderRadius="10px"
                                    cursor="pointer"
                                    onClick={() =>
                                      setHorarioSelecionado(horario[0])
                                    }
                                  >
                                    {getHour('2024-01-01 ' + horario[0])}
                                  </Box>
                                  {horario.length > 1 && (
                                    <Box
                                      textAlign="center"
                                      bgColor={
                                        horarioSelecionado === horario[1]
                                          ? '#63ADEC'
                                          : '#63ADEC4F'
                                      }
                                      borderRadius="10px"
                                      cursor="pointer"
                                      onClick={() =>
                                        setHorarioSelecionado(horario[1])
                                      }
                                    >
                                      {getHour('2024-01-01 ' + horario[1])}
                                    </Box>
                                  )}
                                </>
                              )
                            )}
                          </SimpleGrid>
                        </TabPanel>
                      )}
                      {horariosDisponiveisAgenda?.tarde.length > 0 && (
                        <TabPanel>
                          <SimpleGrid columns={4} spacingX={3} spacingY={3}>
                            {horariosDisponiveisAgenda.tarde.map(
                              (horario: string[]) => (
                                <>
                                  <Box
                                    textAlign="center"
                                    bgColor={
                                      horarioSelecionado === horario[0]
                                        ? '#63ADEC'
                                        : '#63ADEC4F'
                                    }
                                    borderRadius="10px"
                                    cursor="pointer"
                                    onClick={() =>
                                      setHorarioSelecionado(horario[0])
                                    }
                                  >
                                    {getHour('2024-01-01 ' + horario[0])}
                                  </Box>
                                  {horario.length > 1 && (
                                    <Box
                                      textAlign="center"
                                      bgColor={
                                        horarioSelecionado === horario[1]
                                          ? '#63ADEC'
                                          : '#63ADEC4F'
                                      }
                                      borderRadius="10px"
                                      cursor="pointer"
                                      onClick={() =>
                                        setHorarioSelecionado(horario[1])
                                      }
                                    >
                                      {getHour('2024-01-01 ' + horario[1])}
                                    </Box>
                                  )}
                                </>
                              )
                            )}
                          </SimpleGrid>
                        </TabPanel>
                      )}
                      {horariosDisponiveisAgenda?.noite.length > 0 && (
                        <TabPanel>
                          <SimpleGrid columns={4} spacingX={3} spacingY={3}>
                            {horariosDisponiveisAgenda.noite.map(
                              (horario: string[]) => (
                                <>
                                  <Box
                                    textAlign="center"
                                    bgColor={
                                      horarioSelecionado === horario[0]
                                        ? '#63ADEC'
                                        : '#63ADEC4F'
                                    }
                                    borderRadius="10px"
                                    cursor="pointer"
                                    onClick={() =>
                                      setHorarioSelecionado(horario[0])
                                    }
                                  >
                                    {getHour('2024-01-01 ' + horario[0])}
                                  </Box>
                                  {horario.length > 1 && (
                                    <Box
                                      textAlign="center"
                                      bgColor={
                                        horarioSelecionado === horario[1]
                                          ? '#63ADEC'
                                          : '#63ADEC4F'
                                      }
                                      borderRadius="10px"
                                      cursor="pointer"
                                      onClick={() =>
                                        setHorarioSelecionado(horario[1])
                                      }
                                    >
                                      {getHour('2024-01-01 ' + horario[1])}
                                    </Box>
                                  )}
                                </>
                              )
                            )}
                          </SimpleGrid>
                        </TabPanel>
                      )}
                    </TabPanels>
                  </Tabs>
                )}
              {agendamentos.tipo === 'exame' &&
                horariosDisponiveisExame.length > 0 && (
                  <Box w="full">
                    <SimpleGrid columns={4} spacingX={3} spacingY={3}>
                      {horariosDisponiveisExame.map((horario: any) => (
                        <>
                          {horario.available && (
                            <Box
                              textAlign="center"
                              bgColor={
                                horarioSelecionado === horario.hour
                                  ? '#63ADEC'
                                  : '#63ADEC4F'
                              }
                              borderRadius="10px"
                              cursor="pointer"
                              onClick={() =>
                                setHorarioSelecionado(horario.hour)
                              }
                            >
                              {getHour('2024-01-01 ' + horario.hour)}
                            </Box>
                          )}
                        </>
                      ))}
                    </SimpleGrid>
                  </Box>
                )}
              {/* <Box display="flex" mb={3}>
                <Box mr={3}>
                  <img src={timeIcon} alt="icon1" />
                </Box>
                <Box>
                  <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                    {getHour(agendamentos.agDataHoraAgenda)}
                  </Text>
                  <Text fontSize="14px" color="rgba(0, 0, 0, 0.4)">
                    {getDay(agendamentos.agDataHoraAgenda)}
                  </Text>
                </Box>
              </Box> */}
            </Box>
          </ModalBody>

          <ModalFooter w="full" display="flex" justifyContent="center">
            <Button
              bgColor="rgba(86, 161, 225, 1)"
              color="white"
              disabled={horarioSelecionado || loading ? false : true}
              onClick={remarcar}
            >
              Remarcar
            </Button>
          </ModalFooter>
          {loading && <Loading />}
        </ModalContent>
      </Modal>
    </>
  );
};

export default RemarcarAgendamento;
