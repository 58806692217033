import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import {
  DialogConfirm,
  DialogConfirmRefProps
} from 'components/dialog-confirm';
import { Pagination } from 'components/pagination';
import { WrapperLoading } from 'components/wraper-loading';
import { useApiRequest, useFlashText, useUrlParams, useUser } from 'hooks';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { dateTimeToServer, LOG } from 'shared/util';
import { getDataValue } from 'shared/util/pegar-hora-consultas';
import { TipoAtendimentoType } from 'types/consulta';
import { Modaltrigem } from './modal-triagem';

type LocaisType = {
  sgCliente: string;
  sgInstSaude: string;
  laDescricao: string;
  laEndereco: string;
  laCEP: string;
  laNumero: string;
  laCidade: string;
  laUF: string;
  laBairro: string;
  laComplemento: string;
  dtInclusao: string;
  dtModificacao: string;
  laNome: string;
  laTriagem: number;
  laAtivo: number;
  laHoraIncio?: Date;
  laHoraFim?: Date;
  laQRCode?: string;
  filaAtendimento: Array<{
    faCodigo: string;
    faDescricao: string;
  }>;
};

export type AgendaPresencialType = {
  agTeleFisico: string;
  agendamento: string;
  convenio: string;
  cpf: string;
  descricao: string;
  dtChechin: Date;
  genero: string;
  idConsulta: string;
  nmInstSaude: string;
  nome: string;
  numeroCartao: string;
  planoConvenio: string;
  sgInstSaude: string;
  status: string;
  usuario: string;
  usuarioNomeSocial:string
  usuarioCPF: string;
  usuarioGenero: string;
  tempoMedioConsulta: number;
  usuarioIdade: number;
  triagemExecutada: number;
  linkProfSaude?: string;
  locationName?: string;
  validadeCartao?: Date;

  statusTriagem?: string;
};

type ListagemAtendimentosProps = {
  tipoAtendimento: TipoAtendimentoType;
};

type MotivoCancelamentoType = {
  mcDescricao: string;
  mcOrdemExibicao: number;
  mcPSPaciente: string;
};

export const ListagemAtendimentos = (props: ListagemAtendimentosProps) => {
  const history = useHistory();
  const urlParam = useUrlParams();
  const listarConversasInterval = useRef(null);
  const controller = new AbortController();
  const dataHoje = new Date();
  const [intervaloBusca, setIntervaloBusca] = useState(true);

  const confirmCancelRef = useRef<DialogConfirmRefProps>(null);

  const [openModalWithConsulta, setOpenModalWithConsulta] =
    useState<AgendaPresencialType | null>(null);

  const IS_PRESENCIAL = props.tipoAtendimento === 'presencial';
  const usuarioLogado = useUser();

  const [local, setLocal] = useState('');
  const [sgInstSaude, setSgInstSaude] = useState('');

  const agendamentosHook = useApiRequest<AgendaPresencialType[]>(
    'aswagendausuprofsaude',
    []
  );
  const [atendimentoSelecionado, setAtendimentoSelecionado] =
    useState<AgendaPresencialType | null>(null);

  const motivoFlash = useFlashText();

  const [motivoCancelamento, setMotivoCancelamento] =
    useState<MotivoCancelamentoType | null>(null);
  const [busca, setBusca] = useState('');
  const [agendamentosToList, setAgendamentosToList] = useState<any[]>([]);

  const motivosHook = useApiRequest<Array<MotivoCancelamentoType>>(
    'aswmotivocancelamento',
    []
  );
  const locaisHook = useApiRequest<LocaisType[]>(
    'aswagendalocaisatendimento',
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  //página
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [currentPosts, setCurrentPosts] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  async function listarLocais() {
    locaisHook.request({
      method: 'listarAgendaLocalAtendimento',
      laDescricao: '',
      psCpf: usuarioLogado?.cpf,
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: sgInstSaude,
    });
  }

  async function listarAgendamentos({
    sgInstSaude,
    local,
    dataHoje,
  }: {
    sgInstSaude: string;
    local: string;
    dataHoje: Date;
  }) {

    if (IS_PRESENCIAL && !local) {
      return;
    }
    agendamentosHook.request({
      method: IS_PRESENCIAL
        ? '_listarAgendamentosPresencialPS'
        : '_listarAgendamentosTelessaudePS',
      psCPF: usuarioLogado?.cpf,
      dataInicial: dateTimeToServer(new Date(dataHoje.setHours(0, 0, 0))),
      localAtendimento: local,
      sgCliente: usuarioLogado?.sgCliente,
      sgInstSaude: sgInstSaude,
      config: {
        reloadStateToInitial: true,
      },
    });
    agendamentosHook.state.sort(function (a, b) {
      if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
        return 1;
      }
      if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
        return -1;
      }
      return 0;
    });
    setAgendamentosToList(agendamentosHook.state);
    setIntervaloBusca(false);
  }

  useEffect(() => {
    let dadosAtuais = agendamentosToList.slice(
      indexOfFirstPost,
      indexOfLastPost
    );
    setCurrentPosts(dadosAtuais);
  }, [agendamentosToList, indexOfFirstPost, indexOfLastPost]);

  useEffect(() => {
    if (!intervaloBusca) {
      setIntervaloBusca(true);
      clearTimeout(listarConversasInterval.current);
      controller.abort();
      listarConversasInterval.current = setTimeout(
        () =>
          listarAgendamentos({
            sgInstSaude,
            local: local,
            dataHoje,
          }),
        30000
      );
    }
  }, [intervaloBusca]);

  useEffect(() => {
    if (locaisHook.state.length > 0 && props.tipoAtendimento === 'presencial') {
      const local = urlParam.get('instlocal');
      if (local) {
        const index = local.indexOf('/');
        const localSelected = local.substring(index + 1);
        setLocal(localSelected);
        listarAgendamentos({
          sgInstSaude,
          local: localSelected,
          dataHoje,
        });
      }
    }
  }, [locaisHook.state, sgInstSaude]);

  useEffect(() => {
    if (usuarioLogado?.sgCliente) {
      let instituicaoParam = urlParam.get('instlocal');

      if (instituicaoParam) {
        if (props.tipoAtendimento === 'presencial') {
          instituicaoParam = instituicaoParam.split('/', 1)[0];
        } else {
          listarAgendamentos({
            sgInstSaude: instituicaoParam,
            local: '',
            dataHoje,
          });
        }
        setSgInstSaude(instituicaoParam);
      }
    }

  }, [usuarioLogado]);

  useEffect(() => {
    if (!busca) {
      filtrarDados('');
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  }, [agendamentosHook.state]);

  useEffect(() => {
    if (!!sgInstSaude) {
      listarLocais();
      setLocal('');
    }
  }, [sgInstSaude]);

  const filtrarDados = (busca: string) => {
    if (busca.trim()) {
      const buscaFiltrada = agendamentosHook.state.filter(
        (paciente) =>
          paciente.usuario.toLowerCase().includes(busca.toLocaleLowerCase()) ||
          paciente.usuarioCPF.toLowerCase().includes(busca.toLocaleLowerCase())
      );
      buscaFiltrada.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(buscaFiltrada);
    } else {
      agendamentosHook.state.sort(function (a, b) {
        if (getDataValue(a.agendamento) > getDataValue(b.agendamento)) {
          return 1;
        }
        if (getDataValue(a.agendamento) < getDataValue(b.agendamento)) {
          return -1;
        }
        return 0;
      });
      setAgendamentosToList(agendamentosHook.state);
    }
  };

  return (
    <Box>
      <Box>
        <Heading as="h3" fontSize={'lg'} fontWeight={'normal'}>
          ATENDIMENTO - {IS_PRESENCIAL ? 'PRESENCIAL' : 'TELESSAÚDE'}
        </Heading>
        <div className="mt-3">
          <p>
            <small>Instituição de Saúde</small>
          </p>
          <Select
            name={'instituicao'}
            size={'md'}
            placeholder={'Selecione a Instituição'}
            value={sgInstSaude}
            onChange={(event) => {
              const { value } = event.target;
              if (value) {
                if (props.tipoAtendimento != 'presencial') {
                  history.push('?instlocal=' + value);
                }
                setSgInstSaude(value);
                listarAgendamentos({
                  sgInstSaude: value,
                  local: local,
                  dataHoje,
                });
              }
            }}
          >
            {(usuarioLogado?.institucoes || []).map((item, index) => {
              return (
                <option key={index} value={item.institucao}>
                  {item.institucaoNome}
                </option>
              );
            })}
          </Select>
        </div>
        {props.tipoAtendimento === 'presencial' && (
          <div className="mt-3">
            <p>
              <small>Local de Atendimento</small>
            </p>
            <Flex alignItems="center">
              <Box flex="1">
                <WrapperLoading isLoading={locaisHook.isLoading}>
                  <Select
                    disabled={sgInstSaude == ''}
                    size={'md'}
                    placeholder={'Selecione o local'}
                    value={local}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value) {
                        history.push('?instlocal=' + sgInstSaude + '/' + value);
                        setLocal(value);
                        listarAgendamentos({
                          sgInstSaude,
                          local: value,
                          dataHoje,
                        });
                      }
                    }}
                  >
                    {locaisHook.state.map((item, index) => {
                      return (
                        <option key={index} value={item.laDescricao}>
                          {item.laNome}
                        </option>
                      );
                    })}
                  </Select>
                </WrapperLoading>
              </Box>
            </Flex>
          </div>
        )}
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col md="12" className="col-filter-cadastro-cliente-cliente">
              <Row className="mr-1 mt-1">
                <input
                  className="form-control"
                  type="text"
                  style={{ borderRadius: '1rem' }}
                  id="cadastro-cliente-cliente"
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  placeholder="Buscar"
                />
              </Row>
            </Col>
          </div>
          <div className="row mb-2 mr-4 justify-content-end pull-right">
            <Button
              id="search-submit"
              color="primary"
              onClick={() => filtrarDados(busca)}
            >
              <i className="fa fa-search" />
            </Button>
          </div>
        </div>
      </Box>
      <Flex mt={5}>
        <Box mx="auto" w="full" >
          <div className="table-responsive">
            <>
              {agendamentosToList.length > 0 ? (
                <div id="atendimento-table-list" className="table-list">
                  <Table
                    id="atendimento-table-list"
                    responsive
                    aria-describedby="atendimento-heading"
                    className={
                      'table-hover table-striped table-responsive-css td-atendimentos'
                    }
                  >
                    <thead className={'thead-light'}>
                      <tr>
                        <th className="text-left">Horário de atendimento</th>
                        <th className="text-left">Paciente</th>
                        <th className="text-center">CPF</th>
                        <th className="text-center">Idade</th>
                        <th className="text-center">Gênero</th>
                        <th className="text-left">Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((atendimento, i: number) => (
                        <tr key={`entity-${i}`}>
                          <td id="horario-cell" className="text-left">
                            <b className="visible-xs">Horário de Atendimento</b>
                            {moment(atendimento.agendamento).format(
                              'HH:mm'
                            )} -{' '}
                            {moment(atendimento.agendamento)
                              .add(
                                Number(atendimento.tempoMedioConsulta),
                                'minutes'
                              )
                              .format('HH:mm')}
                          </td>
                          <td id="paciente-cell" className="text-left">
                            <b className="visible-xs"> Paciente </b>
                            {atendimento.usuario}
                          </td>

                          <td className="text-center">
                            <b className="visible-xs">CPF</b>
                            {atendimento.usuarioCPF}
                          </td>
                          <td className="text-center">
                            <b className="visible-xs">Idade</b>
                            {atendimento.usuarioIdade}
                          </td>
                          <td className="text-center">
                            <b className="visible-xs">Gênero</b>
                            {atendimento.usuarioGenero == 'F'
                              ? 'Feminino'
                              : 'Masculino'}
                          </td>

                          <td id="status-cell" className="text-left">
                            {atendimento.status ? (
                              <span
                                className={
                                  atendimento.status === 'realizado'
                                    ? 'atendimento-status-realizado'
                                    : atendimento.status === 'cancelado'
                                      ? 'atendimento-status-cancelado'
                                      : atendimento.status === 'confirmado'
                                        ? 'atendimento-status-confirmado'
                                        : 'atendimento-status-sem-atendimento'
                                }
                              >
                                {atendimento?.statusTriagem
                                  ? `${atendimento?.statusTriagem} - `
                                  : ''}
                                {atendimento.status}
                              </span>
                            ) : (
                              <> </>
                            )}
                          </td>

                          <td className="text-left">
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Ir para consulta"
                              className="btn btn-primary btn-sm"
                              onClick={() => {

                                history.push(
                                  `${history.location.pathname}/${atendimento.idConsulta}`
                                );
                              }}
                            >
                              <i className="fa fa-play" />
                            </button>
                            &nbsp;&nbsp;
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Ver detalhes "
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                setOpenModalWithConsulta(atendimento);
                              }}
                            >
                              <i className="fa fa-file" />
                            </button>
                            &nbsp;&nbsp;
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Excluir"
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                if (motivosHook.state.length < 1) {
                                  motivosHook.request({
                                    method: '_list',
                                    mcPSPaciente: 'M',
                                  });
                                }
                                setMotivoCancelamento(null);
                                setAtendimentoSelecionado(atendimento);
                                confirmCancelRef?.current?.open();
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {agendamentosToList.length > 10 && (
                    <div>
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={agendamentosToList.length}
                        paginate={paginate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="alert alert-warning">
                  Não foram encontrados atendimentos
                </div>
              )}
            </>
          </div>
        </Box>
      </Flex>

      {openModalWithConsulta && (
        <Modaltrigem
          nomeUsuario={openModalWithConsulta.usuario}
          usuarioNomeSocial={openModalWithConsulta.usuarioNomeSocial}
          idConsulta={openModalWithConsulta.idConsulta}
          close={() => {
            setOpenModalWithConsulta(null);
          }}
        />
      )}

      <DialogConfirm
        ref={confirmCancelRef}
        titulo="Cancelar o atendimento"
        cancelButton={{ text: 'Cancelar' }}
        buttons={[
          {
            text: 'Confirma',
            color: 'red',
            // closeOnClick: true,
            onClick: () => {
              LOG(motivoCancelamento);
              if (!motivoCancelamento) {
                motivoFlash.setText('Selecione o motivo!');
                return;
              }
              agendamentosHook
                .submit({
                  method: '_cancel',
                  agDataHoraAgenda: atendimentoSelecionado?.agendamento,
                  agIdConsulta: atendimentoSelecionado?.idConsulta,
                  psCPF: usuarioLogado?.cpf,
                  usuarioCPF: atendimentoSelecionado?.usuarioCPF,
                  mcDescricao: motivoCancelamento?.mcDescricao,
                  mcPSPaciente: motivoCancelamento?.mcPSPaciente,
                  sgCliente: usuarioLogado?.sgCliente,
                  sgInstSaude,
                })
                .then((response) => {
                  if (response.success) {
                    agendamentosHook.setState(
                      agendamentosHook.state.filter(
                        (a) =>
                          a.idConsulta !== atendimentoSelecionado?.idConsulta
                      )
                    );
                  }
                  confirmCancelRef?.current?.close();
                });
            },
          },
        ]}
      >
        <Box flex="1">
          <WrapperLoading isLoading={motivosHook.isLoading}>
            <Select
              size={'md'}
              value={motivoCancelamento?.mcDescricao || ''}
              onChange={(event) => {
                const { value } = event.target;
                if (value) {
                  event.persist();
                  const motivo = motivosHook.state.find(
                    (m) => m.mcDescricao === value
                  );
                  if (motivo) {
                    setMotivoCancelamento(motivo);
                  }
                }
              }}
            >
              <option value="">Selecione o motivo</option>
              {motivosHook.state.map((item, index) => {
                return (
                  <option key={index} value={item.mcDescricao}>
                    {item.mcDescricao}
                  </option>
                );
              })}
            </Select>
          </WrapperLoading>
          <small>&nbsp;{motivoFlash.text}</small>
        </Box>
      </DialogConfirm>
    </Box>
  );
};
