import { useEffect, useState } from 'react';
import { useApiRequest, useUrlParams, useUser } from 'hooks';
import ReactSelect from 'react-select';
import { Col, Modal, Row, Table } from 'reactstrap';
import { changeInputText, dateTimeToScreen } from 'shared/util';
import { useHistory } from 'react-router-dom';
import { DocumentViewicon } from 'components/icons';
import { Loading } from 'components/Loading';
import { IEpisodioEncaminhamento } from 'types/episodio';
import { PreServEncaminhamentoUsuarioAgenda } from 'components/pre-serv-encaminhamento-paciente-agenda';
import cpfMask from 'shared/util/cpfMask';

export function PrevServEncaminhamentoUsuario() {
  const user = useUser();
  const urlParams = useUrlParams();
  const [episodioSMSFilter, setEpisodioSMSFilter] = useState<
    IEpisodioEncaminhamento[]
  >([]);
  const [instituicaoSelecionada, setInstituicaoSelecionada] = useState<string>(
    urlParams.get('inst')
  );

  const [textoFiltro, setTextoFiltro] = useState<string>('');
  const [episodio, setEpisodio] = useState<IEpisodioEncaminhamento>();

  const episodioAPI = useApiRequest<IEpisodioEncaminhamento[]>(
    'aswepisodiosms',
    []
  );

  const listarEpisodiosUsuario = async (sgInstSaude: string) => {
    if (!sgInstSaude) return;
    await episodioAPI.request({
      method: 'listarEncaminhamentoEpisodio',
      sgCliente: user?.sgCliente,
      sgInstSaude,
      epStTriagemMedica: ['A'],
    });
  };

  const instList = (user?.institucoes || []).map((i) => ({
    ...i,
    value: i.institucao,
    label: i.institucaoNome,
  }));

  useEffect(() => {
    listarEpisodiosUsuario(instituicaoSelecionada);
  }, [instituicaoSelecionada]);

  useEffect(() => {
    setEpisodioSMSFilter(episodioAPI.state);
  }, [episodioAPI.state]);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = episodioAPI.state.filter((consulta) => {
        return Object.values(consulta).some(
          (key) => String(key).toLowerCase().includes(textoFiltro.toLowerCase())
          // ||
          // String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setEpisodioSMSFilter(filtered);
    } else {
      setEpisodioSMSFilter(episodioAPI.state);
    }
  }, [textoFiltro]);

  return (
    <>
      <div className="breadcrumb">
        <h6>ENCAMINHAMENTO DE USUÁRIOS</h6>
      </div>

      <div className="d-flex align-items-end row ml-3 mr-3">
        <Col md="12">
          <Row className="mr-1 mt-1 ">
            <Col md="12">
              <span>Instituição de Saúde</span>
              <ReactSelect
                classNamePrefix="react-select"
                className={'css-select-control'}
                options={instList}
                isMulti={false}
                placeholder="Selecione..."
                value={{
                  value: instituicaoSelecionada,
                  label:
                    instList.find((i) => i.value === instituicaoSelecionada)
                      ?.label || '',
                }}
                onChange={(option) => {
                  if (option.value) {
                    urlParams.set('inst', option.value);
                    setInstituicaoSelecionada(option.value);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col md="12" className="col-filter-cadastro-cliente-cliente">
              <Row className="mr-1 mt-1">
                <input
                  disabled={!instituicaoSelecionada}
                  className="form-control"
                  type="text"
                  id="cadastro-cliente-cliente"
                  placeholder={'Pesquisar...'}
                  value={textoFiltro}
                  onChange={(evento) => setTextoFiltro(changeInputText(evento))}
                />
              </Row>
            </Col>
          </div>
        </div>
      </div>

      {episodioAPI.state?.length > 0 && (
        <div className="table-list mt-4">
          <Table
            responsive
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>Paciente</th>
                <th>CPF</th>
                <th>Especialidade</th>
                <th>Data Encaminhamento</th>
                <th>Serviço</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!episodioAPI.error &&
                episodioSMSFilter.map((a, index) => {
                  return (
                    <tr key={index}>
                      <td>{a.nmUsuario}</td>
                      <td>{cpfMask(a.usuarioCPF)}</td>
                      <td>{a.epEspecialidade}</td>
                      <td>{dateTimeToScreen(a.eeData)}</td>
                      <td>{a.servico}</td>
                      <td>{a.epStTriagemMedicaTexto}</td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <a
                            className="btn-sm"
                            onClick={() => {
                              setEpisodio(a);
                            }}
                          >
                            <DocumentViewicon />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      )}

      {!episodioAPI.isLoading && !episodioAPI.state?.length && (
        <div className="mt-4 alert alert-warning">Nada encontrado</div>
      )}

      <Modal isOpen={!!episodio} size="xl">
        {episodio && (
          <PreServEncaminhamentoUsuarioAgenda
            episodio={episodio}
            onClose={(reload) => {
              setEpisodio(null);
              if (reload) {
                listarEpisodiosUsuario(instituicaoSelecionada);
              }
            }}
          />
        )}
      </Modal>

      {episodioAPI.isLoading && <Loading />}
    </>
  );
}
