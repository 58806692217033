import { Box, Checkbox, Spinner, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SelectAsync from 'react-select/async';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { Input } from 'components/form/input';
import { Select } from 'components/form/select';
import { Loading } from 'components/Loading';
import { useApiRequest, useUser } from 'hooks';
import { GrupoMenuType, useFetchEstadosCidades, useFetchOptionsFormPS, ValueLabelType } from 'hooks/useFetchData';
import { api } from 'services/api';
import { LOG } from 'shared/util';
import { colors } from 'theme/colors';

import { InstType, PSType } from '../../types';
import ModalProfLocalFila from './components/ModalProfLocalFila';
import { LocalSelecionadoFilaType, ValueLabelInstType } from './types';

import 'react-datepicker/dist/react-datepicker.css';

type MedicoExistente = Partial<{
  jaExistePS: boolean;
  nome: string;
  usuarioCPF: string;
  emailUsuario: string;
  nmUsuario: string;
  telefoneUsuario: string;
  usuarioCEP: string;
  usuarioCidade: string;
  usuarioEndereco: string;
  usuarioGenero: string;
  usuarioNasc: string;
  usuarioNumero: string;
  usuarioComplemento: string;
  usuarioUF: string;
  usuarioCargo: string;
  usuarioDepartamento: string;
  usuarioAtivo: number;
  dtCriacao: Date;
  dtModificacao: Date;
  grauParentesco: string | null;
  emailUsuarioFamiliar: string | null;
  usuarioBairro: string | null;
  usuarioFoto: string | null;
  usuarioRGfrente: string | null;
  usuarioRGverso: string | null;
  usuarioGeneroIdentidade: string | null;
  usuarioNomeSocial: string | null;
  usuarioOrientacao: string | null;
  usuarioCNS: string | null;
  usuarioCPFFamiliar: string | null;
  usuarioCorRaca: string | null;
  usuarioRG: string | null;
  usuarioFilial: string | null;
  usuarioSetor: string | null;
  psCPF: string | null;
}>;

type ModalPropsType = {
  isNew: boolean;
  onRequestClose: () => void;
  dataPS?: PSType;
};

const MySwal = withReactContent(Swal);

const requiredFieldText = 'Campo obrigatório';
const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required(requiredFieldText),
  especialidade: Yup.string().required(requiredFieldText),
  cpf: Yup.string().required(requiredFieldText).min(9, 'Formato inválido'),
  cnes: Yup.string(),
  nome: Yup.string()
    .min(8, 'Informe nome e sobrenome')
    .required(requiredFieldText),
  phone: Yup.string()
    .min(15, 'Informe um número válido')
    .required(requiredFieldText),
  rccOrgaoEmissor: Yup.string().required(requiredFieldText),
  regConsClasse: Yup.string(),

  ufRegistro: Yup.string(),
  cidadeRegistro: Yup.string(),
});

type InstSelectedType = ValueLabelType & { grupo?: ValueLabelType };

export const ModalForm = ({
  isNew = false,
  dataPS,
  onRequestClose,
}: ModalPropsType) => {
  const formMethods = useForm<PSType>({
    resolver: yupResolver(schema),
    defaultValues: dataPS,
  });
  const [fotoBase64, setFotoBase64] = useState<string>();
  const [checked, setChecked] = useState<any>();
  const user = useUser();
  const [disableEmail, setDisableEmail] = useState(false);
  const [open, setOpen] = useState(true);
  const [formError, setFormError] = useState(false);
  const [cpfInvalido, setCpfInvalido] = useState('');
  const [instsSelected, setInstsSelected] = useState<InstSelectedType[]>([]);
  const [locaisAtendimentoSelected, setLocaisAtendimentoSelected] = useState<
    LocalSelecionadoFilaType[]
  >([]);
  const [cbos, setCbos] = useState<ValueLabelType[]>([]);
  const [cboStartSelectOptions, setCboStartSelectOptions] = useState<
    ValueLabelType[]
  >([]);
  const { cidades, estados, getCidades, isFetchingEstadosCidades } =
    useFetchEstadosCidades();
  const [isLoading, setIsLoading] = useState(false);
  const [localSelected, setLocalSelected] = useState<LocalSelecionadoFilaType>({
    value: '',
    label: '',
    filas: [],
  });
  const [showModalProfLocalFile, setShowModalProfLocalFile] =
    useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const instHook = useApiRequest<Array<InstType>>('aswinstsaude', []);

  async function listarInst() {
    instHook.request({
      method: '_listIsUsuarioLogado',
    });
  }
  const toggleModalProfLocalFile = () => {
    setShowModalProfLocalFile(!showModalProfLocalFile);
  };

  const {
    especialidadeList,
    orgaoEmissorList,
    grupoMenuList,
    instList,
    isFetchingOptionsFormPS,
    locaisAtendimento,
  } = useFetchOptionsFormPS();

  const locaisAtendimentoList = locaisAtendimento.filter((localAtendimento) =>
    locaisAtendimentoSelected.every((i) => i.value !== localAtendimento.value)
  );

  const handleChangeCpf = async (event) => {
    try {
      const { value } = event.target;
      if (value.length >= 14) {
        setIsLoading(true);
        const { data } = await api.post('aswcheckdata', {
          method: '_psCPF',
          cpf: value,
        });

        if (data?.data?.cpfInvalido) {
          setCpfInvalido('CPF inválido');
          return;
        }

        if (data?.data?.jaExistePS) {
          setCpfInvalido('Usuário já cadastrado');
          return;
        }

        setCpfInvalido('');
        const medicoExistente = data.data as MedicoExistente;
        if (!medicoExistente?.jaExistePS && medicoExistente?.usuarioCPF) {
          formMethods.setValue('nome', medicoExistente?.nome || '');
          if (!!medicoExistente?.emailUsuario) {
            formMethods.setValue('email', medicoExistente.emailUsuario);
            setDisableEmail(true);
            return;
          }
        }

        if (isNew) {
          setDisableEmail(false);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestClose = () => {
    setOpen(false);
    setSelectedFile(null)
    setTimeout(onRequestClose, 500);
  };

  const handleFormSubmit = async (formData: PSType) => {
    LOG({ data: formData, instsSelected });
    setIsLoading(true);
    try {
      if (cpfInvalido == '') {
        if (
          instsSelected.length < 1 ||
          instsSelected.some((i) => !i.grupo)
          // ||
          // cbos.length < 1
        ) {
          setFormError(true);
          return;
        }

        const { data } = await api.post('aswprofissionalsaude', {
          method: isNew ? '_create' : '_update',
          ...formData,
          cbo: cbos,
          instituicoes: instsSelected,
          locaisAtendimento: locaisAtendimentoSelected,
          sgCliente: user.sgCliente,
          foto: {
            type: '@file/jpeg',
            image: fotoBase64,
          },
        });

        if (!isNew) {
          await api.post('aswprofissionalsaude', {
            method: '_disable',
            psCPF: formData.cpf,
            psAtivo: checked ? 0 : 1,
          });
        }
        MySwal.fire({
          title:
            data?.message ||
            (!!data?.success
              ? 'Cadastro efetuado com sucesso'
              : 'Não foi possível cadastar'),
          icon: !!data.success ? 'success' : 'warning',
        });
        if (!!data?.success) setTimeout(onRequestClose, 500);
      } else {
        MySwal.fire({
          title: 'CPF inválido!',
          icon: 'warning',
        });
      }
    } catch (err) {
      LOG('Catch handleFormSubmit()', err);
      MySwal.fire({
        title: 'Houve um erro ao tentar cadastar',
        icon: 'error',
      });
      // setTimeout(onRequestClose, 500);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listarInst();
  }, []);

  useEffect(() => {
    if (!isNew) {
      const ufRegistro = formMethods.getValues('ufRegistro');
      if (ufRegistro) {
        getCidades(ufRegistro);
      }

      setCbos(dataPS.cbo as unknown as ValueLabelType[]);
    }
  }, []);

  useEffect(() => {
    if (
      !isNew &&
      Array.isArray(dataPS?.instituicoes) &&
      dataPS?.instituicoes.length > 0
    ) {
      const selectedInst: InstSelectedType[] = dataPS?.instituicoes.map((i) => {
        return {
          label: i.label,
          value: i.value,
          grupo: {
            label: i.menuDesc,
            value: i.menu,
          },
        };
      });
      setInstsSelected(selectedInst);
      setChecked(dataPS.psAtivo === 1 ? false : true);
    }
  }, [grupoMenuList]);

  useEffect(() => {
    console.log('aqui!!!!!!');
    if (
      !isNew &&
      Array.isArray(dataPS?.locaisAtendimento) &&
      dataPS?.locaisAtendimento.length > 0
    ) {
      const locaisAtendimentoSelected: LocalSelecionadoFilaType[] =
        dataPS?.locaisAtendimento.map((i) => {
          return {
            label: i.label,
            value: i.value,
            filas: i.filas,
            instSaude: i.sgInstSaude,
          };
        });
      setLocaisAtendimentoSelected(locaisAtendimentoSelected);
    }
  }, [locaisAtendimento]);

  const selecionaInst = (inst) => {
    setInstsSelected((stateAntigo) => {
      if (stateAntigo.every((s) => s.value !== inst.sgInstSaude)) {
        return [
          ...stateAntigo,
          { value: inst.sgInstSaude, label: inst.instSaude },
        ];
      }
      return stateAntigo;
    });
  };

  const removeInstSelected = (inst: ValueLabelType) => {
    setInstsSelected((stateAntigo) => {
      return stateAntigo.filter((i) => i.value !== inst.value);
    });
  };

  const selecionaLA = (localAtendimento: ValueLabelType) => {
    setLocaisAtendimentoSelected((stateAntigo) => {
      if (stateAntigo.every((s) => s.value !== localAtendimento.value)) {
        return [...stateAntigo, localAtendimento];
      }
      return stateAntigo;
    });
  };

  const removeLA = (localAtendimento: ValueLabelType) => {
    setLocaisAtendimentoSelected((stateAntigo) => {
      return stateAntigo.filter((i) => i.value !== localAtendimento.value);
    });
  };

  const selecionaGrupoInst = (grupo: GrupoMenuType, inst: ValueLabelType) => {
    setInstsSelected((stateAntigo) => {
      return stateAntigo.map((i) => {
        if (i.value === inst.value) {
          i.grupo = grupo;
        }
        return i;
      });
    });
  };

  const onMenuCboOpen = async () => {
    if (!cboStartSelectOptions.length) {
      const { data } = await api.post('aswcbo', {
        method: '_list',
        page: 0,
        size: 100,
        sort: 'instSaude,asc',
      });
      if (data?.data && Array.isArray(data.data)) {
        setCboStartSelectOptions(
          data.data.map((p) => ({
            value: p.codigo,
            label: p.codigo + ' - ' + p.descricao,
          }))
        );
      }
    }
  };

  const loadOptionsCbo = async (inputValue, callback) => {
    const { data } = await api.post('aswcbo', {
      cbo: inputValue,
      method: '_list',
      page: 0,
      size: 100,
      sort: 'instSaude,asc',
    });
    if (data?.data && Array.isArray(data.data)) {
      callback(
        data.data.map((p: any) => ({
          ...p,
          value: p.codigo,
          label: p.codigo + ' - ' + p.descricao,
        }))
      );
    }
  };

  const handleModalProfLocalFilaClick = (localSelected: ValueLabelType) => {
    setLocalSelected(localSelected);
    toggleModalProfLocalFile();
  };

  const updateLocais = ({ label, value, filas }) => {
    toggleModalProfLocalFile();
    setLocaisAtendimentoSelected((prevState) => {
      return prevState.map((item) => {
        if (item.value === value) {
          item.filas = filas;
        }
        return item;
      });
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Quando a leitura do arquivo estiver concluída
        let base64Data = reader.result as string; // Cast para string

        const prefix = 'data:image/jpeg;base64,';
        if (base64Data.startsWith(prefix)) {
          base64Data = base64Data.substring(prefix.length);
        }
        setFotoBase64(base64Data);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeImage = async () => {
    const cpf = dataPS.cpf;
    formMethods.resetField('psFoto')

    if(dataPS?.usuarioFoto){
      setIsLoading(true);

      const { data } = await api.post('asmuser', {
        method: '_removeImage',
        psCPF: cpf,
      });

      if (data.success) {
        setIsLoading(false);
        setSelectedFile(null);
        onRequestClose();
      }
    }
  };


  return (
    <div>
      {console.log(instHook.state)}
      <Modal
        className="gerenciamento-profissional-saude-new-modal"
        size={'xl'}
        isOpen={open}
      >
        <ModalHeader toggle={handleRequestClose}>
          <div id="page-heading">
            <span className="page-header ml-3">
              {isNew ? (
                <span>CRIAR NOVO PROFISSIONAL SAÚDE</span>
              ) : (
                <span>EDITAR PROFISSIONAL SAÚDE</span>
              )}
            </span>
          </div>
        </ModalHeader>
        <div>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
              <ModalBody>
                <Row className="justify-content-center">
                  <Col md="11">
                    <div>
                      <Row className="row-gerenciamento-profissional-saude-first-column">
                        <Col md="4">
                          <Input name="nome" label="Nome*" />
                        </Col>
                        <Col md="4">
                          <Input
                            name="cpf"
                            label="ID Utente*"
                            placeholder="ID Utente"
                            disabled={!isNew}
                            onKeyUp={handleChangeCpf}
                          />
                          <div className="text-warning"> {cpfInvalido}</div>
                        </Col>
                        <Col md="4">
                          <Input name="cnes" label="CNES" />
                        </Col>
                        <Col md="4">
                          <Input
                            name="regConsClasse"
                            label="Registro Conselho de Classe"
                          />
                        </Col>
                        <Col md="4">
                          <Select
                            name={'rccOrgaoEmissor'}
                            label="Órgão Emissor*"
                            options={orgaoEmissorList}
                          />
                        </Col>
                        <Col md="4">
                          <Input
                            type="email"
                            name="email"
                            label="E-mail*"
                            disabled={!isNew || disableEmail}
                          />
                        </Col>
                        <Col md="4">
                          <Select
                            name={'ufRegistro'}
                            label="UF de Registro"
                            placeholder="Selecione..."
                            options={estados}
                            onChange={(estado: string) => {
                              getCidades(estado);
                              formMethods.resetField('cidadeRegistro', {
                                defaultValue: '',
                              });
                            }}
                            noOptionsMessage={() => 'Nada encontrado'}
                          />
                        </Col>
                        <Col md="4">
                          <Select
                            name={'cidadeRegistro'}
                            label="Cidade de Registro"
                            placeholder="Selecione..."
                            options={cidades}
                            isLoading={isFetchingEstadosCidades}
                            noOptionsMessage={() => 'Nada encontrado'}
                          />
                        </Col>
                        <Col md="4">
                          <Select
                            name={'especialidade'}
                            label="Especialidade*"
                            placeholder="Selecione..."
                            options={especialidadeList}
                            isLoading={isFetchingOptionsFormPS}
                            noOptionsMessage={() => 'Sem dados'}
                          />
                        </Col>
                        <Col md="4">
                          <Input name="phone" mask="phone" label="Telefone*" />
                        </Col>

                        <Col md="12">
                          <div>
                            <Row>
                              <Col md="12">
                                <Label
                                  className="mt-4 label-single-line"
                                  for="gerenciamento-profissional-saude-cbo"
                                >
                                  <span>CBO</span>
                                </Label>
                              </Col>
                              <Col md="12">
                                <SelectAsync
                                  placeholder="Selecione..."
                                  loadingMessage={() => 'Carregando...'}
                                  isMulti
                                  name={'cbo'}
                                  value={cbos}
                                  onChange={(options) => {
                                    setCbos(
                                      options as unknown as ValueLabelType[]
                                    );
                                  }}
                                  options={cboStartSelectOptions}
                                  defaultOptions={cboStartSelectOptions}
                                  noOptionsMessage={() => 'Sem dados'}
                                  onMenuOpen={onMenuCboOpen}
                                  loadOptions={loadOptionsCbo}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="row-gerenciamento-profissional-saude-cbo">
                        <Col md="12">
                          <div>
                            <Row>
                              <Col md="6" sm="12">
                                <Label className="mt-4 label-single-line">
                                  Instituições de Saúde*
                                </Label>
                                {isFetchingOptionsFormPS && (
                                  <>
                                    &nbsp;
                                    <Spinner size="xs" />
                                  </>
                                )}
                                <div className="kn-unselected_status__status___">
                                  Disponíveis
                                </div>
                                <div className={`kn-list__list___22Wuc`}>
                                  {instHook.state
                                    .filter((inst) =>
                                      instsSelected.every(
                                        (i) => i.value !== inst.sgInstSaude
                                      )
                                    )
                                    .map((item, key) => (
                                      <div
                                        onClick={() => {
                                          selecionaInst(item);
                                        }}
                                        key={key}
                                        className="kn-item__item___kQ2Ll kn-item__selected___3tRL6"
                                      >
                                        <div className="kn-item_label__label___2fJ5p">
                                          {item.instSaude} - {item.sgInstSaude}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </Col>

                              <Col md="6" sm="12">
                                <Label className="mt-4 label-single-line"></Label>
                                <div className="kn-unselected_status__status___">
                                  Selecionado*
                                </div>
                                <div className={''}>
                                  <>
                                    <Col md="12">
                                      {instsSelected?.map((inst, key) => {
                                        return (
                                          <div key={key} className="mb-4">
                                            <Row>
                                              <div>
                                                <span
                                                  style={{
                                                    color: colors.blue,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {inst.label}
                                                </span>
                                                <Button
                                                  onClick={() => {
                                                    removeInstSelected(inst);
                                                  }}
                                                  className="btn-sm"
                                                  color="warning"
                                                  style={{ float: 'right' }}
                                                >
                                                  X
                                                </Button>
                                              </div>
                                              <Col md="12">
                                                <Label className="label-single-line">
                                                  <span>
                                                    Perfil / Grupo vinculado
                                                  </span>
                                                </Label>
                                              </Col>
                                              <Col md="12">
                                                <SelectAsync
                                                  isMulti={false}
                                                  placeholder={'Selecione'}
                                                  className="css-select-control"
                                                  value={inst.grupo}
                                                  onChange={(value) =>
                                                    selecionaGrupoInst(
                                                      value,
                                                      inst
                                                    )
                                                  }
                                                  defaultOptions={grupoMenuList}
                                                  isLoading={
                                                    isFetchingOptionsFormPS
                                                  }
                                                  noOptionsMessage={() =>
                                                    'Nada encontrado'
                                                  }
                                                />
                                                {formError && !inst.grupo && (
                                                  <span className="text-warning">
                                                    <small>
                                                      Campo obrigatório
                                                    </small>
                                                  </span>
                                                )}
                                                {/* <br /> */}
                                              </Col>
                                              {instsSelected.includes(inst) && (
                                                <Row>
                                                  <Col md="6" sm="12">
                                                    <Label className="mt-4 label-single-line">
                                                      Locais de Atendimento
                                                    </Label>
                                                    {isFetchingOptionsFormPS && (
                                                      <>
                                                        &nbsp;
                                                        <i className="fas fa-spinner fa-spin fa-1x"></i>
                                                      </>
                                                    )}
                                                    <div className="kn-unselected_status__status___">
                                                      Disponíveis
                                                    </div>
                                                    <div
                                                      className={`kn-list__list___22Wuc`}
                                                    >
                                                      {locaisAtendimentoList
                                                        ?.filter(
                                                          (
                                                            p: ValueLabelInstType
                                                          ) =>
                                                            p.instSaude ==
                                                            inst.value
                                                        )
                                                        .map((item, key) => (
                                                          <div
                                                            onClick={() => {
                                                              selecionaLA(item);
                                                            }}
                                                            key={key}
                                                            className="kn-item__item___kQ2Ll kn-item__selected___3tRL6"
                                                          >
                                                            <div className="kn-item_label__label___2fJ5p">
                                                              {item.label}
                                                            </div>
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </Col>

                                                  <Col md="6" sm="12">
                                                    <Label className="mt-4 label-single-line">
                                                      Locais de Atendimento
                                                    </Label>
                                                    <div className="kn-unselected_status__status___">
                                                      Selecionado
                                                    </div>
                                                    <div
                                                      className={`kn-list__list___22Wuc`}
                                                    >
                                                      <>
                                                        <Col md="12">
                                                          {locaisAtendimentoSelected
                                                            ?.filter(
                                                              (
                                                                p: ValueLabelInstType
                                                              ) =>
                                                                p.instSaude ==
                                                                inst.value
                                                            )
                                                            .map(
                                                              (local, key) => {
                                                                return (
                                                                  <div
                                                                    key={key}
                                                                  >
                                                                    <Row>
                                                                      <div className="mt-2">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              colors.blue,
                                                                            fontWeight: 600,
                                                                          }}
                                                                        >
                                                                          {
                                                                            local.label
                                                                          }
                                                                        </span>
                                                                        <Button
                                                                          onClick={() => {
                                                                            removeLA(
                                                                              local
                                                                            );
                                                                          }}
                                                                          className="btn-sm"
                                                                          color="warning"
                                                                          style={{
                                                                            float:
                                                                              'right',
                                                                          }}
                                                                        >
                                                                          X
                                                                        </Button>
                                                                        <Button
                                                                          onClick={() =>
                                                                            handleModalProfLocalFilaClick(
                                                                              local
                                                                            )
                                                                          }
                                                                          className="btn-sm"
                                                                          color="info"
                                                                          style={{
                                                                            float:
                                                                              'right',
                                                                            marginRight: 5,
                                                                          }}
                                                                        >
                                                                          Filas
                                                                        </Button>
                                                                      </div>
                                                                    </Row>
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                        </Col>
                                                      </>
                                                    </div>
                                                    {formError &&
                                                      !instsSelected.length && (
                                                        <span className="text-warning">
                                                          <small>
                                                            Selecione
                                                            instituição de saúde
                                                          </small>
                                                        </span>
                                                      )}
                                                  </Col>
                                                </Row>
                                              )}
                                            </Row>
                                          </div>
                                        );
                                      })}
                                    </Col>
                                  </>
                                </div>
                                {formError && !instsSelected?.length && (
                                  <span className="text-warning">
                                    <small>
                                      Selecione instituição de saúde
                                    </small>
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Label
                            className="mt-4 label-single-line"
                            for="gerenciamento-profissional-saude-cbo"
                          >
                            <span>Foto do profissional (.jpg)</span>
                          </Label>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ width: '50%' }}>
                            <Input
                              name="psFoto"
                              type="file"
                              onChange={handleFileChange}
                            />
                          </div>
                          { (selectedFile || dataPS?.usuarioFoto) && (
                            <div style={{ marginLeft: '10px' }}>
                              <button onClick={removeImage}>
                                Remover Foto
                              </button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Box
                  display="flex"
                  alignItems="center"
                  w="full"
                  justifyContent="flex-end"
                >
                  {!isNew && (
                    <Box mr={8}>
                      <Checkbox
                        color="#5E5E5E"
                        isChecked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      >
                        Desativar profissional
                      </Checkbox>
                      <Text fontSize="10px" color="#5E5E5E">
                        {
                          '(ao selecionar esta opção, o profissional não será exibido como opção de agendamento)'
                        }
                      </Text>
                    </Box>
                  )}
                  <Button
                    color="primary"
                    type="submit"
                    className="float-right"
                    disabled={isLoading}
                  >
                    {isNew ? 'Cadastrar Profissional' : 'Confirmar Alterações'}{' '}
                    {isLoading && (
                      <>
                        &nbsp;
                        <i className="fas fa-spinner fa-spin fa-1x"></i>
                      </>
                    )}
                  </Button>
                </Box>
              </ModalFooter>
            </form>
          </FormProvider>
        </div>
      </Modal>
      <ModalProfLocalFila
        showModalForm={showModalProfLocalFile}
        toggle={toggleModalProfLocalFile}
        localSelected={localSelected}
        updateLocais={updateLocais}
      />
    </div>
  );
};
