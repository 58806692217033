import { Badge, Button, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useState } from 'react';
import { Table } from 'reactstrap';

import { Loading } from 'components/Loading';
import { useUser } from 'hooks';
import atendimento from 'pages/atendimento';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY, dateTimeToServer } from 'shared/util';
import { calcularIdade } from 'shared/util/calcularIdade';
import { formatDate } from 'shared/util/formatDateAmPm';

import { Pagination } from '../../../../components/pagination';
import cancelIcon from '../../assets/cancelIcon.svg';
import remarcarIcon from '../../assets/remarcarIcon.svg';
import reportIcon from '../../assets/reportIcon.svg';
import ModalCancelarAgendamento from '../modais/cancelarAgendamento';

type TabelaRecepcaoProps = {
  agendamentos: Array<{
    nmUsuario: string;
    usuarioCPF: string;
    emailUsuario: string;
    usuarioNasc: string;
    usuarioGenero: string;
    agDataHoraAgenda: string;
    psNome: string;
    peDescricao: string;
    localAtendimento: string;
    codigo: string;
    tipo: string;
    psCPF: string;
  }>;
  loadAgendamentos: (status?: string) => Promise<void>;
  status: string;
};

export const TabelaRecepcao = ({
  agendamentos,
  loadAgendamentos,
  status,
}: TabelaRecepcaoProps) => {
  const [loading, setLoading] = useState(false);
  const [todayDate, setTodayDate] = useState(dateTimeToServer(new Date()));
  const recepcaoUser = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [atendimentoSelecionado, setAgendamentoSelecionado] = useState({});
  const [action, setAction] = useState('');
  const toast = useToast();
  //página
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = agendamentos
    ? agendamentos.slice(indexOfFirstPost, indexOfLastPost)
    : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openModal = (atendimento: any) => {
    setAgendamentoSelecionado(atendimento);
    onOpen();
  };
  const closeModal = async () => {
    onClose();
  };


  return (
    <div className="table-responsive">
      {loading && <Loading />}
      <>
        <ModalCancelarAgendamento
          isOpen={isOpen}
          onClose={closeModal}
          agendamentos={atendimentoSelecionado}
          status={status}
          action={action}
          loadAgendamentos={loadAgendamentos}
        />
        {agendamentos && agendamentos.length > 0 ? (
          <div id="atendimento-table-list" className="table-list">
            <Table
              id="atendimento-table-list"
              responsive
              aria-describedby="atendimento-heading"
              className={
                'table-hover table-striped table-responsive-css td-atendimentos'
              }
            >
              <thead className={'thead-light'}>
                <tr>
                  <th>Data e horário de atendimento</th>
                  <th>Profissional</th>
                  <th>Especialidade</th>
                  <th>Utente</th>
                  <th>Telefone</th>
                  <th>ID Utente</th>
                  <th>Idade</th>
                  <th>Sexo</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">{status !== 'Canceladas' ? 'Cancelar' : 'Motivo'}</th>
                  {status !== 'Canceladas' && <th className="text-center">Reagendar</th>}
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((atendimento: any, i: number) => (
                  <tr key={`entity-${i}`}>
                    <td>{formatDate(atendimento.agDataHoraAgenda)}</td>

                    <td id="profissional">{atendimento.psNome}</td>
                    <td id="especialidade">{atendimento.peDescricao}</td>
                    <td id="paciente-cell">{atendimento.nmUsuario}</td>
                    <td id="paciente-cell">{atendimento.telefoneUsuario}</td>
                    <td id="CPF">{atendimento.usuarioCPF}</td>
                    <td id="Idade" style={{ textAlign: 'center' }}>
                      {calcularIdade(atendimento.usuarioNasc)}
                    </td>
                    <td id="genero">{atendimento.usuarioGenero}</td>

                    <td style={{ textAlign: 'center' }}>
                      {status === 'Solicitadas' && (
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          borderRadius="15px"
                          id="btn-comparecimento"
                          onClick={() => {
                            setAction('confirm');
                            openModal(atendimento);
                          }}
                        >
                          Confirmar
                        </Button>
                      )}
                      {status === 'Confirmadas' && (
                        <Button
                          bgColor="rgba(198, 246, 213, 1)"
                          borderRadius="15px"
                          id="btn-comparecimento"
                          fontWeight="normal"
                          disabled={true}
                        >
                          Confirmada
                        </Button>
                      )}
                      {status === 'Canceladas' && (
                        <Button
                          bgColor="rgba(246, 198, 198, 1)"
                          borderRadius="15px"
                          id="btn-comparecimento"
                          fontWeight="normal"
                          disabled={true}
                        >
                          Cancelada
                        </Button>
                      )}
                    </td>
                    <td>
                      {status != 'Canceladas' ? (
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAction('cancel');
                              openModal(atendimento);
                            }}
                          >
                            <img
                              src={cancelIcon}
                              alt="cancelar"
                              title="Cancelar"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                          title={`Motivo: ${atendimento.tipoMotivo}`}
                        >
                          <img src={reportIcon} alt="cancelar" />
                        </div>
                      )}
                    </td>
                    {status !== 'Canceladas' && (
                      <td>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAction('remarcar');
                              openModal(atendimento);
                            }}
                          >
                            <img
                              src={remarcarIcon}
                              alt="remarcar"
                              title="Remarcar"
                            />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {agendamentos.length > 10 && (
              <div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={agendamentos.length}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="alert alert-warning">
            Não foram encontrados atendimentos
          </div>
        )}
      </>
    </div>
  );
};
