import { useEffect, useState } from 'react';
import { NavLink as Link, useHistory } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';

import { Logo } from 'components/icons';
import { MenuItem } from 'components/menu-item';
import { NavDropdown } from 'components/nav-dropdown';
import { useUser } from 'hooks';
import { AUTH_USER_DATA, AUTH_USER_ROUTES } from 'shared/util';

import { HeaderContainer } from './styles';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isLogin: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  onLocaleChange: (langKey: string) => void;
}

export const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const toggleMenu = () => {
    const { innerWidth: width } = window;
    if (width < 1400) {
      setMenuOpen(!menuOpen);
    }
  };

  const [dadosUsuario, setDadosUsuario] = useState(null);
  const [dadosRoutes, setDadosRoutes] = useState(null);

  const user = useUser();

  const handleLogout = () => {
    user?.handleLogout();
  };

  useEffect(() => {
    if (localStorage.getItem(AUTH_USER_DATA)) {
      setDadosUsuario(JSON.parse(localStorage.getItem(AUTH_USER_DATA)));
    }
    if (localStorage.getItem(AUTH_USER_ROUTES)) {
      setDadosRoutes(JSON.parse(localStorage.getItem(AUTH_USER_ROUTES)));
    }
  }, [props]);

  if (!props.isLogin && dadosUsuario && dadosRoutes) {
    return (
      <HeaderContainer>
        <Navbar data-cy="navbar" expand="xxl" fixed="top">
          <Container align="center">
            <Link to="/">
              <Logo />
            </Link>

            <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
            <Collapse navbar isOpen={menuOpen}>
              <Nav navbar>
                {/* {dadosRoutes['cadastro-telemedicina'] && (
                  <NavbarBrand tag={Link} to="/cadastro-telemedicina">
                    <span>Telemedicinas</span>
                  </NavbarBrand>
                )} */}
                {dadosRoutes['cadastro-cliente'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/cadastro-cliente"
                    onClick={toggleMenu}
                  >
                    <span>Clientes e Instituição de Saúde</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['cadastro-administradores'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/cadastro-administradores"
                    onClick={toggleMenu}
                  >
                    <span>Administradores</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['grupo-menu'] && (
                  <NavbarBrand tag={Link} to="/grupo-menu" onClick={toggleMenu}>
                    <span>Grupos</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['perfil-menu'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/perfil-menu"
                    onClick={toggleMenu}
                  >
                    <span>Perfis</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['menu-rotas'] && (
                  <NavbarBrand tag={Link} to="/menu-rotas" onClick={toggleMenu}>
                    <span>Rotas</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['perfil-menu-rotas'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/perfil-menu-rotas"
                    onClick={toggleMenu}
                  >
                    <span>Perfil + Grupo + Rotas</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['dashboard-roche'] && (
                  <NavDropdown
                    icon={''}
                    name={'Dashboard-Roche'}
                    onClick={toggleMenu}
                  >
                    {dadosRoutes['dash-roche'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/dash-roche">Geral</MenuItem>
                      </div>
                    )}
                    {dadosRoutes['dash-rocheqt'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/dash-rocheqt">Quantitativo</MenuItem>
                      </div>
                    )}
                  </NavDropdown>
                )}
                {dadosRoutes['dashboard'] && (
                  <NavDropdown
                    icon={''}
                    name={'Dashboard'}
                    onClick={toggleMenu}
                  >
                    {dadosRoutes['dashboard'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/dashboard">Geral</MenuItem>
                      </div>
                    )}
                    {dadosRoutes['dashboard-telemedicina'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/dashboard-telemedicina">
                          Telemedicina
                        </MenuItem>
                      </div>
                    )}
                    {/* {dadosRoutes['dashboard-sindrome-gripal'] && (
                      <MenuItem to="/dashboard-sindrome-gripal">
                        Síndrome Gripal
                      </MenuItem>
                    )} */}
                  </NavDropdown>
                )}
                {/* {dadosRoutes['liberacao-agendamento'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/liberacao-agendamento"
                    onClick={toggleMenu}
                  >
                    <span>Autorização de Consultas</span>
                  </NavbarBrand>
                )} */}
                {dadosRoutes['gerenciamento-profissional-saude'] && (
                  <NavDropdown
                    // tag={Link}
                    // to="/gerenciamento-profissional-saude"
                    name={'Profissional de Saúde'}
                    onClick={toggleMenu}
                  >
                    {dadosRoutes['gerenciamento-profissional-saude'] && (
                      <>
                        <div onClick={toggleMenu}>
                          <MenuItem to="/gerenciamento-profissional-saude">
                            Gerenciamento Profissional de Saúde
                          </MenuItem>
                        </div>
                        <div onClick={toggleMenu}>
                          <MenuItem to="/disponibilidade-agenda">
                            Disponibilidade de Agendas
                          </MenuItem>
                        </div>
                        <div onClick={toggleMenu}>
                          <MenuItem to="/gerenciamento-agenda-exame">
                          Gerenciamento Agenda de Exames
                          </MenuItem>
                        </div>
                      </>
                    )}
                  </NavDropdown>
                )}

                {(dadosRoutes?.['cadastro-paciente'] ||
                  dadosRoutes?.['agendar-paciente']) && (
                  <NavDropdown icon={''} name={'Utente'} onClick={toggleMenu}>
                    {dadosRoutes['cadastro-paciente'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/cadastro-paciente">Cadastro</MenuItem>
                      </div>
                    )}
                    {dadosRoutes['agendar-paciente'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/agendar-paciente">Agenda</MenuItem>
                      </div>
                    )}
                    {/* {dadosRoutes['elegibilidade-paciente'] && (
                      <div onClick={toggleMenu}>
                        <MenuItem to="/elegibilidade-paciente">
                          Elegibilidade Paciente
                        </MenuItem>
                      </div>
                    )} */}
                  </NavDropdown>
                )}

                {/* TODO: VERFIFICAR POR ROTA, NO CASO OU UMA OU OUTRA NOS NÓS */}
                {(dadosRoutes?.['gestao-de-agenda-telessaude'] ||
                  dadosRoutes?.['gestao-de-agenda-presencial']) && (
                  <NavDropdown
                    icon={''}
                    name={'Gestão de agenda'}
                    onClick={toggleMenu}
                  >
                    <>
                      {dadosRoutes?.['gestao-de-agenda-telessaude'] && (
                        <div onClick={toggleMenu}>
                          <MenuItem to="/gestao-de-agenda-telessaude">
                            <div>Telessaúde</div>
                          </MenuItem>
                        </div>
                      )}
                      {dadosRoutes['gestao-de-agenda-presencial'] && (
                        <div onClick={toggleMenu}>
                          <MenuItem to="/gestao-de-agenda-presencial">
                            <div>Presencial</div>
                          </MenuItem>
                        </div>
                      )}
                    </>
                  </NavDropdown>
                )}

                {/* ACABA AQUI */}
                {dadosRoutes['historico-atendimento'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/historico-atendimento"
                    onClick={toggleMenu}
                  >
                    <span>Histórico de Atendimento</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['atendimento'] && (
                  <NavDropdown name={'Atendimento'}>
                    <div>
                      <MenuItem to="/atendimento-telessaude">
                        <div>Telessaúde</div>
                      </MenuItem>
                    </div>
                    <div>
                      <MenuItem to="/atendimento-presencial">
                        <div>Presencial</div>
                      </MenuItem>
                    </div>
                  </NavDropdown>
                )}

                {dadosRoutes['local-atendimento'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/local-atendimento"
                    onClick={toggleMenu}
                  >
                    <span>Locais de Atendimento</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['filas-atendimento'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/filas-atendimento"
                    onClick={toggleMenu}
                  >
                    <span>Filas de Atendimento</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['atendimento-triagem'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/atendimento-triagem"
                    onClick={toggleMenu}
                  >
                    <span>Atendimento Triagem</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['atendimento-recepcao'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/atendimento-recepcao"
                    onClick={toggleMenu}
                  >
                    <span>Recepção</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['fale-conosco'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/fale-conosco"
                    onClick={toggleMenu}
                  >
                    <span>Fale Conosco</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['gestao-agendamentos-geral'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/gestao-agendamentos-geral"
                    onClick={toggleMenu}
                  >
                    <span>Agendamentos</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['sms-cadastro-paciente'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/sms-cadastro-paciente"
                    onClick={toggleMenu}
                  >
                    <span>Cadastro de Usuário</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['sms-cadastro-episodio'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/sms-cadastro-episodio"
                    onClick={toggleMenu}
                  >
                    <span>Cadastro de Episódio</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['sms-pa-autorizacao-paciente'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/sms-pa-autorizacao-paciente"
                    onClick={toggleMenu}
                  >
                    <span>Autorização de Usuários</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['cmo-triagem-medica'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/cmo-triagem-medica"
                    onClick={toggleMenu}
                  >
                    <span>Triagem Médica</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['pre-serv-encaminhamento-paciente'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/pre-serv-encaminhamento-paciente"
                    onClick={toggleMenu}
                  >
                    <span>Encaminhamento de Usuários</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['sms-consulta-paciente'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/sms-consulta-paciente"
                    onClick={toggleMenu}
                  >
                    <span>Consulta de Usuário</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['especialidade-profissional'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/especialidade-profissional"
                    onClick={toggleMenu}
                  >
                    <span>Especialidades</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['atendimento-compartilhado'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/atendimento-compartilhado"
                    onClick={toggleMenu}
                  >
                    <span>Atendimento Emergencial</span>
                  </NavbarBrand>
                )}
                {dadosRoutes['atendimento medical center'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/medical-center-atendimentos"
                    onClick={toggleMenu}
                  >
                    <span> Centro Médico</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['consulta-agendamento'] && (
                  <NavbarBrand
                    tag={Link}
                    to="/consulta-agendamento"
                    onClick={toggleMenu}
                  >
                    <span>Consulta de Agendamentos</span>
                  </NavbarBrand>
                )}

                {dadosRoutes['gestao-questionarios'] && (
                  <NavDropdown name={'Questionario'}>
                    <div>
                      <MenuItem to="/gestao-questionarios">
                        <div>Gestão questionário</div>
                      </MenuItem>
                    </div>
                    <div>
                      <MenuItem to="/gestao-campanhas">
                        <div>Gestão campanha</div>
                      </MenuItem>
                    </div>
                  </NavDropdown>
                )}

                <NavDropdown
                  icon={'fa-user-circle'}
                  name={dadosUsuario['nome']}
                >
                  <div
                    aria-current="page"
                    className="dropdown-item"
                    style={{ cursor: 'pointer' }}
                    onClick={handleLogout}
                  >
                    Sair
                  </div>
                </NavDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </HeaderContainer>
    );
  }
  return (
    <HeaderContainer>
      <Navbar data-cy="navbar" expand="xxl" fixed="top">
        <Container id="app-header">
          <Logo />
        </Container>
      </Navbar>
    </HeaderContainer>
  );
};
