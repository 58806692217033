import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: gray;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  #main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #videos {
    width: 100%;
    height: 100%;
    float: left;
  }

  #subscriber {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    z-index: 10;
  }

  #publisher {
    position: absolute;
    width: 40vmin;
    height: 20vmin;
    top: 5px;
    right: 5px;
    z-index: 100;
    border: none;
    min-width: 100px;
    min-height: 100px;
    border-radius: 15px;
  }

  #publisher .OT_mirrored {
    border-radius: 10px;
  }

  #textchat {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f7f7f7;
  }

  #history {
    width: 100%;
    height: calc(100% - 114px);
    overflow: auto;
  }

  input#msgTxt {
    height: 30px;
    width: 100%;
  }

  #history .mine {
    color: black;
    text-align: right;
    margin-right: 10px;
  }

  #history .theirs {
    color: blue;
    margin-left: 10px;
  }

  .Embed-control-container {
    opacity: 1;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 2;
  }

  .Embed-control-container {
    width: 100%;
    z-index: 100;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease 2s;
    -moz-transition: opacity 0.5s ease 2s;
    transition: opacity 0.5s ease 2s;
  }

  .Embed-control-container .ots-video-control {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .Embed-control-container .ots-video-control,
  .Embed-control-subscriber-container .ots-video-control {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    background-position: center;
    background-color: #fff;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .Embed-control-container .ots-video-control.leave-meeting-red {
    background-image: url(/assets/icons/EndVector.svg);
  }

  .ots-video-control.leave-meeting-red.entrar {
    background-image: url(/assets/icons/InitVector.svg);
  }

  .Embed-control-container .ots-video-control.audio {
    background-image: url(/assets/icons/icon-mic.svg);
  }

  .ots-video-control.audio.muted {
    background-image: url(/assets/icons/icon-muted-mic.svg);
  }

  .Embed-control-container .ots-video-control.video,
  .Embed-control-subscriber-container .ots-video-control.video {
    background-image: url(/assets/icons/icon-video.svg);
  }

  .ots-video-control.video.muted {
    background-image: url(/assets/icons/icon-no-video.svg);
  }

  .ots-video-control.switch {
    background-image: url(/assets/icons/icon-switch-camera.svg);
  }

  .Embed-control-container .ots-video-control.chat,
  .Embed-control-subscriber-container .ots-video-control.chat {
    background-image: url(/assets/icons/ChatVector.svg);
  }

  .Embed-control-container .ots-video-control.record,
  .Embed-control-subscriber-container .ots-video-control.record {
    background-image: url(/assets/icons/record.png);
    background-size: contain;
  }

  .Embed-control-container .ots-video-control.stop,
  .Embed-control-subscriber-container .ots-video-control.stop {
    background-image: url(/assets/icons/stop.png);
    background-size: contain;
  }

  #cabec {
    background-color: #333;
    color: white;
    z-index: 110;
  }

  #cabec p {
    margin: 0;
    padding: 16px;
  }

  #subscriber video.OT_video-element {
    object-fit: contain;
  }

  main {
    flex: 1;
    display: flex;
  }

  aside input,
  button {
    font-size: inherit;
    padding: 0.5em;
  }

  aside {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    min-width: 20em;
    width: 25%;
    height: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
    transition: transform 0.5s ease;
  }

  aside.active {
    z-index: 111;
    transform: translateX(0);
  }

  .chat-header {
    background-color: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chat-form {
    min-height: 57px;

    input {
      width: 100%;
    }
  }

  .chat-aviso {
    background-color: green !important;
  }

  #aguardo {
    text-align: center;
    width: 100%;
  }
  #desconectado {
    text-align: center;
    width: 100%;
  }

  .oculto {
    display: none;
  }
`;

export const Content = styled.div``;
