import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Heading,
  Switch,
  useDisclosure
} from '@chakra-ui/react';
import { Select } from 'components/crud/Select';
import { useApiRequest, useUser } from 'hooks';
import { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { IoIosArrowForward } from 'react-icons/io';
import { Link as ReactLink } from 'react-router-dom';

import { ICampanhaDTO } from './dtos/IGestaoCampanha.dto';

import { ConfirmarExclusaoModal } from 'components/confirmar-exclusao';
import { api } from 'services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from 'theme/colors';
import { Input } from '../../../components/crud/Input';
import { Table } from '../../../components/crud/Table';
import {
  ActionComponents,
  HandleActionProps,
  TableParamsProps
} from '../../../components/crud/Table/types';
import { ModalCrud } from './components/modal-crud';

const GestaoCampanhas = () => {
  const user = useUser();
  const MySwal = withReactContent(Swal);
  const dataCampanhaHook = useApiRequest('aswquestionario', []);
  const [acoes, setAcoes] = useState([]);
  const [instituicao, setInstituicao] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [originParams, setOriginParams] = useState(null);
  const [inputFilter, setInputFilter] = useState('');
  const [showDeletarModal, setShowDeletarModal] = useState<boolean>(false);
  const [qcIDDelete, setQcIDDelete] = useState<string>('');
  const tableParams: TableParamsProps[] = [
    {
      header: 'Nome da campanha',
      key: 'qcNome',
    },
    {
      header: 'Descrição',
      key: 'qcDescricao',
    },
    {
      header: 'Ponto de ação',
      key: 'acao',
    },
  ];

  const actionComponents: ActionComponents[] = [
    {
      component: Switch,
      type: 'switch',
      action: 'switch-ativar',
      header: 'Ativa',
      props: { isReadOnly: true },
    },
    {
      component: FiEdit,
      type: 'icon',
      action: 'icon-edit',
      header: 'Editar',
      props: {
        size: 25,
        color: colors.blue,
      },
    },
    {
      component: BsTrash,
      type: 'icon',
      action: 'icon-remover-campanha',
      header: 'Remover',
      props: {
        size: 25,
        color: colors.gray_2,
      },
    },
  ];

  const buscarAcoes = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'listQuizActions',
    });

    let acoeslist = [];
    data.data.map((element) => {
      acoeslist.push({
        value: element.qaID,
        option: element.qaDescricao,
      });
    });
    setAcoes(acoeslist);
  };
  useEffect(() => {
    buscarAcoes();
  }, []);

  const deleteCampanha = async () => {
    const { data } = await api.post('aswquestionario', {
      method: 'deleteCampaign',
      qcID: qcIDDelete,
    });
    if (data.success == true) {
      MySwal.fire({
        title: data?.message || 'Apagado com sucesso!',
        icon: 'success',
      });
      handleGetCampanha();
    } else {
      MySwal.fire({
        title: data?.message || 'Houve um erro ao tentar apagar!',
        icon: 'error',
      });
    }
  };

  const handlerAction = async (props: HandleActionProps<ICampanhaDTO>) => {
    if (props.action === 'icon-edit') {
      setOriginParams(props.dataLn);
      onOpen();
    }
    if (props.action === 'icon-remover-campanha') {
      setQcIDDelete(props.dataLn.qcID);
      setShowDeletarModal(true);
    }

    if (props.action == 'switch-ativar') {
      await api
        .post('aswquestionario', {
          method: 'updateCampaing',
          qcAtiva: props.value == true ? 1 : 0,
          qcID: props.dataLn.qcID,
          qcNome: props.dataLn.qcNome,
          qcDescricao: props.dataLn.qcDescricao,
          qcRespQuestObrigatoria:
            props.dataLn.qcRespQuestObrigatoria == true ? 1 : 0,
          qaID: props.dataLn.qaID,
          qtID: props.dataLn.qtID,
        })
        .then(() => {
          handleGetCampanha();
        });
    }
  };

  const handleGetCampanha = async () => {
    if (instituicao) {
      setIsLoading(true);
      await dataCampanhaHook.request({
        method: 'listCampaigns',
        sgCliente: user.sgCliente,
        sgInstSaude: instituicao,
      });
    }
    setIsLoading(false);
  };

  const filterCapanhas =
    inputFilter.length > 0
      ? dataCampanhaHook.state?.filter((campanha) =>
        campanha.qcNome?.toUpperCase().includes(inputFilter.toUpperCase())
      )
      : dataCampanhaHook.state;

  useEffect(() => {
    handleGetCampanha();
  }, [instituicao]);

  return (
    <Flex gap="2rem" justifyContent="" flexDirection="column">
      <ModalCrud
        isOpen={isOpen}
        onClose={onClose}
        configParams={{ instituicao }}
        originParams={originParams}
        instituicao={instituicao}
        handlerChange={async () => {
          handleGetCampanha();
        }}
      />
      <Breadcrumb spacing="8px" separator={<IoIosArrowForward />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={ReactLink} to="/gestao-questionario">
            Campanhas
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider color={colors.gray_blue} />
      <Flex gap="2rem" justifyContent="space-between" flexDirection="row">
        <Heading as="h3" size="md">
          Campanha:
        </Heading>
        {!!instituicao && (
          <Button
            variant="outline"
            border="1px"
            color={colors.blue}
            borderColor={colors.blue}
            _hover={{ bg: colors.blue, color: '#fff' }}
            _active={{ bg: colors.textBlue }}
            type="submit"
            onClick={() => {
              setOriginParams(null);
              onOpen();
            }}
          >
            Cadastrar campanha
          </Button>
        )}
      </Flex>
      <Flex gap="1rem" justifyContent="space-between" flexDirection="column">
        <Select
          data={user.institucoes?.map((param) => ({
            value: param.institucao,
            option: param.institucaoNome,
          }))}
          placeholder="Selecione a instituição de saúde"
          handlerChange={(value) => setInstituicao(value)}
        />
        <Input
          queryDeelay={1000}
          placeholder="Digite sua pesquisa aqui"
          handlerChange={(e) => setInputFilter(e)}
        />
      </Flex>
      {instituicao && (
        <Table
          isLoading={isLoading}
          data={filterCapanhas}
          tableParams={tableParams}
          actionComponents={actionComponents}
          handlerAction={handlerAction}
        />
      )}

      {showDeletarModal && (
        <ConfirmarExclusaoModal
          close={(value: boolean) => {
            setShowDeletarModal(value);
          }}
          confirmar={() => {
            deleteCampanha();
          }}
        />
      )}
    </Flex>
  );
};

export { GestaoCampanhas };

