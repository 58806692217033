/* eslint no-console: off */

import zGlobal from './z-global.json';
import global from './global.json';
import agendamentoConsultas from './agendamentoConsultas.json';
import atendimento from './atendimento.json';
import cadastroAdministradores from './cadastroAdministradores.json';
import cadastroCliente from './cadastroCliente.json';
import cadastroInstituicao from './cadastroInstituicao.json';
import cadastroTelemedicina from './cadastroTelemedicina.json';
import cadastroUsuario from './cadastroUsuario.json';
import dashboard from './dashboard.json';
import detalhesLiberacaoAgendamento from './detalhesLiberacaoAgendamento.json';
import documentosExames from './documentosExames.json';
import gerenciamentoProfissionalSaude from './gerenciamentoProfissionalSaude.json';
import gestaoDeAgenda from './gestaoDeAgenda.json';
import grupoMenu from './grupoMenu.json';
import grupoMenuPerfilMenu from './grupoMenuPerfilMenu.json';
import historicoDeAtendimentos from './historicoDeAtendimentos.json';
import liberacaoAgendamento from './liberacaoAgendamento.json';
import mensagemAtendimentoFinalizado from './mensagemAtendimentoFinalizado.json';
import menuRotas from './menuRotas.json';
import perfilMenu from './perfilMenu.json';
import perfilMenuRotas from './perfilMenuRotas.json';
import periodoSemAtividade from './periodoSemAtividade.json';
import profissionalSaude from './profissionalSaude.json';

const i18n: any = {
  //  ...zGlobal,
  // ..global
};
const fillArray = (transactions: any, prefix = '') => {
  for (const key in transactions) {
    if (Object.prototype.hasOwnProperty.call(transactions, key)) {
      const element = transactions[key];
      if (typeof transactions[key] === 'object') {
        fillArray(transactions[key], prefix + key + '.');
      } else {
        i18n[prefix + key] = transactions[key];
      }
    }
  }
};

export default function text() {
  fillArray(global);
  fillArray(agendamentoConsultas);
  fillArray(atendimento);
  fillArray(cadastroAdministradores);
  fillArray(cadastroCliente);
  fillArray(cadastroInstituicao);
  fillArray(cadastroTelemedicina);
  fillArray(cadastroUsuario);
  fillArray(dashboard);
  fillArray(detalhesLiberacaoAgendamento);
  fillArray(documentosExames);
  fillArray(gerenciamentoProfissionalSaude);
  fillArray(gestaoDeAgenda);
  fillArray(grupoMenu);
  fillArray(grupoMenuPerfilMenu);
  fillArray(historicoDeAtendimentos);
  fillArray(liberacaoAgendamento);
  fillArray(mensagemAtendimentoFinalizado);
  fillArray(menuRotas);
  fillArray(perfilMenu);
  fillArray(perfilMenuRotas);
  fillArray(periodoSemAtividade);
  fillArray(profissionalSaude);
  fillArray(zGlobal);
  return i18n;
}
