import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { LocalAtendimentoType } from '../pages';
import ModalPrintPreview from './components/modalPrintPreview';

interface IPropsModalQrCode {
  showModal: boolean;
  toggle: () => void;
  localAtendimentoSelected: LocalAtendimentoType | null;
}

export default function ModalQrCode({
  showModal,
  toggle,
  localAtendimentoSelected,
}: IPropsModalQrCode) {
  const [showModalPrintPreview, setShowModalPrintPreview] =
    useState<boolean>(false);
  const [formato, setFormato] = useState<string>(null);

  const handleModalPrintPreviewModalClick = (formato: string) => {
    setFormato(formato);
    togglePrintPreviewModal();
  };

  const togglePrintPreviewModal = () => {
    setShowModalPrintPreview(!showModalPrintPreview);
  };

  const handleOpenModal = () => {
    console.log(localAtendimentoSelected);
  };

  return (
    <Modal
      size={'md'}
      isOpen={showModal}
      toggle={toggle}
      onOpened={handleOpenModal}
      centered
    >
      <ModalHeader toggle={toggle}>
        Favor selecionar o tamanho desejado
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md="10">
            <div className="d-grid gap-2">
              <Button
                onClick={() => handleModalPrintPreviewModalClick('A4')}
                className="btn btn-primary text-center justify-content-center"
                type="button"
              >
                Grande (Folha A4)
              </Button>
              <Button
                onClick={() => handleModalPrintPreviewModalClick('A5')}
                className="btn btn-primary justify-content-center"
                type="button"
              >
                Pequeno (Folha A5)
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter></ModalFooter>
      <ModalPrintPreview
        showModal={showModalPrintPreview}
        localAtendimentoSelected={localAtendimentoSelected}
        toggle={togglePrintPreviewModal}
        formato={formato}
      />
    </Modal>
  );
}
