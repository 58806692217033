import { useEffect, useState } from 'react';
import { useApiRequest, useUser } from 'hooks';
import ReactSelect from 'react-select';
import { Button, Col, Row, Table } from 'reactstrap';
import { changeInputText, dateToScreen, LOG } from 'shared/util';
import { useHistory } from 'react-router-dom';
import { DocumentViewicon } from 'components/icons';
import { Loading } from 'components/Loading';
import { IEpisodioUsuarioLista } from 'types/episodio';
import cpfMask from 'shared/util/cpfMask';

function SmsPAAutorizacaoPaciente() {
  const user = useUser();
  const history = useHistory();
  const [episodioSMSFilter, setEpisodioSMSFilter] = useState<
    IEpisodioUsuarioLista[]
  >([]);
  const [instituicaoSelecionada, setInstituicaoSelecionada] =
    useState<string>('');

  const [textoFiltro, setTextoFiltro] = useState<string>('');

  const episodioAPI = useApiRequest<IEpisodioUsuarioLista[]>(
    'aswepisodiosms',
    []
  );

  const listarEpisodiosUsuario = async (sgInstSaude: string) => {
    await episodioAPI.request({
      method: 'listarEpisodiosSMS',
      sgCliente: user?.sgCliente,
      sgInstSaude,
      epStTriagemMedica: ['P'],
    });
  };

  const instList = (user?.institucoes || []).map((i) => ({
    ...i,
    value: i.institucao,
    label: i.institucaoNome,
  }));

  useEffect(() => {
    if (instituicaoSelecionada) {
      listarEpisodiosUsuario(instituicaoSelecionada);
    }
  }, [instituicaoSelecionada]);

  useEffect(() => {
    setEpisodioSMSFilter(episodioAPI.state);
  }, [episodioAPI.state]);

  useEffect(() => {
    if (textoFiltro.trim()) {
      const filtered = episodioAPI.state.filter((consulta) => {
        return Object.values(consulta).some(
          (key) => String(key).toLowerCase().includes(textoFiltro.toLowerCase())
          // ||
          // String(dateTimeToScreen(new Date(key))).includes(textoFiltro)
        );
      });
      setEpisodioSMSFilter(filtered);
    } else {
      setEpisodioSMSFilter(episodioAPI.state);
    }
  }, [textoFiltro]);

  return (
    <>
      <div className="breadcrumb">
        <h6>AUTORIZAÇÃO DE USUÁRIOS</h6>
      </div>

      <div className="d-flex align-items-end row ml-3 mr-3">
        <Col md="12">
          <Row className="mr-1 mt-1 ">
            <Col md="12">
              <span>Instituição de Saúde</span>
              <ReactSelect
                classNamePrefix="react-select"
                className={'css-select-control'}
                options={instList}
                isMulti={false}
                placeholder="Selecione..."
                onChange={(option) => {
                  if (option.value) {
                    setInstituicaoSelecionada(option.value);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <div id="form-filter">
          <div className="row mt-1 ml-3 mr-3">
            <Col md="12" className="col-filter-cadastro-cliente-cliente">
              <Row className="mr-1 mt-1">
                <input
                  disabled={!instituicaoSelecionada}
                  className="form-control"
                  type="text"
                  id="cadastro-cliente-cliente"
                  placeholder={'Pesquisar...'}
                  value={textoFiltro}
                  onChange={(evento) => setTextoFiltro(changeInputText(evento))}
                />
              </Row>
            </Col>
          </div>
        </div>
      </div>

      {episodioAPI.state?.length > 0 && (
        <div className="table-list mt-4">
          <Table
            responsive
            className={'table-hover table-striped table-responsive-css'}
          >
            <thead className={'thead-light'}>
              <tr>
                <th>Paciente</th>
                <th>CPF</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!episodioAPI.error &&
                episodioSMSFilter.map((a) => {
                  return (
                    <tr key={a.usuarioCPF + a.nmUsuario}>
                      <td>{a.nmUsuario}</td>
                      <td>{cpfMask(a.usuarioCPF)}</td>
                      <td>
                        <div className="btn-group flex-btn-group-container">
                          <a
                            className="btn-sm"
                            onClick={() => {
                              history.push(
                                `${history.location.pathname}/${a.usuarioCPF}/perfil-completo`
                              );
                            }}
                          >
                            <DocumentViewicon />
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      )}
      {!episodioAPI.isLoading && !episodioAPI.state?.length && (
        <div className="mt-4 alert alert-warning">Nada encontrado</div>
      )}
      {episodioAPI.isLoading && <Loading />}
    </>
  );
}

export default SmsPAAutorizacaoPaciente;
