import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { useConsultaContext } from 'contexts/Consulta'
import { AlergiasType, DoencasType, MedicamUsoType } from 'contexts/Consulta/types'
import { useAlert, useApiRequest } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs'
import { DateInputForm, DateInputFormRefProps } from '../date-input-form'

type ButtonTextProps = {
  type: 'confirm' | 'cancel'
}

const ButtonText = ({ type }: ButtonTextProps) => (
  <>
    {' '}
    <i className={type === 'cancel' ? 'fa fa-ban' : 'fa fa-trash'} />{' '}
    {type === 'cancel' ? 'Cancelar' : 'Apagar'}{' '}
  </>
)

export const Prontuario = () => {

  const alert = useAlert()
  const { consulta: dadosConsulta } = useConsultaContext()
  const [teste, setTeste] = useState([])

  const prontdoencasApi = useApiRequest<DoencasType[]>(
    'aswusuprontdoencas',
    []
  )
  const prontmedicamusoApi = useApiRequest<MedicamUsoType[]>(
    'aswusuprontmedicamuso',
    []
  )
  const prontalergiasApi = useApiRequest<AlergiasType[]>(
    'aswusuprontalergias',
    []
  )
  const loadProntuario = () => {
    if (dadosConsulta != undefined) {
      prontdoencasApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
      prontmedicamusoApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
      prontalergiasApi.request({
        method: '_list',
        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
      });
    }
  }

  useEffect(() => {
    loadProntuario();
  }, [dadosConsulta]);

  const doencaInputRef = useRef<DateInputFormRefProps>(null)
  const medicamInputRef = useRef<DateInputFormRefProps>(null)
  const prontalergiasRef = useRef<DateInputFormRefProps>(null)

  const actionModal = (
    title: string,
    text: string,
    onConfirm: () => Promise<void>
  ) => {
    alert
      .warning({
        title: title,
        html: text,
        showCancelButton: true,
        cancelButtonText: <ButtonText type="cancel" />,
        confirmButtonText: <ButtonText type="confirm" />,
      })
      .then(async (response) => {
        if (response.isConfirmed) {
          await onConfirm()
          alert.success({
            title: 'Apagado com successo',
          })
        }
      })
  }

  return (
    <Box>
      <Box>
        <Box>
          <Box>
            <Flex width={'100%'} alignItems={'center'}>
              <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
                Doenças Prévias{' '}
                {prontdoencasApi.isLoading && <Spinner size="sm" />}
              </Text>
              <Button
                variant={'outline'}
                leftIcon={<BsPlusCircleFill />}
                border={'none'}
                color={'blue.400'}
                _hover={{
                  bg: 'transparent'
                }}
                _focus={{
                  outline: 'none'
                }}
                onClick={() => {
                  doencaInputRef?.current?.open()
                }}
              >
                Adicionar
              </Button>

            </Flex>
          </Box>
          <Box>
            <div className="form-control">
              {prontdoencasApi.state?.map((doenca, index) => {
                return (
                  <DateInputForm
                    originalData={doenca}
                    key={doenca.pdGUID}
                    data={doenca.pdData}
                    descricao={doenca.pdDescricao}
                    onDelete={({ originalData }) => {
                      actionModal(
                        'Doença prévia',
                        `Excluir ${originalData.pdDescricao}?`,
                        async () => {
                          await prontdoencasApi.request({
                            method: '_delete',
                            usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                            id: originalData.pdGUID,
                          })
                        }
                      )
                    }}
                    onUpdate={async ({ data, descricao, originalData }) => {
                      await prontdoencasApi.request({
                        method: '_update',
                        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                        data,
                        descricao,
                        id: originalData.pdGUID,
                      })
                    }}
                  />
                )
              })}

              <DateInputForm
                ref={doencaInputRef}
                onSave={async ({ data, descricao }) => {
                  await prontdoencasApi.request({
                    method: '_insert',
                    usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                    data,
                    descricao,
                  })
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Flex alignItems={'center'}>
            <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
              Medicamentos em Uso
              {prontmedicamusoApi.isLoading && <Spinner size="sm" />}
            </Text>
            <Button
              variant={'outline'}
              leftIcon={<BsPlusCircleFill />}
              border={'none'}
              color={'blue.400'}
              _hover={{
                bg: 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              onClick={() => {
                medicamInputRef.current.open()
              }}
            >
              Adicionar
            </Button>

          </Flex>
          <Box>
            <div className="form-control">
              {prontmedicamusoApi.state?.map((medicamento, i) => {
                return (
                  <DateInputForm
                    originalData={medicamento}
                    key={medicamento.muGUID}
                    data={medicamento.ppData}
                    descricao={medicamento.muDescricao}
                    onDelete={({ originalData }) => {
                      actionModal(
                        'Medicamento em uso',
                        `Excluir ${originalData.muDescricao} ?`,
                        async () => {
                          await prontmedicamusoApi.request({
                            method: '_delete',
                            usuarioCPF:
                              dadosConsulta.dadosConsulta.usuarioCPF,
                            id: originalData.muGUID,
                          })
                        }
                      )
                    }}
                    onUpdate={async ({ data, descricao, originalData }) => {
                      prontmedicamusoApi.request({
                        method: '_update',
                        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                        data,
                        descricao,
                        id: originalData.muGUID,
                      })
                    }}
                  />
                )
              })}
              <DateInputForm
                ref={medicamInputRef}
                onSave={async ({ data, descricao }) => {
                  await prontmedicamusoApi.request({
                    method: '_insert',
                    usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                    data,
                    descricao,
                  })
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Flex alignItems={'center'}>
            <Text flex={'1'} fontSize={'md'} fontWeight={'normal'} color={'gray.600'}>
              Alergias
              {prontalergiasApi.isLoading && <Spinner size="sm" />}
            </Text>
            <Button
              variant={'outline'}
              leftIcon={<BsPlusCircleFill />}
              border={'none'}
              color={'blue.400'}
              _hover={{
                bg: 'transparent'
              }}
              _focus={{
                outline: 'none'
              }}
              onClick={() => {
                prontalergiasRef.current.open()
              }}
            >
              Adicionar
            </Button>
          </Flex>
          <Box>
            <div className="form-control">
              {prontalergiasApi.state?.map((item, i) => {
                return (
                  <DateInputForm
                    originalData={item}
                    key={item.paGUID}
                    data={item.paData}
                    descricao={item.paDescricao}
                    onDelete={({ originalData }) => {
                      actionModal(
                        'Alergia',
                        `Excluir ${originalData.paDescricao}?`,
                        async () => {
                          await prontalergiasApi.request({
                            method: '_delete',
                            usuarioCPF:
                              dadosConsulta.dadosConsulta.usuarioCPF,
                            id: originalData.paGUID,
                          })
                        }
                      )
                    }}
                    onUpdate={async ({ data, descricao, originalData }) => {
                      prontalergiasApi.request({
                        method: '_update',
                        usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                        data,
                        descricao,
                        id: originalData.paGUID,
                      })
                    }}
                  />
                )
              })}
              <DateInputForm
                ref={prontalergiasRef}
                onSave={async ({ data, descricao }) => {
                  await prontalergiasApi.request({
                    method: '_insert',
                    usuarioCPF: dadosConsulta.dadosConsulta.usuarioCPF,
                    data,
                    descricao,
                  })
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
