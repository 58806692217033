import { Box, Button, CloseButton, Grid, GridItem, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, PinInput, PinInputField, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Input } from 'components/form/input';
import { Select } from 'components/form/select';
import { Loading } from 'components/Loading';
import { api } from 'services/api';

import checkIcon from '../assets/checkIcon.svg';

interface ModalNovoUtenteProps {
  isNew: boolean;
  isOpen: boolean;
  onClose: () => void;
  dataToEdit?: any;
  loadUtentes?: () => void;
}

interface CreateUtente {
  emailUsuario: string;
  nmUsuario: string;
  telefoneUsuario: string;
  usuarioGenero: string;
  usuarioNasc: string;
}

export const ModalNovoUtente = ({
  isNew,
  isOpen,
  onClose,
  dataToEdit,
  loadUtentes,
}: ModalNovoUtenteProps) => {
  const [loading, setLoading] = useState(false);
  const [codigo, setCodigo] = useState<string>();
  const toast = useToast();
  const requiredFieldMessage = 'Campo obrigatório';

  const generos = [
    {
      value: 'Masculino',
      label: 'Masculino',
    },
    {
      value: 'Feminino',
      label: 'Feminino',
    },
  ];

  const getSchema = () =>
    yup.object().shape({
      nmUsuario: yup.string().required(requiredFieldMessage),
      emailUsuario: yup.string().email().required(requiredFieldMessage),
      telefoneUsuario: yup.string().required(requiredFieldMessage),
      usuarioGenero: yup.string().required(requiredFieldMessage),
      usuarioNasc: yup.string().required(requiredFieldMessage),
    });

  const formMethods = useForm<CreateUtente>({
    resolver: yupResolver(getSchema()),
  });

  const handleSubmit = async () => {
    const values = formMethods.getValues();
    try {
      setLoading(true);
      const { data } = await api.post('asmuser', {
        method: isNew ? '_novoUtente' : '_atualizarUtente',
        ...values,
      });

      if (data.success) {
        setLoading(false);
        toast({
          title: `Utente ${isNew ? 'cadastrado' : 'atualizado'} com sucesso!`,
          status: 'success',
        });
      } else {
        setLoading(false);
        toast({ title: data.message, status: 'warning' });
      }
    } catch (e) {
      console.log(e)
      setLoading(false);
      toast({
        title: 'Não foi possível cadastrar o utente',
        description: 'Entre em contato com o suporte!',
        status: 'error',
      });
    }
  };

  const onCloseEditModal = () => {
    formMethods.reset();
    // setChecked(true)
    // loadUsuarios();
    setCodigo(null);
    onClose();
  };

  useEffect(() => {
    const loadDataToEdit = () => {
      formMethods.setValue('nmUsuario', dataToEdit?.nmUsuario);
      formMethods.setValue('emailUsuario', dataToEdit?.emailUsuario);
      formMethods.setValue('telefoneUsuario', dataToEdit?.telefoneUsuario);
      formMethods.setValue('usuarioGenero', dataToEdit?.usuarioGenero);
      formMethods.setValue('usuarioNasc', dataToEdit?.usuarioNasc);
    };
    if (!isNew && isOpen) {
      loadDataToEdit();
    }
  }, [dataToEdit, isNew, isOpen]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onCloseEditModal}
        motionPreset="slideInBottom"
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent tabIndex={150}>
          <ModalHeader display="flex" justifyContent="space-between">
            <Text fontWeight="semibold">
              {isNew ? 'CADASTRAR ' : 'ATUALIZAR'} UTENTE
            </Text>
            <Grid templateColumns="repeat(5, 1fr)" gap={1}>
              <GridItem colStart={6} colEnd={6} h="10">
                <CloseButton size="sm" onClick={() => onCloseEditModal()} />
              </GridItem>
            </Grid>
          </ModalHeader>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
              <ModalBody mb={3}>
                {!codigo && (
                  <Box>
                    <Box>
                      <Text p={1} color="#9C9C9C">
                        Nome completo
                      </Text>

                      <Box>
                        <Input
                          name="nmUsuario"
                          placeholder="Nome Completo"
                          // onBlur={loadUser}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <Text mt={3} p={1} color="#9C9C9C">
                        E-mail
                      </Text>

                      <Box>
                        <Input
                          name="emailUsuario"
                          placeholder="E-mail"
                          type="email"
                          // onBlur={loadUser}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Text mt={3} p={1} color="#9C9C9C">
                      Telefone
                      </Text>

                      <Box>
                        <Input
                          name="telefoneUsuario"
                          placeholder="Telefone"
                          // onBlur={loadUser}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Text mt={3} p={1} color="#9C9C9C">
                        Sexo
                      </Text>

                      <Box>
                        <Select
                          name="usuarioGenero"
                          placeholder="Sexo"
                          options={generos}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Text mt={3} p={1} color="#9C9C9C">
                        Data de nascimento
                      </Text>

                      <Box>
                        <Input
                          name="usuarioNasc"
                          placeholder="Data de nascimento"
                          type="date"
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {codigo && (
                  <Box>
                    <Box display="flex" justifyContent="center" mb={7}>
                      <Text fontSize="24px">Utente cadastrado com sucesso</Text>
                    </Box>

                    <Box display="flex" justifyContent="center" mb={7}>
                      <img src={checkIcon} alt="sucesso" />
                    </Box>
                    <Box display="flex" justifyContent="center" mb={3}>
                      <Text fontSize="20px">ID Utente</Text>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <HStack>
                        <PinInput
                          defaultValue={String(codigo)}
                          isDisabled
                          size="lg"
                        >
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                          <PinInputField />
                        </PinInput>
                      </HStack>
                    </Box>
                  </Box>
                )}
              </ModalBody>
              <ModalFooter bgColor="#F5F5F5" p={4}>
                {!codigo && (
                  <Button
                    type="submit"
                    bgColor="#002953"
                    color="white"
                    fontWeight="semibold"
                    _hover={{ bgColor: '#00366be6' }}
                    onClick={() => handleSubmit}
                  >
                    Salvar
                  </Button>
                )}
                {codigo && (
                  <Button
                    bgColor="#002953"
                    color="white"
                    fontWeight="semibold"
                    _hover={{ bgColor: '#00366be6' }}
                    onClick={() => onCloseEditModal()}
                  >
                    Fechar
                  </Button>
                )}
              </ModalFooter>
            </form>
          </FormProvider>
          {loading && <Loading />}
        </ModalContent>
      </Modal>
    </div>
  );
};
