import { useApiRequest, useUser } from 'hooks';
import { ChangeEvent, useState } from 'react';
import {
  Button, Input,
  Label,
  Modal, ModalFooter, ModalHeader
} from 'reactstrap';
import Col from 'reactstrap/es/Col';
import cpfMask from 'shared/util/cpfMask';
import isValidCpf from 'shared/util/validacao-cpf';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
type TypePaciente = {
  nmUsuario: string;
  emailUsuario: string;
  sgCliente: string;
  telefoneUsuario: string;
  usuarioCEP: string;
  usuarioCNS: string;
  usuarioCPF: string;
  usuarioCidade: string;
  usuarioEndereco: string;
  usuarioGenero: string;
  usuarioInstSaudePrefer: string;
  usuarioNasc: string;
  usuarioNomeSocial: string;
  usuarioNumero: string;
  usuarioComplemento: string;
  usuarioPais: string;
  usuarioUF: string;
  dtCriacao: string;
  dtModificacao: string;
  usuarioBairro: null;
  usuarioCargo: null;
  dadosConvenio: {
    sgCliente: string;
    convenio: string;
    plano: string;
    instSaude: string;
    usuarioCPF: string;
    numeroCartao: string;
    cartaoData?: string;
  };
};

export const Modalcpf = ({
  isOpen,
  onClose,
  encerrarAtendimento,
}: {
  isOpen: boolean;
  onClose: () => void;
  encerrarAtendimento: (cpf?: string) => void;
}) => {
  const MySwal = withReactContent(Swal);
  const [cpf, setCpf] = useState('');
  const [cpfError, setCpfError] = useState('');
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const user = useUser();
  const userApi = useApiRequest<TypePaciente>(
    'aswusuarios',
    {} as TypePaciente
  );

  const verificaCPF = async (value: string) => {
    setCpfError('');
    userApi.submit({
      method: '_findByCpf',
      sgCliente: user?.sgCliente,
      cpf: value,
    });

  };
  const validarCPF = async (value: string) => {
    if (isValidCpf(value.match(/\d/g).join('')) == false) {
      setCpfInvalido(true);
      return true;
    } else {
      setCpfInvalido(false);
      return false;
    }
  };
  const handleChangeCpf = (event: ChangeEvent<HTMLInputElement>) => {
    setCpfError('');
    const { value } = event.target;
    let formatted = cpfMask(value);
    setCpf(formatted);

    if (formatted.length >= 14) {
      if (validarCPF(formatted)) {
        verificaCPF(formatted);
      }
    }
  };

  const handleEncerrar = () => {
    encerrarAtendimento(cpf);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} toggle={() => onClose()} size="lg">
      <ModalHeader toggle={() => onClose()}>
        <Label>Informar CPF do Paciente</Label>
      </ModalHeader>

      <Col style={{ marginLeft: '1rem', marginRight: '1rem' }}>
        <Label htmlFor="">Informe o CPF do paciente</Label>
        <Input
          value={cpf}
          onChange={handleChangeCpf}
          id="usuario-cpf"
          name="cpf"
          className={'form-control'}
          mask="999.999.999-99"
          alwaysShowMask={true}
        />
      </Col>
      <ModalFooter className="border-top">
        <Button
          type="submit"
          color="primary"
          className="btn-outline-primary"
          onClick={() => handleEncerrar()}
        >
          Encerrar atendimento
        </Button>
      </ModalFooter>
    </Modal>
  );
};
