import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ConsultaProvider } from 'contexts/Consulta';
import { AppointmentTabs } from 'contexts/Consulta/components/appointment-tabs';

type Props = {
  idConsulta: string;
  close: () => void;
  nomeUsuario: string;
  usuarioNomeSocial:string;
  eHistorico?: boolean;
};

function ModaltrigemComponent({ close, nomeUsuario, usuarioNomeSocial }: Props) {
  return (
    <Modal isOpen size={'xl'}>
      <ModalHeader toggle={() => close()}>
        <p><strong>Informações sobre o paciente:</strong> {nomeUsuario}</p>
        <p><strong>Nome social:</strong> {usuarioNomeSocial}</p>
     
    
      </ModalHeader>
      <ModalBody>
        <AppointmentTabs />
      </ModalBody>
      <ModalFooter>
        <Button mr={3} onClick={close}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export const Modaltrigem = (props: Props) => (
  <ConsultaProvider
  {...props}
  idConsulta={props.idConsulta}
  eHistorico={props.eHistorico}
  >
    <ModaltrigemComponent {...props} />
  </ConsultaProvider>
);
