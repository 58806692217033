import React from 'react';
import { useApiRequest, useUser } from 'hooks';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';

import { RightIcon } from 'components/icons';

import { FormUsuarioSMS } from 'components/formUsuarioSMS';
import { SMSPacienteType } from 'components/formUsuarioSMS/types';
import * as S from './styles';

function SmsPAAutorizacaoPacientePerfilCompleto() {
  const user = useUser();
  const history = useHistory();
  const { cpf } = useParams<{ cpf: string }>();

  const pacienteApi = useApiRequest<any>('aswusuarios', {} as any);

  useEffect(() => {
    if (!!cpf && !!user?.cpf) {
      pacienteApi.request({
        cpf,
        method: '_findByCpf',
        sgCliente: user?.sgCliente,
      });
    }
  }, [, cpf, user]);

  if (!cpf) {
    // Tem de tratar
    return <>Nada encontrado!</>;
  }

  return (
    <S.Container>
      <S.Row>
        <div>
          <Button color="primary" onClick={history.goBack}>
            <i className="fa fa-arrow-left" />
          </Button>
          <b className="ms-2 fw-bold">
            <span style={{ textTransform: 'uppercase' }}>
              AUTORIZAÇÃO DE USUÁRIOS <RightIcon width={20} />
              {pacienteApi?.state?.nmUsuario}
              <RightIcon width={20} /> Perfil Completo
            </span>
          </b>
        </div>
        <div></div>
      </S.Row>

      {pacienteApi?.state?.usuarioCPF && (
        <FormUsuarioSMS onlyView perfil="sms-pa" />
      )}
    </S.Container>
  );
}

export default SmsPAAutorizacaoPacientePerfilCompleto;
