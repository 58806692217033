import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { InputRadio } from 'components/form/radio';
import { Loading } from 'components/Loading';
import { api } from 'services/api';
import { AUTH_TOKEN_KEY } from 'shared/util';

import personIncon from '../../assets/personIcon.svg';
import pinIcon from '../../assets/pinIcon.svg';
import professionalIcon from '../../assets/professionalIcon.svg';
import specialityIcon from '../../assets/specialityIcon.svg';
import timeIcon from '../../assets/timeIcon.svg';
import CancelarOuConfirmar from './modalConfirmarOuCancelar';
import RemarcarAgendamento from './remarcarAgendamento';

interface cancelarAgendamentoProps {
  agendamentos: any;
  isOpen: boolean;
  onClose: () => void;
  status: string;
  action: string;
  loadAgendamentos: (status?: string) => Promise<void>;
}
const ModalCancelarAgendamento = ({
  agendamentos,
  isOpen,
  onClose,
  status,
  action,
  loadAgendamentos,
}: cancelarAgendamentoProps) => {
  const [motivos, setMotivos] = useState([]);
  const formMethods = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  return (
    <>
      {action != 'remarcar' && (
        <CancelarOuConfirmar
          action={action}
          agendamentos={agendamentos}
          isOpen={isOpen}
          loadAgendamentos={loadAgendamentos}
          onClose={onClose}
          status={status}
        />
      )}

      {action === 'remarcar' && (
        <RemarcarAgendamento
          action={action}
          agendamentos={agendamentos}
          isOpen={isOpen}
          loadAgendamentos={loadAgendamentos}
          onClose={onClose}
          status={status}
        />
      )}
    </>
  );
};

export default ModalCancelarAgendamento;
